import React, { useState} from 'react'
import "../../styles/vendor/vendor-crypto-withdrawal-modal.scss"
import {CloseIcon, LeftArrowIcon,CryptoDepositIcon } from '../icons/index'


const VendorCryptoWithdrawalModal = (props) => {
    const{controlModal,modal,closeAll}=props

    const [walletAddress, setWalletAddress] = useState('');
    const [memo, setMemo] = useState('');
    const [amount, setAmount] = useState('');



  return (
    <>
{modal &&    (<div className="vendor-crypto-withdraw-modal">
    <div onClick={controlModal} className="vendor--crypto-withdraw-overlay"></div>
    <div className='vendor-crypto-withdrawal-case'>
      <div className="vendor-crypto-withdraw-head">
      <span className='left-arrow' onClick={controlModal}>
          <LeftArrowIcon/>
      </span>
          <h3 className='vendor-crypto-withdraw-heading'>
                Crypto (USDC)
          </h3>

      <span onClick={()=>{
            controlModal()
            closeAll()
            }
        }
      >
          <CloseIcon
              color="#D13D2A"
          />
      </span>
      </div>
            <span className='vendor-bar-code'>
                <CryptoDepositIcon />
            </span>


        <div className='vendor-crypto-withdraw-holder-address'>
            <span className='vendor-left-section-withdraw'>
                <h3 className='vendor-wallet-address-title'>
                    Wallet Address                  
                </h3>
                <p className='vendor-crypto-withdraw-address'>
                    <input
                        type="text"
                        className="vendor-input-text"
                        value={walletAddress}
                        onChange={(e)=>setWalletAddress(e.target.value)}
                        placeholder='E.g: GBH4TZYZ4IRCPO44CBOLFUHULU2WGALXTAVESQA6432MBJMABBB4GIYI'
                    />
                </p>              
             </span> 
            </div>

            <div className='vendor-crypto-withdraw-holder-address'>
            <span className='vendor-left-section-withdraw'>
                <h3 className='vendor-wallet-address-title'>
                    Memo                
                </h3>
                <p className='vendor-crypto-withdraw-address'>
                    <input
                        type="text"
                        className="vendor-input-text"
                        value={memo}
                        onChange={(e)=>setMemo(e.target.value)}
                        placeholder='E.g GBH4TZYZ4IRCPO44CBOLFUHULU2WGALXTAVESQA6432MBJMA'
                    />
                </p>
             </span> 
            </div>

            <div className='vendor-crypto-withdraw-holder-address'>
            <span className='vendor-left-section-withdraw'>
                <h3 className='vendor-wallet-address-title'>
                    Amount                
                </h3>
                <p className='vendor-crypto-withdraw-address'>
                    <input
                        type="text"
                        className="vendor-input-text"
                        value={amount}
                        onChange={(e)=>setAmount(e.target.value)}
                        placeholder='E.g  300,000'
                    />
                </p>
             </span> 
            </div>
            
           

            <div className='vendor-crypto-withdraw-btn-case'>
               <button className='vendor-crypto-withdraw-btn'>
                        Confirm Payment
               </button>

               <p className='vendor-page-statement'>
                    Your withdrawal amount will be 
                    deducted from your Quidroo balance
               </p>
           </div>
        </div>
    </div>)}
    </>
  )
}

export default VendorCryptoWithdrawalModal;