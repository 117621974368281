import React from "react";
import "../../../../styles/admin/admin-financial/admin-financial-card.scss";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { TotalCommissionIcon } from "../../../icons";

const TotalCommissionCard = ({ invoices, percent }) => {
  return (
    <div
      className="admin-financial-card-container"
      style={{ background: "#E5AA33" }}
    >
      <div className="stat-svg-container">
        <div className="stat-top-container">
          <div className="first-part">
            <span className="top-section">
              <TotalCommissionIcon />
            </span>
            <span className="bottom-section">
              <h3 className="header-text">Total Commission</h3>
              <h3 className="digit">₦{invoices}</h3>
            </span>
          </div>
          <div className="second-part">
            <span className="progress-bar-holder">
              <CircularProgressbarWithChildren
                value={percent}
                minValue={0}
                maxValue={100}
                styles={{
                  root: {
                    width: "100%",
                  },
                  path: {
                    stroke: `#E5AA33`,
                    strokeLinecap: "butt",
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    transform: "rotate(1turn)",
                    transformOrigin: "center center",
                  },
                  trail: {
                    stroke: "#F3F3F3",
                    strokeLinecap: "butt",
                    transform: "rotate(1turn)",
                    transformOrigin: "center center",
                  },
                }}
              >
                <div className="text-case">
                  <h5
                    style={{ color: "#E5AA33" }}
                    className="progress-bar-header"
                  >
                    AVG Rate
                  </h5>
                  <p className="percent-digit">{percent}%</p>
                </div>
              </CircularProgressbarWithChildren>
            </span>
          </div>
        </div>
        <div>
          <p>{/* Invoice:  */}</p>
        </div>
      </div>
    </div>
  );
};

export default TotalCommissionCard;
