import { Link } from "react-router-dom";

export const SessionExpired = ({ isAdmin }) => (
  <div className="session-container">
    <h1>Your session has timed out.</h1>
    <p>
      Click <Link to={isAdmin ? "/admin" : "/"}>here</Link> to log in again
    </p>
  </div>
);
