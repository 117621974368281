import { CloseIcon } from "../../icons/index";
import "../../../styles/seller/dashboard/transaction-modal-card.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";

export const TransactionModalCard = (props) => {
  const {
    onClose,
    modal,
    title,
    amount,
    date,
    time,
    content,
    account,
    isDebit,
    loading,
    currency,
  } = props;

  return (
    <AnimatePresence>
      {modal && (
        <FadeTransition>
          <div className="modal">
            <div onClick={onClose} className="overlay">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="transaction-modal-card"
                onClick={(evt) => evt.stopPropagation()}
                transition={{ type: "spring" }}
              >
                {loading ? (
                  "Loading..."
                ) : (
                  <>
                    <div className="transaction-modal-head">
                      <h3 className="trans-modal-header">
                        Transaction details
                      </h3>
                      <span onClick={onClose} className="trans-modal-presser">
                        <CloseIcon />
                      </span>
                    </div>
                    <div className="transaction-modal-upper">
                      <div className="trans-modal-upper-left">
                        <h5 className="trans-upper-top-header">
                          Transaction Name
                        </h5>
                        <h3 className="trans-upper-bottom-title">{title}</h3>
                      </div>
                      <div className="trans-modal-upper-right">
                        <h5 className="trans-upper-top-header">
                          Invoice Price
                        </h5>
                        <h3
                          className={`trans-upper-bottom-amount ${
                            isDebit && "debit"
                          }`}
                        >
                          {`${currency === "NGN" ? "₦" : "$"}${amount}`}
                        </h3>
                      </div>
                    </div>
                    <div className="transaction-modal-middle">
                      <div className="trans-modal-middle-left">
                        <h5 className="trans-upper-top-header">
                          Transaction date
                        </h5>
                        <h3 className="trans-middle-bottom-date">{date}</h3>
                      </div>
                      <div className="trans-modal-middle-right">
                        <h5 className="trans-upper-top-header">
                          Transaction time
                        </h5>
                        <h3 className="trans-middle-bottom-time">{time}</h3>
                      </div>
                    </div>
                    <div className="transaction-modal-bottom">
                      <div className="trans-modal-bottom-left">
                        <h5 className="trans-upper-top-header">
                          Transaction reference
                        </h5>
                        <h3 className="trans-bottom-down-reference">
                          {content}
                        </h3>
                      </div>
                      {/* <div className="trans-modal-bottom-right">
                        <h5 className="trans-upper-top-header">
                          Recepient account
                        </h5>
                        <h3 className="trans-lower-bottom-account">
                          {account}
                        </h3>
                      </div> */}
                    </div>
                    {(title?.includes("Wallet") ||
                      title?.includes("Sentit")) && (
                      <a
                        className="view-tx"
                        href={`https://stellarchain.io/tx/${content}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Transaction
                      </a>
                    )}
                  </>
                )}
              </motion.div>
            </div>
          </div>
        </FadeTransition>
      )}
    </AnimatePresence>
  );
};
