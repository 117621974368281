/**
 * validateBid checks to see if string is  valid email.
 *
 * @param email
 */
import { formatAmount } from "../formatAmount";
export const validateBid = (bid, maxPayable) => {
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));
  // console.log("bid", bid)
  // console.log("maxpl", maxPayable)
  const numberBid = parseFloat(bid);
  const maxPayabl = parseFloat(maxPayable);

  if (!bid.length) {
    return {
      isValid: false,
      error: "Please, enter a bid",
    };
  }

  if (isNaN(numberBid) && !isFinite(bid)) {
    return {
      isValid: false,
      error: "Please, enter a valid number",
    };
  }

  if (numberBid < maxPayabl ) {
    
    return {
      isValid: false,
      error:  `Sorry, your bid can't be less than ${userCurrency ==="naira" ? `₦`:`$`}${formatAmount(maxPayable)}`,
    };
  }

  return {
    isValid: true,
  };
};

// export const validateBid = (bid, roi) => {
//   const numberBid = parseFloat(bid);
//   if (!bid.length) {
//     return {
//       isValid: false,
//       error: "Please, enter a bid",
//     };
//   }

//   if (isNaN(numberBid) && !isFinite(bid)) {
//     return {
//       isValid: false,
//       error: "Please, enter a valid number",
//     };
//   }

//   if (numberBid > parseFloat(roi)) {
//     return {
//       isValid: false,
//       error: `Bids should from 0.01 - ${roi}%`,
//     };
//   }

//   return {
//     isValid: true,
//   };
// };
