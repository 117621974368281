import { validateAmount } from "./validateAmount";
import { validateText } from "./validateText";

/**
 * validateUSDCWithdrawal checks if all fields in USDC withdrawal form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateUSDCWithdrawal({ amount, memo, address }) {
  const a = validateAmount(amount);
  const m = validateText(memo);
  const d = validateText(address);

  const v = {
    isValid: true,
  };

  if (!a.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      amount: a.error,
    };
  }

  if (!m.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      memo: "Please, enter memo",
    };
  }

  if (!d.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      address: "Please, enter address",
    };
  }

  return v;
}
