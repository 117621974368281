export const PasswordIcon = () => {
  return (
    <svg
      width="23"
      height="15"
      viewBox="0 0 23 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_461_232)">
        <path
          d="M14.625 7.50005C14.625 9.27526 13.114 10.7144 11.25 10.7144C9.38601 10.7144 7.875 9.27526 7.875 7.50005C7.875 5.72486 9.38601 4.28577 11.25 4.28577C13.114 4.28577 14.625 5.72486 14.625 7.50005Z"
          fill="#838D99"
        />
        <path
          d="M22.3812 7.02084C19.9533 2.39608 15.641 0 11.25 0C6.85898 0 2.54677 2.39608 0.118769 7.02084C-0.0395898 7.32248 -0.0395898 7.67752 0.118769 7.97916C2.54677 12.6039 6.85898 15 11.25 15C15.641 15 19.9533 12.6039 22.3812 7.97916C22.5396 7.67752 22.5396 7.32248 22.3812 7.02084ZM11.25 12.8571C7.91012 12.8571 4.49781 11.1206 2.39586 7.5C4.49781 3.87941 7.91012 2.14286 11.25 2.14286C14.5899 2.14286 18.0022 3.87941 20.1041 7.5C18.0022 11.1206 14.5899 12.8571 11.25 12.8571Z"
          fill="#838D99"
        />
      </g>
      <defs>
        <clipPath id="clip0_461_232">
          <rect width="22.5" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
