import React from 'react'

export const TotalInvestmentCardIcon = () => {
  return (
    <svg 
        width="50" 
        height="50" 
        viewBox="0 0 50 50" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >
    <rect 
        width="50" 
        height="50" 
        rx="8" 
        fill="#F3F3F3"
    />
    <path 
        d="M25 10.4258C21.1339 10.4258 18 13.5751 18 17.4601C18 21.3451 21.1339 24.4944 25 24.4944C28.866 24.4944 31.9999 21.3451 31.9999 17.4601C31.9999 13.5751 28.866 10.4258 25 10.4258ZM25 16.9576C26.3785 16.9576 27.5 18.0846 27.5 19.4699C27.5 20.6827 26.6395 21.6977 25.5 21.9314V22.987H24.5V21.9314C23.3605 21.6977 22.5 20.6834 22.5 19.4699H23.5C23.5 20.301 24.1729 20.9772 25 20.9772C25.827 20.9772 26.5 20.301 26.5 19.4699C26.5 18.6388 25.827 17.9625 25 17.9625C23.6214 17.9625 22.5 16.8356 22.5 15.4503C22.5 14.2375 23.3605 13.2224 24.5 12.9887V11.9331H25.5V12.9887C26.6395 13.2225 27.5 14.2368 27.5 15.4503H26.5C26.5 14.6192 25.827 13.9429 25 13.9429C24.1729 13.9429 23.5 14.6192 23.5 15.4503C23.5 16.2814 24.1729 16.9576 25 16.9576Z" 
        fill="#BB3F63"
    />
    <path 
        d="M33 16.958H35V17.9629H33V16.958Z" 
        fill="#BB3F63"
    />
    <path 
        d="M15 16.958H17V17.9629H15V16.958Z" 
        fill="#BB3F63"
    />
    <path 
        d="M30.3027 23.4997L31.0096 22.7891L32.4242 24.2101L31.7172 24.9207L30.3027 23.4997Z" 
        fill="#BB3F63"
    />
    <path 
        d="M17.5762 10.7106L18.2833 10L19.6976 11.4212L18.9905 12.1318L17.5762 10.7106Z" 
        fill="#BB3F63"
    />
    <path 
        d="M30.3027 11.4212L31.717 10L32.4242 10.7106L31.0099 12.1318L30.3027 11.4212Z" 
        fill="#BB3F63"
    />
    <path 
        d="M17.5762 24.213L18.9907 22.792L19.6976 23.5026L18.2831 24.9236L17.5762 24.213Z" 
        fill="#BB3F63"
    />
    <path 
        d="M24.5 25.4287H25.5V27.4385H24.5V25.4287Z" 
        fill="#BB3F63"
    />
    <path 
        d="M14 37.9903C14 39.1001 13.1044 40.0001 12 40.0001C10.8955 40.0001 10 39.1001 10 37.9903C10 36.8804 10.8955 35.9805 12 35.9805C13.1044 35.9805 14 36.8804 14 37.9903Z" 
        fill="#BB3F63"
    />
    <path 
        d="M27 37.9903C27 39.1001 26.1044 40.0001 25 40.0001C23.8955 40.0001 23 39.1001 23 37.9903C23 36.8804 23.8955 35.9805 25 35.9805C26.1044 35.9805 27 36.8804 27 37.9903Z" 
        fill="#BB3F63"
    />
    <path 
        d="M39.998 37.9903C39.998 39.1001 39.1025 40.0001 37.998 40.0001C36.8936 40.0001 35.998 39.1001 35.998 37.9903C35.998 36.8804 36.8936 35.9805 37.998 35.9805C39.1025 35.9805 39.998 36.8804 39.998 37.9903Z" 
        fill="#BB3F63"
    />
    <path 
        d="M23.9016 31.2578L24.5016 30.4538V34.9759H25.5016V30.4538L26.1016 31.2578L26.9015 30.6548L25.4016 28.645C25.2132 28.3918 24.79 28.3918 24.6016 28.645L23.1016 30.6548L23.9016 31.2578Z" 
        fill="#BB3F63"
    />
    <path 
        d="M14.7585 35.9071C15.923 35.1972 16.9706 34.3199 17.873 33.2988C18.4299 32.6687 18.9215 31.9844 19.3536 31.2619L19.5676 32.3361L20.5482 32.1387L20.0482 29.6264C20.0192 29.4802 19.9268 29.3541 19.7962 29.2833C19.6652 29.2119 19.5096 29.2034 19.3721 29.2586L16.8721 30.2635L17.2435 31.1966L18.5391 30.6755C18.1295 31.3705 17.6596 32.0272 17.1257 32.6311C16.2898 33.5768 15.3193 34.3896 14.2402 35.0473L14.7585 35.9071Z" 
        fill="#BB3F63"
    />
    <path 
        d="M30.9315 32.3369L31.1456 31.2627C31.5775 31.9847 32.069 32.6691 32.6261 33.2996C33.5286 34.3207 34.5761 35.198 35.7406 35.9079L36.2592 35.0488C35.1801 34.3911 34.2096 33.5782 33.3737 32.6326C32.8397 32.0287 32.3697 31.3719 31.9603 30.677L33.2558 31.1981L33.6273 30.265L31.1273 29.2601C30.9898 29.2053 30.8342 29.2143 30.7032 29.2847C30.5726 29.3561 30.4802 29.4817 30.4512 29.6279L29.9512 32.1402L30.9315 32.3369Z" 
        fill="#BB3F63"
    />
    </svg>
    
  )
}
