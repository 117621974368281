export const ViewPhotoIcon =()=>{
    return(
        <svg 
            width="30" 
            height="30" 
            viewBox="0 0 30 30" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M15 0C6.70732 0 0 6.70732 0 15C0 23.2927 6.70732 30 15 30C23.2927 30 30 23.2927 30 15C30 6.70732 23.2927 0 15 0ZM15 29.2683C11.2501 29.2683 7.80488 27.8049 5.27439 25.3963C5.36584 22.134 7.9878 19.2987 11.8598 18.2317C10.3049 17.1951 9.29883 15.4572 9.29883 13.4756C9.29883 10.3354 11.8598 7.7439 15.0305 7.7439C18.2012 7.74412 20.7318 10.3356 20.7318 13.4762C20.7318 15.4578 19.6952 17.2261 18.1708 18.2322C22.0122 19.2994 24.6343 22.1346 24.7257 25.3968C22.1952 27.7749 18.7501 29.2686 15.0001 29.2686L15 29.2683Z" 
            fill="#414141"
        />
        </svg>
        

    )
};