export const EditorPencilIcon =()=>{
    return(
        <svg 
            width="26" 
            height="34" 
            viewBox="0 0 26 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M19.6183 4.53127C20.7374 3.37218 22.5897 3.34169 23.7571 4.45272L24.3741 5.04254C25.5415 6.15364 25.5797 8.00503 24.4507 9.16168L23.5313 10.11L18.689 5.47709L19.6183 4.53127Z" 
            fill="#081B33"
        />
        <path 
            d="M0.0655533 29.1406L2.88983 21.5666L17.953 6.24091L22.8126 10.8884L7.94976 26.3361L7.91031 26.3679L0.36964 29.4339C0.182236 29.5124 -0.0127338 29.3288 0.0660717 29.1402L0.0655533 29.1406ZM18.7594 10.5416L18.5496 10.3335L7.17646 22.0248L7.38625 22.2328L18.7594 10.5416ZM6.07478 23.4486C5.03821 22.4011 3.88107 22.0002 3.33349 21.8438L1.70097 26.2162L3.25583 27.7042L7.59829 25.9383C7.42776 25.407 7.03249 24.3933 6.07485 23.4486L6.07478 23.4486Z" 
            fill="#081B33"
        />
        </svg>

    )
}