import React, { useRef, useState } from "react";
import "../../styles/vendor/vendor-crypto-deposit-modal.scss";
import {
  AutoCopyIcon,
  CloseIcon,
  LeftArrowIcon,
  CryptoDepositIcon,
} from "../icons/index";

const shortText = "1088330687";
const shortLink = "GBH4TZYZ4IRCPO44CBOLFUHULU2WGALXTAVESQA6432MBJMABBB4GIYI";
const VendorCryptoDepositModal = (props) => {
  const { controlModal, modal, closeAll } = props;

  const [copyAchieved, setCopyAchieved] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [amount, setAmount] = useState("");

  const inputArea = useRef(null);
  const textArea = useRef(null);

  function copyLink(e) {
    inputArea.current?.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess("");
    }, 5000);
  }

  function copyText(e) {
    textArea.current?.select();
    document.execCommand("copy");
    e.target.focus();
    setCopyAchieved("Copied!");
    setTimeout(() => {
      setCopyAchieved("");
    }, 5000);
  }

  return (
    <>
      {modal && (
        <div className="vendor-crypto-deposit-modal">
          <div
            onClick={controlModal}
            className="vendor-crypto-deposit-overlay"
          ></div>
          <div className="vendor-crypto-deposition-case">
            <div className="vendor-crypto-deposit-head">
              <span className="left-arrow" onClick={controlModal}>
                <LeftArrowIcon />
              </span>
              <h3 className="vendor-crypto-deposit-heading">Crypto (USDC)</h3>

              <span
                onClick={() => {
                  controlModal();
                  closeAll();
                }}
              >
                <CloseIcon color="#D13D2A" />
              </span>
            </div>
            <span className="bar-code">
              <CryptoDepositIcon />
            </span>

            <div className="vendor-crypto-deposit-wallet-address">
              <span className="vendor-left-section">
                <h3 className="vendor-wallet-address-title">Wallet Address</h3>

                <p className="vendor-crypto-deposit-address">
                  {shortLink && (
                    <textarea
                      ref={inputArea}
                      type="text"
                      className="vendor-shorten-text"
                      value={shortLink}
                    />
                  )}
                </p>
                <span className="vendor-alert">{copySuccess}</span>
              </span>
              <span onClick={copyLink} className="vendor-right-section">
                <AutoCopyIcon />
              </span>
            </div>

            <div className="vendor-crypto-deposit-memo-address">
              <span className="vendor-left-section">
                <h3 className="vendor-wallet-address-title">Memo</h3>
                <p className="vendor-crypto-deposit-address">
                  {shortText && (
                    <textarea
                      ref={textArea}
                      type="text"
                      className="vendor-short-address"
                      value={shortText}
                    />
                  )}
                </p>
                <span className="vendor-alert">{copyAchieved}</span>
              </span>
              <span onClick={copyText} className="vendor-right-section">
                <AutoCopyIcon />
              </span>
            </div>
            <div className="vendor-crypto-withdraw-holder-address">
              <span className="vendor-left-section">
                <h3 className="vendor-wallet-address-title">Amount</h3>
                <p className="vendor-crypto-withdraw-address">
                  <input
                    type="text"
                    className="vendor-input-text-deposit"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="E.g  300,000"
                  />
                </p>
              </span>
            </div>

            <div className="vendor-crypto-deposit-btn-case">
              <button className="vendor-crypto-deposit-btn">
                Confirm Payment
              </button>

              <p className="vendor-page-statement">
                Please do not click on this button until you have made a
                succesfull transaction on your USDC account.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VendorCryptoDepositModal;
