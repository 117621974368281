import React from "react";
import "../../../styles/investor/investor-dashboard-styles/fait-selection-card.scss";

const FiatCard = (props) => {
  const { icon, title1, title2, statement, onClick } = props;
  return (
    <>
      <div className="fiat-card-container" onClick={onClick}>
        <div className="fiat-card-icon-case">
          {icon}
          <h3 className="fait-card-title window-hide">
            {title1} <span className="mobile-hide">{title2}</span>
          </h3>
        </div>

        <div className="fiat-card-word-case">
          <h3 className="fait-card-title mobile-hide">
            {title1} <span>{title2}</span>
          </h3>
          <p className="fiat-card-statement">{statement}</p>
        </div>
      </div>
    </>
  );
};

export default FiatCard;
