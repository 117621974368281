import styles from "../../styles/selectioncard.module.scss";
import { Link } from "react-router-dom";
import {
  MobileInvestor,
  MobileSMESIcon,
  MobileVendor,
} from "../../components/icons/mobile";
import { 
    AdminsIcon,
    InvestorIcon, 
    SmallMEIcon 
} from "../../components/icons";
import {
   MainLogo, 
   MobileLogo 
} from "../../components/icons/logo";
import SelectionCard from "../../components/welcome/SelectionCard";
import CardMobile from "../../components/welcome/CardMobile";

const card = [
  {
    leader: "/signup/investor",
    mobile: <MobileInvestor />,
    icon: <InvestorIcon />,
    title: "Investors",
    body: "Lend funds to SMEs and earn a percentage of interest",
  },
  {
    leader: "/signup/seller",
    mobile: <MobileSMESIcon />,
    icon: <SmallMEIcon />,
    title: "SMEs",
    body: "Upload your invoice and get it funded quickly by Investors",
  },
  {
    leader: "/signup/vendor",
    mobile: <MobileVendor />,
    icon: <AdminsIcon />,
    title: "Vendor",
    body: "Verify and manage invoices and user accounts",
  },
];

const SelectionPage = () => {
  return (
    <div className={styles.mainContainer}>
      <span className={styles.topLogo}>
        <MainLogo />
      </span>

      <div className={styles.pageCard}>
        <span className={styles.mobileLogo}>
          <MobileLogo />
        </span>
        <h3 className={styles.pageTitle}>
          What kind of account would you like to create?
        </h3>
        <div className={styles.cardButton}>
          {card.map((item, index) => (
            <SelectionCard
              key={index}
              title={item.title}
              body={item.body}
              icon={item.icon}
              leader={item.leader}
            />
          ))}
          {card.map((item, index) => (
            <CardMobile
              key={index}
              title={item.title}
              body={item.body}
              icon={item.mobile}
              leader={item.leader}
            />
          ))}
        </div>

        <p className={styles.directMember}>
          Already a member?
          <Link to="/signin">Log In</Link>
        </p>
      </div>
    </div>
  );
};

export default SelectionPage;
