import { CloseIcon } from "../../icons/index";
import { useState } from "react";
import "../../../styles/vendor/vendor-invoice/vendor-confirmation-modal.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { NoInvoiceIcon } from "../../icons/index";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";

export const VendorConfirmationModal = (props) => {
  const { toggleChange, modal, url, id, loadData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleConfirm = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/vendor_confirm_pending_invoice/${token}/${id}`,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          toggleChange();
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition>
            <div className="vendor-modal">
              <div onClick={toggleChange} className="vendor-overlay">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  className="vendor-confirmation-modal-card"
                  onClick={(evt) => evt.stopPropagation()}
                  transition={{ type: "spring" }}
                >
                  {/* {loading ? (
                  "Loading..."
                ) : ( */}
                  <>
                    <div className="vendor-confirmation-modal-head">
                      <h3 className="vendor-confirmation-modal-header">
                        Confirmation
                      </h3>
                      <span
                        onClick={toggleChange}
                        className="vendor-confirmation-close"
                      >
                        <CloseIcon color="#D13D2A" />
                      </span>
                    </div>
                    <div className="vendor-confirmation">
                      <p className="statement-holder ">
                        Would you like to confirm/Authorize the sale of this
                        Invoice
                      </p>

                      <span className="invoice-icon-holder">
                        <NoInvoiceIcon />
                      </span>

                      <div className="yes-no-holder">
                        <button
                          className="confirm-btn no"
                          onClick={toggleChange}
                        >
                          No
                        </button>

                        <button
                          className="confirm-btn yes"
                          onClick={handleConfirm}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                    <p className="last-statement">
                      To preview Invoice,
                      <a
                        href={url}
                        className="vendor-invoice-confirm-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here
                      </a>
                    </p>
                  </>
                  {/* )} */}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {success && <Toolkit text="Invoice Confirmed Successfully" />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};
