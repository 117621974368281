import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/seller/dashboard/dashboard-transaction-card.scss";
import { TransactionModalCard } from "./index";

export const TransactionCard = (props) => {
  const { title, amount, date, time, content, account, currency, isDebit, id } =
    props;
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState();

  const onModalOpen = async () => {
    const token = localStorage.getItem("token");

    setModal(true);

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/transaction_details/${id}`,
        config
      );
      setIsLoading(false);
      console.log(data);

      if (data.success) setModalData(data.tx_details[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const onModalClose = () => {
    setModal(false);
    setModalData(undefined);
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  const shortContent = content.substring(0, 30) + "...";
  const shortTitle = title.substring(0, 18) + "...";
  return (
    <>
      <div onClick={onModalOpen} className="transaction-card-container">
        <div className="transaction-card-upper">
          <h3 className="transaction-title">{shortTitle}</h3>
          <h3 className={`transaction-amount ${isDebit && "debit"}`}>
            {`${isDebit ? "-" : "+"}₦${amount}`}
          </h3>
        </div>
        <div className="transaction-card-middle">
          <h5 className="transaction-reference">{shortContent}</h5>
        </div>
        <div className="transaction-card-bottom">
          <h5 className="transaction-date">{date}</h5>
          <h5 className="transation-time">{time}</h5>
        </div>
      </div>
      <TransactionModalCard
        modal={modal}
        isDebit={isDebit}
        onClose={onModalClose}
        title={modalData?.transaction_name}
        amount={amount}
        date={moment(modalData?.tx_date).format("MMM Do, YYYY")}
        time={modalData?.tx_time}
        content={content}
        loading={isLoading}
        // account={modalData?.sender}
      />
    </>
  );
};
