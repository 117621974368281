import React, { useState } from "react";
import ModifiedInvestorInputOption from "../../../general-component/ModifiedInvestorInputOption";

import "../../../../styles/admin/admin-financial/admin-financial-card.scss";

import { NigeriaFlag, TotalWalletBalanceIcon } from "../../../icons";

const TotalWalletBalanceCard = ({ usd, naira, totalNumber }) => {
  const [symbol, setSymbol] = useState("naira");
  const [flag, setFlag] = useState(<NigeriaFlag />);

  const amount = symbol === "dollar" ? usd : naira;

  return (
    <div
      className="admin-financial-card-container"
      style={{ background: "#565CD6" }}
    >
      <div className="stat-svg-container">
        <div className="stat-top-container">
          <div className="first-part">
            <span className="top-section">
              <TotalWalletBalanceIcon />
            </span>
            <span className="bottom-section">
              <h3 className="header-text">Total Wallet Balance</h3>
              <h3 className="digit">
                <ModifiedInvestorInputOption
                  selected={flag}
                  setSelected={setFlag}
                  setSymbol={setSymbol}
                  // width="50px"
                />
                {symbol === "naira" ? (
                  <p>₦{amount}</p>
                ) : symbol === "dollar" ? (
                  <p>${amount}</p>
                ) : (
                  <p>£{amount}</p>
                )}
              </h3>
            </span>
          </div>
          <div className="second-part">
            <span className="progress-bar-holder"></span>
          </div>
        </div>
        <div className="bottomText">
          <p>
            Invoices:
            <span style={{ color: "#565CD6" }}>{totalNumber}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TotalWalletBalanceCard;
