import { Link } from "react-router-dom";
import { CheckBoxIcon } from "../components/icons/CheckBoxIcon";
import "../styles/reset-success.scss";
import styles from "../styles/loginform.module.scss";
import { MobileLogo } from "../components/icons/logo";

const ResetSuccess = () => (
  <main>
    <span className={styles.mobileLogo}>
      <MobileLogo />
    </span>
    <div className="main-card">
      <CheckBoxIcon />
      <h1>Reset Successful</h1>
      <p className="description">
        Password reset successfull you can now login to your Quidroo account
      </p>

      <button>Done</button>

      <div className="login-text">
        To login <Link to="/signin">Click here</Link>
      </div>
    </div>
  </main>
);

export default ResetSuccess;
