import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/admin/admin-dashboard/admin-bidder-invoice-card.scss";
import { formatAmount } from "../../../util/formatAmount";
import { AdminInvoiceDetailNotifyModal } from "../admin-invoice/AdminInvoiceDetailNotifyModal";
import { AdminSellerDetailModal } from "../AdminSellerDetailModal";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";


const AdminBidderInvoiceCard = (props) => {
  const {
    invoiceName,
    price,
    seller,
    vendor,
    bidderNumber,
    topBid,
    url,
    // dueTime,
    id,
    loadData,
    sellerId,
    vendorId,
  } = props;

  const [detailNotify, setDetailNotify] = useState(false);
  const [sellerDetail, setSellerDetail] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(false);

  /** loading states */
  const [isLoading, setIsLoading] = useState(false);
  const [isVendorLoading, setIsVendorLoading] = useState(false);
  const [isSellerLoading, setIsSellerLoading] = useState(false);

  /** data */
  const [detailsData, setDetailsData] = useState();
  const [vendorDetailsData, setVendorDetailsData] = useState();
  const [sellerDetailsData, setSellerDetailsData] = useState();
  

  const onDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setDetailNotify(true);

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_invoice_details/${id}`,
        config
      );
      setIsLoading(false);
      console.log(data);

      if (data.success) setDetailsData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const onDetailClose = () => {
    setDetailNotify(false);
    setDetailsData(undefined);
  };

  const onVendorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setVendorDetail(true);

    try {
      setIsVendorLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_vendor_detail/${vendorId}`,
        config
      );
      setIsVendorLoading(false);

      if (data.success) setVendorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onVendorDetailClose = () => {
    setVendorDetail(false);
    setVendorDetailsData(undefined);
  };

  const onSellerDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setSellerDetail(true);

    try {
      setIsSellerLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_seller_detail/${sellerId}`,
        config
      );
      setIsSellerLoading(false);
      console.log(data);

      if (data.success) setSellerDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onSellerDetailClose = () => {
    setSellerDetail(false);
    setSellerDetailsData(undefined);
  };

  return (
    <>
      <tr className="admin-bidder-invoice-cards">
      <td><a href={url} target="_blank">{invoiceName}</a></td>
        <td>₦{price}</td>
        {/* <td>{topBid}%</td> */}
        <td>{bidderNumber}</td>
        <td onClick={onSellerDetailOpen}>{seller}</td>
        <td onClick={onVendorDetailOpen}>{vendor}</td>
        <td>
          <button
            className="admin-invoice-bidder-btn active-admin-bidder-btn"
            onClick={onDetailOpen}
          >
            Detail
          </button>
        </td>
      </tr>

      <AdminInvoiceDetailNotifyModal
        onClose={onDetailClose}
        modal={detailNotify}
        loadData={loadData}
        invoiceAmount={formatAmount(
          detailsData?.invoice_details[0].invoice_amount
        )}
        invoiceDate={moment(detailsData?.invoice_details[0].due_date).format(
          "MMM Do, YYYY"
        )}
        invoiceName={invoiceName}
        vendorName={vendor}
        sellerName={seller}
        status={detailsData?.invoice_details[0].invoice_status}
        countDown={detailsData?.invoice_details[0].due_date}
        bidders={detailsData?.bidders}
        invoiceURL={url}
        loading={isLoading}
        id={id}
      />

      <AdminSellerDetailModal
        onClose={onSellerDetailClose}
        modal={sellerDetail}
        sellerAddress={sellerDetailsData?.address}
        sellerName={sellerDetailsData?.companyName}
        typeBusiness={sellerDetailsData?.business_type}
        annualRevenue={sellerDetailsData?.revenue}
        phoneNumber={sellerDetailsData?.phone}
        country={sellerDetailsData?.country}
        emailAddress={sellerDetailsData?.email}
        creditScore={sellerDetailsData?.credit_score}
        numberStaff={sellerDetailsData?.staffNo}
        ninNumber={sellerDetailsData?.nin}
        bankVerificationNumber={sellerDetailsData?.bvn}
        cacNumber={sellerDetailsData?.cac}
        bankName={sellerDetailsData?.bank ?? "Nil"}
        accountName={sellerDetailsData?.account_name ?? "Nil"}
        accountNumber={sellerDetailsData?.account_no ?? "Nil"}
        memoNumber={sellerDetailsData?.memo_id}
        loading={isSellerLoading}
        cacUrl={sellerDetailsData?.cac_url}
      />

      <AdminVendorDetailModal
        onClose={onVendorDetailClose}
        modal={vendorDetail}
        vendorName={
          vendorDetailsData?.account_name ?? vendorDetailsData?.companyName
        }
        vendorAddress="52 Odalume, Oshodi Lagos"
        creditScore={vendorDetailsData?.credit_score}
        vendorPhone={vendorDetailsData?.phone}
        emailAddress={vendorDetailsData?.email}
        accountNumber={vendorDetailsData?.account_no ?? "Nil"}
        bankName={vendorDetailsData?.bank ?? "Nil"}
        memoNumber={vendorDetailsData?.memo_id}
        vendorBalance={formatAmount(vendorDetailsData?.wallet_balance)}
        accountName={vendorDetailsData?.account_name ?? "Nil"}
        loading={isVendorLoading}
        verified={vendorDetailsData?.verified}
      />
    </>
  );
};

export default AdminBidderInvoiceCard;
