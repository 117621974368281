import axios from "axios";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/admin/admin-financial/admin-financial-transaction-card.scss";
import { formatAmount } from "../../../util/formatAmount";
import { AdminInvestorDetailModal } from "../AdminInvestorDetailModal";
// import picHolder from "../../../assest/blank-profile-picture.png"
import { AdminSellerDetailModal } from "../AdminSellerDetailModal";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";
// import { AdminConfirmationModal } from "../admin-invoice/AdminConfirmationModal";
import { AdminTransactionModal } from "./AdminTransactionModal";

const AdminFinancialTransactionCard = (props) => {
  const {
    invoiceName,
    url,
    id,
    loadData,
    requestDate,
    imageTitle,
    image,
    referenceCode,
    requestAmount,
    description,
    userName,
    userType,
    userId,
    isCredit,
    currency,
  } = props;

  const [sellerDetail, setSellerDetail] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(false);
  const [investorDetail, setInvestorDetail] = useState(false);
  const [appear, setAppear] = useState(false);

  const [isVendorLoading, setIsVendorLoading] = useState(false);
  const [vendorDetailsData, setVendorDetailsData] = useState();

  const toggleSellerDetail = () => {
    setSellerDetail(!sellerDetail);
  };

  const toggleVendorDetail = () => {
    setVendorDetail(!vendorDetail);
  };

  const toggleInvestorDetail = () => {
    setInvestorDetail(!investorDetail);
  };

  const toggleAppearance = () => {
    setAppear(!appear);
  };

  const fullName = userName;
  const initials = fullName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  const bgColor = (initials) => {
    if (initials.charAt(0) === "A" || "B" || "C" || "D" || "E") {
      return "#DE58DE";
    } else if (initials.charAt(0) === "F" || "G" || "H" || "I" || "J") {
      return "#7F94FF";
    } else if (initials.charAt(0) === "K" || "L" || "M" || "N" || "O") {
      return "#d5965b";
    } else if (initials.charAt(0) === "P" || "Q" || "R" || "S" || "T") {
      return "#742c9e";
    } else if (initials.charAt(0) === "U" || "V" || "W" || "X" || "Y" || "Z") {
      return "#812a2a";
    }
  };

  const onVendorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setVendorDetail(true);

    try {
      setIsVendorLoading(true);

      const body = {
        user_id: userId,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_payment_request_user_details/${token}`,
        body,
        config
      );

      console.log(data);

      setIsVendorLoading(false);

      if (data.success) setVendorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onVendorDetailClose = () => {
    setVendorDetail(false);
    setVendorDetailsData(undefined);
  };

  return (
    <>
      <tr className="admin-financial-transaction-cards-contain">
        <td>
          <span className="admin-financial-transaction-first-half">
            {image ? (
              <img src={image} alt={imageTitle} className="imageControl" />
            ) : (
              <span
                className="admin-fin-transaction-placeholder"
                style={{ backgroundColor: bgColor(initials) }}
              >
                {initials}
              </span>
            )}
          </span>
          <span className="admin-financial-transaction-second-half">
            <h3
              className="admin-invoice-requester-name"
              onClick={toggleAppearance}
            >
              {invoiceName}
            </h3>
            <h3 className="referenceText">{referenceCode}</h3>
          </span>
        </td>
        <td style={{ color: isCredit === true ? "#62B758" : "#BF3030" }}>
          {isCredit === true ? (
            <p>+{`${currency === "NGN" ? "₦" : "$"}${requestAmount}`}</p>
          ) : (
            <p>-{`${currency === "NGN" ? "₦" : "$"}${requestAmount}`}</p>
          )}
        </td>
        {/* <td>{description}</td> */}
        <td>{requestDate}</td>
        <td>
          {userType === "seller" ? (
            <span
              className="admin-financial-user-name _seller"
              onClick={toggleSellerDetail}
            >
              {userName}
            </span>
          ) : userType === "investor" ? (
            <span
              className="admin-financial-user-name _investor"
              onClick={toggleInvestorDetail}
            >
              {userName}
            </span>
          ) : (
            <span
              className="admin-financial-user-name _vendor"
              onClick={onVendorDetailOpen}
            >
              {userName}
            </span>
          )}
        </td>
      </tr>

      <AdminInvestorDetailModal
        onClose={toggleInvestorDetail}
        isUser
        modal={investorDetail}
        investorName="Shining Star"
        investorAddress="52 Odalume, Oshodi Lagos"
        investorCredit={80}
        phoneNumber="001122332233"
        emailAddress="emailAddress@email.com"
        investorBalance="12,000,000"
        country="U.S.A"
        passportNumber="1234567AA"
        typeBusiness="Sole Proprietorship"
        numberStaff="11 - 50"
        image="https://images.pexels.com/photos/7413949/pexels-photo-7413949.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        accountNumber="001122112233"
        accountName="James John Jonas"
        bankName="bank of America"
        memoNumber="12233454433221"
        passportUrl="https://images.pexels.com/photos/5405597/pexels-photo-5405597.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      />

      <AdminSellerDetailModal
        onClose={toggleSellerDetail}
        modal={sellerDetail}
        sellerAddress="52 Odalume, Oshodi Lagos"
        sellerName="Shining Star"
        typeBusiness="Sole Proprietorship"
        annualRevenue="1,000,000 - 5,000,000"
        phoneNumber="08012345678"
        country="Nigeria"
        emailAddress="quidroomail@gmail.com"
        image="https://images.pexels.com/photos/4349736/pexels-photo-4349736.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        cacNumber="123456789AD"
        ninNumber="123451234512345"
        accountNumber="001122112233"
        accountName="James John Jonas"
        bankName="bank of America"
        bankVerificationNumber="12121212121112133"
        memoNumber="12233454433221"
        creditScore={80}
        numberStaff="6-15"
        sellerBalance="12,000,000"
      />

      <AdminVendorDetailModal
        onClose={onVendorDetailClose}
        modal={vendorDetail}
        vendorName={
          vendorDetailsData?.account_name ??
          vendorDetailsData?.companyName ??
          vendorDetailsData?.name
        }
        creditScore={vendorDetailsData?.credit_score}
        vendorPhone={vendorDetailsData?.phone}
        emailAddress={vendorDetailsData?.email}
        accountNumber={vendorDetailsData?.account_no}
        bankName={vendorDetailsData?.bank}
        memoNumber={vendorDetailsData?.memo_id}
        vendorBalance={formatAmount(vendorDetailsData?.wallet_balance)}
        accountName={vendorDetailsData?.account_name}
        loading={isVendorLoading}
        verified={vendorDetailsData?.verified}
      />

      <AdminTransactionModal
        onClose={toggleAppearance}
        modal={appear}
        invoiceName={invoiceName}
        userType={userType}
        trans_reference_No={referenceCode}
        imageTitle={imageTitle}
        trans_description={description}
        image={image}
        trans_Amount={requestAmount}
        date={requestDate}
      />
    </>
  );
};

export default AdminFinancialTransactionCard;
