export const InvestorIcon = () => {
  return (
    <svg
      width="83"
      height="90"
      viewBox="0 0 83 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.3267 46.9014L57.8145 46.6158L59.0341 46.5381C59.669 46.4977 60.159 46.012 60.1742 45.4083C60.1886 44.8231 59.7394 44.3178 59.1286 44.2334L57.8217 44.0519L53.4337 43.4426C53.3924 43.4373 53.3515 43.4341 53.3113 43.4341C52.9347 43.4341 52.598 43.6736 52.5154 44.0176C52.3751 44.5999 52.2561 45.4798 52.4852 46.3652V46.3656C52.5708 46.6965 52.9371 46.9237 53.3267 46.9015L53.3267 46.9014Z"
        fill="#3FBB97"
      />
      <path
        d="M15.503 77.4346C12.9959 77.4346 10.9634 75.5058 10.9634 73.1266C10.9634 72.1332 11.3211 71.221 11.9164 70.4926C9.95817 68.0618 8.84664 65.7825 8.33485 64.5625L0.822059 74.5867C-0.955596 76.7636 -0.34344 82.5301 7.64874 87.6037C15.6406 92.6774 18.9216 88.1174 18.9216 88.1174L24.71 78.7942C22.3876 78.1793 20.3574 77.3033 18.5953 76.2733C17.7847 76.9916 16.6993 77.4342 15.5028 77.4342L15.503 77.4346Z"
        fill="#3FBB97"
      />
      <path
        d="M73.6826 54.0413C73.2145 53.5978 72.9573 53.0074 72.9573 52.3798C72.9573 51.7521 73.2148 51.1621 73.6826 50.7179L78.6332 46.0199C74.3229 44.2145 70.7294 47.6602 70.7294 47.6602L62.9225 54.3174C62.6196 54.5763 62.2268 54.7192 61.8193 54.7192H46.4164C46.1722 54.7192 45.9366 54.6317 45.7574 54.4744L41.5895 50.8185C41.2652 50.5339 41.4771 50.0195 41.919 50.0195H53.0598C54.6305 50.0195 56.0274 49.3237 56.9347 48.2415L53.4402 48.4641C53.3818 48.468 53.3233 48.47 53.2659 48.47C52.1351 48.47 51.1491 47.7631 50.885 46.7386C50.6394 45.7889 50.6477 44.7562 50.9087 43.6695C51.191 42.4975 52.4054 41.7184 53.6755 41.893L56.888 42.3389C55.9797 41.2896 54.6023 40.6202 53.0599 40.6202H34.1001C34.1001 40.6202 25.1568 39.4045 15.643 54.8136L9.479 63.0387C9.50858 63.0873 9.54572 63.13 9.56429 63.1855C9.58321 63.2426 10.5909 66.1737 13.1919 69.4247C13.8697 69.0422 14.659 68.8186 15.505 68.8186C18.0122 68.8186 20.0446 70.7474 20.0446 73.1266C20.0446 73.7953 19.8792 74.4262 19.5927 74.9907C21.2913 75.9806 23.2444 76.8165 25.484 77.3758C25.5156 77.3837 25.539 77.4042 25.5689 77.4153L27.0602 75.0136C31.8171 67.3094 40.9576 67.6851 40.9576 67.6851C57.0437 67.6851 64.927 65.6182 64.927 65.6182C64.927 65.6182 70.9035 59.962 75.3256 55.553C75.275 55.5226 75.2197 55.4995 75.1767 55.4587L73.6826 54.0413Z"
        fill="#3FBB97"
      />
      <path
        d="M82.0576 45.9035C81.9241 45.7769 81.7487 45.7139 81.5737 45.7139C81.3983 45.7139 81.2225 45.7769 81.0891 45.9038L74.8487 51.8257C74.6926 51.9739 74.6069 52.1704 74.6069 52.3796C74.6069 52.5888 74.6929 52.7852 74.8487 52.9331L75.7157 53.7562L80.6991 48.3101L82.091 46.7889C82.3252 46.5327 82.3108 46.1437 82.0576 45.9034L82.0576 45.9035Z"
        fill="#3FBB97"
      />
      <path
        d="M54.677 0C50.1847 0 45.8767 1.78494 42.7007 4.96163C39.5245 8.13885 37.7406 12.4472 37.7417 16.9396C37.7427 21.4314 39.5282 25.7394 42.7058 28.915C45.8836 32.0901 50.1925 33.8731 54.6848 33.8709C59.1772 33.8689 63.4846 32.0823 66.6592 28.9042C69.8338 25.726 71.6157 21.4165 71.6125 16.9241C71.6063 12.4353 69.8198 8.13263 66.6447 4.95951C63.4696 1.78639 59.1658 0.00304281 54.677 0V0ZM59.2382 23.4296C58.3679 24.2678 57.2052 24.7329 55.997 24.7257H55.8347V27.3535C55.8347 27.9928 55.3163 28.5111 54.6771 28.5111C54.0378 28.5111 53.5195 27.9928 53.5195 27.3535V24.7257H51.0308C50.3915 24.7257 49.8732 24.2079 49.8732 23.5681C49.8732 22.9289 50.3915 22.4105 51.0308 22.4105H56.043C56.6497 22.409 57.2327 22.1775 57.6756 21.7625C58.0885 21.3661 58.3226 20.8189 58.3236 20.2462C58.3086 19.6504 58.0549 19.0856 57.6198 18.6783C57.1846 18.2716 56.6038 18.0561 56.0084 18.0814H53.3459C52.1382 18.1062 50.9708 17.6483 50.1016 16.8096C49.2329 15.9714 48.7337 14.8205 48.7156 13.6128C48.7332 12.4004 49.2432 11.2475 50.128 10.4181C50.9936 9.5892 52.1476 9.12874 53.3459 9.1334H53.5082V6.4937C53.5082 5.85444 54.026 5.33611 54.6658 5.33611C55.3051 5.33611 55.8234 5.85444 55.8234 6.4937V9.13287H58.3701V9.13338C59.0093 9.13338 59.5277 9.6512 59.5277 10.291C59.5277 10.9302 59.0093 11.4486 58.3701 11.4486H53.3459C52.7418 11.4449 52.1604 11.6775 51.7253 12.0966C51.3108 12.4925 51.0767 13.0402 51.0772 13.6128C51.0917 14.2077 51.3459 14.7715 51.7816 15.1766C52.2172 15.5817 52.7981 15.7941 53.3924 15.7662H56.0316C57.2398 15.7445 58.4078 16.2045 59.2764 17.0453C60.1451 17.8856 60.6433 19.0375 60.662 20.2462C60.6382 21.4559 60.124 22.6047 59.2382 23.4295L59.2382 23.4296Z"
        fill="#3FBB97"
      />
    </svg>
  );
};
