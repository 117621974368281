/**
 * validatePassportNumber checks to see if string is  valid passport number.
 *
 * @param passportNumber
 */

export const validatePassportNumber = (passportNo) => {
  if (!passportNo.trim().length) {
    return {
      isValid: false,
      error: "Please, enter a passport number.",
    };
  }

  //   if (/^([A-Z a-z]){1}([0-9]){7}$/.test(passportNo)) {
  return {
    isValid: true,
  };
  //   }

  //   return {
  //     isValid: false,
  //     error: "Please, enter a valid passport number.",
  //   };
};
