import { useEffect, useState } from "react";
import "../../styles//vendor/vendor-transaction/vendor-transaction-page.scss";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import FilterOptionDropdown from "../../components/seller-component/invoice/FilterOptionDropdown";
import MobileFilterOptionDropdown from "../../components/seller-component/invoice/MobileFilterOptionDropdown";
import { PlusIcon } from "../../components/icons/index";
import { MobileVendorDepositWithdrawModal } from "../../components/vendor-component/MobileVendorDepositWithdrawModal";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { VendorStatusBar } from "../../components/vendor-component/VendorStatusBar";
import { VendorTransactionInvoiceCard } from "../../components/vendor-component/vendor-transaction/VendorTransactionInvoiceCard";
import { PageLoader } from "../../components/general-component/PageLoader";
import { formatAmount } from "../../util/formatAmount";
import moment from "moment";

const transactionData = [
  {
    deductedPrice: "- ₦31,961.56",
    transactionDate: "Dec 6th, 2021",
    transactionTime: "11:06 a.m.",
    transactionReference:
      "fe34e65hjk56ref911bnxfe34e65hjk56ref911bnx11bnxfe34e6jk56rebnxfe34e65hjk56ref911bnx11bnxfe34e6jk56re",
    transactionName: "Debit Payment for Road Project Supplies Invoice",
  },
  {
    deductedPrice: "- ₦31,961.56",
    transactionDate: "Dec 6th, 2021",
    transactionTime: "11:06 a.m.",
    transactionReference:
      "fe34e65hjk56ref911bnxfe34e65hjk56ref911bnx11bnxfe34e6jk56rebnxfe34e65hjk56ref911bnx11bnxfe34e6jk56re",
    transactionName: "Debit Payment for Road Project Supplies Invoice",
  },
  {
    deductedPrice: "- ₦31,961.56",
    transactionDate: "Dec 6th, 2021",
    transactionTime: "11:06 a.m.",
    transactionReference:
      "fe34e65hjk56ref911bnxfe34e65hjk56ref911bnx11bnxfe34e6jk56rebnxfe34e65hjk56ref911bnx11bnxfe34e6jk56re",
    transactionName: "Debit Payment for Road Project Supplies Invoice",
  },
  {
    deductedPrice: "- ₦31,961.56",
    transactionDate: "Dec 6th, 2021",
    transactionTime: "11:06 a.m.",
    transactionReference:
      "fe34e65hjk56ref911bnxfe34e65hjk56ref911bnx11bnxfe34e6jk56rebnxfe34e65hjk56ref911bnx11bnxfe34e6jk56re",
    transactionName: "Debit Payment for Road Project Supplies Invoice",
  },
];

const invoiceStatus = ["Debit Transaction", "Credit Transaction"];

const invoiceDueDate = [
  "Today",
  "Last 7 Days",
  "Last 30 days",
  "Last 3 Months",
  "Last 6 Months",
];

const VendorTransaction = () => {
  // const [activated, setActivated] = useState(false);
  const [filterStatus, setFilterStatus] = useState("Status");
  const [filterDate, setFilterDate] = useState("Due Date");
  const [modal, setModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState();

  const controlModal = () => {
    setModal(!modal);
  };

  // invoice uploader control
  const invoiceUploader = () => {
    console.log("where is invoice");
  };

  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.get(
          `${BACKEND_URL}/vendor_transaction_page/${token}`,
          config
        );
        setIsLoading(false);

        console.log(data);

        if (data.success) setTransactionData(data.transaction);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <VendorStatusBar activated={true} />
      <div className="vendor-transaction-page-container">
        {/* <div className="transaction-first-half-filter">
          <span className="filter input-first mobile-hide">
            <p className="filter mobile-hide">Filters</p>
            <FilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
              height="80px"
            />
          </span>
          <span className="filter input-second mobile-hide">
            <FilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
            />
          </span>

          <span className=" mobile-input-filter window-hide">
            <MobileFilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
              width="154px"
              height="100px"
            />

            <MobileFilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
              width="154px"
            />
          </span>
        </div> */}

        <div className="transaction-scroll">
          {transactionData?.length ? (
            <div className="transaction-data-holder ">
              {transactionData.map((trx) => (
                <VendorTransactionInvoiceCard
                  key={trx?.tx_id}
                  id={trx?.tx_id}
                  type={trx?.transaction_type}
                  price={formatAmount(trx?.amount)}
                  date={moment(trx?.created_at).format("MMM Do, YYYY")}
                  time={moment(trx?.created_at).format("hh:mm a")}
                  reference={trx?.tx_hash}
                  name={trx?.transaction_note}
                />
              ))}
            </div>
          ) : (
            <div className="empty-transaction">
              <InvoiceEmptyIcon />
              <p>You currently do not have any Invoices </p>
            </div>
          )}
        </div>
        <div className="window-hide">
          <button
            onClick={controlModal}
            className="vendor-mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>

          <MobileVendorDepositWithdrawModal
            controlModal={controlModal}
            modal={modal}
            fundWithdrawal={fundWithdrawal}
            invoiceUploader={invoiceUploader}
          />
        </div>
      </div>
    </>
  );
};

export default VendorTransaction;
