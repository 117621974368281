import React, { useState } from "react";
import { useNavigate } from "react-router";
import { LeftArrowIcon } from "../components/icons";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import "../styles/index.scss";
import styles from "../styles/email-verification.module.scss";
import EmailConfirmation from "../components/icons/EmailConfirmation";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { Toolkit } from "../components/general-component/Toolkit";
import { AnimatePresence } from "framer-motion";

const EmailVerification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = localStorage.getItem("email");

  const [success, setSuccess] = useState(false);

  const svgColor = (accountType) => {
    switch (accountType) {
      case "investor":
        return "#3FBB97";
      case "seller":
        return "#565CD6";
      case "vendor":
        return "#8A44B2";
      default:
        return "#c4c4c4";
    }
  };

  const handleResend = async () => {
    try {
      const body = {
        email,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/resend_link`,
        body,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => setSuccess(false), [1000]);
      }
      /** data format {success: true, status: 200, message: 'Email sent!'} */
      /** TODO: create a modal to display success */
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="main-container">
      <span className={styles.topLogo}>
        <MainLogo />

        {/* {resend !== "" && <p className={styles.popUp}>A new verification code was just sent</p> }  */}
      </span>

      <div className="page-card">
        <span className="mobile-logo">
          <MobileLogo />
          {/* <button className="mobile-top-link" onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button> */}
        </span>

        <div className="card-button">
          <p className={styles.verifyLogo}>
            <EmailConfirmation svgColor={svgColor(searchParams.get("acc"))} />
          </p>

          <h2 className={styles.header}>Verify your Email</h2>

          <p className={styles.statement}>
            Hello! Thank you for registering on Quidroo. We’ve sent a message to{" "}
            <span className={styles.emailColor}>{email}</span> with a link to
            activate your account. To complete your registration, please check
            and confirm your email address.
          </p>

          <p className={styles.bottomLink}>
            Didn’t get a link?
            <button
              onClick={handleResend}
              // style={{color:
              // {investor? "#3FBB97": seller? "#565CD6" : vendor? "#8A44B2" : "#c4c4c4"}
              // }}
            >
              Resend
            </button>
          </p>
        </div>
      </div>

      <AnimatePresence>
        {success && <Toolkit text="A new verification code was just sent" />}
      </AnimatePresence>
    </div>
  );
};

export default EmailVerification;
