import { Outlet } from "react-router";
import { InvestorTopBar } from "./index";
import InvestorNavigatorBottom from "../navigator/investor-navigator/InvestorNavigatorBottom";
import "../../styles/seller/dashboard/dash-board.scss";

export const InvestorLayout = () => (
  <div className="main-holder">
    <InvestorTopBar show />
    <div className="display-container">
      <div className="side-bar-holder">
        <InvestorNavigatorBottom />
      </div>

      <div className="pages">
        <Outlet />

        <div className="bottom-bar-holder">
          <InvestorNavigatorBottom />
        </div>
      </div>
    </div>
  </div>
);