import React, { useState } from "react";
import {
  CloseIcon,
  FiatWithdrawIcon,
  CryptoTransactionIcon,
} from "../icons/index";
import "../../styles/vendor/vendor-fiat-withdraw-modal.scss";
import FiatCard from "./VendorFiatCard";
import VendorCryptoWithdrawalModal from "./VendorCryptoWithdrawalModal";
import VendorFiatBankWithdrawalModal from "./VendorFiatBankWithdrawalModal";

const VendorWithdrawModalCard = (props) => {
  const { modal, controlModal, loadData, balance } = props;

  const [appear, setAppear] = useState(false);
  const [show, setShow] = useState(false);

  const controlShow = () => {
    setShow(!show);
  };

  const controlAppear = () => {
    setAppear(!appear);
  };
  return (
    <>
      {modal && (
        <div className="investor-fiat-withdraw-modal">
          <div
            onClick={controlModal}
            className="investor-fiat-withdraw-overlay"
          >
            <div
              className="investor-withdraw-modal"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="investor-withdraw-head">
                <h3>Withdrawal</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>

              <p className="withdraw-heading">
                Select a method to withdraw from your Quidroo Account
              </p>

              <FiatCard
                icon={<FiatWithdrawIcon svgColor="#8A44B2" />}
                title1="Fiat/Bank"
                title2="Withdrawal"
                statement="I want to withdraw money 
                        from my Quidroo account into 
                        my bank account."
                onClick={controlShow}
              />

              <FiatCard
                icon={<CryptoTransactionIcon svgColor="#8A44B2" />}
                title1="Crypto"
                title2="Withdrawal"
                statement="I want to withdraw money 
                        from my Quidroo account 
                        into crypto account."
                onClick={controlAppear}
              />
            </div>
          </div>
        </div>
      )}

      <VendorCryptoWithdrawalModal
        controlModal={controlAppear}
        modal={appear}
        closeAll={controlModal}
      />

      <VendorFiatBankWithdrawalModal
        controlModal={controlShow}
        modal={show}
        loadData={loadData}
        closeAll={controlModal}
        balance={balance}
      />
    </>
  );
};
export default VendorWithdrawModalCard;
