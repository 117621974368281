import { validateAmount } from "./validateAmount";
import { validateDate } from "./validateDate";
import { validatePassportNumber } from "./validatePassportNumber";
import { validatePostalCode } from "./validatePostalCode";
import { validateText } from "./validateText";

/**
 * validateInvestorActivate checks if all fields in investor activate form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateInvestorActivate({
  city,
  passportNo,
  passport,
  postalCode,
  street,
}) {
  const c = validateText(city);
  const s = validateText(street);
  const n = validatePassportNumber(passportNo);
  const p = validatePostalCode(postalCode);

  const v = {
    isValid: true,
  };

  if (!n.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      passportNo: n.error,
    };
  }

  if (!p.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      postalCode: p.error,
    };
  }

  if (!c.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      city: "Please enter a city",
    };
  }

  if (!s.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      street: "Please enter a street",
    };
  }

  if (passport === "" || !passport) {
    v.isValid = false;
    v.error = {
      passport: "Please, upload a passport",
    };
  }

  return v;
}
