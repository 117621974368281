import moment from "moment";
import React, { useState } from "react";
import "../../../styles/seller/dashboard/open-bid-card.scss";
// import "../../index"
import { OpenBidModalCard } from "./index";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { formatAmount } from "../../../util/formatAmount";

// export const OpenBidCard = (props) => {
//   const { topBid, invoiceName, amount, dueDate, id } = props;
export const SmallOpenCard = (props) => {
  const { topBid, invoiceName, amount, dueDate, activated, id } = props;

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState();

  const onModalOpen = async () => {
    const token = localStorage.getItem("token");

    setModal(true);

    console.log(token);

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/invoice_details/${id}`,
        config
      );
      setIsLoading(false);
      console.log(data);

      if (data.success) setModalData(data.invoice_details[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const onModalClose = () => {
    setModal(false);
    setModalData(undefined);
  };

  // const controlModal = () => {
  //   setModal(!modal);
  // };

  const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  return (
    <div className="mobile-open-invoice-container">
      <div className="mobile-first-half" style={{ width: "75%" }}>
        <h2 className="mobile-open-invoice-name">{shortInvoiceName}</h2>
        <h4 className="mobile-open-invoice-bid">
          top Bid:{" "}
          <span
            style={{
              color: topBid > 5 ? "blue" : topBid < 4.5 ? "red" : "yellow",
            }}
          >
            {topBid}%
          </span>
        </h4>
        <h2 className="mobile-open-invoice-data">
          {moment(dueDate).format("MMM Do, YYYY")}
        </h2>
      </div>
      <div className="mobile-second-half" style={{ width: "25%" }}>
        <h2 className="mobile-open-invoice-amount">₦{amount}</h2>
        {activated === true && (
          <button onClick={onModalOpen} className="mobile-open-invoice-btn">
            view
          </button>
        )}

        {activated === false && (
          <button
            style={{ backgroundColor: "rgba(86, 92, 214, 0.4)" }}
            className="mobile-open-invoice-btn"
          >
            view
          </button>
        )}
      </div>
      <OpenBidModalCard
        modal={modal}
        loading={isLoading}
        onClose={onModalClose}
        totalBids={modalData?.no_of_bidders}
        dueDate={modalData?.due_date}
        transPrice={formatAmount(modalData?.invoice_amount)}
        transName={modalData && modalData["invoice_name "]}
        dueTime={modalData?.bid_closing_time}
        // latestBid={modalData?.myBid.toFixed(2)}
        topBid={modalData?.max_discount.toFixed(2)}
      />
    </div>
  );
};
