import React, { useState, useEffect } from "react";
import { VendorStatusBar } from "../../components/vendor-component/VendorStatusBar";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  DownwardArrowIcon,
  HidePasswordIcon,
  NoInvoiceIcon,
  PasswordIcon,
  PlusIcon,
  UpwardArrowIcon,
} from "../../components/icons";
import "../../styles/vendor/vendor-index/vendor-index-dashboard.scss";
import { VendorBalanceCard } from "../../components/vendor-component/vendor-dashboard/VendorBalanceCard";
import VendorInvoiceCard from "../../components/vendor-component/vendor-dashboard/VendorInvoiceCard";
import { MobileVendorInvoiceCard } from "../../components/vendor-component/vendor-dashboard/MobileVendorInvoiceCard";
import { MobileVendorDepositWithdrawModal } from "../../components/vendor-component/MobileVendorDepositWithdrawModal";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { PageLoader } from "../../components/general-component/PageLoader";
import { formatAmount } from "../../util/formatAmount";
import { SessionExpired } from "../../components/general-component/SessionExpired";

ChartJS.register(ArcElement, Tooltip, Legend);

const VendorIndex = () => {
  const [amount, setAmount] = useState(false);
  const [high, setHigh] = useState(false);
  const [modal, setModal] = useState(false);
  const [expire, setExpire] = useState(false);

  const [dashboardData, setDashboardData] = useState();

  const [isLoading, setIsLoading] = useState(false);

  // mobile deposit & withdrawal Modal
  const controlModal = () => {
    setModal(!modal);
  };

  // invoice container toggler
  const toggleControl = () => {
    setHigh(!high);
  };
  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };

  // balance security
  const handleAmountVisibility = () => {
    if (amount === false) {
      setAmount(true);
    } else {
      setAmount(false);
    }
  };
  //  chart data Array

  const chartData = dashboardData && [
    parseInt(dashboardData.invoice_summary.pending),
    parseInt(dashboardData.invoice_summary.approved),
    parseInt(dashboardData.invoice_summary.sold),
    parseInt(dashboardData.invoice_summary.closed),
    parseInt(dashboardData.invoice_summary.rejected),
  ];
  const dataChart = {
    labels: ["Pending", "Approved", "Sold", "Closed"],
    datasets: [
      {
        label: "My First Dataset",
        data: chartData,
        backgroundColor: ["#565CD6", "#25BECE", "#62B758", "#B240B2"],
        cutout: "90%",
        circumference: 360,
        rotation: 270,
        hoverOffset: 5,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const total =
    dashboardData && chartData[0] + chartData[1] + chartData[2] + chartData[3];

  const loadData = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/vendor_dashboard/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setDashboardData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  return (
    <>
      <VendorStatusBar
        activated={dashboardData?.user_details.verified}
        pending={dashboardData?.user_details?.providedVerificationDetails}
      />

      <div className="vendor-dashboard-app-holder">
        <div className="vendor-dashboard-first-half">
          <div className="vendor-dashboard-chart">
            {total ? (
              <div className="vendor-chart">
                <h1 className="vendor-invoice-header ">Invoice Details</h1>
                <span className="centralization-cover">
                  <Doughnut
                    data={dataChart}
                    options={options}
                    className="doughnut"
                  />
                  <div className="vendor-chart-text">
                    <h5 className="vendor-invoice-label">Total:</h5>
                    <h4 className="vendor-invoice-total">{total}</h4>
                  </div>
                </span>
                <ul className="vendor-invoice-color-panel mobile-hide">
                  <li className="color-status">
                    <span style={{ background: "#565CD6" }}></span>
                    Pending Invoice
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#25BECE" }}></span>
                    Approved Invoice
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#62B758" }}></span>
                    Sold Invoice
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#B240B2" }}></span>
                    Closed Invoice
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <h4 className="chart-empty-header">Invoice Status</h4>
                <NoInvoiceIcon />
                <p className="no-data-text">
                  You currently do not have any Existing Invoice
                </p>
              </>
            )}
          </div>
          <div className="vendor-dashboard-balance-card">
            <VendorBalanceCard
              onClick={handleAmountVisibility}
              icon={amount ? <HidePasswordIcon /> : <PasswordIcon />}
              money={
                amount
                  ? formatAmount(dashboardData?.user_details.balance)
                  : // parseInt(dashboardData.user_details.balance).toFixed(2)
                    "*****"
              }
              balance={dashboardData?.user_details.balance}
              score={
                dashboardData?.user_details.credit_score
                // parseInt(dashboardData.user_details.credit_score)
              }
              cashWithdrawal={fundWithdrawal}
              loadData={loadData}
              email={dashboardData?.user_details.email}
            />
          </div>
        </div>
        <div className="vendor-dashboard-second-half">
          {dashboardData?.invoices.length ? (
            <div className="vendor-second-half-header mobile-hide">
              <span className="vendor-filter mobile-hide">
                <p className="vendor-filter mobile-hide">Open Investments</p>
              </span>
              <table className="vendor-page-invoice-table">
                <tbody>
                  <tr className="vendor-table-invoice-header table">
                    <th>Invoice Name</th>
                    <th>Invoice Price</th>
                    <th>Status</th>
                    <th>Seller</th>
                    <th>Due Date</th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="vendor-invoice-empty-header mobile-hide">
              All Invoices
            </div>
          )}

          <div
            className={
              high
                ? "vendor-invoice-scroll-long scroll"
                : "vendor-invoice-scroll scroll"
            }
          >
            {dashboardData?.invoices.length ? (
              <div className="vendor-invoice-data-holder mobile-hide">
                {dashboardData?.invoices.map((invoice) => (
                  <VendorInvoiceCard
                    key={invoice["invoice_id "]}
                    loadData={loadData}
                    id={invoice["invoice_id "]}
                    sellerId={invoice?.seller_id}
                    price={formatAmount(invoice?.invoice_amount)}
                    dueDate={invoice?.due_date}
                    seller={invoice?.seller}
                    status={invoice?.invoice_status}
                    url={invoice?.invoice_url}
                    seller_id={invoice?.seller_id}
                    invoiceName={invoice["invoice_name "]}
                  />
                ))}
              </div>
            ) : (
              <div className="vendor-empty-invoice mobile-hide">
                <NoInvoiceIcon />
                <p>You currently do not have any active Invoice </p>
              </div>
            )}
            {dashboardData?.invoices.length ? (
              <div className="window-hide">
                {dashboardData?.invoices.map((invoice) => (
                  <MobileVendorInvoiceCard
                    key={invoice["invoice_id "]}
                    loadData={loadData}
                    id={invoice["invoice_id "]}
                    price={formatAmount(invoice?.invoice_amount)}
                    dueDate={invoice?.due_date}
                    seller={invoice?.seller}
                    status={invoice?.invoice_status}
                    url={invoice?.invoice_url}
                    invoiceName={invoice["invoice_name "]}
                  />
                ))}
              </div>
            ) : (
              <div className="vendor-empty-invoice window-hide">
                <NoInvoiceIcon />
                <p>You currently do not have any active Invoice </p>
              </div>
            )}
          </div>
          {high === true ? (
            <button className="vendor-control-btn" onClick={toggleControl}>
              Show Less
              <span className="vendor-sizer">
                <UpwardArrowIcon color="#8A44B2" />
              </span>
            </button>
          ) : (
            <button className="vendor-control-btn" onClick={toggleControl}>
              Show More
              <span className="vendor-sizer">
                <DownwardArrowIcon color="#8A44B2" />
              </span>
            </button>
          )}
        </div>
        <div className="window-hide">
          <button
            onClick={controlModal}
            className="vendor-mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>
          <MobileVendorDepositWithdrawModal
            controlModal={controlModal}
            modal={modal}
          />
        </div>
      </div>
    </>
  );
};

export default VendorIndex;
