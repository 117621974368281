import React, { useEffect, useState } from "react";
import { CloseIcon, UploadInvoiceFile } from "../../icons/index";
import "../../../styles/seller/dashboard/activation-modal-form.scss";
import SelectionInputOption from "../../general-component/SelectionInputOption";
import { OptimizedInput } from "../../form/OptimizedInput";
import { ActivationSuccessModal } from "./ActivationSuccessModal";
import Okra from "okra-js";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { validateYear } from "../../../util/validate/validateYear";
import { FadeTransition } from "../../FadeTransition";
import { Toolkit } from "../../general-component/Toolkit";

const listBusiness = ["PLC", "LLC", "SP"];
const listStaff = ["1-10", "11-50", "51-150", "151-200", "200 Above"];
const listCoFounder = ["YES", "NO"];
const revenueList = [
  "less than 500,000",
  "500,001 - 1,000,000",
  "1,000,001 - 5,000,000",
  "over 5 million naira",
];

export const ActivationModalForm = (props) => {
  const { toggleChange, modal, onSubmit, loadData } = props;

  const [okraData, setOkraData] = useState();
  const [business, setBusiness] = useState("Select Type of Buisness");
  const [staff, setStaff] = useState("Select a range of No of staff");
  const [coFounder, setCoFounder] = useState("Select an option");
  const [figure, setFigure] = useState("E.g ₦6,000,000");
  const [cacNo, setCacNo] = useState("");
  const [cacNoError, setCacNoError] = useState(null);

  const [uploadFile, setUploadFile] = useState("");
  const [show, setShow] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [cac, setCac] = useState(null);
  const [yearError, setYearError] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);

  const [error, setError] = useState(null);

  const widgetOkra = () => {
    // SANDBOX;
    Okra.buildWithShortUrl({
      short_url: "ACY-OMLWp",
      // short_url:process.env.OKRA_URL,
      onSuccess: function (data) {
        console.log("success", data);
        setOkraData(data);
      },
    });

    // PRODUCTION
    // Okra.buildWithShortUrl({
    //   short_url: "ACY-OMLWp",
    //   onSuccess: function (data) {
    //     console.log("successful production: ", data);
    //     setOkraData(data);
    //   },

    // });
  };

  const handleSubmit = () => {
    const { isValid, error } = validateYear(year);
    if (
      business === "Select Type of Buisness" ||
      staff === "Select a range No of staff" ||
      coFounder === "Select an option" ||
      figure === "E.g ₦6,000,000" ||
      !cacNo.length
    ) {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
      }, 1000);
    } else if (!isValid) {
      setYearError(error);
    } else if (uploadFile === "" || !uploadFile) {
      setUploadFileError("Please, upload your CAC file");
    } else {
      onSubmit();
      widgetOkra();
    }
  };

  const handleClose = () => {
    toggleChange();
    setBusiness("Select Type of Buisness");
    setStaff("Select a range No of staff");
    setCoFounder("Select an option");
    setFigure("E.g ₦6,000,000");
  };

  const onLoad = () => {
    setShow(false);
    loadData();
  };
  

  useEffect(() => {
    (async () => {
      if (!okraData) return;
      try {
        const token = localStorage.getItem("token");

        setIsLoading(true);
        const formdata = new FormData();

        formdata.append("business_type", business);
        formdata.append("staff_no", staff);
        formdata.append("female_cofounder", coFounder);
        formdata.append("annual_revenue", figure);
        formdata.append("address", okraData.identity.address[0]);
        formdata.append("country", okraData.identity.nationality);
        formdata.append("score", okraData.identity.score ?? "0");
        formdata.append("bvn", okraData.identity.bvn);
        formdata.append("nin", okraData.identity.nin);
        formdata.append("year", year.trim());
        formdata.append("cac", cacNo.trim());
        formdata.append("cac_file", uploadFile, uploadFile.name);
        formdata.append("acc_no", okraData.accounts[0].nuban);
        formdata.append("acc_name", okraData.accounts[0].name);
        formdata.append("bank", okraData.auth.bank_details.name);

        console.log({
          business_type: business,
          staff_no: staff,
          female_cofounder: coFounder === "YES" ? true : false,
          annual_revenue: figure,
          address: okraData.identity.address[0],
          country: okraData.identity.nationality,
          score: 40,
          bvn: okraData.identity.bvn,
          nin: okraData.identity.nin,
          year: year.trim(),
          cac: cacNo.trim(),
          cac_file: uploadFile,
          date: uploadFile.name,
          acc_no: okraData.accounts[0].nuban,
          acc_name: okraData.accounts[0].name,
          bank: okraData.auth.bank_details.name,
        });

        const body = formdata;

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/seller_verification/${token}`,
          body,
          config
        );
        // console.log(data);
        setIsLoading(false);

        if (!data.success) {
          console.error(data.message);
          return;
        }
        setShow(true);
        setBusiness("Select Type of Buisness");
        setStaff("Select a range No of staff");
        setCoFounder("Select an option");
        setFigure("E.g ₦6,000,000");
        setOkraData(undefined);
      } catch (error) {
        const message =
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error;

        if (message.includes("duplicate key value violates unique constraint"))
          setError("You have already used the details in another account");
        console.error(message);
      }
    })();
  }, [okraData]);
  return (
    <AnimatePresence>
      {modal && (
        <FadeTransition absolute>
          <div className="activation-form-modal">
            <div onClick={handleClose} className="activation-form-overlay">
              <motion.div
                className="activation-modal-card"
                onClick={(evt) => evt.stopPropagation()}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ type: "spring" }}
              >
                <section className="mobile-header window-hide">
                  <span className="seller-form ">Verification Form</span>
                </section>
                <span className="seller-form mobile-hide">
                  Verification Form
                </span>
                <div className="modal-content">
                  <div className="one">
                    <span className="form-carrier first">
                      <h4>Type of Business</h4>
                      <SelectionInputOption
                        selected={business}
                        setSelected={setBusiness}
                        option={listBusiness}
                        contentHeight={60}
                      />
                    </span>
                  </div>
                  <span
                    onClick={toggleChange}
                    className="trans-modal-presser activation"
                  >
                    <CloseIcon />
                  </span>

                  <div className="row two">
                    <span className="form-carrier">
                      <h4>No of Staff</h4>
                      <SelectionInputOption
                        selected={staff}
                        setSelected={setStaff}
                        option={listStaff}
                        contentHeight={110}
                      />
                    </span>
                  </div>

                  <div className="row three">
                    <span className="form-carrier">
                      <h4>Female Co-founder Status</h4>
                      <SelectionInputOption
                        selected={coFounder}
                        setSelected={setCoFounder}
                        option={listCoFounder}
                        contentHeight={50}
                      />
                    </span>
                  </div>

                  <div className="row four">
                    <span className="form-carrier">
                      <h4>Annual Revenue</h4>
                      <SelectionInputOption
                        selected={figure}
                        setSelected={setFigure}
                        option={revenueList}
                      />
                    </span>
                  </div>

                  <OptimizedInput
                    mt={30}
                    title="Year of establishment"
                    name="established_date"
                    placeholder="Enter year of establishment"
                    maxlength={4}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    type="text"
                    onFocus={() => yearError && setYearError(null)}
                  />
                  {yearError && <div className="error-text">{yearError}</div>}

                  <OptimizedInput
                    mt={30}
                    title="CAC Number"
                    name="cac_number"
                    placeholder="Enter your CAC number"
                    value={cacNo}
                    onChange={(e) => setCacNo(e.target.value)}
                    type="text"
                    onFocus={() => cacNoError && setCacNoError(null)}
                  />
                  {cacNoError && <div className="error-text">{cacNoError}</div>}

                  <div className="row four">
                    <span className="upload-form-carrier">
                      <h4 className="file-header">Upload CAC Documents</h4>
                      <p className="notice">Kindly upload CAC registration form,CAC 2, 3 and 7 as one file</p>
                      <label for="upload-file">
                        <span className="file-placeholder-carrier">
                          {uploadFile ? (
                            <p className="file-placeholder">
                              {uploadFile.name}
                              <span className="shift-present">
                                <UploadInvoiceFile color="#565cd6" />
                              </span>
                            </p>
                          ) : (
                            <p className="file-placeholder">
                              File type - (png, jpeg, pdf, etc.){" "}
                              <span className="shift">
                                <UploadInvoiceFile />
                              </span>
                            </p>
                          )}
                        </span>

                        <input
                          className="file-input"
                          name="Upload File"
                          // value={uploadFile}
                          onChange={(evt) => {
                            if (evt.target.files) {
                              const file = evt.target.files[0];
                              if (file.size > 2621440) {
                                setUploadFileError(
                                  "File should not exceed 2.5mb"
                                );
                              } else {
                                setUploadFile(evt.target.files[0]);
                              }
                            }
                          }}
                          onClick={() =>
                            uploadFileError && setUploadFileError(null)
                          }
                          type="file"
                          id="upload-file"
                        />
                        {uploadFileError && (
                          <div className="error-text">{uploadFileError}</div>
                        )}
                      </label>
                    </span>
                  </div>

                  <div className="row five">
                    <span className="form-carrier">
                      <button className="submit-btn" onClick={handleSubmit}>
                        Submit
                      </button>
                    </span>
                  </div>
                </div>
              </motion.div>

              <AnimatePresence>
                {hasError && (
                  <motion.div
                    className="failure-modal"
                    initial={{ opacity: 0, y: "-100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "-100%" }}
                    transition={{ duration: 0.4 }}
                  >
                    <div>A field is empty</div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </FadeTransition>
      )}
      {isLoading && (
        <div className="activation-form-overlay">
          <div className="loading" />
        </div>
      )}

      <ActivationSuccessModal
        modal={show}
        onLoad={onLoad}
        title="Verification Data Uploaded successfully"
        desc="Your Account and Financial detail's have been recieved successfully and your account would be activated within 24 hours."
      />

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>
    </AnimatePresence>
  );
};
