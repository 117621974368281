import React,{useState} from 'react'
import { SearchBlackIcon } from '../icons'
import "../../styles/admin/admin-search-filter.scss"

export const AdminSearchFilter = (props) => {
        const {search, setSearch}=props


    const [ isOpen, setIsOpen ]=useState(false)


    const toggleOpen =()=>{
        setIsOpen(!isOpen)
      }

  return (
    <>
        <div className='admin-user-search-container'>
    {isOpen  &&  (<span className='admin-user-input-holder'>
                <input 
                  type="text"
                  placeholder='Enter your search...'
                  className='admin-user-input-text'
                  value={search}
                  onChange={(event)=>setSearch(event.target.value)}
                />
                <p
                  onClick={toggleOpen}
                  className='admin-user-search-button black'>
                  <SearchBlackIcon />
                </p>
              </span>)}
      {!isOpen &&    (<span> 
                <p 
                  onClick={toggleOpen}
                  className='admin-user-search-button blue'>
                  <SearchBlackIcon color="#4473B7"/>
                </p>
              </span>)}
            </div>
        </>
  )
}
