import styles from "../../styles/Forms.module.scss";
import "../../styles/index.scss";

export const OptimizedInput = (props) => {
  const {
    name,
    placeholder,
    value,
    onChange,
    type,
    maxlength,
    icon,
    title,
    onIconClick,
    onFocus,
    mt,
    error,
    onBlur,
    disabled,
  } = props;

  return (
    <section className={styles.formComponent} style={{ marginTop: mt }}>
      <label>{title}</label>
      <div className={styles.inputHolder}>
        <input
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type}
          maxLength={maxlength}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
        <span className={styles.iconImage} onClick={onIconClick}>
          {icon}
        </span>
      </div>
      {error && <div className="error-text">{error}</div>}
    </section>
  );
};
