import React from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/seller/dashboard/open-bid-modal-card.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import moment from "moment";

export const OpenBidModalCard = (props) => {
  const {
    modal,
    onClose,
    transName,
    transPrice,
    dueDate,
    dueTime,
    totalBids,
    topBid,
    loading,
  } = props;

  return (
    <AnimatePresence>
      {modal && (
        <FadeTransition>
          <div className="modal">
            <div onClick={onClose} className="overlay">
              <motion.div
                className="open-bid-modal-card"
                onClick={(evt) => evt.stopPropagation()}
                initial="hidden"
                animate="enter"
                exit="exit"
                variants={{
                  hidden: { scale: 0 },
                  enter: { scale: 1 },
                  exit: { scale: 0 },
                }}
                transition={{ type: "spring" }}
              >
                {loading ? (
                  "Loading..."
                ) : (
                  <>
                    <div className="open-bid-modal-head">
                      <h3 className="open-modal-header">Open Bids</h3>
                      <span onClick={onClose} className="open-modal-presser">
                        <CloseIcon />
                      </span>
                    </div>
                    <div className="open-bid-modal-upper">
                      <div className="open-modal-upper-left">
                        <h5 className="open-upper-top-header">
                          Transaction Name
                        </h5>
                        <h3 className="open-upper-bottom-title">{transName}</h3>
                      </div>
                      <div className="open-modal-upper-right">
                        <h5 className="open-upper-top-header">
                          Transaction Price
                        </h5>
                        <h3 className="open-upper-bottom-amount">
                          ₦{transPrice}
                        </h3>
                      </div>
                    </div>
                    <div className="open-bid-modal-middle">
                      <div className="open-modal-middle-left">
                        <h5 className="open-upper-top-header">
                          Invoice Due date
                        </h5>
                        <h3 className="open-middle-bottom-date">{dueDate}</h3>
                      </div>
                      {/* <div className="open-modal-middle-right">
                        <h5 className="open-upper-top-header">
                          Time remaining
                        </h5>
                        <h3 className="open-middle-bottom-time">
                          {moment(dueTime).format("HH : mm : ss")}
                        </h3>
                      </div> */}
                    </div>
                    <div className="open-bid-modal-bottom">
                      <div className="open-modal-bottom-left">
                        <h5 className="open-upper-top-header">No of Bidders</h5>
                        <h3 className="open-bottom-down-reference">
                          {totalBids}
                        </h3>
                      </div>
                      <div className="open-modal-bottom-right">
                        <h5 className="open-upper-top-header">Top Bid</h5>
                        <h3
                          style={{
                            color: "blue",
                            // topBid > 5
                            //   ? "blue"
                            //   : topBid < 4.5
                            //   ? "red"
                            //   : "yellow",
                          }}
                          className="open-lower-bottom-account"
                        >
                          {topBid}%
                        </h3>
                      </div>
                    </div>
                  </>
                )}
              </motion.div>
            </div>
          </div>
        </FadeTransition>
      )}
    </AnimatePresence>
  );
};
