import { Outlet } from "react-router";
import { TopBar } from ".";
import NavigatorBottom from "../navigator/seller-navigator/NavigatorBottom";
import "../../styles/seller/dashboard/dash-board.scss";

export const Layout = () => (
  <div className="main-holder">
    <TopBar show />
    <div className="display-container">
      <div className="side-bar-holder">
        <NavigatorBottom />
      </div>

      <div className="pages">
        <Outlet />

        <div className="bottom-bar-holder">
          <NavigatorBottom />
        </div>
      </div>
    </div>
  </div>
);
