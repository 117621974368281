import { CloseIcon } from "../../icons/index";
// import { useState } from "react";
import "../../../styles/admin/admin-invoice/admin-confirmation-modal.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { NoInvoiceIcon } from "../../icons/index";

export const AdminConfirmationModal = (props) => {
  const {
    topic,
    statement,
    toggleChange,
    handleConfirm,
    modal,
    url,
    id,
    loadData,
    topicColor,
    noBgColor,
    noColor,
    yesBgColor,
    yesColor,
  } = props;

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition>
            <div className="admin-invoice-confirmation-modal">
              <div
                onClick={toggleChange}
                className="admin-invoice-confirmation-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  className="admin-invoice-confirmation-modal-card"
                  onClick={(evt) => evt.stopPropagation()}
                  transition={{ type: "spring" }}
                >
                  {/* {loading ? (
                  "Loading..."
                ) : ( */}
                  <>
                    <div className="admin-invoice-confirmation-modal-head">
                      <h3
                        style={{ color: topicColor }}
                        className="admin-invoice-confirmation-modal-header"
                      >
                        {topic}
                      </h3>
                      <span
                        onClick={toggleChange}
                        className="admin-invoice-confirmation-close"
                      >
                        <CloseIcon color="#D13D2A" />
                      </span>
                    </div>
                    <div className="admin-invoice-confirmation">
                      <p className="admin-invoice-statement-holder ">
                        {statement}
                      </p>

                      <span className="admin-invoice-icon-holder">
                        <NoInvoiceIcon />
                      </span>

                      <div className="admin-yes-no-holder">
                        <button
                          className="admin-confirm-btn no"
                          onClick={toggleChange}
                          style={{ background: noBgColor, color: noColor }}
                        >
                          No
                        </button>

                        <button
                          className="admin-confirm-btn yes"
                          onClick={handleConfirm}
                          style={{ background: yesBgColor, color: yesColor }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                    {/* {statement.includes("Invoice")  &&   ( <p className="admin-invoice-last-statement">
                      To preview Invoice,
                      <a
                        href={url}
                        className="admin-invoice-confirm-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here
                      </a>
                    </p>)} */}
                  </>
                  {/* )} */}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>

      {/* <AnimatePresence>
        {success && <Toolkit text="Invoice Confirmed Successfully" />}
      </AnimatePresence>

      {isLoading && <ModalLoader />} */}
    </>
  );
};
