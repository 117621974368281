import { validateEmail } from "./validateEmail";
import { validatePassword } from "./validatePassword";

/**
 * validateSignIn checks if all fields in sign in form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 * @param ValidateSignInProps
 */
export function validateSignIn({ email, password }) {
  const e = validateEmail(email);
  const p = validatePassword(password);

  const v = {
    isValid: true,
    error: undefined,
  };

  if (!e.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      email: e.error,
    };
  }

  if (!p.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      password: p.error,
    };
  }

  return v;
}
