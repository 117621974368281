export const MobileLogo = () => {
  return (
    <svg
      width="31"
      height="35"
      viewBox="0 0 31 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.317 31.4299L15.1268 29.0179V34.8238L19.317 32.4118C19.4033 32.3619 19.4749 32.2903 19.5247 32.2041C19.5745 32.1179 19.6007 32.0203 19.6007 31.9208C19.6007 31.8214 19.5745 31.7237 19.5247 31.6376C19.4749 31.5514 19.4033 31.4798 19.317 31.4299Z"
        fill="#31319B"
      />
      <path
        d="M10.0854 31.9208L15.1268 34.8238V29.0179L10.0854 31.9208Z"
        fill="#3C3CAA"
      />
      <path
        d="M5.04138 29.0179L10.0855 31.9208V26.115L5.04138 29.0179Z"
        fill="#4747C9"
      />
      <path d="M0 26.1149L5.04132 29.0179V23.2148L0 26.1149Z" fill="#565CD6" />
      <path d="M0 20.3119L5.04132 23.2149V17.409L0 20.3119Z" fill="#4473B7" />
      <path d="M0 14.509L5.04132 17.4119V11.6061L0 14.509Z" fill="#209AB7" />
      <path d="M0 8.70597L5.04132 11.6061V5.80304L0 8.70597Z" fill="#4AD1A0" />
      <path
        d="M5.04138 5.80301L10.0855 8.70594V2.90009L5.04138 5.80301Z"
        fill="#3EAF6E"
      />
      <path
        d="M10.0826 2.90293L15.1268 5.80302V0L10.0826 2.90293Z"
        fill="#ABCC46"
      />
      <path
        d="M15.1239 5.80304L20.1652 8.70597V2.90295L15.1239 5.80304Z"
        fill="#E5AA33"
      />
      <path
        d="M20.1625 8.70029L25.2038 11.6032V5.79736L20.1625 8.70029Z"
        fill="#D65F2B"
      />
      <path
        d="M25.2037 11.6032L30.2451 14.5033V8.70032L25.2037 11.6032Z"
        fill="#BF3030"
      />
      <path
        d="M25.2037 17.4005L30.2451 20.3034V14.4976L25.2037 17.4005Z"
        fill="#AA3F91"
      />
      <path
        d="M15.1239 17.4148L20.1681 20.3149V14.5118L15.1239 17.4148Z"
        fill="#4542B7"
      />
      <path
        d="M25.2037 23.1979L30.2451 26.098V20.295L25.2037 23.1979Z"
        fill="#AB46D1"
      />
      <path
        d="M21.0193 26.5917L25.2038 29.0009V23.1979L21.0193 25.6042C20.9317 25.6537 20.8588 25.7255 20.8081 25.8122C20.7574 25.899 20.7307 25.9976 20.7307 26.098C20.7307 26.1984 20.7574 26.297 20.8081 26.3837C20.8588 26.4704 20.9317 26.5422 21.0193 26.5917Z"
        fill="#6D4499"
      />
      <path
        d="M15.1268 29.0179L10.0854 26.115V31.9208L15.1268 29.0179Z"
        fill="#4542B7"
      />
      <path
        d="M10.0855 26.1149L5.04138 23.2148V29.0179L10.0855 26.1149Z"
        fill="#5151E0"
      />
      <path
        d="M5.04132 23.2149L0 20.312V26.115L5.04132 23.2149Z"
        fill="#596ED8"
      />
      <path
        d="M5.04132 17.412L0 14.509V20.312L5.04132 17.412Z"
        fill="#2380AF"
      />
      <path
        d="M15.124 17.4091L10.0826 14.509V20.312L15.124 17.4091Z"
        fill="#4747C9"
      />
      <path
        d="M15.1268 17.4119V11.6089L10.0854 14.509L15.1268 17.4119Z"
        fill="#5151E0"
      />
      <path
        d="M15.1239 17.4118V23.2148L20.1681 20.3147L15.1239 17.4118Z"
        fill="#3C3CAA"
      />
      <path
        d="M15.1239 17.4148L20.1681 14.5118L15.1239 11.6089V17.4148Z"
        fill="#4747C9"
      />
      <path
        d="M15.124 17.409L10.0826 20.3119L15.124 23.2149V17.409Z"
        fill="#4542B7"
      />
      <path
        d="M10.0855 8.70597L5.04138 5.80304V11.6061L10.0855 8.70597Z"
        fill="#3FBB97"
      />
      <path
        d="M15.1268 5.80304L10.0826 2.90295V8.70597L15.1268 5.80304Z"
        fill="#62B758"
      />
      <path
        d="M20.1681 2.90293L15.1239 0V5.80302L20.1681 2.90293Z"
        fill="#EACE49"
      />
      <path
        d="M25.2038 5.79737L20.1625 2.89728V8.70029L25.2038 5.79737Z"
        fill="#E07E24"
      />
      <path
        d="M30.2451 8.70029L25.2037 5.79736V11.6032L30.2451 8.70029Z"
        fill="#D13D2A"
      />
      <path
        d="M30.2451 14.5034L25.2037 11.6004V17.4063L30.2451 14.5034Z"
        fill="#AF3869"
      />
      <path
        d="M30.2451 20.2949L25.2037 17.392V23.1979L30.2451 20.2949Z"
        fill="#B240B2"
      />
      <path
        d="M30.2451 26.098L25.2037 23.1979V29.0009L30.2451 26.098Z"
        fill="#8A44B2"
      />
      <path
        d="M5.04132 11.6061L0 8.70599V14.509L5.04132 11.6061Z"
        fill="#25BECE"
      />
    </svg>
  );
};
