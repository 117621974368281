import React, { useState } from "react";
import NavigateCard from "./NavigateCard";
import {
  InvoiceIcon,
  ReportIcon,
  DashBoardIcon,
  ProfileIcon,
} from "../../icons/navigator/Index";
import LogOutCard from "./LogOutCard";
import "../../../styles/Navigator.scss";
import { LogOutIcon } from "../../icons/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

const NavigatorBottom = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate("/");
    localStorage.removeItem("token");
  };

  const tabs = [
    {
      route: "/seller/dashboard",
      icon: (
        <DashBoardIcon
          svgColor={
            location.pathname === "/seller/dashboard" ? "#565CD6" : "#000"
          }
        />
      ),
      label: "Dashboard",
    },
    {
      route: "/seller/dashboard/invoice",
      icon: (
        <InvoiceIcon
          svgColor={
            location.pathname === "/seller/dashboard/invoice"
              ? "#565CD6"
              : "#000"
          }
        />
      ),
      label: "Invoice",
    },
    {
      route: "/seller/dashboard/report",
      icon: (
        <ReportIcon
          svgColor={
            location.pathname === "/seller/dashboard/report"
              ? "#565CD6"
              : "#000"
          }
        />
      ),
      label: "Report",
    },
    {
      route: "/seller/dashboard/profile",
      icon: (
        <ProfileIcon
          svgColor={
            location.pathname === "/seller/dashboard/profile"
              ? "#565CD6"
              : "#000"
          }
        />
      ),
      label: "Profile",
    },
  ];

  return (
    <div className="side-bar">
      <div className="window-nav">
        {tabs.map((tab, index) => (
          <NavigateCard
            isActive={tab.route === location.pathname}
            key={index}
            icon={tab.icon}
            to={tab.route}
            label={tab.label}
            onClick={tab.onClick}
            svgColor={tab.svgColor}
          />
        ))}
      </div>

      <div className="log-out-bar">
        <LogOutCard
          icon={<LogOutIcon svgColor="#000" />}
          onClick={handleLogOut}
          color="#818181"
        />
      </div>
    </div>
  );
};

export default NavigatorBottom;
