// import React, {useState} from 'react'
import { Link } from "react-router-dom";
import "../../../styles/Navigator.scss";

const NavigateCard = (props) => {
  const { to, icon, label, isActive, onClick } = props;

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`tab-container ${isActive && "active"}`}
    >
      <div className="to">
        <div className="icon-holder">{icon}</div>
        <div className="icon-name">
          <h4
            className="icon-id"
            style={{ color: isActive ? "#565CD6" : "#000" }}
          >
            {label}
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default NavigateCard;
