export const LeftArrowIcon = () => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.98528 0C9.68999 0 9.39492 0.117992 9.18818 0.324735L0.332042 9.21039C-0.110681 9.65311 -0.110681 10.3617 0.332042 10.8044L9.18818 19.69C9.60145 20.1033 10.3689 20.1033 10.7822 19.69C11.2249 19.2473 11.2249 18.5387 10.7822 18.096L3.81535 11.1292H25.3062C25.9263 11.1292 26.4281 10.6274 26.4281 10.0074C26.4281 9.38738 25.9262 8.88556 25.3062 8.88556H3.81535L10.7527 1.91873C11.1954 1.47601 11.1954 0.767436 10.7527 0.324735C10.5754 0.117992 10.2803 0 9.98502 0H9.98528Z"
        fill="#818181"
      />
    </svg>
  );
};
