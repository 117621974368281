import React from 'react';
import "../../../styles/investor/stats-styles/stat-investment-invoice-card.scss"



export const MobileStatInvestmentInvoiceCard = (props) => {
    const{
        invoiceName,
        amount,
        dueDate,
        yourBid,
        payAmount,
        amountUSD, 
        payAmountUSD
        
    }=props

    const shortInvoiceName= invoiceName.substring(0, 17) + "...";

    const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  return (  
        <div className='mobile-stat-invoice-container' >
                <div className="mobile-first-card-stat" style={{width:"75%"}}>
                    <h2 className='mobile-invoice-name-stat'>
                        {shortInvoiceName}
                    </h2>
                    <h4 className='mobile-invoice-top-stat' >
                       Profit: 
                       <span className='big-stat' 
                        style={{
                            color: yourBid > 5 ? "black" : yourBid < 4.5 ? "red" : "#3FBB97",
                        }}
                       >
                           {userCurrency === "naira" ? `₦`:`$` }{amount-payAmount}
                        </span>
                    </h4>
                    
                    <h4 className='mobile-card-your-stat' >
                    Pay Amount: 
                       <span className='big-stat'
                        style={{
                            color: payAmount > 5 ? "black" : payAmount < 4.5 ? "red" : "#3FBB97",
                          }}
                       >
                           {userCurrency === "naira" ? `₦`:`$` }{payAmount}
                       </span>
                    </h4>
                </div>
                <div className="mobile-second-card-stat" style={{width:"25%"}}>
                    <h2 className='mobile-invoice-amount-stat'>
                    {userCurrency === "naira" ? `₦`:`$` }{amount} 
                    </h2>
                    <h2 className='mobile-invoice-date'>
                        {dueDate}
                    </h2>
                </div>

        </div>
  )
}
