import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";

import { OptimizedInput } from "../components/form/OptimizedInput";
import { LeftArrowIcon } from "../components/icons";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import { BACKEND_URL } from "../config";
import styles from "../styles/forgetPassword.module.scss";
import { validateEmail } from "../util/validate/validateEmail";

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateEmail(email.toLowerCase().trim());

    if (!isValid) {
      setEmailError(error);
    } else {
      try {
        setIsLoading(true);
        const body = {
          email: email.toLowerCase().trim(),
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/forgot_password`,
          body,
          config
        );

        setIsLoading(false);

        if (!data.success) {
          setEmailError(data.message);
          return;
        }

        localStorage.setItem("resetEmail", email);
        navigate("/reset-link");
      } catch (error) {
        setEmailError(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <span className={styles.topLogo}>
        <MainLogo />
      </span>

      <div className={styles.pageCard}>
        <span className={styles.mobileLogo}>
          <MobileLogo />
          <button className={styles.mobileTopLink} onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button>
        </span>
        <h3 className={styles.pageTitle}>
          <button className={styles.topLink} onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button>
          Forgot Password
        </h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.cardButton}>
            <h4 className={styles.pageSubtitle}>
              Let’s help you get back into your account. Enter your Quidroo
              account email below
            </h4>
            <section className={styles.cover}>
              <OptimizedInput
                title="Email Address"
                name="Email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                maxlength="30"
                onFocus={() => emailError && setEmailError(null)}
              />
              {emailError && <div className="error-text">{emailError}</div>}
            </section>
          </div>

          <div style={{ textAlign: "center" }}>
            <button className="signin-press">
              {isLoading ? <div className="loading" /> : "Reset"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
