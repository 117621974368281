import React from "react";

const DashBoardIcon = ({ svgColor }) => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2492 11.9407L14.5936 2.75269C14.0434 2.27826 14.0434 2.27826 13.4643 2.75269L2.80869 11.9407H0L13.4354 0.355818C13.9856 -0.118606 13.9856 -0.118606 14.5646 0.355818C15.1148 0.830243 28 11.9407 28 11.9407H25.2492ZM24.12 12.8895V23.5256C24.12 24 24.12 24 23.5698 24H16.273V18.2076H11.7848V24H4.488C3.90895 24 3.9378 24 3.9378 23.5256V12.8895L13.4642 4.67522C14.0144 4.20079 14.0144 4.20079 14.5934 4.67522C15.1729 5.14946 24.1203 12.8895 24.1203 12.8895H24.12ZM11.8139 13.3888C11.8139 14.4623 12.8274 15.3113 14.0435 15.3113C15.2596 15.3113 16.2731 14.4374 16.2731 13.3888C16.2731 12.3153 15.2596 11.4663 14.0435 11.4663C12.8274 11.4663 11.8139 12.3153 11.8139 13.3888Z"
        fill={svgColor}
      />
    </svg>
  );
};

export default DashBoardIcon;
