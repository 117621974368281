import React from "react";
import "../../../styles/investor/stats-styles/stat-investment-invoice-card.scss";
import { formatAmount } from "../../../util/formatAmount";
const StatInvestmentInvoiceCard = (props) => {
  const { invoiceName, amount, yourBid, payAmount, dueDate, profit } = props;
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  return (
    <>
      <tr className="stat-invoice-cards">
        <td>{invoiceName}</td>
        <td>{userCurrency === "naira" ? `₦`:`$` }{amount} </td>
        <td
          style={{
            color: "black",
          }}
        >
          {userCurrency === "naira" ? `₦`:`$` }{formatAmount(profit)}
        </td>

        <td>{userCurrency === "naira" ? `₦`:`$` }{payAmount} </td>
        <td>{dueDate}</td>
      </tr>
    </>
  );
};

export default StatInvestmentInvoiceCard;
