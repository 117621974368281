/**
 * validateYear checks to see if string is valid year
 *
 * @param year
 */
export function validateYear(year) {
  const text = /^[0-9]+$/;
  const current_year = new Date().getFullYear();

  if (year !== "" && !text.test(year)) {
    return {
      isValid: false,
      error: "Please enter numeric values only",
    };
  }

  if (year.length !== 4) {
    return {
      isValid: false,
      error: "Year is not proper. Please check",
    };
  }

  if (year < 1960 || year > current_year) {
    return {
      isValid: false,
      error: "Year should be in range 1960 to current year",
    };
  }

  return {
    isValid: true,
  };
}
