import React from 'react'

export const AdminExpandUpperIcon = () => {
  return (
    <>
    <svg 
        width="19" 
        height="19" 
        viewBox="0 0 19 19" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M17.3616 0H11.5757C11.346 0 11.1337 0.122547 11.0189 0.321439C10.9041 0.52033 10.9041 0.765428 11.0189 0.964316C11.1337 1.1632 11.346 1.28576 11.5757 1.28576H15.8095L10.4784 6.61723C10.3207 6.78053 10.2607 7.01486 10.3207 7.23384C10.3808 7.45268 10.5519 7.62372 10.7707 7.68386C10.9897 7.74384 11.224 7.68386 11.3873 7.52615L16.7188 2.19504V6.42885C16.7188 6.65859 16.8414 6.87082 17.0402 6.98562C17.2391 7.10042 17.4842 7.10042 17.6831 6.98562C17.882 6.87082 18.0046 6.65859 18.0046 6.42885V0.642951C18.0038 0.472618 17.936 0.309602 17.8156 0.189079C17.695 0.068685 17.532 0.000808139 17.3617 9.17892e-05L17.3616 0Z" 
        fill="#081B33"
    />
    <path 
        d="M1.92912 18.0025H7.71502C8.22631 18.002 8.71666 17.7986 9.07811 17.437C9.43973 17.0755 9.64307 16.5852 9.64365 16.0739V10.288C9.64308 9.77672 9.43974 9.28637 9.07811 8.92492C8.71663 8.5633 8.22627 8.35996 7.71502 8.35938H1.92912C1.41783 8.35995 0.927481 8.56329 0.566037 8.92492C0.204416 9.2864 0.00107606 9.77676 0.000488281 10.288V16.0739C0.00106228 16.5852 0.204402 17.0755 0.566037 17.437C0.927511 17.7986 1.41787 18.002 1.92912 18.0025ZM1.28624 10.288C1.28653 10.1175 1.35426 9.95423 1.4748 9.8337C1.59534 9.71316 1.75865 9.64542 1.92911 9.64514H7.71501C7.88549 9.64543 8.04879 9.71316 8.16933 9.8337C8.28986 9.95423 8.3576 10.1175 8.35789 10.288V16.0739C8.3576 16.2444 8.28986 16.4077 8.16933 16.5282C8.04879 16.6488 7.88548 16.7165 7.71501 16.7168H1.92911C1.75864 16.7165 1.59534 16.6488 1.4748 16.5282C1.35427 16.4077 1.28653 16.2444 1.28624 16.0739V10.288Z" 
        fill="#081B33"
    />
    </svg>
    </>
  )
}
