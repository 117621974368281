import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FadeTransition } from "../../FadeTransition";
import { AdminUserDistinctIcon, CloseIcon } from "../../icons";
import "../../../styles/admin/admin-seller-detail-modal.scss";
import picHolder from "../../../assest/blank-profile-picture.png";
import { PageLoader } from "../../general-component/PageLoader";

export const VendorSellerDetailModal = ({
  onClose,
  modal,
  sellerAddress,
  sellerName,
  typeBusiness,
  annualRevenue,
  imageTitle,
  creditScore,
  numberStaff,
  sellerBalance,
  phoneNumber,
  image,
  emailAddress,
  cacNumber,
  ninNumber,
  accountNumber,
  accountName,
  bankName,
  bankVerificationNumber,
  memoNumber,
  femaleCofounder,
  loading,
}) => {

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="admin-detail-seller-info-modal">
              <div
                onClick={onClose}
                className="admin-detail-seller-info-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="admin-detail-seller-info-modal-container"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {loading ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="admin-modal-seller-invoice-head">
                        <h3>Seller Detail</h3>
                        <span onClick={onClose}>
                          <CloseIcon color="#D13D2A" />
                        </span>
                      </div>

                      <div className="admin-seller-main-container">
                        <div className="admin-seller-main-container-head">
                          <img src={image ?? picHolder} alt={imageTitle} />
                          <span className="admin-multi-user-header-case">
                            <h4 className="admin-multi-user-header">
                              <p className="admin-multi-user-header-text">
                                {sellerName}
                              </p>
                              <AdminUserDistinctIcon svgColor="#565CD6" />
                            </h4>
                            <span className="admin-multi-user-sub-header-case">
                              <h4 className="admin-multi-user-sub-header">
                                Phone:
                                <h3 className="admin-multi-user-sub-title">
                                  {phoneNumber}
                                </h3>
                              </h4>
                              <h4 className="admin-multi-user-sub-header">
                                Email:
                                <h3 className="admin-multi-user-sub-title">
                                  {emailAddress}
                                </h3>
                              </h4>
                            </span>
                          </span>
                        </div>
                        <div className="admin-multi-user-holder-case">
                          <section>
                            <span className="double-label-holder">
                              <h6 className="admin-small-head">seller Name</h6>

                              <h2 className="admin-name">{sellerName}</h2>
                            </span>
                            {sellerAddress && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Seller Address
                                </h6>

                                <h2 className="admin-name">{sellerAddress}</h2>
                              </span>
                            )}
                            {creditScore && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Seller Credit Score
                                </h6>

                                <h2 className="admin-name">{creditScore}</h2>
                              </span>
                            )}
                            {numberStaff && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  No of Staff
                                </h6>

                                <h2 className="admin-name">{numberStaff}</h2>
                              </span>
                            )}
                            {ninNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">NIN Number</h6>

                                <h2 className="admin-name">{ninNumber}</h2>
                              </span>
                            )}
                            {bankName && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">Bank Name</h6>

                                <h2 className="admin-name">{bankName}</h2>
                              </span>
                            )}
                            {accountName && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Account Name
                                </h6>

                                <h2 className="admin-name">{accountName}</h2>
                              </span>
                            )}

                            {memoNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Memo Number
                                </h6>

                                <h2 className="admin-name">{memoNumber}</h2>
                              </span>
                            )}
                          </section>

                          <section>
                            {typeBusiness && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Type of Business
                                </h6>

                                <h2 className="admin-name">{typeBusiness}</h2>
                              </span>
                            )}
                            {femaleCofounder !== undefined && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Female Cofounder Status
                                </h6>

                                <h2 className="admin-name">
                                  {femaleCofounder ? "Yes" : "No"}
                                </h2>
                              </span>
                            )}
                            {annualRevenue && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Annual Revenue
                                </h6>

                                <h2 className="admin-name">{annualRevenue}</h2>
                              </span>
                            )}
                            {sellerBalance && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  seller Wallet
                                </h6>

                                <h2 className="admin-name">{sellerBalance}</h2>
                              </span>
                            )}

                            {cacNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">CAC Number</h6>

                                <h2 className="admin-name">{cacNumber}</h2>
                              </span>
                            )}

                            {bankVerificationNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Bank Verification Number
                                </h6>

                                <h2 className="admin-name">
                                  {bankVerificationNumber}
                                </h2>
                              </span>
                            )}

                            {accountNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Account Number
                                </h6>

                                <h2 className="admin-name">{accountNumber}</h2>
                              </span>
                            )}
                          </section>
                        </div>
                      </div>
                    </>
                  )}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
    </>
  );
};
