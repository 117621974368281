import React from 'react'

export const AdminUserIcon = ({svgColor}) => {
  return (
    <svg 
        width="26" 
        height="30" 
        viewBox="0 0 26 30" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M12.8568 16.9189C14.9224 16.9189 19.779 14.4278 19.779 8.45944C19.779 3.78722 19.1496 0 12.8568 0C6.56397 0 5.93457 3.78743 5.93457 8.45944C5.93457 14.4278 10.791 16.9189 12.8568 16.9189ZM6.96959 7.0473C7.45576 6.10498 8.50768 5.01598 10.7695 5.06431C10.7695 5.06431 12.1178 8.34115 18.7364 6.8696C18.7631 7.39297 18.7715 7.92721 18.7715 8.45896C18.7715 11.3765 17.4859 13.1841 16.4072 14.1868C15.0483 15.4503 13.5395 15.9088 12.8568 15.9088C12.1744 15.9088 10.6656 15.4503 9.30646 14.1868C8.22782 13.184 6.94223 11.3764 6.94223 8.45896C6.94244 7.98707 6.94933 7.51353 6.96938 7.04725L6.96959 7.0473Z" 
        fill={svgColor}
    />
    <path 
        d="M25.7084 25.5945C25.3551 23.3926 24.6275 20.5563 23.1682 19.5484C22.1718 18.8599 18.6951 16.9988 17.2104 16.204L17.1847 16.1902C17.0149 16.0993 16.8078 16.1175 16.6564 16.2366C15.8787 16.8485 15.0269 17.2607 14.1243 17.4621C13.9649 17.4976 13.834 17.6113 13.7761 17.7642L12.8572 20.1915L11.9383 17.7642C11.8803 17.6113 11.7496 17.4978 11.5902 17.4621C10.6878 17.2607 9.83582 16.8483 9.05811 16.2366C8.90671 16.1175 8.69955 16.0993 8.52976 16.1902C7.06288 16.9755 3.54362 18.8748 2.55062 19.5453C0.870751 20.6793 0.136296 24.7833 0.0061703 25.5945C-0.00677756 25.6751 0.000740738 25.7573 0.0278891 25.8341C0.0880333 26.0043 1.62965 29.9993 12.8571 29.9993C24.0845 29.9993 25.6264 26.0041 25.6862 25.8341C25.7132 25.7576 25.7207 25.6751 25.7078 25.5948L25.7084 25.5945Z" 
        fill={svgColor}
    />
    </svg>

  )
}
