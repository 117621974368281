import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/investor/investor-dashboard-styles/fiat-bank-withdrawal-modal.scss";
import { validateAccountDetails } from "../../../util/validate/validateAccountDetails";
import { validateAmount } from "../../../util/validate/validateAmount";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { CloseIcon, LeftArrowIcon } from "../../icons";
import { InvestorActivationSuccessModal } from "./InvestorActivationSuccessModal";

const FiatBankWithdrawalModal = (props) => {
  const { controlModal, modal, userDetails, loadData } = props;

  const [acctName, setAcctName] = useState("");
  const [acctNo, setAcctNo] = useState("");
  const [bank, setBank] = useState("");
  const [amount, setAmount] = useState("");

  const [acctNameError, setAcctNameError] = useState(null);
  const [acctNoError, setAcctNoError] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [bankError, setBankError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    if (
      !userDetails?.account_name &&
      !userDetails?.account_no &&
      !userDetails?.bank
    ) {
      const { isValid, error } = validateAccountDetails({
        accountNo: acctNo.trim(),
        accountName: acctName.trim(),
        bank: bank.trim(),
      });
      if (!isValid) {
        if (error?.accountNo) setAcctNoError(error.accountNo);
        if (error?.accountName) setAcctNameError(error.accountName);
        if (error?.bank) setBankError(error.bank);
        return;
      }
    }

    const { isValid, error } = validateAmount(amount.trim());

    if (!isValid) {
      setAmountError(error);
    } else {
      const amountNo = parseInt(amount.trim());

      if (amountNo < 3000) {
        setAmountError("You cannot withdraw less than 3000NGN");
        return;
      }

      if (amountNo > parseInt(userDetails?.NG_balance)) {
        setAmountError("You have insufficient balance");
        return;
      }
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);
        const body = {
          amount: amount.trim(),
          acc_no: userDetails?.account_no ?? acctNo.trim(),
          acc_name: userDetails?.account_name ?? acctName.trim(),
          bank: userDetails?.bank ?? bank.trim(),
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${BACKEND_URL}/withdrawalNG2/${token}`,
          body,
          config
        );

        if (data.success && data.status === 200) {
          setIsLoading(false);
          setSuccess(true);
          setAcctName("");
          setAcctNo("");
          setBank("");
          setAmount("");
        }
      } catch (error) {
        setIsLoading(false);
        console.error(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };

  return (
    <>
      {modal && (
        <div className="investor-bank-withdraw-modal">
          <div
            onClick={controlModal}
            className="investor-bank-withdraw-overlay"
          >
            <div
              className="bank-withdrawal-case"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="investor-bank-withdraw-head">
                {/* <span className="left-arrow" onClick={controlModal}>
                  <LeftArrowIcon />
                </span> */}
                <div />
                <h3 className="bank-withdraw-heading">Bank Account</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>
              {/* <div className="crypto-long-input"> */}
              <form onSubmit={handleSubmit}>
                <span className="bank-input-case">
                  <label className="bank-input-title">Account Number</label>
                  <input
                    type="text"
                    className="bank-edit"
                    placeholder="Enter Account Number"
                    value={userDetails?.account_no ?? acctNo}
                    onChange={(evt) =>
                      !userDetails?.account_no && setAcctNo(evt.target.value)
                    }
                    maxLength={10}
                    onFocus={() => acctNoError && setAcctNoError(null)}
                    disabled={userDetails?.account_no ? true : false}
                  />
                  {acctNoError && (
                    <div className="error-text">{acctNoError}</div>
                  )}
                </span>

                {/* </div> */}
                <span className="bank-input-case">
                  <label className="bank-input-title">Account Name</label>
                  <input
                    type="text"
                    className="bank-edit"
                    placeholder="Enter Account Name"
                    value={userDetails?.account_name ?? acctName}
                    onChange={(evt) =>
                      !userDetails?.account_name &&
                      setAcctName(evt.target.value)
                    }
                    onFocus={() => acctNameError && setAcctNameError(null)}
                    disabled={userDetails?.account_name ? true : false}
                  />
                  {acctNameError && (
                    <div className="error-text">{acctNameError}</div>
                  )}
                </span>
                <span className="bank-input-case">
                  <label className="bank-input-title">Bank</label>
                  <input
                    type="text"
                    className="bank-edit"
                    placeholder="Enter Bank Name"
                    value={userDetails?.bank ?? bank}
                    onChange={(evt) =>
                      !userDetails?.bank && setBank(evt.target.value)
                    }
                    onFocus={() => bankError && setBankError(null)}
                    disabled={userDetails?.bank ? true : false}
                  />
                  {bankError && <div className="error-text">{bankError}</div>}
                </span>
                <span className="bank-input-case">
                  <label className="bank-input-title">Amount</label>
                  <input
                    type="text"
                    className="bank-edit"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(evt) => setAmount(evt.target.value)}
                    onFocus={() => amountError && setAmountError(null)}
                  />
                  {amountError && (
                    <div className="error-text">{amountError}</div>
                  )}
                </span>

                <div className="bank-btn-case">
                  <button className="bank-btn">Proceed</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isLoading && <ModalLoader />}

      <InvestorActivationSuccessModal
        modal={success}
        onLoad={() => {
          controlModal();
          setSuccess(false);
          loadData();
        }}
        title="Withdrawal Successful"
        desc="You have successfully withdrawn from your account. The amount will be transferred to your bank account"
      />
    </>
  );
};

export default FiatBankWithdrawalModal;
