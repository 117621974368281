import React from "react";
import "../../styles/place-holder-header.scss";

export const PlaceHolderHeader = (props) => {
  const { 
      text, 
      width, 
      aligner
    } = props;
  return (
    <div className="place-holder-container" style={{ width: width ,justifyContent: aligner}}>
      <h3 className="place-holder-name">{text}</h3>
    </div>
  );
};
