import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FadeTransition } from "../FadeTransition";
import { CloseIcon } from "../icons";
import "../../styles/admin/admin-vendor-detail-modal.scss";
import picHolder from "../../assest/blank-profile-picture.png";
import { AdminUserDistinctIcon } from "../icons";
import { PageLoader } from "../general-component/PageLoader";

export const AdminVendorDetailModal = ({
  onClose,
  modal,
  vendorName,
  vendorAddress,
  creditScore,
  imageTitle,
  vendorBalance,
  image,
  vendorPhone,
  emailAddress,
  accountNumber,
  accountName,
  bankName,
  memoNumber,
  loading,
  isUser,
  verified,
}) => {
  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="admin-detail-vendor-info-modal">
              <div
                onClick={onClose}
                className="admin-detail-vendor-info-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="admin-detail-vendor-info-modal-container"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {loading ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="admin-modal-vendor-invoice-head">
                        <h3>{isUser ? "User" : "Vendor"} Detail</h3>
                        <span onClick={onClose}>
                          <CloseIcon color="#D13D2A" />
                        </span>
                      </div>
                      <div className="admin-vendor-main-container">
                        <div className="admin-vendor-main-container-head">
                          <img src={image ?? picHolder} alt={imageTitle} />
                          <span className="admin-multi-user-header-case">
                            <h4 className="admin-multi-user-header">
                              <p className="admin-multi-user-header-text">
                                {vendorName}
                              </p>
                              {verified && (
                                <AdminUserDistinctIcon svgColor="#8A44B2" />
                              )}
                            </h4>
                            <span className="admin-multi-user-sub-header-case">
                              {vendorPhone && vendorPhone !== "None" && (
                                <h4 className="admin-multi-user-sub-header">
                                  Phone:
                                  <h3 className="admin-multi-user-sub-title">
                                    {vendorPhone}
                                  </h3>
                                </h4>
                              )}
                              {emailAddress && (
                                <h4 className="admin-multi-user-sub-header">
                                  Email:
                                  <h3 className="admin-multi-user-sub-title">
                                    {emailAddress}
                                  </h3>
                                </h4>
                              )}
                            </span>
                          </span>
                        </div>
                        <div className="admin-multi-user-holder-case">
                          <section>
                            <span className="double-label-holder">
                              <h6 className="admin-small-head">Vendor Name</h6>

                              <h2 className="admin-name">{vendorName}</h2>
                            </span>

                            {/* <span className="double-label-holder">
                              <h6 className="admin-small-head">
                                Vendor Address
                              </h6>

                              <h2 className="admin-name">{vendorAddress}</h2>
                            </span> */}

                            {bankName && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">Bank Name</h6>

                                <h2 className="admin-name">{bankName}</h2>
                              </span>
                            )}
                            {accountName && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Account Name
                                </h6>
                                <h2 className="admin-name">{accountName}</h2>
                              </span>
                            )}
                          </section>

                          <section>
                            {creditScore && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Vendor Credit Score
                                </h6>
                                <h2 className="admin-name">{creditScore}</h2>
                              </span>
                            )}

                            {vendorBalance && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Vendor Wallet
                                </h6>

                                <h2 className="admin-name">{vendorBalance}</h2>
                              </span>
                            )}

                            {accountNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Account Number
                                </h6>

                                <h2 className="admin-name">{accountNumber}</h2>
                              </span>
                            )}

                            {memoNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  memo Number
                                </h6>

                                <h2 className="admin-name">{memoNumber}</h2>
                              </span>
                            )}
                          </section>
                        </div>
                      </div>
                    </>
                  )}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
    </>
  );
};
