import React, { useEffect, useState } from "react";
import { LeftArrowIcon, UploadInvoiceFile } from "../../icons";
import "../../../styles/seller/dashboard/upload-invoice-modal.scss";
import { OptimizedInput } from "../../form/OptimizedInput";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { validateInvoiceInfo } from "../../../util/validate/validateInvoiceInfo";
import { validateInvoiceUpload } from "../../../util/validate/validateInvoiceUpload";
import { ActivationSuccessModal } from "./ActivationSuccessModal";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import { formatAmount } from "../../../util/formatAmount";
import { validateDTI } from "../../../util/validate/validateDTI";
import moment from "moment";
export const UploadInvoiceModal = (props) => {
  const { toggleChange, modal, loadData } = props;

  const [invoiceName, setInvoiceName] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [invoiceNameError, setInvoiceNameError] = useState(null);
  const [invoiceAmountError, setInvoiceAmountError] = useState(null);
  const [invoiceDateError, setInvoiceDateError] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);

  const [vendorName, setVendorName] = useState("");
  const [vendorContactName, setVendorContactName] = useState("");
  const [vendorPhoneNumber, setVendorPhoneNumber] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorNameError, setVendorNameError] = useState(null);
  const [vendorContactNameError, setVendorContactNameError] = useState(null);
  const [vendorPhoneNumberError, setVendorPhoneNumberError] = useState(null);
  const [vendorEmailError, setVendorEmailError] = useState(null);

  // amount shown in the bottom text
  const [actualAmount, setActualAmount] = useState(null);
  const [amountLoading, setAmountLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // vendor email suggestion
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [vendor, setVendor] = useState();
  const [vendorLoading, setVendorLoading] = useState(false);

  const [formValues, setFormvalues] = useState({
    step: 1,
  });

  const handleInfoSubmit = (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateInvoiceInfo({
      name: invoiceName.trim(),
      amount: invoiceAmount.trim(),
      dueDate: invoiceDate.trim(),
      invoice: uploadFile,
    });

    if (!isValid) {
      if (error.name) setInvoiceNameError(error.name);
      if (error.amount) setInvoiceAmountError(error.amount);
      if (error.dueDate) setInvoiceDateError(error.dueDate);
      if (error.invoice) setUploadFileError(error.invoice);
    } else {
      const { step } = formValues;
      setFormvalues({ ...formValues, step: step + 1 });
    }
  };

  const { step } = formValues;

  // const nextStep = () => {
  //   const { step } = formValues;
  //   setFormvalues({ ...formValues, step: step + 1 });
  // };

  const prevStep = () => {
    const { step } = formValues;
    setFormvalues({ ...formValues, step: step - 1 });
  };

  const onLoad = () => {
    setIsSuccess(false);
    loadData();
  };

  const clearInput = () => {
    setInvoiceName("");
    setInvoiceAmount("");
    setInvoiceDate("");
    setUploadFile("");
    setVendorName("");
    setVendorContactName("");
    setVendorPhoneNumber("");
    setVendorEmail("");
    setInvoiceNameError(null);
    setInvoiceAmountError(null);
    setInvoiceDateError(null);
    setUploadFileError(null);
    setVendorNameError(null);
    setVendorContactNameError(null);
    setVendorPhoneNumberError(null);
    setVendorEmailError(null);
  };

  const onClose = () => {
    toggleChange();
    clearInput();
  };

  const handleInvoiceUpload = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateInvoiceUpload({
      vendorName: vendor ? vendor.vendor_name : vendorName.trim(),
      contactName: vendor ? vendor.vendor_name : vendorContactName.trim(),
      phoneNumber: vendor ? vendor.vendor_phone : vendorPhoneNumber.trim(),
      email: vendor ? vendor.vendor_email : vendorEmail.toLowerCase().trim(),
    });

    if (!isValid) {
      if (error.vendorName) setVendorNameError(error.vendorName);
      if (error.contactName) setVendorContactNameError(error.contactName);
      if (error.phoneNumber) setVendorPhoneNumberError(error.phoneNumber);
      if (error.email) setVendorEmailError(error.email);
    } else {
      const token = localStorage.getItem("token");
      try {
        const { step } = formValues;
        setFormvalues({ ...formValues, step: 0 });
        toggleChange();
        setIsLoading(true);

        const formdata = new FormData();
        formdata.append("file", uploadFile, `${invoiceName} file`);
        formdata.append("invoice_name", invoiceName.trim());
        formdata.append("invoice_amount", invoiceAmount.trim());
        formdata.append("invoice_date", invoiceDate.trim());
        formdata.append(
          "vendor_name",
          vendor ? vendor.vendor_name : vendorName.trim()
        );
        formdata.append(
          "vendor_phone",
          vendor ? vendor.vendor_phone : vendorPhoneNumber.trim()
        );
        formdata.append(
          "vendor_email",
          vendor ? vendor.vendor_email : vendorEmail.toLowerCase().trim()
        );
        formdata.append(
          "vendor_contact",
          vendor ? vendor.vendor_name : vendorContactName.trim()
        );

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const body = formdata;

        const { data } = await axios.post(
          `${BACKEND_URL}/upload_invoice/${token}`,
          body,
          config
        );
        console.log(data);

        setIsLoading(false);

        if (data.success) {
          setIsSuccess(true);
          clearInput();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      console.log(invoiceDate.trim());
      const { isValid, error } = validateDTI({
        amount: invoiceAmount.trim(),
        dueDate: invoiceDate.trim(),
      });

      if (!isValid) {
        if (error.amount || error.dueDate) setActualAmount(null);
      } else {
        const token = localStorage.getItem("token");
        try {
          setAmountLoading(true);

          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const body = {
            invoice_amount: parseInt(invoiceAmount.trim()),
            invoice_date: invoiceDate.trim(),
          };
          console.log(body);
          const { data } = await axios.post(
            `${BACKEND_URL}/dti/${token}`,
            body,
            config
          );
            console.log(data, "dtu")
          setAmountLoading(false);
          if (data.success) {
            setActualAmount(data.recievable_amount);

          }
        } catch (error) {
          setActualAmount(null);
          console.error(error);
        }
      }
    })();
  }, [invoiceAmount, invoiceDate]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      try {
        setVendorLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const body = {
          search: vendorName,
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/vendor_search_by_name_for_seller_upload`,
          body,
          config
        );

        console.log(data);

        setVendorLoading(false);

        if (data.success) {
          setSuggestions(data.vendors);
        }
      } catch (error) {
        // setActualAmount(null);
        console.error(error);
      }
    })();
  }, [vendorName]);

  switch (step) {
    case 1:
      return (
        <AnimatePresence>
          {modal && (
            <FadeTransition absolute>
              <div className="upload-activation-form-modal">
                <div
                  onClick={onClose}
                  className="upload-activation-form-overlay"
                >
                  <motion.div
                    className="upload-activation-modal-card"
                    onClick={(evt) => evt.stopPropagation()}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{ type: "spring" }}
                  >
                    <span onClick={onClose} className="control-icons">
                      <LeftArrowIcon />
                    </span>
                    <form onSubmit={handleInfoSubmit}>
                      <div className="upload-modal-content">
                        <div className="one">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Invoice Name"
                              name="Invoice Name"
                              placeholder="Enter Invoice name"
                              value={invoiceName}
                              onChange={(e) => setInvoiceName(e.target.value)}
                              type="text"
                              maxlength="30"
                              mt={0}
                              onFocus={() =>
                                invoiceNameError && setInvoiceNameError(null)
                              }
                              error={invoiceNameError}
                            />
                          </span>
                        </div>

                        <div className="upload-row two">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Invoice Amount"
                              name="Invoice Amount"
                              placeholder="Enter your Invoice Amount"
                              value={invoiceAmount}
                              onChange={(e) => setInvoiceAmount(e.target.value)}
                              type="text"
                              maxlength="30"
                              mt={0}
                              onFocus={() =>
                                invoiceAmountError &&
                                setInvoiceAmountError(null)
                              }
                              error={invoiceAmountError}
                            />
                          </span>
                        </div>

                        <div className="row three">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Invoice Due Date"
                              name="date"
                              placeholder="yyyy-mm-dd"
                              value={invoiceDate}
                              onChange={(e) =>
                                setInvoiceDate(
                                  moment(e.target.value).format("YYYY-MM-DD")
                                )
                              }
                              type="date"
                              maxlength="30"
                              mt={0}
                              onFocus={() =>
                                invoiceDateError && setInvoiceDateError(null)
                              }
                              error={invoiceDateError}
                            />
                          </span>
                          {/* {console.log(moment(invoiceDate).format("YYYY-MM-DD"))} */}
                        </div>

                        <div className="row four">
                          <span className="upload-form-carrier">
                            <h4 className="file-header">Upload File</h4>
                            <label for="upload-file">
                              <span className="file-placeholder-carrier">
                                {uploadFile ? (
                                  <p className="file-placeholder">
                                    {uploadFile.name}
                                    <span className="shift-present">
                                      <UploadInvoiceFile color="#565cd6" />
                                    </span>
                                  </p>
                                ) : (
                                  <p className="file-placeholder">
                                    File type - (png, jpeg, pdf,
                                    etc.){" "}
                                    <span className="shift">
                                      <UploadInvoiceFile />
                                    </span>
                                  </p>
                                )}
                              </span>

                              <input
                                className="file-input"
                                name="Upload File"
                                // value={uploadFile}
                                onChange={(evt) => {
                                  if (evt.target.files) {
                                    const file = evt.target.files[0];
                                    if (file.size > 2621440) {
                                      setUploadFileError(
                                        "File should not exceed 2.5mb"
                                      );
                                    } else {
                                      setUploadFile(evt.target.files[0]);
                                    }
                                  }
                                }}
                                onClick={() =>
                                  uploadFileError && setUploadFileError(null)
                                }
                                type="file"
                                id="upload-file"
                              />
                              {uploadFileError && (
                                <div className="error-text">
                                  {uploadFileError}
                                </div>
                              )}
                            </label>
                          </span>
                        </div>

                        <div className="last-row">
                          <span className="upload-form-carrier">
                            <button className="upload-modal-button">
                              Next
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>

                    <span className="statement-case">
                      {amountLoading ? (
                        <div className="actual-price-loading">
                          <div />
                          <div />
                          <div />
                        </div>
                      ) : actualAmount ? (
                        <p className="statement-section">
                          You are eligible to receive{" "}
                          <span>{formatAmount(actualAmount)}</span> on approval
                          and sale of this invoice please confirm before you
                          proceed
                        </p>
                      ) : (
                        <p>Waiting for a valid amount and due date</p>
                      )}
                    </span>
                  </motion.div>

                  {/* <AnimatePresence>
                {hasError && (
                  <motion.div
                    className="failure-modal"
                    initial={{ opacity: 0, y: "-100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "-100%" }}
                    transition={{ duration: 0.4 }}
                  >
                    <div>You have to fill all fields</div>
                  </motion.div>
                )}
             
             </AnimatePresence> */}
                </div>
              </div>
            </FadeTransition>
          )}
        </AnimatePresence>
      );
    case 2:
      return (
        <AnimatePresence>
          {modal && (
            <FadeTransition absolute>
              <div className="upload-activation-form-modal">
                <div
                  onClick={onClose}
                  className="upload-activation-form-overlay"
                >
                  <motion.div
                    className="upload-activation-modal-card"
                    onClick={(evt) => evt.stopPropagation()}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{ type: "spring" }}
                  >
                    <span onClick={prevStep} className="control-icons">
                      <LeftArrowIcon />
                    </span>

                    <form onSubmit={handleInvoiceUpload}>
                      <div className="upload-modal-content">
                        <div className="one">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Vendor Name"
                              name="Vendor Name"
                              placeholder="Enter Vendor name"
                              value={vendor ? vendor.vendor_name : vendorName}
                              onChange={(e) => {
                                setVendorName(e.target.value);
                                setVendor(undefined);
                                setIsOpen(true);
                              }}
                              onBlur={() => setIsOpen(false)}
                              type="text"
                              maxlength="30"
                              mt={0}
                              error={vendorNameError}
                              onFocus={() =>
                                vendorNameError && setVendorNameError(null)
                              }
                            />
                          </span>
                          <AnimatePresence>
                            {isOpen && (
                              <FadeTransition>
                                <div className="email-suggestion-container">
                                  {vendorLoading ? (
                                    <p>Loading...</p>
                                  ) : suggestions.length ? (
                                    suggestions.map((vendor) => (
                                      <div
                                        key={vendor["id "]}
                                        onClick={() => {
                                          setVendor(vendor);
                                          setIsOpen(false);
                                        }}
                                      >
                                        {vendor.vendor_name}
                                      </div>
                                    ))
                                  ) : (
                                    <p>Vendor not available</p>
                                  )}
                                </div>
                              </FadeTransition>
                            )}
                          </AnimatePresence>
                        </div>

                        <div className="row two">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Vendor Contact Name"
                              name="Vendor Contact Name"
                              placeholder="Enter your contact person name"
                              value={
                                vendor ? vendor.vendor_name : vendorContactName
                              }
                              onChange={(e) =>
                                setVendorContactName(e.target.value)
                              }
                              type="text"
                              maxlength="30"
                              mt={0}
                              disabled={vendor ? true : false}
                              error={vendorContactNameError}
                              onFocus={() =>
                                vendorContactNameError &&
                                setVendorContactNameError(null)
                              }
                            />
                          </span>
                        </div>

                        <div className="row three">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Vendor Email Address"
                              name="Vendor Email Address"
                              placeholder="Enter your contact person Email"
                              value={vendor ? vendor.vendor_email : vendorEmail}
                              disabled={vendor ? true : false}
                              onChange={(e) => setVendorEmail(e.target.value)}
                              type="text"
                              maxlength="30"
                              mt={0}
                              error={vendorEmailError}
                              onFocus={() =>
                                vendorEmailError && setVendorEmailError(null)
                              }
                            />
                          </span>
                        </div>

                        <div className="row four">
                          <span className="upload-form-carrier">
                            <OptimizedInput
                              title="Vendor Phone Number"
                              name="Vendor Phone Number"
                              placeholder="Enter your contact person number"
                              value={
                                vendor ? vendor.vendor_phone : vendorPhoneNumber
                              }
                              disabled={vendor ? true : false}
                              onChange={(e) =>
                                setVendorPhoneNumber(e.target.value)
                              }
                              type="text"
                              maxlength="30"
                              mt={0}
                              error={vendorPhoneNumberError}
                              onFocus={() =>
                                vendorPhoneNumberError &&
                                setVendorPhoneNumberError(null)
                              }
                            />
                          </span>
                        </div>

                        <div className="row five">
                          <span className="upload-form-carrier">
                            <button className="upload-modal-submit-button">
                              Upload Invoice
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>

                    <span className="statement-case">
                      {amountLoading ? (
                        <div className="actual-price-loading">
                          <div />
                          <div />
                          <div />
                        </div>
                      ) : actualAmount ? (
                        <p className="statement-section">
                          You are eligible to receive{" "}
                          <span>{formatAmount(actualAmount)}</span> on approval
                          and sale of this invoice please confirm before you
                          proceed
                        </p>
                      ) : (
                        <p>Waiting for a valid amount and due date</p>
                      )}
                    </span>
                  </motion.div>

                  {/* <AnimatePresence>
                      {hasError && (
                        <motion.div
                          className="failure-modal"
                          initial={{ opacity: 0, y: "-100%" }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: "-100%" }}
                          transition={{ duration: 0.4 }}
                        >
                          <div>You have to fill all fields</div>
                        </motion.div>
                      )}
                   
                   </AnimatePresence> */}
                </div>
              </div>
            </FadeTransition>
          )}
        </AnimatePresence>
      );
    default:
      return (
        <>
          {isLoading && (
            <div className="activation-form-overlay">
              <div className="loading" />
            </div>
          )}
          <ActivationSuccessModal
            modal={isSuccess}
            onLoad={onLoad}
            title="Uploaded Successfully"
            desc="Your invoice has been uploaded successfully"
          />
        </>
      );
  }
};
