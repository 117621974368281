import React from 'react'

export const TotalInvoicePayableIcon = () => {
  return (
    <svg 
        width="50" 
        height="50" 
        viewBox="0 0 50 50" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <rect 
        width="50" 
        height="50" 
        rx="8" 
        fill="#F3F3F3"
    />
    <path 
        d="M29.1887 26.1124L30.6353 26.0203L31.0284 25.9953C31.233 25.9823 31.3909 25.8258 31.3958 25.6312C31.4005 25.4427 31.2557 25.2799 31.0588 25.2527L30.6376 25.1942L29.2232 24.9979C29.2099 24.9962 29.1967 24.9951 29.1837 24.9951C29.0624 24.9951 28.9538 25.0723 28.9272 25.1832C28.882 25.3708 28.8436 25.6543 28.9175 25.9396V25.9397C28.9451 26.0463 29.0631 26.1195 29.1887 26.1124L29.1887 26.1124Z" 
        fill="#3FBB97"
    />
    <path 
        d="M16.997 35.9514C16.1889 35.9514 15.5338 35.3299 15.5338 34.5633C15.5338 34.2431 15.6491 33.9492 15.841 33.7145C15.2098 32.9313 14.8515 32.1968 14.6865 31.8037L12.265 35.0337C11.692 35.7352 11.8893 37.5933 14.4654 39.2281C17.0414 40.863 18.0989 39.3936 18.0989 39.3936L19.9647 36.3895C19.2161 36.1913 18.5617 35.9091 17.9937 35.5772C17.7325 35.8087 17.3826 35.9513 16.997 35.9513L16.997 35.9514Z" 
        fill="#3FBB97"
    />
    <path 
        d="M35.7502 28.4139C35.5993 28.271 35.5164 28.0807 35.5164 27.8785C35.5164 27.6763 35.5994 27.4861 35.7502 27.343L37.3459 25.8292C35.9565 25.2475 34.7983 26.3577 34.7983 26.3577L32.2819 28.5029C32.1843 28.5862 32.0577 28.6323 31.9263 28.6323H26.9615C26.8828 28.6323 26.8069 28.6041 26.7491 28.5534L25.4057 27.3754C25.3012 27.2837 25.3695 27.118 25.5119 27.118H29.1029C29.6092 27.118 30.0594 26.8938 30.3519 26.5451L29.2255 26.6168C29.2067 26.618 29.1878 26.6187 29.1693 26.6187C28.8048 26.6187 28.487 26.3909 28.4019 26.0608C28.3227 25.7548 28.3254 25.422 28.4095 25.0718C28.5005 24.6942 28.892 24.4432 29.3013 24.4994L30.3368 24.6431C30.0441 24.305 29.6001 24.0893 29.1029 24.0893H22.9917C22.9917 24.0893 20.109 23.6976 17.0425 28.6627L15.0557 31.313C15.0652 31.3287 15.0772 31.3425 15.0832 31.3603C15.0893 31.3787 15.414 32.3232 16.2524 33.3707C16.4709 33.2475 16.7253 33.1754 16.998 33.1754C17.8061 33.1754 18.4612 33.797 18.4612 34.5636C18.4612 34.779 18.4079 34.9823 18.3156 35.1642C18.8631 35.4832 19.4926 35.7525 20.2145 35.9328C20.2247 35.9353 20.2322 35.9419 20.2419 35.9455L20.7225 35.1716C22.2558 32.6891 25.202 32.8102 25.202 32.8102C30.387 32.8102 32.928 32.1442 32.928 32.1442C32.928 32.1442 34.8544 30.3216 36.2797 28.901C36.2634 28.8912 36.2456 28.8837 36.2317 28.8706L35.7502 28.4139Z" 
        fill="#3FBB97"
    />
    <path 
        d="M38.4494 25.7906C38.4064 25.7498 38.3498 25.7295 38.2934 25.7295C38.2369 25.7295 38.1802 25.7498 38.1372 25.7907L36.1258 27.6989C36.0755 27.7466 36.0479 27.8099 36.0479 27.8773C36.0479 27.9447 36.0756 28.008 36.1258 28.0557L36.4052 28.3209L38.0115 26.566L38.4602 26.0759C38.5357 25.9933 38.531 25.868 38.4494 25.7906L38.4494 25.7906Z" 
        fill="#3FBB97"
    />
    <path 
        d="M29.6247 11C28.1767 11 26.7881 11.5751 25.7644 12.5987C24.7407 13.6225 24.1657 15.0108 24.166 16.4583C24.1663 17.9057 24.7419 19.2938 25.7661 20.3171C26.7904 21.3401 28.1792 21.9146 29.6272 21.914C31.0752 21.9133 32.4636 21.3376 33.4869 20.3136C34.5101 19.2895 35.0845 17.9009 35.0835 16.4533C35.0815 15.0069 34.5056 13.6205 33.4822 12.5981C32.4588 11.5756 31.0716 11.001 29.6247 11V11ZM31.0949 18.5495C30.8144 18.8196 30.4396 18.9695 30.0502 18.9672H29.9979V19.8139C29.9979 20.0199 29.8308 20.1869 29.6247 20.1869C29.4187 20.1869 29.2516 20.0199 29.2516 19.8139V18.9672H28.4494C28.2434 18.9672 28.0763 18.8003 28.0763 18.5942C28.0763 18.3882 28.2434 18.2212 28.4494 18.2212H30.065C30.2606 18.2207 30.4485 18.1461 30.5912 18.0124C30.7243 17.8846 30.7998 17.7083 30.8001 17.5238C30.7953 17.3318 30.7135 17.1498 30.5732 17.0186C30.433 16.8875 30.2458 16.8181 30.0539 16.8262H29.1957C28.8064 16.8342 28.4301 16.6867 28.1499 16.4164C27.8699 16.1463 27.709 15.7755 27.7032 15.3863C27.7088 14.9957 27.8733 14.6242 28.1584 14.3569C28.4374 14.0899 28.8094 13.9415 29.1957 13.943H29.248V13.0924C29.248 12.8864 29.4149 12.7194 29.6211 12.7194C29.8271 12.7194 29.9942 12.8864 29.9942 13.0924V13.9428H30.8151V13.943C31.0211 13.943 31.1882 14.1098 31.1882 14.316C31.1882 14.522 31.0211 14.689 30.8151 14.689H29.1957C29.0009 14.6878 28.8135 14.7627 28.6733 14.8978C28.5397 15.0253 28.4642 15.2019 28.4644 15.3864C28.4691 15.578 28.551 15.7597 28.6914 15.8902C28.8319 16.0208 29.0191 16.0892 29.2107 16.0802H30.0613C30.4508 16.0732 30.8272 16.2214 31.1072 16.4924C31.3872 16.7631 31.5478 17.1343 31.5538 17.5238C31.5462 17.9136 31.3804 18.2837 31.0949 18.5495L31.0949 18.5495Z" 
        fill="#3FBB97"
    />
</svg>

  )
}
