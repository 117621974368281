import React from 'react'

export const UpwardArrowIcon = ({color}) => {
  return (
    <svg 
        width="11" 
        height="7" 
        viewBox="0 0 11 7" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M6.06195 0.300248L10.9366 6.89479L5.97578 4.79942L0.938397 6.7033L6.06195 0.300248Z" 
        fill={color || "#414141"}
    />
    </svg>

  )
}
