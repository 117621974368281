// import { useState } from "react";
import "../../styles/uneditable-input-editor.scss";

const UnEditableInputEditor = (props) => {
  const { icon, nameLabel, nameTitle } = props;
//   const [open, setOpen] = useState();

//   const toggleOpen = () => {
//     setOpen(!open);
//   };

  return (
    <div className="uneditable-input-editor-container">
      {/* {open ? (
        <section className="first-show">
          <input
            type="text"
            className="editor-input"
            onChange={onChange}
            value={value}
          />

          <span className="confirm-presser" onClick={toggleOpen}>
            Confirm
          </span>
        </section>
      ) : ( */}
        <section className="uneditable-first-show">
          <span className="uneditable-name">
            <h3 className="editor-label">{nameLabel}</h3>
            <h3 className="editor-name">{nameTitle}</h3>
          </span>

          {icon && (
            <span className="pencil-icon" >
              {icon}
            </span>
          )}
        </section>
      {/* )} */}
    </div>
  );
};

export default UnEditableInputEditor;