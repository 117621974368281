import React, {useState} from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/seller/dashboard/mobile-uploader-withdraw-modal.scss";
import { UploadInvoiceModal } from "./UploadInvoiceModal";

export const MobileUpLoaderWithdrawModal = (props) => {
  const { controlModal, modal, fundWithdrawal} = props;

  const [appear, setAppear] = useState(false);




  const controlAppear = () => {
    setAppear(!appear);
  };
  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={controlModal} className="overlay"></div>
          <div className="mobile-withdraw-modal-card">
            <span onClick={controlModal} className="close-modal-presser">
              <CloseIcon />
            </span>
            <div className="uploader-withdraw-container">
              <button onClick={fundWithdrawal} className="withdrawal-presser">
                Withdraw
              </button>
              <button
                onClick={controlAppear}
                with
                className="uploader-presser"
              >
                Upload Invoice
              </button>
            </div>
          </div>
        </div>
      )}

      <UploadInvoiceModal
          toggleChange={controlAppear}
          modal={appear}
      
      />
    </>
  );
};
