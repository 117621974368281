import axios from "axios";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/seller/invoice/invoice-card.scss";
import { formatAmount } from "../../../util/formatAmount";
import { MobileInvoiceDetailModal } from "./MobileInvoiceDetailModal";

const InvoiceCard = (props) => {
  const {
    invoiceName,
    amount,
    payAmount,
    payRate,
    vendor,
    dueDate,
    status,
    id,
  } = props;

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState();

  const controlModal = () => {
    setModal(!modal);
  };

  const onModalOpen = async () => {
    const token = localStorage.getItem("token");

    setModal(true);

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/invoice_details/${id}`,
        config
      );
      setIsLoading(false);

      console.log(data);

      if (data.success) setInvoiceData(data.invoice_details[0]);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <tr className="invoice-cards" onClick={onModalOpen}>
        <td>{invoiceName}</td>
        <td>₦{amount}</td>
        <td
          style={{
            color: payRate > 5 ? "blue" : payRate < 4.5 ? "red" : "yellow",
          }}
        >
          {(100 - payRate).toFixed(2)}%
        </td>

        <td>₦{payAmount}</td>
        <td>{vendor}</td>
        <td>
          <span
            className="indicator pending"
            style={{ display: status === "pending" ? "flex" : "none" }}
          >
            Pending
          </span>

          <span
            className="indicator approved"
            style={{ display: status === "approved" ? "flex" : "none" }}
          >
            Approved
          </span>

          <span
            className="indicator sold"
            style={{ display: status === "sold" ? "flex" : "none" }}
          >
            Sold
          </span>

          <span
            className="indicator closed"
            style={{ display: status === "closed" ? "flex" : "none" }}
          >
            Closed
          </span>

          <span
            className="indicator rejected"
            style={{ display: status === "rejected" ? "flex" : "none" }}
          >
            Rejected
          </span>
        </td>
      </tr>

      <MobileInvoiceDetailModal
        controlModal={controlModal}
        modal={modal}
        loading={isLoading}
        dueDate={invoiceData?.due_date}
        transPrice={amount}
        transName={invoiceName}
        dueTime={invoiceData?.bid_close_time}
        ROI={invoiceData?.max_discount.toFixed(2)}
        credit={invoiceData?.seller_credit_score}
        bidderNumber={invoiceData?.no_of_bidders}
        sellerName={invoiceData?.seller_name}
        vendorPhoneNumber={invoiceData?.vendor_phone}
        payRate={payRate}
        vendorEmail={invoiceData?.vendor_email}
        maxPayable={formatAmount(invoiceData?.payable_amount_by_investor)}
        invoiceAmount={formatAmount(invoiceData?.invoice_amount)}
        vendorName={invoiceData?.vendor_name}
        invoiceName={invoiceName}
        statusIndicator={status}
        invoiceURL={invoiceData?.invoice_url}
      />
    </>
  );
};

export default InvoiceCard;
