import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export const FadeTransition = ({ children, absolute }) => (
  <motion.div
    initial="hidden"
    animate="enter"
    exit="exit"
    variants={variants}
    style={{ position: absolute ? "absolute" : "initial" }}
    transition={{ type: "linear", delay: 0.1 }}
  >
    {children}
  </motion.div>
);
