export const convertStatus = (status) => {
  switch (status) {
    case "Pending Invoices":
      return 0;
    case "Approved Invoices":
      return 1;
    case "Sold Invoices":
      return 2;
    case "Due Invoices":
      return 2;
    case "Closed Invoices":
      return 3;
    case "Rejected Invoices":
      return 4;

    default:
      return null;
  }
};
