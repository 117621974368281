import React from "react";
import { TotalInvoicePayableIcon } from "../../../icons";
import "../../../../styles/admin/admin-financial/admin-financial-card.scss";



const TotalInvoicesPayableCard = ({ amount, totalNumber }) => {
  return (
    <div className="admin-financial-card-container" style={{ background: "#3FBB97" }}>
      <div className="stat-svg-container">
      <div className="stat-top-container">
        <div className="first-part">
          <span className="top-section">
            <TotalInvoicePayableIcon />
          </span>
          <span className="bottom-section">
            <h3 className="header-text">Total Invoice Payable</h3>
            <h3 className="digit">₦{amount}</h3>
          </span>
        </div>
        <div className="second-part">
          <span className="progress-bar-holder"></span>
        </div>
      </div>
        <div className="bottomText">
          <p>
            Invoices: 
            <span
              style={{color: "#3FBB97"}}
            >
              {totalNumber}
            </span>
          </p>
      </div>
      </div>
    </div>
  );
};

export default TotalInvoicesPayableCard;