import React from 'react'

const ReportIcon = ({svgColor}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.78947 17.0526L0 20.7663V10.1053H3.78947V17.0526ZM10.1053 14.7284L8.12211 13.0358L6.31579 14.7032V5.05263H10.1053V14.7284ZM16.4211 12.6316L12.6316 16.4211V0H16.4211V12.6316ZM19.9705 12.3916L17.6842 10.1053H24V16.4211L21.7389 14.16L12.6316 23.1916L8.24842 19.3768L3.47368 24H0L8.17263 15.9916L12.6316 19.7558" fill={svgColor}/>
        </svg>   
    )
}

export default ReportIcon
