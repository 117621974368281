import { useNavigate } from "react-router";
import { LeftArrowIcon } from "../components/icons";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import "../styles/index.scss";
import styles from "../styles/email-verification.module.scss";
import EmailConfirmation from "../components/icons/EmailConfirmation";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../config";

const ResetLink = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = localStorage.getItem("resetEmail");

  const svgColor = (accountType) => {
    switch (accountType) {
      case "investor":
        return "#3FBB97";
      case "seller":
        return "#565CD6";
      case "vendor":
        return "#8A44B2";
      default:
        return "#c4c4c4";
    }
  };

  return (
    <div className="main-container">
      <span className={styles.topLogo}>
        <MainLogo />

        {/* {resend !== "" && <p className={styles.popUp}>A new verification code was just sent</p> }  */}
      </span>

      <div className="page-card">
        <span className="mobile-logo">
          <MobileLogo />
        </span>

        <div className="card-button">
          <p className={styles.verifyLogo}>
            <EmailConfirmation svgColor={svgColor(searchParams.get("acc"))} />
          </p>

          <h2 className={styles.header}>Password reset</h2>

          <p className={styles.statement}>
            Hello! Thank you for registering on Quidroo. We’ve sent a message to
            <span className={styles.emailColor}> {email}</span> with a link to
            reset your password.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetLink;
