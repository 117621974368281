import React, { useState } from "react";
import "../../../styles/investor/investor-dashboard-styles/investor-transaction-card.scss";
import { TransactionModalCard } from "../../seller-component/dashboard-component";
// import { TransactionModalCard } from "./index";

export const InvestorTransactionCard = (props) => {
  const {
    title,
    amount,
    date,
    time,
    content,
    currency,
    // account,
    isDebit,
  } = props;
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  const shortContent = content.substring(0, 30) + "...";
  const shortTitle = title.substring(0, 18) + "...";
  return (
    <div onClick={toggleModal} className="transaction-card-container">
      <div className="transaction-card-upper">
        <h3 className="transaction-title">{shortTitle}</h3>
        {currency === "NGN" ? (
          <h3 className={`transaction-amount ${isDebit && "debit"}`}>
            {`${isDebit ? "-" : "+"}₦${amount}`}
          </h3>
        ) : (
          <h3 className={`transaction-amount ${isDebit && "debit"}`}>
            {`${isDebit ? "-" : "+"}$${amount}`}
          </h3>
        )}
      </div>
      <div className="transaction-card-middle">
        <h5 className="transaction-reference">{shortContent}</h5>
      </div>
      <div className="transaction-card-bottom">
        <h5 className="transaction-date">{date}</h5>
        <h5 className="transation-time">{time}</h5>
      </div>
      <TransactionModalCard
        toggleChange={toggleModal}
        modal={modal}
        title={title}
        amount={amount}
        date={date}
        time={time}
        content={content}
        currency={currency}
        // account={account}
      />
    </div>
  );
};
