import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FadeTransition } from "../FadeTransition";
import { AdminUserDistinctIcon, CloseIcon } from "../icons";
import "../../styles/admin/admin-investor-detail-modal.scss";
import picHolder from "../../assest/blank-profile-picture.png";
import { PageLoader } from "../general-component/PageLoader";

export const AdminInvestorDetailModal = (props) => {
  const {
    onClose,
    modal,
    investorName,
    investorAddress,
    accountNumber,
    accountName,
    isUser,
    country,
    passportNumber,
    passportUrl,
    investorBalance,
    imageTitle,
    image,
    phoneNumber,
    emailAddress,
    bankName,
    memoNumber,
    loading,
    user_id,
  } = props;
  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="admin-detail-investor-info-modal">
              <div
                onClick={onClose}
                className="admin-detail-investor-info-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="admin-detail-investor-info-modal-container"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {loading ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="admin-modal-investor-invoice-head">
                        <h3>{isUser ? "User" : "Investor"} Detail</h3>
                        <span onClick={onClose}>
                          <CloseIcon color="#D13D2A" />
                        </span>
                      </div>
                      <div className="admin-investor-main-container">
                        <div className="admin-investor-main-container-head">
                          <img src={image ?? picHolder} alt={imageTitle} />
                          <span className="admin-multi-user-header-case">
                            <h4 className="admin-multi-user-header">
                              <p className="admin-multi-user-header-text">
                                {investorName}
                              </p>
                              <AdminUserDistinctIcon svgColor="#3FBB97" />
                            </h4>
                            <span className="admin-multi-user-sub-header-case">
                              {phoneNumber && phoneNumber !== "None" && (
                                <h4 className="admin-multi-user-sub-header">
                                  Phone:
                                  <h3 className="admin-multi-user-sub-title">
                                    {phoneNumber}
                                  </h3>
                                </h4>
                              )}
                              <h4 className="admin-multi-user-sub-header">
                                Email:
                                <h3 className="admin-multi-user-sub-title">
                                  {emailAddress}
                                </h3>
                              </h4>
                            </span>
                          </span>
                        </div>
                        <div className="admin-multi-user-holder-case">
                          <section>
                            <span className="double-label-holder">
                              <h6 className="admin-small-head">
                                Investor Name
                              </h6>

                              <h2 className="admin-name">{investorName}</h2>
                            </span>

                            {investorAddress && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Investor Address
                                </h6>

                                <h2 className="admin-name">
                                  {investorAddress}
                                </h2>
                              </span>
                            )}
                            {accountName && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Account Name
                                </h6>

                                <h2 className="admin-name">{accountName}</h2>
                              </span>
                            )}
                            {passportNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Passport Number
                                </h6>

                                <h2 className="admin-name">{passportNumber}</h2>
                              </span>
                            )}
                            {memoNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Memo Number
                                </h6>

                                <h2 className="admin-name">{memoNumber}</h2>
                              </span>
                            )}
                          </section>

                          <section>
                            {country && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  companyNumber
                                </h6>

                                <h2 className="admin-name">{country}</h2>
                              </span>
                            )}
                            {bankName && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">Bank Name</h6>

                                <h2 className="admin-name">{bankName}</h2>
                              </span>
                            )}
                            {accountNumber && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Account Number
                                </h6>

                                <h2 className="admin-name">{accountNumber}</h2>
                              </span>
                            )}
                            {passportUrl && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Passport Url
                                </h6>

                                <h2 className="admin-name ">
                                  <a
                                    className="passport-url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={passportUrl}
                                  >
                                    Passport Image Url
                                  </a>
                                </h2>
                              </span>
                            )}

                            {investorBalance && (
                              <span className="double-label-holder">
                                <h6 className="admin-small-head">
                                  Investor Wallet
                                </h6>

                                <h2 className="admin-name">
                                  {investorBalance}
                                </h2>
                              </span>
                            )}
                          </section>
                        </div>
                      </div>
                    </>
                  )}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
    </>
  );
};
