import axios from "axios";
import React, { useEffect, useState } from "react";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { PlusIcon } from "../../components/icons";
import BidsInvoiceCard from "../../components/investor-component/bids/BidsInvoiceCard";
import { MobileInvestorInvoiceCard } from "../../components/investor-component/bids/MobileInvestorInvoiceCard";
import { MobileDepositWithdrawModal } from "../../components/investor-component/dashboard/MobileDepositWithdrawModal";
import { InvestorStatusBar } from "../../components/investor-component/InvestorStatusBar";
import FilterOptionDropdown from "../../components/seller-component/invoice/FilterOptionDropdown";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import MobileFilterOptionDropdown from "../../components/seller-component/invoice/MobileFilterOptionDropdown";
import { BACKEND_URL } from "../../config";
import "../../styles/investor/bids-styles/bid-pages.scss";
import { formatAmount } from "../../util/formatAmount";

const invoiceCardData = [
  {
    amount: "150,000.00",
    topBid: "4.5",
    timeRemaining: "48:11:35",
    yourBid: "4.5",
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    amount: "150,000.00",
    topBid: "4.5",
    timeRemaining: "48:11:35",
    yourBid: "9.5",
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    amount: "150,000.00",
    topBid: "4.5",
    timeRemaining: "48:11:35",
    yourBid: "4.5",
    invoiceName: "Road Project Supplies Invoice",
  },
];

const invoiceStatus = ["All Bids", "Open Bids", "Sold Bids"];
const invoiceDueDate = [
  "Today",
  "Last 7 Days",
  "Last 30 days",
  "Last 3 Months",
  "Last 6 Months",
];

const InvestorBids = () => {
  const [filterStatus, setFilterStatus] = useState("Bid Status");
  const [filterDate, setFilterDate] = useState("Bids Due Date");
  const [bidData, setBidData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [expire, setExpire] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  const loadBids = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/investor_bid_page/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setBidData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (filterStatus === "All Bids" || filterStatus === "Bid Status") {
        loadBids();
      } else {
        const token = localStorage.getItem("token");
        try {
          setIsLoading(true);

          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const status = () => {
            switch (filterStatus) {
              case "Open Bids":
                return 1;

              case "Sold Bids":
                return 2;

              default:
                return null;
            }
          };
          const body = {
            search: status(),
          };

          const { data } = await axios.post(
            `${BACKEND_URL}/investor_bid_search/${token}`,
            body,
            config
          );
          console.log(data);
          setIsLoading(false);

          if (data.success) setBidData(data);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [filterStatus, filterDate]);

  useEffect(() => {
    loadBids();
  }, []);
  {
    console.log(filterStatus);
  }
  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  return (
    <>
      <section>
        <InvestorStatusBar
          ownerName={`Bids`}
          pending={bidData?.providedVerificationDetails}
          activated={bidData?.verified}
          loadData={loadBids}
        />
      </section>
      <div className="bid-container">
        <div className="bid-half-filter">
          <span className="bid-filter input-bid mobile-hide">
            <p className="bid-filter mobile-hide">Bids</p>
            <FilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
              height={159}
            />
          </span>
          <span className="bid-filter input-second mobile-hide">
            {/* <FilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
            /> */}
          </span>

          <span className=" mobile-bid-input-filter window-hide">
            <MobileFilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
              width="154px"
              height={100}
            />

            {/* <MobileFilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
              width="154px"
            /> */}
          </span>
        </div>

        <div className="investor-bid-scroll">
          <div className="second-half mobile-hide">
            <table className="bid-invoice-table">
              <tbody>
                <tr className="bid-invoice-header table">
                  <th className="invoice-name">Invoice Name</th>
                  <th className="invoice-amount">Amount</th>
                  <th className="invoice-top-bid">Top Bids</th>
                  <th className="invoice-your-bid">Your Bids</th>
                  <th className="invoice-time">Time Remaining</th>
                  <th className="invoice-due-date">{"     "}</th>
                </tr>
              </tbody>
            </table>
          </div>
          {console.log(bidData, "bi data")}
          <div className="bid-invoice-scroll">
            {bidData?.bids && bidData?.bids.length ? (
              <div className="bid-invoice-data-holder mobile-hide">
                {bidData?.bids.map((invoice, index) => (
                  
                  <BidsInvoiceCard
                    key={invoice["invoice_id "]}
                    id={invoice.bid_id}
                    // amount={invoice.payable_amount}
                    amount={invoice.invoice_amount}
                    // amountUSD={invoice.payable_amount_USD}
                    amountUSD={invoice.invoice_amount_USD}
                    // topBid={invoice.top_bid.toFixed(2)}
                    topBid={invoice.top_bid}
                    top_bidUSD={invoice.top_bidUSD}
                    timeRemaining={invoice.bid_close_time}
                    status={invoice.invoice_status}
                    yourBid={invoice.payable_amount}
                    yourBidUSD={invoice.payable_amount_USD}
                    invoiceName={invoice["invoice_name "]}
                    url={invoice.invoice_url}
                    ROI={invoice.investor_dti}
                    originalAmount={invoice.invoice_amount}
                    loadBids={loadBids}
                    maxPayable={invoice.maxPayable}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-invoice mobile-hide">
                <InvoiceEmptyIcon />
                <p>You currently do not have any Invoices </p>
              </div>
            )}
            {bidData?.bids && bidData?.bids.length ? (
              <div className="window-hide">
                {bidData?.bids.map((invoice, index) => (
                  <MobileInvestorInvoiceCard
                    key={invoice["invoice_id "]}
                    id={invoice.bid_id}
                    amount={invoice.invoice_amount}
                    amountUSD={invoice.invoice_amount_USD}
                    ROI={invoice.investor_dti}
                    // topBid={invoice.top_bid.toFixed(2)}
                    topBid={invoice.top_bid}
                    top_bidUSD={invoice.top_bidUSD}
                    timeRemaining={invoice.bid_close_time}
                    // yourBid={invoice.investor_dti.toFixed(2)}
                    yourBid={invoice.payable_amount}
                    yourBidUSD={invoice.payable_amount_USD}
                    invoiceName={invoice["invoice_name "]}
                    url={invoice.invoice_url}
                    loadBids={loadBids}
                    maxPayable={invoice.maxPayable}
                    originalAmount={invoice.invoice_amount}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-invoice window-hide">
                <InvoiceEmptyIcon />
                <p>You currently do not have any Invoices </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="window-hide">
        <button
          onClick={controlModal}
          className="investor-mobile-uploader-btn window-hide"
        >
          <PlusIcon />
        </button>

        <MobileDepositWithdrawModal controlModal={controlModal} modal={modal} />
      </div>
    </>
  );
};

export default InvestorBids;
