import React from 'react'

export const UploadInvoiceFile = ({color}) => {
  return (
    <svg 
        width="19" 
        height="24" 
        viewBox="0 0 19 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M8.63636 13.8214H5.72159C5.66433 13.8214 5.60941 13.844 5.56892 13.8842C5.52843 13.9244 5.50568 13.9789 5.50568 14.0357V15.3214C5.50568 15.3783 5.52843 15.4328 5.56892 15.473C5.60941 15.5131 5.66433 15.5357 5.72159 15.5357H8.63636V18.4286C8.63636 18.4854 8.65911 18.5399 8.6996 18.5801C8.74009 18.6203 8.79501 18.6429 8.85227 18.6429H10.1477C10.205 18.6429 10.2599 18.6203 10.3004 18.5801C10.3409 18.5399 10.3636 18.4854 10.3636 18.4286V15.5357H13.2784C13.3357 15.5357 13.3906 15.5131 13.4311 15.473C13.4716 15.4328 13.4943 15.3783 13.4943 15.3214V14.0357C13.4943 13.9789 13.4716 13.9244 13.4311 13.8842C13.3906 13.844 13.3357 13.8214 13.2784 13.8214H10.3636V10.9286C10.3636 10.8717 10.3409 10.8172 10.3004 10.777C10.2599 10.7369 10.205 10.7143 10.1477 10.7143H8.85227C8.79501 10.7143 8.74009 10.7369 8.6996 10.777C8.65911 10.8172 8.63636 10.8717 8.63636 10.9286V13.8214ZM18.7463 6.01875C18.9082 6.17946 19 6.39643 19 6.62411V23.1429C19 23.617 18.6141 24 18.1364 24H0.863636C0.385937 24 0 23.617 0 23.1429V0.857143C0 0.383036 0.385937 0 0.863636 0H12.3257C12.5551 0 12.7764 0.0910715 12.9384 0.251786L18.7463 6.01875ZM17.0082 7.01786L11.929 1.97679V7.01786H17.0082Z" 
        fill={color || "#818181"}
    />
    </svg>

  )
}
