import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CloseIcon } from "../../icons";
import "../../../styles/investor/investor-dashboard-styles/investor-active-bid-editor-modal.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { validateBid } from "../../../util/validate/validateBid";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";

const InvestorActiveBidEditorModal = (props) => {
  const { modal, controlModal, url, invoiceAmount, ROI, id, loadInvestor, maxPayable } =
    props;
// console.log(invoiceAmount)
  const [bid, setBid] = useState("");
  const [bidError, setBidError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleBidPlacing = async (evt) => {
    evt.preventDefault();

    const { isValid, error } = validateBid(bid, maxPayable);
// console.log(invoiceAmount)
    if (!isValid) {
      setBidError(error);
    } else {
      const token = localStorage.getItem("token");

      try {
        setIsLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const amount = parseFloat(bid);
        const dti = (1-(amount/invoiceAmount))*100;
        const body = {
          amount: bid,
          buyer_dti: dti,
          bid_id: id,
        };

        // const amount = (1 - parseFloat(bid) / 100) * invoiceAmount;

        // const body = {
        //   amount: amount.toFixed(2),
        //   buyer_dti: bid,
        //   bid_id: id,
        // };

        console.log(body);

        const { data } = await axios.post(
          `${BACKEND_URL}/edit_bid/${token}`,
          body,
          config
        );

        setIsLoading(false);

        if (data.success) {
          setSuccess(true);
          controlModal();
          loadInvestor();

          setTimeout(() => {
            setSuccess(false);
          }, 3000);
          return;
        }

        if (data.status === 201) {
          setError(data.message);
          setSuccess(true);

          setTimeout(() => setSuccess(false), 2000);
          setTimeout(() => setError(null), 2500);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="investor-active-bid-modal">
              <div
                onClick={controlModal}
                className="investor-active-bid-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="investor-bid-editor-modal"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <div className="investor-active-head">
                    <span onClick={controlModal}>
                      <CloseIcon />
                    </span>
                  </div>

                  <form onSubmit={handleBidPlacing}>
                    <div className="active-bid-editor-holder">
                      <input
                        type="text"
                        placeholder="Enter your bid here..."
                        className="active-bid-editor"
                        onChange={(evt) => {
                          bidError && setBidError(null);
                          setBid(evt.target.value);
                        }}
                        value={bid}
                      />
                      {bidError && <div className="error-text">{bidError}</div>}
                    </div>

                    <div className="invest-active-preview-holder">
                      <button className="active-place-bid-btn">
                        Place Bid
                      </button>

                      <p className="invest-active-preview">
                        To preview Invoice,
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          Click here
                        </a>
                      </p>
                    </div>
                  </form>
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
      {isLoading && <ModalLoader />}

      <AnimatePresence>
        {success && <Toolkit text={error ?? "Bid Created"} />}
      </AnimatePresence>
    </>
  );
};

export default InvestorActiveBidEditorModal;
