export const MobileVendor = () => {
  return (
    <svg
      width="30"
      height="35"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9995 0C19.3194 0 22.8207 3.28521 22.8207 7.33841C22.8207 11.3913 19.3194 17.6181 14.9995 17.6181C10.6801 17.6181 7.179 11.391 7.179 7.33841C7.179 3.28521 10.6803 0 14.9995 0ZM14.9995 19.4918C23.2837 19.4918 30 22.9638 30 27.2459C30 31.528 23.2839 35 14.9995 35C6.7154 35 0 31.528 0 27.2459C0 22.9638 6.71568 19.4918 14.9995 19.4918ZM17.4768 31.0975L15.8693 25.0253C15.8693 25.0253 16.7099 24.5412 16.99 23.545C17.667 21.137 12.3326 21.137 13.0096 23.545C13.2896 24.5409 14.1303 25.0253 14.1303 25.0253L12.5228 31.0975H17.4768Z"
        fill="#8A44B2"
      />
    </svg>
  );
};
