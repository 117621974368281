import React, { useRef, useState } from "react";
import "../../styles/selection-input-option.scss";
import { DownwardArrowIcon } from "../../components/icons/DownwardArrowIcon";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { AnimatePresence } from "framer-motion";
import { FadeTransition } from "../FadeTransition";

const SelectionInputOption = (props) => {
  const {
    selected,
    setSelected,
    option,
    width,
    height,
    contentHeight,
    onChange,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  function toggler() {
    setIsOpen(!isOpen);
  }

  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className="general-dropdown " style={{ width: width }}>
      <div
        className="general-dropdown-btn"
        onClick={toggler}
        ref={ref}
        style={{ height: height }}
      >
        {selected}
        <span>
          <DownwardArrowIcon />
        </span>
      </div>

      <AnimatePresence>
        {isOpen && (
          <FadeTransition>
            <div className="general-dropdown-content" style={{ width }}>
              {option.map((option, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setSelected(option);
                    setIsOpen(false);
                    onChange();
                  }}
                  className="general-dropdown-item"
                >
                  {option}
                </div>
              ))}
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SelectionInputOption;
