import React,{useState} from 'react';
import "../../styles/vendor/vendor-profile/vendor-profile-page.scss"
import dummy from "../../assest/blank-profile-picture.png"
import { CameraIcon, CloseIcon, EditorPencilIcon } from '../../components/icons';
import { motion } from 'framer-motion';
import UnEditableInputEditor from '../../components/general-component/UnEditableInputEditor';


const AdminProfile = (props) => { 
  const {toggleOpen,image, setImage}=props                                                                                 
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
   }
  
  return (
    <motion.div
    className="profile-container"
    initial={{ x: "100%" }}
    animate={{ x: 0 }}
    exit={{ x: "100%" }}
    transition={{ type: "just" }}>
        
              <div className='vendor-profile-close'>
                  <span onClick={toggleOpen}> 
                   <CloseIcon
                    color="#D13D2A"
                  />
                </span>
              </div>
              <div className='vendor-profile-picture'>
                <label htmlFor="photo-upload">
                      <div className="vendor-image-carrier">
                          {image?  (
                          <img src={image} alt="" />
                          )
                          : 
                          (<img src={dummy} alt="" />)}
                      </div>
                         <p className='vendor-camera-icon' >
                            <CameraIcon/>
                         </p>
                      <input 
                        type="file" 
                        onChange={(event) => onImageChange(event)} 
                        className="filetype" 
                        id="photo-upload"
                      />
                  </label> 
                <div className='profile-name-holder'>
                    <h3 className="vendor-compound-name">
                        {firstName} {lastName}
                  </h3> 

                  <h6 className="vendor-compound-title">
                     Admin
                  </h6>
                </div>
                
              </div>
                <div className='vendor-editor-form-case'>
               <UnEditableInputEditor
                  nameLabel="First Name"
                  nameTitle={firstName}
                  // value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                  icon={<EditorPencilIcon/>}
               />
                <UnEditableInputEditor
                  nameLabel="Last Name"
                  nameTitle={lastName}
                  // value={lastName}
                  // onChange={(e) => setLastName(e.target.value)}
                  icon={<EditorPencilIcon/>}
               />
                <UnEditableInputEditor
                  nameLabel="Phone Number"
                  nameTitle={phoneNumber}
                  // value={phoneNumber}
                  // onChange={(e) => setPhoneNumber(e.target.value)}
                  icon={<EditorPencilIcon/>}
               />
                <UnEditableInputEditor
                  nameLabel="House Address"
                  nameTitle={address}
                  // value={address}
                  // onChange={(e) => setAddress(e.target.value)}
                  icon={<EditorPencilIcon/>}
               />
               <span className="vendor-btn-container">
                 <button className='vendor-profile-submit-btn'>
                    Save
                 </button>
               </span>
                </div> 

        </motion.div>

        );
};

export default AdminProfile;
