import React, { useState } from "react";
import {
  CloseIcon,
  FiatWithdrawIcon,
  CryptoTransactionIcon,
} from "../../icons/index";
import "../../../styles/investor/investor-dashboard-styles/investor-fiat-withdraw-modal.scss";
import FiatCard from "./FiatCard";
import CryptoWithdrawalModal from "./CryptoWithdrawalModal";
import FiatBankWithdrawalModal from "./FiatBankWithdrawalModal";

const InvestorWithdrawModalCard = (props) => {
  const { modal, controlModal, loadData, country, userDetails } = props;

  const [appear, setAppear] = useState(false);
  const [show, setShow] = useState(false);

  const controlShow = () => {
    setShow(!show);
  };

  const controlAppear = () => {
    setAppear(!appear);
  };
  return (
    <>
      {modal && (
        <div className="investor-fiat-withdraw-modal">
          <div
            onClick={controlModal}
            className="investor-fiat-withdraw-overlay"
          >
            <div
              className="investor-withdraw-modal"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="investor-withdraw-head">
                <h3>Withdrawal</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>

              <p className="withdraw-heading">
                Select a method to withdraw from your Quidroo Account
              </p>

              {country === "Nigeria" && (
                <FiatCard
                  icon={<FiatWithdrawIcon svgColor="#3FBB97" />}
                  title1="Fiat/Bank"
                  title2="Withdrawal"
                  statement="I want to withdraw money 
                        from my Quidroo account into 
                        my bank account."
                  onClick={controlShow}
                />
              )}

              <FiatCard
                icon={<CryptoTransactionIcon svgColor="#3FBB97" />}
                title1="Crypto"
                title2="Withdrawal"
                statement="I want to withdraw money 

                        from my Quidroo account 
                        into crypto account."
                onClick={controlAppear}
              />
            </div>
          </div>
        </div>
      )}

      <CryptoWithdrawalModal
        controlModal={controlAppear}
        modal={appear}
        loadData={loadData}
        closeAll={controlModal}
      />

      <FiatBankWithdrawalModal
        controlModal={controlShow}
        modal={show}
        closeAll={controlModal}
        userDetails={userDetails}
        loadData={loadData}
      />
    </>
  );
};
export default InvestorWithdrawModalCard;
