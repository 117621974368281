

export const LogOutIcon = ({svgColor}) => {
    return (
        <svg 
            width="28" 
            height="30" 
            viewBox="0 0 28 30" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M27.7878 12.0684L24.9881 14.7956C24.8612 14.9274 24.6838 15.0023 24.4982 15.0023C24.3125 15.0023 24.1351 14.9274 24.0082 14.7956C23.8729 14.6721 23.796 14.4992 23.796 14.3184C23.796 14.1376 23.8729 13.9647 24.0082 13.8411L25.604 12.2729H22.3984V10.9093H25.604L24.0082 9.34107C23.7376 9.07747 23.7376 8.65012 24.0082 8.38652C24.2788 8.12293 24.7175 8.12293 24.9881 8.38652L27.7878 11.1138C27.9231 11.2374 28 11.4103 28 11.5911C28 11.7719 27.9231 11.9448 27.7878 12.0684ZM15.3986 11.5911C15.3986 11.2146 15.712 10.9093 16.0986 10.9093H22.398V0.681943C22.398 0.50114 22.3242 0.327638 22.193 0.199798C22.0617 0.0719587 21.8836 0.00012207 21.698 0.00012207H3.14942L10.4428 5.93197C10.9224 6.31853 11.1996 6.89384 11.1987 7.50015V23.182H21.6976C21.8832 23.182 22.0614 23.1102 22.1926 22.9824C22.3238 22.8545 22.3976 22.681 22.3976 22.5002V12.2729H16.0982C15.7117 12.2729 15.3983 11.9676 15.3983 11.5911H15.3986ZM1.14801 0.16376C0.939902 -0.00487228 0.651186 -0.0420026 0.405585 0.0687875C0.160297 0.179585 0.00218458 0.418223 7.89122e-07 0.681819V22.5001C-0.000311661 22.701 0.0921752 22.8912 0.251848 23.0182L8.65104 29.8364C8.7754 29.9417 8.93445 29.9998 9.09912 30.0001C9.20067 29.9968 9.30066 29.9736 9.39316 29.9319C9.64126 29.8239 9.80062 29.5831 9.79906 29.3183V7.50003C9.79937 7.29914 9.70688 7.1089 9.54721 6.98197L1.14801 0.16376Z" 
            fill={svgColor}
            />
        </svg>
    )
}


