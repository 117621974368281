export const ProfileIcon =()=>{
    return(
        <svg 
            width="28" 
            height="30" 
            viewBox="0 0 28 30" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M13.9986 16.9192C16.2478 16.9192 21.5362 14.4281 21.5362 8.45959C21.5362 3.78729 20.8508 0 13.9986 0C7.1463 0 6.46094 3.7875 6.46094 8.45959C6.46094 14.4281 11.7491 16.9192 13.9986 16.9192ZM7.58797 7.04742C8.11736 6.10509 9.2628 5.01607 11.7257 5.0644C11.7257 5.0644 13.1939 8.3413 20.4009 6.86973C20.43 7.3931 20.4391 7.92735 20.4391 8.45911C20.4391 11.3767 19.0392 13.1843 17.8647 14.187C16.385 15.4506 14.742 15.9091 13.9986 15.9091C13.2555 15.9091 11.6125 15.4506 10.1326 14.187C8.95806 13.1842 7.55818 11.3766 7.55818 8.45911C7.55841 7.98721 7.56591 7.51366 7.58774 7.04737L7.58797 7.04742Z" 
            fill="#414141"
        />
        <path 
            d="M27.994 25.5946C27.6093 23.3927 26.817 20.5563 25.2279 19.5485C24.143 18.86 20.3572 16.9988 18.7405 16.204L18.7125 16.1902C18.5276 16.0993 18.302 16.1175 18.1372 16.2366C17.2903 16.8485 16.3628 17.2608 15.38 17.4621C15.2065 17.4977 15.0639 17.6113 15.0009 17.7643L14.0003 20.1916L12.9997 17.7643C12.9365 17.6113 12.7941 17.4979 12.6206 17.4621C11.638 17.2608 10.7103 16.8483 9.86342 16.2366C9.69855 16.1175 9.47297 16.0993 9.28809 16.1902C7.6908 16.9755 3.85866 18.8748 2.77738 19.5453C0.948164 20.6793 0.148413 24.7835 0.00671886 25.5947C-0.00738012 25.6753 0.000806592 25.7575 0.0303685 25.8343C0.0958599 26.0045 1.77453 29.9996 14.0001 29.9996C26.2257 29.9996 27.9047 26.0042 27.9699 25.8343C27.9992 25.7577 28.0074 25.6753 27.9933 25.5949L27.994 25.5946Z" 
            fill="#414141"
        />
        </svg>

    )
}