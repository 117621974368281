import { useEffect, useState } from "react";
import "../../styles//vendor/vendor-invoice/vendor-invoice-dashboard.scss";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import FilterOptionDropdown from "../../components/seller-component/invoice/FilterOptionDropdown";
import MobileFilterOptionDropdown from "../../components/seller-component/invoice/MobileFilterOptionDropdown";
import { PlusIcon } from "../../components/icons/index";
import { MobileVendorDepositWithdrawModal } from "../../components/vendor-component/MobileVendorDepositWithdrawModal";
import { VendorStatusBar } from "../../components/vendor-component/VendorStatusBar";
import VendorInvoiceCard from "../../components/vendor-component/vendor-dashboard/VendorInvoiceCard";
import { MobileVendorInvoiceCard } from "../../components/vendor-component/vendor-dashboard/MobileVendorInvoiceCard";
import { PageLoader } from "../../components/general-component/PageLoader";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { formatAmount } from "../../util/formatAmount";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { convertStatus } from "../../util/convertStatus";
import { convertDate } from "../../util/convertDate";

const invoiceStatus = [
  "Pending Invoices",
  "Approved Invoices",
  "Sold Invoices",
  "Closed Invoices",
  "Due Invoices",
  "Rejected Invoices",
];

const invoiceDueDate = [
  "Today",
  "Last 7 Days",
  "Last 30 days",
  "Last 3 Months",
  "Last 6 Months",
];

const VendorInvoice = () => {
  // const [activated, setActivated] = useState(false);
  const [filterStatus, setFilterStatus] = useState("Status");
  const [filterDate, setFilterDate] = useState("Due Date");
  const [modal, setModal] = useState(false);
  const [expire, setExpire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState();

  const controlModal = () => {
    setModal(!modal);
  };

  // invoice uploader control
  const invoiceUploader = () => {
    console.log("where is invoice");
  };

  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };

  const loadInvoice = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/vendor_invoice_page/${token}`,
        config
      );
      setIsLoading(false);

      console.log(data);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) {
        setInvoiceData(data.invoices);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");

      try {
        setIsLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const status = convertStatus(filterStatus);
        const date = convertDate(filterDate);
        const body = {
          status: status ?? 5,
          due_date: date ?? 5,
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/vendor_invoice_search/${token}`,
          body,
          config
        );

        console.log(body);

        console.log(data);

        setIsLoading(false);

        if (data.success) setInvoiceData(data.invoices);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [filterDate, filterStatus]);

  useEffect(() => {
    loadInvoice();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  return (
    <>
      <VendorStatusBar activated={true} title="Invoices" />
      <div className="vendor-invoice-page-container">
        <div className="first-half-filter">
          <span className="filter input-first mobile-hide">
            <p className="filter mobile-hide">Filters</p>
            <FilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
            />
          </span>
          <span className="filter input-second mobile-hide">
            <FilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
            />
          </span>

          <span className=" mobile-input-filter window-hide">
            <MobileFilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
              width="154px"
            />

            <MobileFilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
              width="154px"
            />
          </span>
        </div>

        <div className="vendor-invoice-second-half mobile-hide">
          <table className="vendor-invoice-page-table">
            <tbody>
              <tr className="vendor-invoice-page-header table">
                <th>Invoice Name</th>
                <th>Invoice Price</th>
                <th>Status</th>
                <th>Seller</th>
                <th>Due Date</th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="invoice-scroll">
          {invoiceData?.length ? (
            <div className="invoice-data-holder mobile-hide">
              {invoiceData?.map((invoice, index) => (
                <VendorInvoiceCard
                  key={invoice["invoice_id "]}
                  price={formatAmount(invoice?.invoice_amount)}
                  dueDate={invoice?.due_date}
                  invoiceName={invoice["invoice_name "]}
                  status={invoice?.invoice_state}
                  seller={invoice.seller ?? "Batman"}
                  loadData={loadInvoice}
                  id={invoice["invoice_id "]}
                  url={invoice?.invoice_url}
                  sellerId={invoice?.seller_id}
                />
              ))}
            </div>
          ) : (
            <div className="empty-invoice">
              <InvoiceEmptyIcon />
              <p>You currently do not have any Invoices </p>
            </div>
          )}
          {invoiceData?.length ? (
            <div className="window-hide">
              {invoiceData?.map((invoice, index) => (
                <MobileVendorInvoiceCard
                  key={invoice["invoice_id "]}
                  price={formatAmount(invoice?.invoice_amount)}
                  dueDate={invoice?.due_date}
                  invoiceName={invoice["invoice_name "]}
                  status={invoice?.invoice_state}
                  seller={invoice.seller ?? "Batman"}
                  loadData={loadInvoice}
                  url={invoice?.invoice_url}
                  id={invoice["invoice_id "]}
                />
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="window-hide">
          <button
            onClick={controlModal}
            className="vendor-mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>

          <MobileVendorDepositWithdrawModal
            controlModal={controlModal}
            modal={modal}
            fundWithdrawal={fundWithdrawal}
            invoiceUploader={invoiceUploader}
          />
        </div>
      </div>
    </>
  );
};

export default VendorInvoice;
