import { validateAmount } from "./validateAmount";
import { validateDate } from "./validateDate";
import { validateEmail } from "./validateEmail";
import { validatePhoneNumber } from "./validatePhoneNumber";
import { validateText } from "./validateText";

/**
 * validateInvoiceUpload checks if all fields in upload invoice form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateInvoiceUpload({
  vendorName,
  contactName,
  phoneNumber,
  email,
}) {
  const n = validateText(vendorName);
  const c = validateText(contactName);
  const p = validatePhoneNumber(phoneNumber);
  const e = validateEmail(email);

  const v = {
    isValid: true,
  };

  if (!n.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      vendorName: "Vendor Name is required",
    };
  }

  if (!c.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      contactName: "Contact Name is required",
    };
  }

  if (!p.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      phoneNumber: p.error,
    };
  }

  if (!e.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      email: e.error,
    };
  }

  return v;
}
