import React, { useState } from "react";
import "../../styles/seller/dashboard/activation-status-bar.scss";
import { ActivationModalForm } from "./dashboard-component/ActivationModalForm";
import { UploadInvoiceModal } from "./dashboard-component/UploadInvoiceModal";

export const ActivationStatusBar = (props) => {
  const {
    activated,
    // handleActivation,
    invoicePush,
    name,
    title,
    loadData,
    pending,
  } = props;
  const [modal, setModal] = useState(false);
  const [appear, setAppear] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  const controlAppear = () => {
    setAppear(false);
  };

  return (
    <div className="uploader-container">
      <div className="activate-status">
        {activated ? (
          title && <h2 className="activated-status">{title}</h2>
        ) : pending ? (
          <p className="inactivated-status">
            Our agent is confirming your details. Your account will be verified
            within 24 hours
          </p>
        ) : (
          <p className="inactivated-status">
            Your account is deactivated,{" "}
            <span className="mobile-hide">Click here to </span>
            <span className="leader" onClick={controlModal}>
              Activate now
            </span>
          </p>
        )}
      </div>

      {title && (
        <div className="uploader-button-case mobile-hide">
          {activated === true && (
            <button
              onClick={() => setAppear(true)}
              className="uploader-button mobile-hide"
            >
              Upload Invoice
            </button>
          )}

          {activated === false && (
            <button
              style={{
                backgroundColor: "rgba(86, 92, 214, 0.4)",
                cursor: "not-allowed",
              }}
              className="uploader-button mobile-hide"
            >
              Upload Invoice
            </button>
          )}
        </div>
      )}

      <ActivationModalForm
        modal={modal}
        toggleChange={controlModal}
        loadData={loadData}
        onSubmit={() => setModal(false)}
      />

      <UploadInvoiceModal
        toggleChange={controlAppear}
        modal={appear}
        loadData={loadData}
      />
    </div>
  );
};
