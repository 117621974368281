import React, { useState, useEffect } from "react";
import "../../styles/investor/investor-profile-styles/profile.scss";
import dummy from "../../assest/blank-profile-picture.png";
import {
  CameraIcon,
  CloseIcon,
  EditorPencilIcon,
} from "../../components/icons";
import { AnimatePresence, motion } from "framer-motion";
import DisplayInputEditor from "../../components/investor-component/profile/DisplayInputEditor";
import { LogoIcon } from "../../components/icons/logo";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { Toolkit } from "../../components/general-component/Toolkit";
import { ModalLoader } from "../../components/general-component/ModalLoader";

const InvestorProfile = (props) => {
  const {
    toggleOpen,
    initImage,
    initFirstName,
    initLastName,
    initPhoneNumber,
    initAddress,
    loading,
    loadProfile,
    onSuccess,
  } = props;

  const [firstName, setFirstName] = useState("");
  const [finalFirstName, setFinalFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [finalLastName, setFinalLastName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [finalPhoneNumber, setFinalPhoneNumber] = useState("");

  const [address, setAddress] = useState("");
  const [finalAddress, setFinalAddress] = useState("");

  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage({
        url: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      });
    }
  };

  const handleSubmit = async () => {
    if (
      !finalAddress.length &&
      !finalFirstName.length &&
      !finalLastName.length &&
      !finalPhoneNumber.length &&
      !image
    ) {
      toggleOpen();
    } else {
      const token = localStorage.getItem("token");
      try {
        toggleOpen();
        setIsLoading(true);

        const formdata = new FormData();
        image &&
          formdata.append(
            "profile_pics",
            image.file,
            `${initFirstName} profile-pic`
          );
        formdata.append(
          "firstname",
          finalFirstName.length ? finalFirstName : initFirstName
        );
        formdata.append(
          "lastname",
          finalLastName.length ? finalLastName : initLastName
        );

        formdata.append(
          "phonenumber",
          finalPhoneNumber.length ? finalPhoneNumber : initPhoneNumber
        );

        console.log(formdata);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const body = formdata;

        const { data } = await axios.put(
          `${BACKEND_URL}/edit_investor_profile/${token}`,
          body,
          config
        );
        console.log(data);

        setIsLoading(false);

        if (data.success) {
          onSuccess();
          loadProfile();
          setFinalAddress("");
          setFinalFirstName("");
          setFinalLastName("");
          setFinalPhoneNumber("");
          setImage(undefined);
          setTimeout(() => {
            window.location.reload();
          }, 4000);

          // setTimeout(() => {
          //   setIsSuccess(false);
          // }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClose = () => {
    setFinalAddress("");
    setFinalFirstName("");
    setFinalLastName("");
    setFinalPhoneNumber("");
    setImage(undefined);
    toggleOpen();
  };

  // if (isLoading) return <ModalLoader />;

  return (
    <motion.div
      className="profile-container"
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "just" }}
    >
      {loading ? (
        <div className="profile-loading">
          <LogoIcon />
        </div>
      ) : (
        <>
          <div className="profile-close">
            <span onClick={onClose}>
              <CloseIcon color="#D13D2A" />
            </span>
          </div>
          <div className="profile-picture">
            <label htmlFor="photo-upload">
              <div className="image-carrier">
                {initImage ? (
                  <img
                    src={image ? image.url : initImage}
                    alt={initFirstName}
                  />
                ) : (
                  <img src={dummy} alt={initFirstName} />
                )}
              </div>
              <p className="camera-icon">
                <CameraIcon />
              </p>
              <input
                type="file"
                onChange={(event) => onImageChange(event)}
                className="filetype"
                id="photo-upload"
                accept="image/png, image/jpeg"
              />
            </label>
            <div className="profile-name-holder">
              <h3 className="compound-name">
                {initFirstName} {initLastName}
              </h3>

              <h6 className="compound-title">Investor</h6>
            </div>
          </div>
          <div className="editor-form-case">
            <DisplayInputEditor
              nameLabel="First Name"
              nameTitle={
                finalFirstName.trim().length ? finalFirstName : initFirstName
              }
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onConfirm={() => setFinalFirstName(firstName.trim())}
              icon={<EditorPencilIcon />}
            />
            <DisplayInputEditor
              nameLabel="Last Name"
              nameTitle={
                finalLastName.trim().length ? finalLastName : initLastName
              }
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onConfirm={() => setFinalLastName(lastName.trim())}
              icon={<EditorPencilIcon />}
            />

            <DisplayInputEditor
              nameLabel="Phone Number"
              nameTitle={
                finalPhoneNumber.trim().length
                  ? finalPhoneNumber
                  : initPhoneNumber
              }
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onConfirm={() => setFinalPhoneNumber(phoneNumber.trim())}
              icon={<EditorPencilIcon />}
            />

            <DisplayInputEditor
              nameLabel="House Address"
              nameTitle={
                finalAddress.trim().length ? finalAddress : initAddress
              }
            />
            <span className="btn-container">
              <button className="profile-submit-btn" onClick={handleSubmit}>
                Save
              </button>
            </span>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default InvestorProfile;
