import React from "react";
import source from "../../assest/Ellipse.png";
import "../../styles/top-bar.scss";

import { MainLogo } from "../icons/logo/index";

export const TopBar = ({ show }) => {
  return (
    <div className="container-case">
      <div className="logo-aside">{show === true && <MainLogo />}</div>
      <div className="image-logo">
        <img src={source} alt="profile-id" className="profile-id" />
      </div>
    </div>
  );
};
