import React, { useState } from "react";
// import NavigateCard from "./InvestorNavigateCard";
import {
  InvoiceIcon,
  TransactionIcon,
  DashBoardIcon,
} from "../../icons/navigator/Index";
// import LogOutCard from "./InvestorLogOutCard";

import "../../../styles/Navigator.scss";
import { LogOutIcon } from "../../icons/index";
import { useLocation } from "react-router-dom";
import VendorLogOutCard from "./VendorLogOutCard";
import VendorNavigateCard from "./VendorNavigateCard";
import { useNavigate } from "react-router";
// import { BidsIcon } from "../../icons/navigator/BIdsIcon";

const VendorNavigatorBottom = () => {
  const [logout, setLogout] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate("/");
    localStorage.removeItem("token");
  };

  const tabs = [
    {
      route: "/vendor/dashboard",
      icon: (
        <DashBoardIcon
          svgColor={
            location.pathname === "/vendor/dashboard" ? " #8A44B2" : "#818181"
          }
        />
      ),
      label: "Dashboard",
      onClick: function () {
        setLogout(false);
      },
    },
    // {
    //   route: "/investor/dashboard/bids",
    //   icon: (
    //     <BidsIcon
    //       svgColor={location.pathname === "/investor/dashboard/bids" ? " #8A44B2" : "#818181"}
    //     />
    //   ),
    //   label: "Bids",
    //   onClick: function () {
    //     setLogout(false);
    //   },
    // },
    {
      route: "/vendor/dashboard/invoice",
      icon: (
        <InvoiceIcon
          svgColor={
            location.pathname === "/vendor/dashboard/invoice"
              ? " #8A44B2"
              : "#818181"
          }
        />
      ),
      label: "Invoice",
      onClick: function () {
        setLogout(false);
      },
    },
    {
      route: "/vendor/dashboard/transaction",
      icon: (
        <TransactionIcon
          svgColor={
            location.pathname === "/vendor/dashboard/transaction"
              ? " #8A44B2"
              : "#818181"
          }
        />
      ),
      label: "Transaction",
      onClick: function () {
        setLogout(false);
      },
    },
  ];

  return (
    <div className="side-bar ">
      <div className="window-nav-vendor">
        {tabs.map((tab, index) => (
          <VendorNavigateCard
            isActive={tab.route === location.pathname}
            key={index}
            icon={tab.icon}
            to={tab.route}
            label={tab.label}
            onClick={tab.onClick}
            svgColor={tab.svgColor}
          />
        ))}
      </div>

      <div className="log-out-bar">
        <VendorLogOutCard
          icon={<LogOutIcon svgColor={logout ? " #8A44B2" : "#818181"} />}
          onClick={handleLogOut}
          color={logout ? " #8A44B2" : "#818181"}
          ground={logout ? "#FAF0FE" : null}
        />
      </div>
    </div>
  );
};

export default VendorNavigatorBottom;
