import { useState } from "react";
import styles from "../styles/loginform.module.scss";
import "../styles/index.scss";
import { Link } from "react-router-dom";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import { OptimizedInput } from "../components/form/OptimizedInput";
import { HidePasswordIcon, PasswordIcon } from "../components/icons";
import { validatePassword } from "../util/validate/validatePassword";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";

const ChangePasswordPage = () => {
  let { user_id } = useParams();
  // error states
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [control, setControl] = useState(false);

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  // loading state
  const [isLoading, setIsLoading] = useState(false);

  const toggleChange = () => {
    if (control === true) {
      setControl(false);
    } else {
      setControl(true);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validatePassword(password);

    if (!isValid) {
      setPasswordError(error);
    } else {
      if (password !== confirmPassword) {
        setConfirmPasswordError("Password is not the same");
        return;
      }

      try {
        setIsLoading(true);
        const body = {
          confirm_password: confirmPassword,
          password,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.put(
          `${BACKEND_URL}/change_password/${user_id}`,
          body,
          config
        );
        setIsLoading(false);

        if (!data.success) {
          setPasswordError(data.message);
          return;
        }

        navigate("/reset-success");
      } catch (error) {
        setPasswordError(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <span className={styles.topLogo}>
        <MainLogo />
      </span>

      <div className={styles.pageCard}>
        <span className={styles.mobileLogo}>
          <MobileLogo />
        </span>
        <h3 className={styles.pageTitle}>
          Change Password
          <Link className={styles.topLink} to="/signin">
            Signin
          </Link>
        </h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.cardButton}>
            <section className={styles.cover}>
              <OptimizedInput
                // title="Password"
                name="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onIconClick={toggleChange}
                type={control ? "text" : "password"}
                icon={control ? <HidePasswordIcon /> : <PasswordIcon />}
                onFocus={() => passwordError && setPasswordError(null)}
              />
              {passwordError && (
                <div className="error-text">{passwordError}</div>
              )}
              <OptimizedInput
                // title="Confirm New Password"
                name=" Confirm Password"
                placeholder="Confirm New  password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onIconClick={toggleChange}
                type={control ? "text" : "password"}
                icon={control ? <HidePasswordIcon /> : <PasswordIcon />}
                onFocus={() =>
                  confirmPasswordError && setConfirmPasswordError(null)
                }
              />
              {confirmPasswordError && (
                <div className="error-text">{confirmPasswordError}</div>
              )}
            </section>
          </div>
          <div style={{ textAlign: "center" }}>
            <button className="change-password-press" onClick={handleSubmit}>
              {isLoading ? <div className="loading" /> : "Done"}
            </button>
          </div>
        </form>

        <p className={styles.directMember}>
          New on Quidroo?
          <Link to="/signup">Register</Link>
        </p>
      </div>

      {/* <AnimatePresence>
        {showModal && (
          <motion.div
            className="success-modal"
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={{
              hidden: { scale: 0 },
              enter: { scale: 1 },
              exit: { scale: 0 },
            }}
            transition={{ type: "spring", delay: 0.1 }}
          >
            <div className="success-modal-card">
              You have successfully changed password
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}
    </div>
  );
};

export default ChangePasswordPage;
