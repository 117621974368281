import React, { useState } from "react";
import "../../styles/investor/investor-dashboard-styles/modified-investor-input-option.scss";
import { NigeriaFlag, UKFlag, USAFlag } from "../icons";
import { DownwardArrowIcon } from "../icons/DownwardArrowIcon";

const flagList = [
  {
    sym: "naira",
    element: <NigeriaFlag />,
  },
  {
    sym: "dollar",
    element: <USAFlag />,
  },
  // {
  //   sym: "pound",
  //   element: <UKFlag />,
  // },
];

const ModifiedInvestorInputOption = (props) => {
  const { selected, setSelected, setSymbol, width, height, contentHeight } =
    props;

  const [isOpen, setIsOpen] = useState(false);

  function toggler() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="investor-general-dropdown " style={{ width: width }}>
      <div
        className="investor-general-dropdown-btn"
        onClick={toggler}
        style={{ height: height }}
      >
        {selected}
        <span>
          <DownwardArrowIcon />
        </span>
      </div>
      {isOpen && (
        <div
          className="investor-general-dropdown-content"
          style={{ width: width, height: contentHeight }}
        >
          {flagList.map((option, index) => (
            <div
              onClick={() => {
                setSymbol(option.sym);
                setSelected(option.element);
                setIsOpen(false);
                localStorage.setItem(
                  "userCurrency",
                  JSON.stringify(option.sym)
                );
              }}
              key={index}
              className="investor-general-dropdown-item"
            >
              {option.element}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ModifiedInvestorInputOption;
