const phonePrefixes = [
  706, 803, 806, 810, 813, 814, 816, 903, 705, 805, 811, 815, 905, 701, 708,
  802, 808, 812, 902, 809, 817, 818, 909, 804, 908, 703, 915, 912,
];

//search array for specific values
const inArray = (value, array) => {
  const index = array.indexOf(value);
  if (index === -1) {
    return false;
  } else {
    return index;
  }
};

export const validatePhoneNumber = (phoneNumber) => {
  let dialingCode, mobilePrefix, checkArray;

  // get value length
  const length = phoneNumber.length;

  //if length is less than the required length of 14
  if (length < 11) {
    return {
      isValid: false,
      error: "Phone number should be between 11 - 14 digits.",
    };
  }
  //if length is equal to 11 (070xxxxxxxx)
  if (length === 11) {
    //get mobile number prefix - 706 or 703 - depending on telco
    mobilePrefix = Number(phoneNumber.substr(1, 3));
    const firstFigure = Number(phoneNumber[0]);

    //check if mobile prefix exists in phonePrefixes array
    checkArray = inArray(mobilePrefix, phonePrefixes);
    if (checkArray === false) {
      return {
        isValid: false,
        error: "Invalid phone number prefix.",
      };
    }

    if (checkArray > 0 && firstFigure === 0) {
      return {
        isValid: true,
      };
    }

    return {
      isValid: false,
      error: "Invalid phone number.",
    };
  }
  //if length is equal to 13 (23470xxxxxxxx)
  if (length === 13) {
    //get mobile number prefix - 706 or 703 - depending on telco
    mobilePrefix = Number(phoneNumber.substr(3, 3));

    //get dialling code from mobile number
    dialingCode = Number(phoneNumber.substr(0, 3));

    //check if mobile prefix exists in phonePrefixes array
    checkArray = inArray(mobilePrefix, phonePrefixes);
    if (checkArray === false) {
      return {
        isValid: false,
        error: "Invalid phone number prefix.",
      };
    }

    if (checkArray >= 0 && dialingCode === 234) {
      return { isValid: true };
    }

    return {
      isValid: false,
      error: "Invalid phone number.",
    };
  }
  //if length is equal to 14 (+23470xxxxxxxx)
  if (length === 14) {
    //get mobile number prefix from entered value
    mobilePrefix = Number(phoneNumber.slice(4, 7));

    //get dialling code from mobile number - +234
    dialingCode = phoneNumber.slice(0, 4);

    //check if prefix exists in mobile prefix array
    checkArray = inArray(mobilePrefix, phonePrefixes);

    //if prefix not found in array
    if (checkArray === false) {
      return {
        isValid: false,
        error: "Invalid phone number prefix.",
      };
    }
    //if found in array

    if (checkArray >= 0 && dialingCode === "+234") {
      return { isValid: true };
    }
    return {
      isValid: false,
      error: "Invalid phone number.",
    };
  }

  if (length > 14) {
    return {
      isValid: false,
      error: "Phone number should be between 11 - 14 digits.",
    };
  }

  return {
    isValid: true,
  };
};
