import React from 'react'
import { AdminExpandDownIcon, AdminExpandUpperIcon } from '../icons';
import "../../styles/admin/admin-expansion-hide-button.scss"

export const AdminExpansionHideButton = (props) => {

const {toggleControl, high}=props
   
  return (
    <>  
         {high === true ? (
          <span 
            onClick={toggleControl}
            className="admin-expansion-hide-btn-case"
          >
            <AdminExpandUpperIcon/>
          </span>
      ) : (
        <span 
            onClick={toggleControl} 
            className="admin-expansion-hide-btn-case"
        > 
            <AdminExpandDownIcon/>
        </span>
      )}
      </>
  )
}
