import React, { useState } from "react";
import "../../../styles/investor/investor-dashboard-styles/fiat-bank-withdrawal-modal.scss";
import { validateAmount } from "../../../util/validate/validateAmount";
import { validateNairaDeposit } from "../../../util/validate/validateNairaDeposit";
import { CloseIcon, LeftArrowIcon } from "../../icons";
import { usePaystackPayment } from "react-paystack";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { AnimatePresence } from "framer-motion";
import { InvestorActivationSuccessModal } from "./InvestorActivationSuccessModal";

const NairaFiatDepositModal = (props) => {
  const { controlModal, modal, email, loadData } = props;

  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const [amount, setAmount] = useState("");

  /** LOADING STATE */
  const [isLoading, setIsLoading] = useState(false);

  /** ERROR STATES */
  const [amountError, setAmountError] = useState(null);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateAmount(amount.trim());

    if (!isValid) {
      setAmountError(error);
    } else {
      initializePayment(onSuccess, onClose);
    }
  };
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    // publicKey: process.env.REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY,
  };
  const initializePayment = usePaystackPayment(config);
  const onSuccess = (res) => {
    if (res.status === "success" && res.message === "Approved") {
      // todo: update FE wallet balance
      // update the DB and credit user
      controlModal();
      const loadPaymentNG = async () => {
        const token = localStorage.getItem("token");

        try {
          setIsLoading(true);
          const body = {
            amount: amount,
            txref: res.trxref,
          };

          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const { data } = await axios.post(
            `${BACKEND_URL}/depositFiatNG/${token}`,
            body,
            config
          );

          console.log(data);

          setIsLoading(false); // todo: make the loader appear while fetching data
          if (data.success === true && data.status === 200) {
            setSuccess(true);
          } else {
            setFail(true);
            // todo: implement the successful transaction but not yet confirmed screen here
          }
        } catch (error) {
          console.error(error);
        }
      };
      loadPaymentNG();
    } else {
      // todo: implement the failed transaction screen here
      console.log(res);
    }
  };

  const onClose = () => {
    // todo: in case you need to redirect to a particulr screen use this function
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  console.log(success);

  return (
    <>
      <AnimatePresence>
        {modal && (
          <div className="investor-bank-withdraw-modal">
            <div
              onClick={() => {
                controlModal();
              }}
              className="investor-bank-withdraw-overlay"
            >
              <div
                className="bank-withdrawal-case naira-deposit"
                onClick={(evt) => evt.stopPropagation()}
              >
                <div className="investor-bank-withdraw-head">
                  {/* <span className="left-arrow" onClick={controlModal}>
                    <LeftArrowIcon />
                  </span> */}
                  <div />
                  <h3 className="bank-withdraw-heading">Naira Deposit</h3>

                  <span
                    onClick={() => {
                      controlModal();
                    }}
                  >
                    <CloseIcon color="#D13D2A" />
                  </span>
                </div>

                <form onSubmit={handleSubmit}>
                  {/* <div className="crypto-long-input"> */}
                  <span className="bank-input-case">
                    <label className="bank-input-title">Amount</label>
                    <input
                      type="text"
                      className="bank-edit"
                      placeholder="Enter Amount"
                      onChange={(evt) => setAmount(evt.target.value)}
                      onFocus={() => amountError && setAmountError(null)}
                    />
                    {amountError && (
                      <div className="error-text">{amountError}</div>
                    )}
                  </span>

                  {/* </div> */}
                  <span className="bank-input-case">
                    <label className="bank-input-title">Email</label>
                    <input
                      type="text"
                      className="bank-edit"
                      value={email}
                      disabled
                    />
                  </span>

                  <div className="bank-btn-case">
                    <button className="bank-btn">Proceed</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </AnimatePresence>
      {isLoading && <ModalLoader />}

      <InvestorActivationSuccessModal
        modal={success}
        onLoad={() => {
          controlModal();
          setSuccess(false);
          loadData();
        }}
        title="Deposit Successful"
        desc="You have successfully deposited into your account. The amount has been added to your Quidroo Wallet"
      />

      <AnimatePresence>
        {fail && (
          <Toolkit
            text="Deposit can't be confirmed. Kindly reach out to admin at admin@quidroo.com"
            onClose={() => setFail(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default NairaFiatDepositModal;
