import { useState } from "react";
import styles from "../../styles/InvestorSignUp.module.scss";
import "../../styles/index.scss";
import { Link } from "react-router-dom";
import { validateInvestorSignUp } from "../../util/validate/validateInvestorSignUp";
import { MainLogo } from "../../components/icons/logo";
import { MobileInvestor } from "../../components/icons/mobile";
import {
  HidePasswordIcon,
  LeftArrowIcon,
  PasswordIcon,
} from "../../components/icons";
import { OptimizedInput } from "../../components/form/OptimizedInput";
import { useNavigate } from "react-router";
import axios from "axios";
import { BACKEND_URL } from "../../config";

const InvestorRegisterPage = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [control, setControl] = useState(false);

  /** LOADING STATE */
  const [isLoading, setIsLoading] = useState(false);

  /** ERROR STATES */
  const [emailError, setEmailError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const navigate = useNavigate();

  const toggleChange = () => {
    if (control === true) {
      setControl(false);
    } else {
      setControl(true);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateInvestorSignUp({
      firstName: firstName.trim(),
      lastName: firstName.trim(),
      email: email.toLowerCase().trim(),
      password,
    });

    if (!isValid) {
      if (error.firstName) setFirstNameError(error.firstName);
      if (error.lastName) setLastNameError(error.lastName);
      if (error.email) setEmailError(error.email);

      if (error.password) setPasswordError(error.password);
    } else {
      try {
        setIsLoading(true);
        const body = {
          firstname: firstName.trim(),
          lastname: lastName.trim(),
          email: email.toLowerCase().trim(),
          password,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/investor_signup`,
          body,
          config
        );

        setIsLoading(false);

        if (!data.success) {
          setEmailError(data.message);
          return;
        }

        localStorage.setItem("email", email.toLowerCase().trim());
        navigate({
          pathname: "/email-verification",
          search: "?acc=investor",
        });
      } catch (error) {
        setEmailError(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <span className={styles.topLogo}>
        <MainLogo />
      </span>
      <div className={styles.multiCard}>
        <p className={styles.directMember}>
          Not an Investor?
          <Link to="/signup">Choose a different account</Link>
        </p>
      </div>
      <div className={styles.pageCard}>
        <span className={styles.mobileLogo}>
          <MobileInvestor />
          <button className="mobile-top-link" onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button>
        </span>
        <h3 className={styles.pageTitle}>
          <button className="top-link" onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button>
        </h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.cardButton}>
            <section className={styles.cover}>
              <OptimizedInput
                title="First Name"
                name="First Name"
                placeholder="Enter Your First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                maxlength="30"
                onFocus={() => firstNameError && setFirstNameError(null)}
              />
              {firstNameError && (
                <div className="error-text">{firstNameError}</div>
              )}

              <OptimizedInput
                title="Last Name"
                name="Last Name"
                placeholder="Enter Your Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                maxlength="30"
                onFocus={() => lastNameError && setLastNameError(null)}
              />
              {lastNameError && (
                <div className="error-text">{lastNameError}</div>
              )}

              <OptimizedInput
                title="Email Address"
                name="Email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                maxlength="30"
                onFocus={() => emailError && setEmailError(null)}
              />
              {emailError && <div className="error-text">{emailError}</div>}

              {/* <OptimizedInput
                title="Phone number"
                name="telephone"
                placeholder="Enter your Phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                maxlength="30"
                onFocus={() => phoneError && setPhoneError(null)}
              />
              {phoneError && <div className="error-text">{phoneError}</div>} */}

              <OptimizedInput
                title="Password"
                name="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onIconClick={toggleChange}
                type={control ? "text" : "password"}
                icon={control ? <HidePasswordIcon /> : <PasswordIcon />}
                maxlength="20"
                onFocus={() => passwordError && setPasswordError(null)}
              />
              {passwordError && (
                <div className="error-text">{passwordError}</div>
              )}
            </section>
          </div>
          <div className="create-account-holder">
            <button className="signup-press" disabled={isLoading}>
              {isLoading ? <div className="loading" /> : "Create Account"}{" "}
            </button>
          </div>
        </form>

        <div className={styles.mobileMultiCard}>
          <p className={styles.directMember}>
            Not an Investor?
            <Link to="/signup">Choose a different account</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvestorRegisterPage;
