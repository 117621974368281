import React from 'react'

export const SearchBlackIcon = ({color}) => {
  return (
    <svg 
        width="18" 
        height="18" 
        viewBox="0 0 18 18" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M17.701 16.2965L13.2866 11.8767C15.5061 8.98777 15.2841 4.8149 12.6207 2.1729C11.1657 0.716143 9.29124 0 7.39237 0C5.49337 0 3.61912 0.715967 2.16407 2.1729C-0.721357 5.06181 -0.721357 9.75321 2.16407 12.6421C3.61907 14.0989 5.4935 14.815 7.39237 14.815C8.97072 14.815 10.5491 14.3212 11.8562 13.3088L16.2953 17.7038C16.4926 17.9013 16.7391 18 17.0104 18C17.2571 18 17.5284 17.9013 17.7255 17.7038C18.0955 17.3335 18.0955 16.6914 17.7009 16.2964L17.701 16.2965ZM7.41707 12.8149C5.96207 12.8149 4.63025 12.2471 3.59445 11.2347C1.49811 9.13579 1.49811 5.70375 3.59445 3.58027C4.60563 2.56788 5.96207 2.00002 7.41707 2.00002C8.87208 2.00002 10.2039 2.56788 11.2397 3.58027C12.2755 4.59267 12.818 5.95075 12.818 7.40751C12.818 8.86427 12.2509 10.1977 11.2397 11.2347C10.2285 12.2718 8.8475 12.815 7.41707 12.815V12.8149Z" 
        fill={color || "black"}
    />
    </svg>
    
  )
}
