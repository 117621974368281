import React, { useState } from "react";
import {
  CloseIcon,
  FiatDepositIcon,
  CryptoTransactionIcon,
  LeftArrowIcon,
} from "../../icons/index";
import "../../../styles/investor/investor-dashboard-styles/investor-fiat-deposit-modal.scss";
import FiatCard from "./FiatCard";
import CryptoDepositModal from "./CryptoDepositModal";
import NairaFiatDepositModal from "./NairaFiatDepositModal";

const InvestorDepositModalCard = (props) => {
  const {
    modal,
    controlModal,
    email,
    loadData,
    memo_field,
    wallet_address,
    country,
  } = props;

  const [appear, setAppear] = useState(false);
  const [show, setShow] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const controlAppear = () => {
    setAppear(!appear);
  };

  return (
    <>
      {modal && (
        <div className="investor-fiat-deposit-modal">
          <div onClick={controlModal} className="investor-fiat-deposit-overlay">
            <div
              className="investor-deposit-modal"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="investor-deposit-head">
                {/* {showOptions && (
                  <span onClick={() => setShowOptions(false)}>
                    <LeftArrowIcon />
                  </span>
                )} */}

                <h3>Deposit</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>

              <p className="deposit-heading">
                I want to deposit USDC from my crypto account to Quidroo
              </p>

              {/* {showOptions ? (
                <>
                  <FiatCard
                    icon={<div className="deposit-currency">₦</div>}
                    title1="Naira"
                    onClick={() => setShow(true)}
                    statement="I want to deposit from my naira account to Quidroo"
                  />

                  <FiatCard
                    icon={<div className="deposit-currency">$</div>}
                    title1="Dollar"
                    statement="I want to deposit from my dollar account to Quidroo"
                  />
                </>
              ) : (
                <> */}
              {country === "Nigeria" && (
                <FiatCard
                  icon={<FiatDepositIcon svgColor="#3FBB97" />}
                  title1="Fiat"
                  statement="I want to deposit fiat money (such as EUR, AUD,etc.) from my bank account to Quidroo"
                  onClick={() => setShow(true)}
                />
              )}

              <FiatCard
                icon={<CryptoTransactionIcon svgColor="#3FBB97" />}
                title1="Crypto (USDC)"
                statement="I want to deposit USDC from my crypto account to Quidroo"
                onClick={controlAppear}
              />
              {/* </>
              )} */}
            </div>
          </div>
        </div>
      )}
      <CryptoDepositModal
        controlModal={controlAppear}
        modal={appear}
        closeAll={controlModal}
        memo_field={memo_field}
        loadData={loadData}
        wallet_address={wallet_address}
      />

      <NairaFiatDepositModal
        controlModal={() => setShow(false)}
        modal={show}
        email={email}
        closeAll={controlModal}
        loadData={loadData}
      />
    </>
  );
};

export default InvestorDepositModalCard;
