import React, { useState } from "react";
import "../../styles/vendor/vendor-profile/vendor-profile-page.scss";
import dummy from "../../assest/blank-profile-picture.png";
import {
  CameraIcon,
  CloseIcon,
  EditorPencilIcon,
} from "../../components/icons";
import { motion } from "framer-motion";
import DisplayInputEditor from "../../components/investor-component/profile/DisplayInputEditor";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { LogoIcon } from "../../components/icons/logo";

const VendorProfile = (props) => {
  const {
    toggleOpen,
    initImage,
    companyName,
    phoneNumber,
    email,
    loading,
    loadProfile,
    onSuccess,
  } = props;

  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage({
        url: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      });
    }
  };

  const handleSubmit = async () => {
    if (!image) {
      toggleOpen();
      return;
    }
    const token = localStorage.getItem("token");
    try {
      toggleOpen();
      setIsLoading(true);

      const formdata = new FormData();
      image &&
        formdata.append(
          "profile_pics",
          image.file,
          `${companyName} profile-pic`
        );

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = formdata;

      const { data } = await axios.put(
        `${BACKEND_URL}/update_vendor_profile_pics/${token}`,
        body,
        config
      );
      console.log(data);

      setIsLoading(false);

      if (data.success) {
        onSuccess();
        loadProfile();
        setImage(undefined);
        setTimeout(() => {
          window.location.reload();
        }, 4000);

        // setTimeout(() => {
        //   setIsSuccess(false);
        // }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => {
    setImage(undefined);
    toggleOpen();
  };

  return (
    <motion.div
      className="vendor-profile-container"
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "just" }}
    >
      {loading ? (
        <div className="profile-loading">
          <LogoIcon />
        </div>
      ) : (
        <>
          <div className="vendor-profile-close">
            <span onClick={onClose}>
              <CloseIcon color="#D13D2A" />
            </span>
          </div>
          <div className="vendor-profile-picture">
            <label htmlFor="photo-upload">
              <div className="vendor-image-carrier">
                {initImage ? (
                  <img src={image ? image.url : initImage} alt={companyName} />
                ) : (
                  <img src={dummy} alt={companyName} />
                )}
              </div>
              <p className="vendor-camera-icon">
                <CameraIcon />
              </p>
              <input
                type="file"
                onChange={(event) => onImageChange(event)}
                className="filetype"
                id="photo-upload"
                accept="image/png, image/jpeg"
              />
            </label>
            <div className="profile-name-holder">
              <h3 className="vendor-compound-name">{companyName}</h3>

              <h6 className="vendor-compound-title">Vendor</h6>
            </div>
          </div>
          <div className="vendor-editor-form-case">
            <DisplayInputEditor
              nameLabel="Company Name"
              nameTitle={companyName}
            />

            <DisplayInputEditor
              nameLabel="Phone Number"
              nameTitle={phoneNumber}
            />
            <DisplayInputEditor nameLabel="Email" nameTitle={email} />
            <span className="vendor-btn-container">
              <button
                className="vendor-profile-submit-btn"
                onClick={handleSubmit}
              >
                Save
              </button>
            </span>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default VendorProfile;
