import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import TotalCommissionCard from "../../components/admin-component/admin-financial/admin-detail-cards/TotalComissionCard";
import TotalInvoicesPayableCard from "../../components/admin-component/admin-financial/admin-detail-cards/TotalInvoicesPayableCard";
import TotalWalletBalanceCard from "../../components/admin-component/admin-financial/admin-detail-cards/TotalWalletBalanceCard";
import AdminFinancialConfirmationCard from "../../components/admin-component/admin-financial/AdminFinancialConfirmationCard";
import AdminFinancialPaymentCard from "../../components/admin-component/admin-financial/AdminFinancialPaymentCard";
import AdminFinancialTransactionCard from "../../components/admin-component/admin-financial/AdminFinancialTransactionCard";
import AdminBidderInvoiceCard from "../../components/admin-component/admin-index/AdminBIdderInvoiceCard";
import { AdminExpansionHideButton } from "../../components/admin-component/AdminExpansionHideButton";
import { AdminStatusBar } from "../../components/admin-component/AdminStatusBar";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { NoInvoiceIcon } from "../../components/icons";
import { BACKEND_URL } from "../../config";
import "../../styles/admin/admin-financial/admin-financial-index.scss";
import { formatAmount } from "../../util/formatAmount";

const dashboardData = [
  {
    sellerName: "Cross Roads Enterprises Plc...",
    businessType: "Partnership",
    creditScore: 70,
    walletBalance: "12,000,000.00",
    femaleCofounder: "NO",
    isVerified: true,
    userType: "vendor",
  },
  {
    sellerName: "Road Project Supplies Invoice",
    businessType: "PLC",
    creditScore: 60,
    walletBalance: "12,000,000.00",
    femaleCofounder: "YES",
    isVerified: false,
    userType: "seller",
  },
  {
    sellerName: "Road Project Supplies Invoice",
    businessType: "Sole Proprietorship",
    creditScore: 75,
    walletBalance: "12,000,000.00",
    femaleCofounder: "YES",
    isVerified: true,
    userType: "investor",
  },
  {
    sellerName: "Road Project Supplies Invoice",
    businessType: "Sole Proprietorship",
    creditScore: 75,
    walletBalance: "12,000,000.00",
    femaleCofounder: "YES",
    isVerified: true,
    userType: "vendor",
  },
  {
    sellerName: "Road Project Supplies Invoice",
    businessType: "Sole Proprietorship",
    creditScore: 75,
    walletBalance: "12,000,000.00",
    femaleCofounder: "YES",
    isVerified: true,
    userType: "seller",
  },
];

const filterStatus = ["Requests", "Confirmations", "Transactions"];

const AdminFinancialPage = () => {
  const [selector, setSelector] = useState("Requests");
  const [high, setHigh] = useState(false);

  const [financeData, setFinanceData] = useState();
  const [expire, setExpire] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_finance/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setFinanceData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired isAdmin />;

  const toggleControl = () => {
    setHigh(!high);
  };
  return (
    <>
      <section>
        <AdminStatusBar
          activated={true}
          loadData={loadData}
          title="Financials"
        />
      </section>
      <div className="admin-financial-container">
        <div
          style={{
            display: !high ? "flex" : "none",
          }}
          className="admin-financial-first-half"
        >
          <h1 className="admin-financial-first-half-header">Financial</h1>
          <div className="admin-financial-card-holder">
            <TotalInvoicesPayableCard
              amount={formatAmount(financeData?.totalInvoiceAmount ?? 0)}
              totalNumber={200}
            />
            <TotalCommissionCard
              invoices={formatAmount(financeData?.totalCommissions ?? 0)}
              percent={77}
            />
            <TotalWalletBalanceCard
              score={345}
              usd={formatAmount(financeData?.totalBalancesUSD ?? 0)}
              naira={formatAmount(financeData?.totalBalancesNG ?? 0)}
              totalNumber={304}
            />
          </div>
        </div>
        <div className="admin-financial-second-half">
          {dashboardData?.length ? (
            <div className="admin-financial-second-half-header mobile-hide">
              <span className="admin-financial-filter mobile-hide">
                <p className=" mobile-hide">Sold Invoices</p>
                <div className="admin-financial-filter-btn-holder">
                  {filterStatus?.map((filter, i) => (
                    <button
                      key={i}
                      onClick={() => setSelector(filter)}
                      className={
                        selector === filter
                          ? "admin-financial-btn admin-financial-active-status"
                          : "admin-financial-btn"
                      }
                    >
                      {filter}
                    </button>
                  ))}
                </div>
                <section className="admin-financial-filter-left">
                  <span className="admin-financial-filter-expansion-case">
                    <AdminExpansionHideButton
                      toggleControl={toggleControl}
                      high={high}
                    />
                  </span>
                </section>
              </span>
              {high && (
                <>
                  {/* PAYMENT REQUEST TABLE HEADER */}

                  {selector === "Requests" && (
                    <table className="admin-page-invoice-table">
                      <tbody>
                        <tr className="admin-table-invoice-header table">
                          <th>Invoice Name</th>
                          <th>Request Amount</th>
                          {/* <th>Description</th> */}
                          <th>Request Date</th>
                          <th></th>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {/* CONFIRMATION TABLE HEADER */}

                  {selector === "Confirmations" && (
                    <table className="admin-page-invoice-table">
                      <tbody>
                        <tr className="admin-table-invoice-header table">
                          <th>Invoice Name</th>
                          <th>Request Amount</th>
                          {/* <th>Description</th> */}
                          <th>User Name</th>
                          <th></th>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {/* TRANSACTION TABLE HEADER */}

                  {selector === "Transactions" && (
                    <table className="admin-page-invoice-table">
                      <tbody>
                        <tr className="admin-table-financial-transaction-header table">
                          <th>Invoice Name</th>
                          <th>Trans Amount</th>
                          {/* <th>Description</th> */}
                          <th>Trans Date</th>
                          <th>User Name</th>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="admin-invoice-empty-header mobile-hide">
              All Invoices
            </div>
          )}

          {selector === "Requests" && (
            <>
              <div
                className={
                  !high === false
                    ? "admin-financial-scroll-long scroll"
                    : "admin-financial-scroll scroll"
                }
              >
                {financeData?.payment_requests?.length ? (
                  <div className="admin-invoice-data-holder mobile-hide">
                    {financeData?.payment_requests?.map((invoice, index) => (
                      <AdminFinancialPaymentCard
                        key={index}
                        invoiceName={invoice?.payee_name}
                        referenceCode={invoice?.payee_id}
                        requestAmount={formatAmount(invoice?.amount)}
                        description="Funding replacements"
                        requestDate={moment(invoice?.date).format("DD-MM-YYYY")}
                        id={invoice?.request_id}
                        loadData={loadData}
                        payeeId={invoice?.payee_id}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="admin-empty-invoice mobile-hide">
                    <NoInvoiceIcon />
                    <p>You currently do not have any active Invoice </p>
                  </div>
                )}
              </div>
              {!high && <span className="admin-financial-control-btn"></span>}
            </>
          )}
          {/* CONFIRMATION SECTION */}
          {selector === "Confirmations" && (
            <>
              <div
                className={
                  !high === false
                    ? "admin-financial-scroll-long scroll"
                    : "admin-financial-scroll scroll"
                }
              >
                {financeData?.payment_confirmations?.length ? (
                  <div className="admin-invoice-data-holder mobile-hide">
                    {financeData?.payment_confirmations?.map(
                      (invoice, index) => (
                        <AdminFinancialConfirmationCard
                          key={index}
                          invoiceName={invoice?.invoice_name}
                          referenceCode={invoice?.vendor_id}
                          requestAmount={formatAmount(invoice?.invoice_amount)}
                          description="Funding replacements"
                          // requestDate="26-02-2022"
                          userName={invoice?.vendor_name}
                          userType="vendor"
                          id={invoice?.confirmation_id}
                          loadData={loadData}
                          url="https://images.pexels.com/photos/730547/pexels-photo-730547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div className="admin-empty-invoice mobile-hide">
                    <NoInvoiceIcon />
                    <p>You currently do not have any active Invoice </p>
                  </div>
                )}
              </div>
              {!high && <span className="admin-financial-control-btn"></span>}
            </>
          )}

          {/* TRANSACTION PART */}
          {selector === "Transactions" && (
            <>
              <div
                className={
                  !high === false
                    ? "admin-financial-scroll-long scroll"
                    : "admin-financial-scroll scroll"
                }
              >
                {financeData?.payment_transactions?.length ? (
                  <div className="admin-invoice-data-holder mobile-hide">
                    {financeData?.payment_transactions?.map(
                      (invoice, index) => (
                        <AdminFinancialTransactionCard
                          key={index}
                          invoiceName={invoice?.transaction_note}
                          referenceCode={invoice?.user_id}
                          requestAmount={formatAmount(invoice?.amount)}
                          currency={invoice?.currency}
                          description="Funding replacements"
                          requestDate={moment(invoice?.date).format(
                            "DD-MM-YYYY"
                          )}
                          userName={invoice?.sender_name}
                          isCredit={invoice?.transaction_type === "Credit"}
                          userType="vendor"
                          userId={invoice?.user_id}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div className="admin-empty-invoice mobile-hide">
                    <NoInvoiceIcon />
                    <p>You currently do not have any active Invoice </p>
                  </div>
                )}
              </div>
              {!high && <span className="admin-financial-control-btn"></span>}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminFinancialPage;
