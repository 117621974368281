import React from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/seller/invoice/mobile-invoice-detail-modal.scss";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { convertTime } from "../../../util/convertTime";

export const MobileInvoiceDetailModal = (props) => {
  const {
    controlModal,
    modal,
    credit,
    ROI,
    dueDate,
    dueTime,
    bidderNumber,
    sellerName,
    vendorPhoneNumber,
    vendorEmail,
    maxPayable,
    invoiceAmount,
    vendorName,
    invoiceName,
    statusIndicator,
    loading,
    invoiceURL,
  } = props;

  const { text, difference } = convertTime(dueTime);

  return (
    <AnimatePresence>
      {modal && (
        <FadeTransition absolute>
          <div className="invoice-detail-modal">
            <div onClick={controlModal} className="invoice-detail-overlay">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ type: "spring" }}
                className="detail-invoice-modal"
                onClick={(evt) => evt.stopPropagation()}
              >
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <div className="modal-invoice-head">
                      <h3>Invoice Detail</h3>
                      <span onClick={controlModal}>
                        <CloseIcon />
                      </span>
                    </div>

                    <section className="direction-change">
                      <div className="row-container">
                        <span className="detail-one">
                          <h5>Invoice Name</h5>
                          <h3>{invoiceName}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>Vendor Name</h5>
                          <h3>{vendorName}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>Seller Name</h5>
                          <h3>{sellerName}</h3>
                        </span>
                      </div>

                      <div className="row-container">
                        <span className="detail-one">
                          <h5>Invoice Amount</h5>
                          <h3>₦{invoiceAmount}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>Max Payable Amount</h5>
                          <h3>₦{maxPayable}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>Seller Credit Score</h5>
                          <h3
                            className="credit-status"
                            style={{
                              color:
                                credit >= 70
                                  ? "green"
                                  : credit >= 50
                                  ? "blue"
                                  : "#E07E24",
                            }}
                          >
                            {credit}
                          </h3>
                        </span>
                      </div>

                      <div className="row-container">
                        <span className="detail-one">
                          <h5>Vendor Email</h5>
                          <h3>{vendorEmail}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>Vendor Contact</h5>
                          <h3>{vendorPhoneNumber}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>No of Bidders</h5>
                          <h3>{bidderNumber}</h3>
                        </span>
                      </div>
                    </section>

                    {/* <section className="direction-change">
                      <div className="row-container"></div>

                      <div className="row-container">
                        <span className="detail-one">
                          <h5>ROI Rate</h5>
                          <h3
                            style={{
                              color: ROI >= 7 ? "#D13D2A" : "#E07E24",
                            }}
                          >
                            {100 - ROI}%
                          </h3>
                        </span>
                      </div>

                      <div className="row-container">
                        <span className="detail-one">
                          <h5>Due date</h5>
                          <h3>{dueDate}</h3>
                        </span>
                        <span className="detail-one">
                          <h5>Time Remaining</h5>
                          <h3>{difference <= 0 ? "Bid Closed" : text}</h3>
                        </span>
                      </div>
                    </section> */}
                    <div className="modal-status-indicators">
                      <span
                        className="indicator pending"
                        style={{
                          backgroundColor:
                            statusIndicator === "pending"
                              ? "#EAEBFF"
                              : "#F6F6F6",
                        }}
                      >
                        Pending
                      </span>
                      <span
                        className="indicator approved"
                        style={{
                          backgroundColor:
                            statusIndicator === "approved"
                              ? "#E1FCFF"
                              : "#F6F6F6",
                        }}
                      >
                        Approved
                      </span>
                      <span
                        className="indicator sold"
                        style={{
                          backgroundColor:
                            statusIndicator === "sold" ? "#E0FFED" : "#F6F6F6",
                        }}
                      >
                        Sold
                      </span>
                      <span
                        className="indicator closed"
                        style={{
                          backgroundColor:
                            statusIndicator === "closed"
                              ? "#F7E1FF;"
                              : "#F6F6F6",
                        }}
                      >
                        Closed
                      </span>
                      <span
                        className="indicator rejected"
                        style={{
                          backgroundColor:
                            statusIndicator === "rejected"
                              ? "#F6E0E0"
                              : "#F6F6F6",
                        }}
                      >
                        Rejected
                      </span>
                    </div>
                    <div className="preview-holder">
                      <p className="preview">
                        To preview Invoice,
                        <a
                          href={invoiceURL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Click here
                        </a>
                      </p>
                    </div>
                  </>
                )}
              </motion.div>
            </div>
          </div>
        </FadeTransition>
      )}
    </AnimatePresence>
  );
};
