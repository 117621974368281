export const CloseIcon = ({ color }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="5" fill="none" />
      <path
        d="M21.9892 21L26.9638 15.2411C27.0472 15.1454 26.9771 15 26.8482 15H25.336C25.2469 15 25.1616 15.0387 25.1029 15.1049L21 19.8552L16.8971 15.1049C16.8403 15.0387 16.755 15 16.664 15H15.1518C15.0229 15 14.9528 15.1454 15.0362 15.2411L20.0108 21L15.0362 26.7589C15.0175 26.7802 15.0055 26.8063 15.0016 26.8341C14.9978 26.8618 15.0022 26.8901 15.0144 26.9154C15.0265 26.9408 15.0459 26.9623 15.0703 26.9773C15.0947 26.9923 15.1229 27.0002 15.1518 27H16.664C16.7531 27 16.8384 26.9613 16.8971 26.8951L21 22.1448L25.1029 26.8951C25.1597 26.9613 25.245 27 25.336 27H26.8482C26.9771 27 27.0472 26.8546 26.9638 26.7589L21.9892 21Z"
        fill={color || "#414141"}
      />
    </svg>
  );
};
