export const CheckBoxIcon = () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Success</title>
    <path
      d="M74.2229 0H15.7771C11.5942 0.00376685 7.5832 1.66678 4.62491 4.62491C1.66662 7.58304 0.00369646 11.594 0 15.7771V74.2229C0.00376685 78.4058 1.66678 82.4168 4.62491 85.3751C7.58304 88.3334 11.594 89.9963 15.7771 90H74.2229C78.4065 89.9962 82.4174 88.3332 85.3751 85.3751C88.3332 82.417 89.9963 78.406 90 74.2229V15.7771C89.9962 11.5942 88.3332 7.5832 85.3751 4.62491C82.4176 1.66678 78.4066 0.00369646 74.2229 0ZM41.003 60.4081L24.6502 44.0634L31.1209 37.592L41.003 47.4654L58.8762 29.5922L65.3469 36.0636L41.003 60.4081Z"
      fill="#081B33"
    />
  </svg>
);
