import React from "react";

const LogOutCard = (props) => {
  const { icon, color, ground, onClick } = props;
  return (
    <div className="log-out-container" onClick={onClick}>
      <div className="logo-svg" style={{ backgroundColor: ground }}>
        {icon}
      </div>
      <div className="text">
        <h4 className="text-name" style={{ color: color }}>
          Log out
        </h4>
      </div>
    </div>
  );
};

export default LogOutCard;
