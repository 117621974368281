import { motion } from "framer-motion";
import { CloseIcon } from "../icons";

export const Toolkit = ({ text, onClose }) => (
  <motion.div
    initial={{ y: "-100%", opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: "-100%", opacity: 0 }}
    className="bid-success"
  >
    <span>
      {text}

      {onClose && (
        <button onClick={onClose}>
          <CloseIcon color="#fff" />
        </button>
      )}
    </span>
  </motion.div>
);
