import axios from "axios";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/seller/invoice/invoice-card.scss";
import { formatAmount } from "../../../util/formatAmount";
// import "../../index"
import { MobileInvoiceDetailModal } from "./MobileInvoiceDetailModal";

export const MobileInvoiceCard = (props) => {
  const {
    invoiceName,
    amount,
    payAmount,
    payRate,
    vendor,
    dueDate,
    status,
    id,
  } = props;

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState();

  const controlModal = () => {
    setModal(!modal);
  };

  const onModalOpen = async () => {
    const token = localStorage.getItem("token");

    setModal(true);

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/invoice_details/${id}`,
        config
      );
      setIsLoading(false);

      console.log(data);

      if (data.success) setInvoiceData(data.invoice_details[0]);
    } catch (error) {
      console.error(error);
    }
  };
  const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  return (
    <div className="mobile-invoice-card-container">
      <div className="mobile-first-card-half">
        <h2 className="mobile-invoice-name">{shortInvoiceName}</h2>
        <h4 className="mobile-invoice-pay-rate">
          Pay Rate:{" "}
          <span className="big" style={{ color: "red" }}>
            {payRate}%
          </span>
        </h4>
        <h2 className="mobile-invoice-date">{dueDate}</h2>
        <h4 className="mobile-card-vendor">
          Vendor: <span className="big">{vendor}</span>
        </h4>
      </div>
      <div className="mobile-second-card-half">
        <h2 className="mobile-invoice-amount">₦{amount}</h2>
        <button onClick={onModalOpen} className="mobile-invoice-btn">
          view
        </button>
      </div>
      <MobileInvoiceDetailModal
        controlModal={controlModal}
        modal={modal}
        loading={isLoading}
        dueDate={invoiceData?.due_date}
        transPrice={amount}
        transName={invoiceName}
        dueTime={invoiceData?.bid_close_time}
        ROI={invoiceData?.max_discount.toFixed(2)}
        credit={invoiceData?.seller_credit_score}
        bidderNumber={invoiceData?.no_of_bidders}
        sellerName={invoiceData?.seller_name}
        vendorPhoneNumber={invoiceData?.vendor_phone}
        payRate={payRate}
        vendorEmail={invoiceData?.vendor_email}
        maxPayable={formatAmount(invoiceData?.payable_amount_by_investor)}
        invoiceAmount={formatAmount(invoiceData?.invoice_amount)}
        vendorName={invoiceData?.vendor_name}
        invoiceName={invoiceName}
        statusIndicator={status}
        invoiceURL={invoiceData?.invoice_url}
      />
    </div>
  );
};
