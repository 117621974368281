import React from 'react'

const ProfileIcon = ({svgColor}) => {
    return (
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.199 13.5355C12.9984 13.5355 17.2292 11.5426 17.2292 6.76776C17.2292 3.02987 16.6809 0 11.199 0C5.71712 0 5.16882 3.03004 5.16882 6.76776C5.16882 11.5426 9.39944 13.5355 11.199 13.5355ZM6.07046 5.63801C6.49398 4.88413 7.41034 4.01291 9.38072 4.05157C9.38072 4.05157 10.5552 6.67312 16.3209 5.49585C16.3442 5.91455 16.3515 6.34196 16.3515 6.76737C16.3515 9.10148 15.2316 10.5476 14.2919 11.3498C13.1081 12.3606 11.7937 12.7275 11.1991 12.7275C10.6045 12.7275 9.29015 12.3607 8.10619 11.3498C7.16655 10.5475 6.04663 9.10139 6.04663 6.76737C6.04681 6.38985 6.05281 6.01101 6.07028 5.63797L6.07046 5.63801Z" fill={svgColor}/>
            <path d="M22.3955 20.476C22.0877 18.7144 21.4539 16.4453 20.1826 15.639C19.3146 15.0882 16.286 13.5992 14.9926 12.9634L14.9702 12.9523C14.8223 12.8797 14.6418 12.8942 14.5099 12.9895C13.8324 13.479 13.0904 13.8088 12.3041 13.9699C12.1653 13.9983 12.0513 14.0892 12.0009 14.2116L11.2004 16.1535L10.3999 14.2116C10.3493 14.0892 10.2354 13.9985 10.0966 13.9699C9.31054 13.8088 8.56832 13.4788 7.89083 12.9895C7.75894 12.8942 7.57847 12.8797 7.43057 12.9523C6.15272 13.5806 3.08697 15.1001 2.22193 15.6365C0.758541 16.5437 0.118732 19.827 0.00537516 20.476C-0.00590417 20.5405 0.000645282 20.6063 0.0242951 20.6677C0.0766889 20.8038 1.41965 24 11.2002 24C20.9809 24 22.324 20.8037 22.3762 20.6677C22.3996 20.6065 22.4062 20.5405 22.3949 20.4762L22.3955 20.476Z" fill={svgColor}/>
        </svg>
    )
}

export default ProfileIcon
