import React from "react";
import "../../../styles/seller/report/report-invoice-card.scss";

const ReportInvoiceCard = (props) => {
  const { invoiceName, amount, yourBid, payAmount, dueDate } = props;

  return (
    <>
      <tr className="report-invoice-cards">
        <td>{invoiceName}</td>
        <td>₦{amount}</td>
        <td
          style={{
            color: yourBid > 5 ? "black" : yourBid < 4.5 ? "red" : "#565CD6",
          }}
        >
          {yourBid}%
        </td>

        <td>₦{payAmount}</td>
        <td>{dueDate}</td>
      </tr>
    </>
  );
};

export default ReportInvoiceCard;
