/**
 * validateAmount checks to see if string is valid amount.
 *
 * @param amount
 */

export const validateAmount = (amount) => {
  const reg = /^\d{0,8}(\.\d{0,2})?$/;
  if (!amount.trim()) {
    return {
      isValid: false,
      error: "Please, enter an amount.",
    };
  }

  if (!reg.test(amount)) {
    return {
      isValid: false,
      error: "Please, enter a valid amount.",
    };
  }

  return {
    isValid: true,
  };
};
