import { useState } from "react";
import styles from "../../styles/VendorSignUp.module.scss";
import { Link } from "react-router-dom";
import { MainLogo } from "../../components/icons/logo";
import {
  HidePasswordIcon,
  LeftArrowIcon,
  PasswordIcon,
} from "../../components/icons";
import { OptimizedInput } from "../../components/form/OptimizedInput";
import { MobileVendor } from "../../components/icons/mobile";
import { useNavigate } from "react-router";
import { validateSMESignUp } from "../../util/validate/validateSMESignup";
import axios from "axios";
import { BACKEND_URL } from "../../config";

const VendorRegisterPage = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameCompany, setNameCompany] = useState("");
  const [password, setPassword] = useState("");
  const [control, setControl] = useState(false);

  /** LOADING STATE */
  const [isLoading, setIsLoading] = useState(false);

  /** ERROR STATES */
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [nameCompanyError, setNameCompanyError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const navigate = useNavigate();

  const toggleChange = () => {
    if (control === true) {
      setControl(false);
    } else {
      setControl(true);
    }
  };
  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateSMESignUp({
      companyName: nameCompany.trim(),
      phoneNumber: phone.trim(),
      email: email.toLowerCase().trim(),
      password,
    });

    if (!isValid) {
      if (error.companyName) setNameCompanyError(error.companyName);
      if (error.phoneNumber) setPhoneError(error.phoneNumber);
      if (error.email) setEmailError(error.email);
      if (error.password) setPasswordError(error.password);
    } else {
      try {
        setIsLoading(true);
        const body = {
          company_name: nameCompany.trim(),
          phone,
          email: email.toLowerCase().trim(),
          password,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/vendor_signup`,
          body,
          config
        );

        setIsLoading(false);

        if (!data.success) {
          setEmailError(data.message);
          return;
        }

        localStorage.setItem("email", email.toLowerCase().trim());
        navigate({
          pathname: "/email-verification",
          search: "?acc=vendor",
        });
      } catch (error) {
        setEmailError(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
        console.err(error);
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <span className={styles.topLogo}>
        <MainLogo />
      </span>
      <div className={styles.multiCard}>
        <p className={styles.directMember}>
          Not a Vendor?
          <Link to="/signup">Choose a different account</Link>
        </p>
      </div>
      <div className={styles.pageCard}>
        <span className={styles.mobileLogo}>
          <MobileVendor />
          <button className="mobile-top-link" onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button>
        </span>
        <h3 className={styles.pageTitle}>
          <button className="top-link" onClick={() => navigate(-1)}>
            <LeftArrowIcon />
          </button>
        </h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.cardButton}>
            <section className={styles.cover}>
              <OptimizedInput
                title="Company Name"
                name="Name"
                placeholder="Enter Your Company Name"
                value={nameCompany}
                onChange={(e) => setNameCompany(e.target.value)}
                type="text"
                maxlength="30"
                onFocus={() => nameCompanyError && setNameCompanyError(null)}
              />
              {nameCompanyError && (
                <div className="error-text">{nameCompanyError}</div>
              )}

              <OptimizedInput
                title="Email Address"
                name="Email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                maxlength="30"
                onFocus={() => emailError && setEmailError(null)}
              />
              {emailError && <div className="error-text">{emailError}</div>}

              <OptimizedInput
                title="Phone number"
                name="telephone"
                placeholder="Enter your Phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                maxlength="30"
                onFocus={() => phoneError && setPhoneError(null)}
              />
              {phoneError && <div className="error-text">{phoneError}</div>}

              <OptimizedInput
                title="Password"
                name="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onIconClick={toggleChange}
                type={control ? "text" : "password"}
                icon={control ? <HidePasswordIcon /> : <PasswordIcon />}
                maxlength="20"
                onFocus={() => passwordError && setPasswordError(null)}
              />
              {passwordError && (
                <div className="error-text">{passwordError}</div>
              )}
            </section>
          </div>

          <div style={{ textAlign: "center" }}>
            <button
              className={styles.press}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <div className="loading" /> : "Create Account"}
            </button>
          </div>
        </form>

        <div className={styles.mobileMultiCard}>
          <p className={styles.directMember}>
            Not an Investor?
            <Link to="/signup">Choose a different account</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VendorRegisterPage;
