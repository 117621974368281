export const HidePasswordIcon = () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2_1901)">
      <path
        d="M3.24853 0.357981C2.7799 -0.119327 2.0201 -0.119327 1.55147 0.357981C1.08284 0.835288 1.08284 1.60916 1.55147 2.08646L4.46048 5.04933C2.74368 6.36457 1.24711 8.17108 0.126688 10.4534C-0.0422293 10.7975 -0.0422293 11.2025 0.126688 11.5466C2.71656 16.8222 7.31626 19.5556 12 19.5556C13.8662 19.5556 15.7191 19.1216 17.4367 18.2659L20.7515 21.642C21.2201 22.1193 21.9799 22.1193 22.4486 21.642C22.9172 21.1647 22.9172 20.3908 22.4486 19.9135L3.24853 0.357981ZM15.6166 16.412C14.4411 16.8803 13.2161 17.1111 12 17.1111C8.43747 17.1111 4.79768 15.1302 2.55559 11C3.53666 9.19277 4.78534 7.79705 6.17807 6.79872L8.75086 9.41916C8.52596 9.89783 8.40002 10.4339 8.40002 11C8.40002 13.0251 10.0118 14.6667 12 14.6667C12.5558 14.6667 13.0822 14.5384 13.5521 14.3093L15.6166 16.412Z"
        fill="#838D99"
      />
      <path
        d="M19.8626 13.3167C20.4422 12.638 20.974 11.8663 21.4442 11C19.2021 6.86986 15.5623 4.88891 11.9998 4.88891C11.8654 4.88891 11.7309 4.89173 11.5963 4.89738L9.45508 2.71651C10.2927 2.53553 11.1448 2.44446 11.9998 2.44446C16.6835 2.44446 21.2832 5.17778 23.8731 10.4534C24.042 10.7975 24.042 11.2025 23.8731 11.5466C23.2203 12.8763 22.4399 14.0445 21.5626 15.0482L19.8626 13.3167Z"
        fill="#838D99"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_1901">
        <rect width="24" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
