import React from "react";
import "../../styles/vendor/vendor-status-bar.scss";
// import { InvestorActivationModalForm } from "./dashboard/InvestorActivationFormModal";

export const VendorStatusBar = ({ activated, title }) => {
  //  const [modal, setModal] = useState(false);

  // const controlModal = () => {
  //   setModal(!modal);
  // };

  // const ownerName = "Vendor Dashboard";

  return (
    <div className="vendor-status-container">
      <div className="vendor-status">
        {/* {activated === false && (
          <p className="vendor-inactivated-status">
            Your account is deactivated,{" "}
            <span className="mobile-hide">Click here to </span>
            <span className="vendor-status-leader" onClick={controlModal}>
              Activate now
            </span>
          </p>Z
        )} */}

        {activated === true && <h2 className="vendor-owner-status">{title}</h2>}
      </div>
    </div>
  );
};
