import React from 'react'
import "../../styles/vendor/vendor-fait-selection-card.scss"

const VendorFiatCard = (props) => {
    const {
        icon,
        title1,
        title2,
        statement,
        onClick
            }=props
  return (
      <>
    <div className='vendor-fiat-card-container' onClick={onClick}>
        <div className="vendor-fiat-card-icon-case">
            {icon}  
        <h3 className='vendor-fait-card-title window-hide'>
                {title1} <span className='mobile-hide'>{title2}</span>
        </h3>
        </div>
        
        <div className="vendor-fiat-card-word-case">
            <h3 className='vendor-fait-card-title mobile-hide'>
                {title1} <span>{title2}</span>
            </h3>
            <p className='vendor-fiat-card-statement'>
                {statement}
            </p>
        </div>

    </div>
    </>
  )
}

export default VendorFiatCard;