import React from "react";
import "../../../styles/investor/stats-styles/stat-card.scss";
import { TotalInvestmentCardIcon } from "../../icons/index.jsx";

const TotalInvestmentCard = ({ amount }) => {
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  return (
    <div className="stat-card-container" style={{ background: "#BB3F63" }}>
      <div className="stat-svg-container">
        <div className="first-part">
          <span className="top-section">
            <TotalInvestmentCardIcon />
          </span>
          <span className="bottom-section">
            <h3 className="header-text">Total Investment</h3>
            <h3 className="digit">{userCurrency === "naira" ? `₦`:`$` }{amount}</h3>
          </span>
        </div>
        <div className="second-part">
          <span className="progress-bar-holder"></span>
        </div>
      </div>
    </div>
  );
};

export default TotalInvestmentCard;
