import React from "react"

export const DownwardArrowIcon=({color})=>{
    return(

        <svg 
            width="8" 
            height="5" 
            viewBox="0 0 8 5" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            
        >
        <path 
            d="M4 5L0 0L4 1.53846L8 0L4 5Z" 
            fill={color || "#414141"}
        />
        </svg>
    )
}