import React from "react";
import { useNavigate } from "react-router";
import { LeftArrowIcon } from "../components/icons";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import "../styles/index.scss";
import styles from "../styles/email-verification.module.scss";
import EmailConfirmation from "../components/icons/EmailConfirmation";
import { Link } from "react-router-dom";

const EmailUnverified = () => {
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <span className={styles.topLogo}>
        <MainLogo />
      </span>

      <div className="page-card">
        <span className="mobile-logo">
          <MobileLogo />
        </span>

        <div className="card-button">
          <p className={styles.verifyLogo}>
            <EmailConfirmation svgColor="#BF3030" />
          </p>

          <h2 className={styles.header}>Server Error!</h2>

          <p className={styles.statement}>
            Sorry! your account could not be verified due to server/network
            issues. Kindly try again
          </p>

          <p className={styles.bottomLink}>
            To login
            <Link to="/signin" className={styles.signinLink}>
              {" "}
              Click here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailUnverified;
