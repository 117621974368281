import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/investor/investor-dashboard-styles/crypto-withdrawal-modal.scss";
import { validateUSDCWithdrawal } from "../../../util/validate/validateUSDCWithdrawal";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { CloseIcon, LeftArrowIcon } from "../../icons/index";
import { InvestorActivationSuccessModal } from "./InvestorActivationSuccessModal";

const CryptoWithdrawalModal = (props) => {
  const { controlModal, modal, loadData } = props;

  const [walletAddress, setWalletAddress] = useState("");
  const [memo, setMemo] = useState("");
  const [amount, setAmount] = useState("");
  const [network, setNetwork] = useState("Stellar");

  const [walletAddressError, setWalletAddressError] = useState(null);
  const [memoError, setMemoError] = useState(null);
  const [amountError, setAmountError] = useState(null);

  const [success, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const handleWithdraw = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateUSDCWithdrawal({
      amount: amount.trim(),
      memo: memo.trim(),
      address: walletAddress.trim(),
    });

    if (!isValid) {
      if (error?.amount) {
        setAmountError(error.amount);
      }

      if (error?.memo) {
        setMemoError(error.memo);
      }

      if (error?.address) {
        setWalletAddressError(error.address);
      }
    } else {
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);

        const body = {
          amount: amount.trim(),
          wallet_address: walletAddress.trim(),
          memo: memo.trim(),
          network,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        console.log(body)
        const { data } = await axios.post(
          `${BACKEND_URL}/withdrawalUSD/${token}`,
          body,
          config
        );

        setIsLoading(false);
          console.log(data)
        if (!data.success && data.status === 202) {
          if (data.message === "Oops! An error occured.")
            setAmountError(data.message);
          if (data.message.includes("This is not a valid account:"))
            setWalletAddressError("Enter a valid wallet address");
          if (
            data.message.includes(
              "the JSON object must be str, bytes or bytearray, not BadRequestError"
            )
          ) {
            setError(
              "Error! Transaction could not be completed. Please try again"
            );

            setTimeout(() => setError(null), 5000);
          }
          else{
            setError(data.message)
            setTimeout(() => setError(null), 5000);
          }
        }

        if (data.success && data.status === 200) {
          controlModal();
          setSuccess(true);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };

  return (
    <>
      {modal && (
        <div className="investor-crypto-withdraw-modal">
          <div
            onClick={controlModal}
            className="investor-crypto-withdraw-overlay"
          >
            <div
              className="crypto-withdrawal-case"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="investor-crypto-withdraw-head">
                {/* <span className="left-arrow" onClick={controlModal}>
                  <LeftArrowIcon />
                </span> */}
                <div />
                <h3 className="crypto-withdraw-heading">Crypto (USDC)</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>
              <span className="bar-code">{/* <CryptoDepositIcon/> */}</span>

              <form onSubmit={handleWithdraw}>
                <div className="crypto-withdraw-holder-address">
                  <span className="left-section-withdraw">
                    <h3 className="wallet-address-title">
                      Blockchain Smart Chain
                    </h3>
                    <p className="crypto-withdraw-address">
                      <select
                        onChange={(e) => setNetwork(e.target.value)}
                        value={network}
                        className="input-text"
                      >
                        <option value="Stellar" defaultValue>
                          Stellar
                        </option>
                        <option value="ERC20">ERC20</option>
                      </select>
                      {/* <input
                            type="text"
                            className="input-text"
                            value={walletAddress}
                            onChange={(e)=>setWalletAddress(e.target.value)}
                            placeholder='E.g: GBH4TZYZ4IRCPO44CBOLFUHULU2WGALXTAVESQA6432MBJMABBB4GIYI'
                        /> */}
                    </p>
                  </span>
                </div>

                <div className="crypto-withdraw-holder-address">
                  <span className="left-section-withdraw">
                    <h3 className="wallet-address-title">Wallet Address</h3>
                    <p className="crypto-withdraw-address">
                      <input
                        type="text"
                        className="input-text"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                        onFocus={() =>
                          walletAddressError && setWalletAddressError(null)
                        }
                        placeholder="E.g: GBH4TZYZ4IRCPO44CBOLFUHULU2WGALXTAVESQA6432MBJMABBB4GIYI"
                      />
                      {walletAddressError && (
                        <div className="error-text">{walletAddressError}</div>
                      )}
                    </p>
                  </span>
                </div>

                <div className="crypto-withdraw-holder-address">
                  <span className="left-section-withdraw">
                    <h3 className="wallet-address-title">Memo</h3>
                    <p className="crypto-withdraw-address">
                      <input
                        type="text"
                        className="input-text"
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                        placeholder="E.g 4376777"
                        onFocus={() => memoError && setMemoError(null)}
                      />
                      {memoError && (
                        <div className="error-text">{memoError}</div>
                      )}
                    </p>
                  </span>
                </div>

                <div className="crypto-withdraw-holder-address">
                  <span className="left-section-withdraw">
                    <h3 className="wallet-address-title">Amount</h3>
                    <p className="crypto-withdraw-address">
                      <input
                        type="text"
                        className="input-text"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="E.g  367"
                        onFocus={() => amountError && setAmountError(null)}
                      />
                      {amountError && (
                        <div className="error-text">{amountError}</div>
                      )}
                    </p>
                  </span>
                </div>

                <div className="crypto-withdraw-btn-case">
                  <button className="crypto-withdraw-btn">Withdraw</button>

                  <p className="page-statement">
                    Your withdrawal amount will be deducted from your Quidroo
                    balance
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {isLoading && <ModalLoader />}

      <AnimatePresence>{error && <Toolkit text={error} />}</AnimatePresence>

      <InvestorActivationSuccessModal
        modal={success}
        onLoad={() => {
          setSuccess(false);
          loadData();
        }}
        title="Withdrawal Successful"
        desc="You have successfully withdrawn from your account. Kindly check your wallet to confirm"
      />
    </>
  );
};

export default CryptoWithdrawalModal;
