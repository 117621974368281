import React, { useState } from "react";
import "../../../styles/seller/invoice/dropdown-select.scss";
import { DownwardArrowIcon } from "../../icons";



const MobileFilterOptionDropdown = (props) => {
    const{selected,setSelected,option,height}=props
    
const [isOpen, setIsOpen] = useState(false)

function toggler () {
    setIsOpen(!isOpen)
}


  return (
    <div className="mobile-dropdown window-hide">
        <div className="mobile-dropdown-btn" onClick={toggler}>
            {selected}
           <span ><DownwardArrowIcon/></span> 
        </div>
{isOpen  &&  (<div className="mobile-dropdown-content" style={{height:height}}>

    {option.map(option=>(
        <div onClick={()=>{
            setSelected(option)
            setIsOpen(false)
        }} 
        className="mobile-dropdown-item">
                {option}
            </div>
    ))}
        </div>)}
    </div>
  );
};

export default MobileFilterOptionDropdown;