import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/selectioncard.module.scss";

const CardMobile = ({ title, body, icon, leader }) => {
  return (
    <>
      {title === "Investors" && (
        <Link to={leader} className={styles.mobileCardInvestor}>
          <div className={styles.mobileBody}>
            <span>{icon}</span>
            <h4 className={styles.cardHeader}>{title}</h4>
          </div>
          <p className={styles.cardStatement}>{body}</p>
        </Link>
      )}

      {title === "SMEs" && (
        <Link to={leader} className={styles.mobileCardSMEs}>
          <div className={styles.mobileBody}>
            <span>{icon}</span>
            <h4 className={styles.cardHeader}>{title}</h4>
          </div>
          <p className={styles.cardStatement}>{body}</p>
        </Link>
      )}

      {title === "Vendor" && (
        <Link to={leader} className={styles.mobileCardVendor}>
          <div className={styles.mobileBody}>
            <span>{icon}</span>
            <h4 className={styles.cardHeader}>{title}</h4>
          </div>
          <p className={styles.cardStatement}>{body}</p>
        </Link>
      )}
    </>
  );
};

export default CardMobile;
