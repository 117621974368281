import axios from "axios";
import React, { useEffect, useState } from "react";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { PlusIcon } from "../../components/icons";
import { MobileDepositWithdrawModal } from "../../components/investor-component/dashboard/MobileDepositWithdrawModal";
import { InvestorStatusBar } from "../../components/investor-component/InvestorStatusBar";
import InvestorInvoiceCard from "../../components/investor-component/invoice/InvestorInvoiceCard";
import InvoiceSearchFilter from "../../components/investor-component/invoice/InvoiceSearchFilter";
import { SmallMobileInvestorInvoiceCard } from "../../components/investor-component/invoice/SmallMobileInvestorInvoiceCard";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import { BACKEND_URL } from "../../config";
import "../../styles/investor/investor-invoice-styles/investor-invoice-pages.scss";
import { formatAmount } from "../../util/formatAmount";

const invoiceSearch = [
  "Vendor",
  "Credit Score",
  // "Seller",
  // "Discount Rate"
];

const InvestorInvoice = () => {
  const [filterSearch, setFilterSearch] = useState("Vendor");
  const [modal, setModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [expire, setExpire] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [isContentLoading, setIsContentLoading] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  const loadInvoice = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/investor_invoice_page/${token}`,
        config
      );
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setInvoiceData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const convertSearchFilter = (filter) => {
    switch (filter) {
      case "Vendor":
        return "vendor";

      case "Credit Score":
        return "credit_score";

      case "Seller":
        return "seller";

      case "Discount Rate":
        return "discount_rate";

      default:
        return "";
    }
  };

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      if (filterText.length) {
        try {
          setIsContentLoading(true);

          const body = {
            search: filterText,
            filterby: convertSearchFilter(filterSearch),
          };

          console.log(body);

          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const { data } = await axios.post(
            `${BACKEND_URL}/investor_invoice_search/${token}`,
            body,
            config
          );
          setIsContentLoading(false);

          if (data.status === 202 && data.message === "Signature has expired")
            setExpire(true);

          if (data.success) setInvoiceData(data);
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          setIsContentLoading(true);

          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const { data } = await axios.get(
            `${BACKEND_URL}/investor_invoice_page/${token}`,
            config
          );
          setIsContentLoading(false);

          if (data.status === 202 && data.message === "Signature has expired")
            setExpire(true);

          if (data.success) setInvoiceData(data);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [filterSearch, filterText]);

  useEffect(() => {
    loadInvoice();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  return (
    <>
      <section>
        <InvestorStatusBar
          ownerName={`Invoices`}
          activated={invoiceData?.verified}
          pending={invoiceData?.providedVerificationDetails}
          loadData={loadInvoice}
        />
      </section>
      <div className="bid-container">
        <div className="bid-half-filter">
          <span className="bid-filter input-bid ">
            <p className="bid-filter mobile-hide">Invoice</p>

            <InvoiceSearchFilter
              selected={filterSearch}
              setSelected={setFilterSearch}
              option={invoiceSearch}
              onInputChange={(evt) => setFilterText(evt.target.value)}
            />
          </span>

          {/* <span className=" mobile-bid-input-filter window-hide">
          
          </span> */}
        </div>

        <div className="investor-bid-scroll">
          <div className="invoice-page-second-half mobile-hide">
            <table className="invoice-page-table">
              <tbody>
                <tr className="invoice-page-header table">
                  <th className="invoice-name">Invoice Name</th>
                  <th className="invoice-amount">Invoice Amount</th>
                  <th className="invoice-credit-score">Credit Score</th>
                  <th className="invoice-time-remaining">Time Remaining</th>
                  <th className="invoice-vendor">Vendor</th>
                  <th className="due-date">{"     "}</th>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="invoice-page-scroll">
            {isContentLoading ? (
              <PageLoader isSub />
            ) : (
              <>
                {invoiceData?.bidded_invoices &&
                invoiceData?.bidded_invoices.length ? (
                  <div className="invoice-page-data-holder mobile-hide">
                    {invoiceData?.bidded_invoices.map((invoice, index) => (
                      <InvestorInvoiceCard
                        id={invoice["invoice_id "]}
                        key={invoice["invoice_id "]}
                        url={invoice.invoice_url}
                        amount={formatAmount(invoice.invoice_amount)}
                        amountUSD={formatAmount(invoice.invoice_amount_USD)}
                        creditScore={invoice.seller_credit_score}
                        timeRemaining={invoice.bid_close_time}
                        vendor={invoice.vendor}
                        invoiceName={invoice["invoice_name "]}
                        loadInvestor={loadInvoice}
                        verified={invoiceData?.verified}
                        maxPayable={invoice.maxPayable}
                        isBidded={invoice.isBidded}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty-invoice mobile-hide">
                    <InvoiceEmptyIcon />
                    <p>You currently do not have any Invoices </p>
                  </div>
                )}
                {invoiceData?.bidded_invoices &&
                invoiceData?.bidded_invoices.length ? (
                  <div className="window-hide">
                    {invoiceData?.bidded_invoices.map((invoice, index) => (
                      <SmallMobileInvestorInvoiceCard
                        id={invoice["invoice_id "]}
                        key={invoice["invoice_id "]}
                        url={invoice.invoice_url}
                        amount={formatAmount(invoice.invoice_amount)}
                        amountUSD={formatAmount(invoice.invoice_amount_USD)}
                        creditScore={invoice.seller_credit_score}
                        timeRemaining={invoice.bid_close_time}
                        vendor={invoice.vendor}
                        invoiceName={invoice["invoice_name "]}
                        loadInvestor={loadInvoice}
                        isBidded={invoice.isBidded}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty-invoice window-hide">
                    <InvoiceEmptyIcon />
                    <p>You currently do not have any Invoices </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="window-hide">
        <button
          onClick={controlModal}
          className="investor-mobile-uploader-btn window-hide"
        >
          <PlusIcon />
        </button>

        <MobileDepositWithdrawModal controlModal={controlModal} modal={modal} />
      </div>
    </>
  );
};

export default InvestorInvoice;
