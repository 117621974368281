import React from "react";
import "../../../styles/admin/admin-dashboard/admin-user-total-summary-card.scss";
import { InvestorIcon, SmallMEIcon } from "../../icons";
import { InvoiceIcon } from "../../icons/navigator/Index";

export const AdminUserTotalSummaryCard = (props) => {
  const { userName, userTotalNumber, statusName, statusCount } = props;

  return (
    <>
      {userName === "Total Investors" ? (
        <div className="admin-user-summary-card-container">
          <div className="admin-summary-card-top">
            <div className="admin-summary-card-top-left">
              <h3 className="admin-summary-card-user-name">{userName}</h3>
              <h1
                style={{ color: "#3FBB97" }}
                className="admin-summary-card-user-total-number"
              >
                {userTotalNumber}
              </h1>
            </div>

            <div className="admin-summary-card-top-right">
              <span className="admin-summary-card-icon">
                <InvestorIcon />
              </span>
            </div>
          </div>

          <div className="admin-summary-card-bottom">
            <h4 className="admin-summary-card-status-name">{statusName}:</h4>
            <h3
              style={{ color: "#3FBB97" }}
              className="admin-summary-card-status-count"
            >
              {statusCount}
            </h3>
          </div>
        </div>
      ) : userName === "Total Vendors" ? (
        <div className="admin-user-summary-card-container">
          <div className="admin-summary-card-top">
            <div className="admin-summary-card-top-left">
              <h3 className="admin-summary-card-user-name">{userName}</h3>
              <h1
                style={{ color: "#E5AA33" }}
                className="admin-summary-card-user-total-number"
              >
                {userTotalNumber}
              </h1>
            </div>
            <div className="admin-summary-card-top-right">
              <span className="admin-summary-card-icon">
                <InvoiceIcon svgColor="#E5AA33" />
              </span>
            </div>
          </div>

          <div className="admin-summary-card-bottom">
            <h4 className="admin-summary-card-status-name">{statusName}:</h4>
            <h3
              style={{ color: "#E5AA33" }}
              className="admin-summary-card-status-count"
            >
              {statusCount}
            </h3>
          </div>
        </div>
      ) : (
        <div className="admin-user-summary-card-container">
          <div className="admin-summary-card-top">
            <div className="admin-summary-card-top-left">
              <h3 className="admin-summary-card-user-name">{userName}</h3>
              <h1
                style={{ color: "#565CD6" }}
                className="admin-summary-card-user-total-number"
              >
                {userTotalNumber}
              </h1>
            </div>
            <div className="admin-summary-card-top-right">
              <span className="admin-summary-card-icon">
                <SmallMEIcon />
              </span>
            </div>
          </div>

          <div className="admin-summary-card-bottom">
            <h4 className="admin-summary-card-status-name">{statusName}:</h4>
            <h3
              style={{ color: "#565CD6" }}
              className="admin-summary-card-status-count"
            >
              {statusCount}
            </h3>
          </div>
        </div>
      )}
    </>
  );
};
