import React from 'react'

export const CryptoDepositIcon = () => {
  return (
    <svg 
        width="200" 
        height="200" 
        viewBox="0 0 200 200" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M64 0H80.0003V7.99926H64V0Z" 
        fill="black"
    />
    <path 
        d="M96 0H103.999V7.99926H120V15.9999H96V0Z" 
        fill="black"
    />
    <path 
    d="M128 0H136.001V87.9997H128V72.0008H120.001V80.0001H111.997V72.0008H104V64.0001H96.0012V40.0006H88.0005V24.0003H64.001V16.001H80.0013V8.00033H88.0005V16.001H96.0012V24.0003H120.001V32.001H104V56.0005H111.997V47.9998H120.001V64.0001H128V40.0006H120.001V32.0013H128V0Z" 
    fill="black"
    />
    <path 
    d="M0 0V55.9998H55.9998V0H0ZM47.9998 47.9998H7.99997V7.99997H47.9998V47.9998Z" 
    fill="black"
    />
    <path 
    d="M144 0V55.9998H200V0H144ZM192 47.9998H152V7.99997H192V47.9998Z" 
    fill="black"
    />
    <path 
    d="M16 16H39.9996V39.9996H16V16Z" 
    fill="black"
    />
    <path 
    d="M159.999 16H183.999V39.9996H159.999V16Z" 
    fill="black"
    />
    <path 
    d="M64 32H80.0003V47.9989H72.001V39.9996H64.0004L64 32Z" 
    fill="black"
    />
    <path 
    d="M63.9994 48H72.0001V56.0007H79.9993V48H87.9986V64.0003H95.9993V72.001H103.999V80.0002H95.9993V87.9995H87.9986V72.0006H79.9993V63.9999H63.999L63.9994 48Z" 
    fill="black"
    />
    <path 
    d="M40 64H56.0003V71.9993H40V64Z" 
    fill="black"
    />
    <path 
    d="M159.999 64H167.998V71.9993H159.999V64Z" 
    fill="black"
    />
    <path 
    d="M175.999 64H183.998V80.0003H199.999V104H183.998V87.9996H175.999V64Z" 
    fill="black"
    />
    <path 
    d="M191.999 64H199.998V71.9993H191.999V64Z" 
    fill="black"
    />
    <path 
    d="M7.99902 72H23.9993V79.9993H7.99902V72Z" 
    fill="black"
    />
    <path 
    d="M32 72H39.9993V79.9993H32V72Z" 
    fill="black"
    />
    <path 
    d="M0 80H7.99926V87.9993H0V80Z" 
    fill="black"
    />
    <path 
    d="M48 80H55.9993V87.9993H48V80Z" 
    fill="black"
    />
    <path 
    d="M16.0003 88H32.0006V104H23.9999V112.001H16.0007V104H8V96.001H16.0007L16.0003 88Z" 
    fill="black"
    />
    <path 
    d="M40 88H47.9993V95.9993H40V88Z" 
    fill="black"
    />
    <path d="M120 88H127.999V95.9993H120V88Z" fill="black"/>
    <path d="M48 96H55.9993V103.999H48V96Z" fill="black"/>
    <path d="M0 104H7.99926V136.001H0V104Z" fill="black"/>
    <path d="M32 104H39.9993V112.004H32V104Z" fill="black"/>
    <path d="M24 111.999H31.9993V119.998H24V111.999Z" fill="black"/>
    <path d="M48 111.999H55.9993V119.998H48V111.999Z" fill="black"/>
    <path d="M64 111.999H72.0007V119.998H79.9999V135.999H72.0007V143.998H64V111.999Z" fill="black"/>
    <path d="M151.999 111.999H167.996V119.998H151.999V111.999Z" fill="black"/>
    <path d="M183.999 111.999H199.999V119.998H183.999V111.999Z" fill="black"/>
    <path d="M32 120H39.9993V127.999H32V120Z" fill="black"/>
    <path d="M16 128H23.9993V136.004H16V128Z" fill="black"/>
    <path d="M40 128H56.0003V136.004H40V128Z" fill="black"/>
    <path d="M80 135.999H87.9993V143.998H80V135.999Z" fill="black"/>
    <path d="M200 135.999V127.999H183.999V119.999H167.999V127.999H136V119.999H143.999V112H152V103.999H167.999V112H175.999V88.0006L167.999 87.9992V79.9999H159.999V87.9992H152V79.9999H159.999V72.0006H152V64.0014L143.999 64V87.9996H136V111.999L120 112.001V96.0002H111.996V112.001H103.997V88.001H95.9978V104.001H87.9986V88.001H63.999V96.0002H71.9983V112.001H80.0004V120H87.9996V112.001H95.9989L96.0003 120H88.001V136H96.0003V143.999H104V127.999H111.999V143.999H120V136H127.999V143.999H120V152H111.996V176H95.9999V183.999H111.997L111.996 191.999H96V199.999H136V191.999H127.999V183.999H120V159.999H127.999V167.999H136V175.999H143.999V167.999H167.999V175.999H175.999V167.999H183.999V159.999H175.999V143.999H167.999V136L200 135.999ZM128 127.999H120.001V119.999H128V127.999ZM160 159.999H136.001V136H160V159.999Z" fill="black"/>
    <path d="M88 144H95.9993V160H88V144Z" fill="black"/>
    <path d="M144 144H152.004V152.004H144V144Z" fill="black"/>
    <path d="M191.999 144H199.998V160H191.999V144Z" fill="black"/>
    <path d="M0 144V200H55.9998V144H0ZM47.9998 192H7.99997V152H47.9998V192Z" fill="black"/>
    <path d="M64 151.999H80.0003V167.998H87.9996V175.999H80.0003V183.998H72.001V159.998H64.0004L64 151.999Z" fill="black"/>
    <path d="M16 159.999H39.9996V183.999H16V159.999Z" fill="black"/>
    <path d="M96 159.999H103.999V167.998H96V159.999Z" fill="black"/>
    <path d="M191.999 168H199.998V200.001H191.999V168Z" fill="black"/>
    <path d="M128 175.999H136.004V183.998H128V175.999Z" fill="black"/>
    <path d="M144 175.999H160V183.998H176.001V199.999H168V191.999H152.001V199.999H144L144 175.999Z" fill="black"/>
    <path d="M175.999 175.999H183.998V183.998H175.999V175.999Z" fill="black"/>
    <path d="M88 183.999H95.9993V192.003H88V183.999Z" fill="black"/>
    <path d="M72 191.999H79.9992V199.998H72V191.999Z" fill="black"/>
    </svg>
    
  )
}
