import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/admin/admin-users/admin-user-investor-verification-card.scss";
import { formatAmount } from "../../../util/formatAmount";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { AdminVerificationIcon } from "../../icons";
import { AdminConfirmationModal } from "../admin-invoice/AdminConfirmationModal";
import { AdminInvestorDetailModal } from "../AdminInvestorDetailModal";
// import { VendorConfirmationModal } from "../vendor-invoice/VendorConfirmationModal";
// import VendorPayNowModalCard from "../vendor-invoice/VendorPayNowModalCard";

const AdminUserInvestorVerificationCard = (props) => {
  const {
    investorName,
    investorPhone,
    walletBalance,
    country,
    emailAddress,
    isVerified,
    url,
    id,
    loading,
    loadData,
  } = props;

  const [investorDetail, setInvestorDetail] = useState(false);
  const [isInvestorLoading, setIsInvestorLoading] = useState(false);
  const [investorDetailsData, setInvestorDetailsData] = useState();

  const [verified, setVerified] = useState(false);
  const [unVerified, setUnVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unverifySuccess, setUnverifySuccess] = useState(false);
  const [error, setError] = useState(null);

  const toggleVerified = () => {
    setVerified(!verified);
  };

  const toggleUnverified = () => {
    setUnVerified(!unVerified);
  };

  // INVESTOR APPROVAL FUNCTION
  const handleConfirmation = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setVerified(false);
      const body = {
        user_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_user_verify/${token}`,
        body,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with verifying");
    }
  };

  const handleUnverify = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setUnVerified(false);
      const body = {
        user_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_user_unverify/${token}`,
        body,
        config
      );

      if (data.success) {
        setUnverifySuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setUnverifySuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with unverifying the investor");
    }
  };

  const onInvestorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setInvestorDetail(true);

    try {
      setIsInvestorLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_investor_detail/${id}`,
        config
      );
      console.log(data);
      setIsInvestorLoading(false);

      if (data.success) setInvestorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };
  // INVESTOR DISAPPROVAL FUNCTION

  const handleUnVerified = () => {
    console.log("disapprove this investor");
  };

  const onInvestorDetailClose = () => {
    setInvestorDetail(false);
    setInvestorDetailsData(undefined);
  };

  return (
    <>
      <tr className="admin-user-investor-verify-invoice-cards">
        <td onClick={onInvestorDetailOpen}>{investorName}</td>
        <td>{country}</td>
        <td>{emailAddress} </td>
        <td>{`${country === "Nigeria" ? "₦" : "$"}${walletBalance}`}</td>
        {/* <td>{investorPhone}</td> */}
        <td>
          {isVerified === false ? (
            <button
              className="admin-invoice-btn active-admin-btn"
              onClick={toggleVerified}
            >
              Verify
            </button>
          ) : (
            <button
              className="admin-user-investor-verified-btn"
              onClick={toggleUnverified}
            >
              <AdminVerificationIcon />
            </button>
          )}
        </td>
      </tr>
      <AdminInvestorDetailModal
        onClose={onInvestorDetailClose}
        modal={investorDetail}
        investorName={investorDetailsData?.name}
        investorAddress={investorDetailsData?.address}
        // investorCredit={80}
        phoneNumber={investorDetailsData?.phone}
        emailAddress={investorDetailsData?.email}
        loading={isInvestorLoading}
        investorBalance={formatAmount(investorDetailsData?.walletBalance)}
        country={investorDetailsData?.country}
        passportNumber={investorDetailsData?.passportNo}
        // typeBusiness="Sole Proprietorship"
        // numberStaff="11 - 50"
        image={investorDetailsData?.profile_pics}
        accountNumber={investorDetailsData?.account_no}
        accountName={investorDetailsData?.account_name}
        bankName={investorDetailsData?.bank}
        memoNumber={investorDetailsData?.memo_id}
        passportUrl={investorDetailsData?.passportUrl}
      />

      <AdminConfirmationModal
        toggleChange={toggleVerified}
        modal={verified}
        topic="Confirmation"
        statement="Would you like to Approve/Authorize this Investor"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleConfirmation}
      />

      <AdminConfirmationModal
        toggleChange={toggleUnverified}
        modal={unVerified}
        topic="Confirmation"
        statement="Would you like to unauthorize/disactivate this Investor"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleUnverify}
      />

      <AnimatePresence>
        {success && (
          <Toolkit text="You have successfully verified the investor" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {unverifySuccess && (
          <Toolkit text="You have successfully unverified the investor" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};

export default AdminUserInvestorVerificationCard;
