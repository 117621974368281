import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusIcon } from "../../components/icons";
import { MobileDepositWithdrawModal } from "../../components/investor-component/dashboard/MobileDepositWithdrawModal";
import { ActivationStatusBar } from "../../components/seller-component/ActivationStatusBar";
import "../../styles/seller//profile/profile-page.scss";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { PageLoader } from "../../components/general-component/PageLoader";

const personalDetails = [
  {
    firstName: "John",
    lastName: "Williams",
    phoneNumber: "08012345678",
    houseAddress: "House 12, 7th Avenue, Zone2, Wuse-Abuja, FCT.",
  },
];

const Profile = () => {
  const [modal, setModal] = useState(false);
  const [profileData, setProfileData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  const loadProfile = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/seller_profile/${token}`,
        config
      );
      setIsLoading(false);

      console.log(data);

      if (data.success) setProfileData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <ActivationStatusBar
        title="Profile"
        activated={profileData?.verified}
        pending={profileData?.providedVerificationDetails}
        loadData={loadProfile}
      />

      <div className="seller-profile-container">
        <section className="mobile-header window-hide">
          <span className="seller-detail-form ">Personal Details</span>
        </section>
        <span className="seller-detail-form mobile-hide">Personal Details</span>
        <div className="modal-content">
          <div className="one">
            <span className="form-carrier first">
              <h4>Company Name</h4>
              <input
                type="text"
                className="profile-input"
                disabled
                value={profileData?.companyName}
                placeholder="Not available yet"
              />
            </span>
          </div>

          <div className="row two">
            <span className="form-carrier">
              <h4>Business Type</h4>
              <input
                type="text"
                className="profile-input"
                disabled
                value={profileData?.business_type}
                placeholder="Not available yet"
              />
            </span>
          </div>

          <div className="row three">
            <span className="form-carrier">
              <h4>Phone Number</h4>
              <input
                type="text"
                className="profile-input"
                disabled
                value={profileData?.phone}
                placeholder="Not available yet"
              />
            </span>
          </div>

          <div className="row four">
            <span className="form-carrier">
              <h4>Address</h4>
              <textarea
                type="text"
                className="profile-input"
                disabled
                value={profileData?.address}
                placeholder="Not available yet"
              />
            </span>
          </div>

          <div className="row five">
            <span className="form-carrier">
              <h4>Email</h4>
              <input
                type="text"
                className="profile-input"
                disabled
                value={profileData?.email}
                placeholder="Not available yet"
              />
            </span>
          </div>

          <div className="row five">
            <span className="form-carrier">
              <h4>Revenue</h4>
              <input
                type="text"
                className="profile-input"
                disabled
                value={profileData?.revenue}
                placeholder="Not available yet"
              />
            </span>
          </div>

          <div className="row five">
            <span className="form-carrier">
              <h4>Number of Staff</h4>
              <input
                type="text"
                className="profile-input"
                disabled
                value={profileData?.staffNo}
                placeholder="Not available yet"
              />
            </span>
          </div>
        </div>

        <div className="statement-folder">
          <span className="statement-carrier">
            <p className="seller-profile-statement">
              You are not allowed to edit any of these details from this page,
              if you would like to edit any of these information please contact
              us
              <Link to="" className=" statement-link">
                here
              </Link>
              .
            </p>
          </span>
        </div>
        <div />

        <div className="window-hide">
          <button
            onClick={controlModal}
            className="mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>

          <MobileDepositWithdrawModal
            controlModal={controlModal}
            modal={modal}
          />
        </div>
      </div>
    </>
  );
};

export default Profile;
