import { useEffect } from "react";

/**
 * useOutsideClick calls a function when a user clicks or hits the 'enter' key outside of
 * the given element.
 *
 * @param ref React ref to an HTMLDivElement
 * @param onOutsideClick function to call when user clicks outside of ref'd element.
 * @param noKeyboard by default if a user hits the 'enter' key outside the given element ref,
 * the onOutsideClick is called. To disable that set this to true.
 */
export function useOutsideClick(ref, onOutsideClick, noKeyboard) {
  function handleOutsideClick(evt) {
    if (ref.current && !evt.composedPath().includes(ref.current)) {
      try {
        onOutsideClick();
      } catch (err) {
        console.error(
          "Error calling onOutsideClick in useOutsideClick hook.",
          err
        );
      }
    }
  }

  function handleOutsideEnterKeyPress(evt) {
    if (noKeyboard) return;

    if (
      evt.key === "Enter" &&
      ref.current &&
      !evt.composedPath().includes(ref.current)
    ) {
      try {
        onOutsideClick();
      } catch (err) {
        console.error(
          "Error calling onOutsideClick in useOutsideClick hook.",
          err
        );
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    document.addEventListener("keypress", handleOutsideEnterKeyPress, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
      document.removeEventListener(
        "keypress",
        handleOutsideEnterKeyPress,
        false
      );
    };
  }, []);
}
