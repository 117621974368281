import React, { useState } from "react";
import "../../../styles/vendor/vendor-invoice/vendor-pay-now-modal-card.scss";
import VendorFiatCard from "../VendorFiatCard";
import VendorCryptoDepositModal from "../VendorCryptoDepositModal";
import {
  QuidrooWalletIcon,
  CloseIcon,
  FiatDepositIcon,
  CryptoTransactionIcon,
  ReceiptUploadIcon,
} from "../../icons";
import { VendorWalletDeductApprovalModal } from "./VendorWalletDeductApprovalModal";
import { VendorReceiptUploader } from "./VendorReceiptUploaderModal";

const VendorPayNowModalCard = ({
  modal,
  controlModal,
  price,
  url,
  id,
  loadData,
  invoiceName,
}) => {
  const [appear, setAppear] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [receiptUploader, setReceiptUploader] = useState(false);

  const controlAppear = () => {
    setAppear(!appear);
  };

  const toggleWalletDeduction = () => {
    setWallet(!wallet);
  };

  const toggleReceiptUploader = () => {
    setReceiptUploader(!receiptUploader);
  };

  const closeUnderModal = () => {
    controlModal();
  };

  return (
    <>
      {modal && (
        <div className="vendor-pay-now-modal">
          <div
            onClick={() => closeUnderModal()}
            className="vendor-pay-now-overlay"
          >
            <div
              className="vendor-pay-now-modal-container"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="vendor-pay-now-head">
                <h3>Pay Now</h3>

                <span onClick={() => closeUnderModal()}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>

              <p className="vendor-pay-now-heading">
                If you Would you like to make a payment of{" "}
                <span className="effect-color"> {price} </span>
                for <span className="effect-color">“{invoiceName}”</span>,
                Please select a payment method.
              </p>
              <div className=" flexibility-case">
                {/* <VendorFiatCard
                icon={<FiatDepositIcon svgColor="#8A44B2" />}
                title1="Fiat"
                statement="I want to deposit fiat money 
                            (such as EUR, AUD,etc.) from
                            my bank account to Quidroo"
              /> */}

                <VendorFiatCard
                  icon={<ReceiptUploadIcon svgColor="#8A44B2" />}
                  title1="Upload Payment"
                  statement="I want to upload a receipt/
                            Invoice of evidence of payment"
                  onClick={toggleReceiptUploader}
                />

                {/* <VendorFiatCard
                icon={<CryptoTransactionIcon svgColor="#8A44B2" />}
                title1="Crypto (USDC)"
                statement="I want to deposit USDC from my 
                        crypto account to Quidroo"
                onClick={controlAppear}
              /> */}

                <VendorFiatCard
                  icon={<QuidrooWalletIcon svgColor="#8A44B2" />}
                  title1="Quidroo Wallet"
                  statement="I want to pay for this Invoice from my Quidroo Wallet balance"
                  onClick={toggleWalletDeduction}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <VendorCryptoDepositModal
        controlModal={controlAppear}
        modal={appear}
        closeAll={closeUnderModal}
      />

      <VendorWalletDeductApprovalModal
        toggleChange={toggleWalletDeduction}
        modal={wallet}
        price={price}
        url={url}
        id={id}
        loadData={loadData}
        closeUnder={closeUnderModal}
      />

      <VendorReceiptUploader
        toggleChange={toggleReceiptUploader}
        closeAll={closeUnderModal}
        modal={receiptUploader}
        price={price}
        url={url}
        id={id}
        invoiceName={invoiceName}
        loadData={loadData}
      />
    </>
  );
};

export default VendorPayNowModalCard;
