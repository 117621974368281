import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FadeTransition } from "../../FadeTransition";
import { AdminUserDistinctIcon, CloseIcon } from "../../icons";
import "../../../styles/admin/admin-financial/admin-transaction-modal.scss";
import picHolder from "../../../assest/blank-profile-picture.png";

export const AdminTransactionModal = (props) => {
  const {
    onClose,
    modal,
    invoiceName,
    userType,
    trans_reference_No,
    imageTitle,
    trans_description,
    image,
    trans_Amount,
    date,
  } = props;

  const bgColorFill = (userType) => {
    switch (userType) {
      case "seller":
        return "#565CD6";
      case "vendor":
        return "#8A44B2";
      case "investor":
        return "#3FBB97";
      default:
        return "#414141";
    }
  };
  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="admin-detail-seller-info-modal">
              <div
                onClick={onClose}
                className="admin-detail-seller-info-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="admin-detail-seller-info-modal-container"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <>
                    <div className="admin-modal-seller-invoice-head">
                      <h3>Seller Detail</h3>
                      <span onClick={onClose}>
                        <CloseIcon color="#D13D2A" />
                      </span>
                    </div>

                    <div className="admin-seller-main-container">
                      <div className="admin-seller-main-container-head">
                        <img src={image ?? picHolder} alt={imageTitle} />
                        <span className="admin-multi-user-header-case">
                          <h4 className="admin-multi-user-header">
                            <p
                              style={{ color: bgColorFill(userType) }}
                              className="admin-multi-user-header-text"
                            >
                              {invoiceName}
                            </p>
                            <AdminUserDistinctIcon
                              svgColor={bgColorFill(userType)}
                            />
                          </h4>
                        </span>
                      </div>
                      <div className="admin-multi-user-holder-case">
                        <section>
                          <span className="double-label-holder">
                            <h6 className="admin-small-head ">
                              Transaction ID
                            </h6>

                            <h2 className=" trans_id">{trans_reference_No}</h2>
                          </span>
                          <span className="double-label-holder">
                            <h6 className="admin-small-head">
                              Transaction Date
                            </h6>

                            <h2 className="admin-name">{date}</h2>
                          </span>
                          {/* <span className='double-label-holder'>
                                <h6 className='admin-small-head'>
                                    Description
                                </h6>

                                <h2 className='admin-name'>
                                    {trans_description}
                                </h2>
                            </span> */}
                        </section>

                        <section>
                          <span className="double-label-holder">
                            <h6 className="admin-small-head">Amount</h6>

                            <h2 className="admin-name">{trans_Amount}</h2>
                          </span>
                          {/* <span className="double-label-holder">
                            <h6 className="admin-small-head">
                              Transaction Date
                            </h6>

                            <h2 className="admin-name">{date}</h2>
                          </span> */}
                        </section>
                      </div>
                    </div>
                  </>
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
    </>
  );
};
