import React from "react";
import "../../../styles/investor/stats-styles/stat-card.scss";
import { TotalBidCardIcon } from "../../icons/index.jsx";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const TotalBidsCard = ({ bids, percent }) => {
  return (
    <div className="stat-card-container" style={{ background: "#593FBB" }}>
      <div className="stat-svg-container">
        <div className="first-part">
          <span className="top-section">
            <TotalBidCardIcon />
          </span>
          <span className="bottom-section">
            <h3 className="header-text">Total bids</h3>
            <h3 className="digit">{bids}</h3>
          </span>
        </div>
        <div className="second-part">
          <span className="progress-bar-holder">
            <CircularProgressbarWithChildren
              value={percent}
              minValue={0}
              maxValue={100}
              styles={{
                root: {
                  width: "100%",
                },
                path: {
                  stroke: `#593FBB`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  transform: "rotate(1turn)",
                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#F3F3F3",
                  strokeLinecap: "butt",
                  transform: "rotate(1turn)",
                  transformOrigin: "center center",
                },
              }}
            >
              <div className="text-case">
                <h5 className="progress-bar-header">Success</h5>
                <p className="percent-digit">{percent}%</p>
              </div>
            </CircularProgressbarWithChildren>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalBidsCard;
