import { React, useState, useEffect } from "react";
import {
  BalanceCard,
  MobileUpLoaderWithdrawModal,
  OpenBidCard,
  SmallOpenCard,
  TransactionCard,
} from "../../components/seller-component/dashboard-component/index";
import { PlaceHolderHeader } from "../../components/general-component/index";
import { ActivationStatusBar } from "../../components/seller-component/ActivationStatusBar";
import "../../styles/seller/dashboard/dash-board.scss";
import "../../styles/index.scss";
import {
  PasswordIcon,
  HidePasswordIcon,
  PlusIcon,
  NoBidIcon,
  NoTransactionIcon,
  NoInvoiceIcon,
} from "../../components/icons/index";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import moment from "moment";
import { UploadInvoiceModal } from "../../components/seller-component/dashboard-component/UploadInvoiceModal";
import { ActivationModalForm } from "../../components/seller-component/dashboard-component/ActivationModalForm";
import { formatAmount } from "../../util/formatAmount";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashBoard = () => {
  const [amount, setAmount] = useState(false);
  // mobile withdrawal and uploader invoice modal control
  const [modal, setModal] = useState(false);

  const [dashboardData, setDashboardData] = useState();
  const [expire, setExpire] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const controlModal = () => {
    setModal(!modal);
  };

  // invoice uploader control
  const invoiceUploader = () => {
    console.log("where is invoice");
  };

  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };

  // balance security
  const handleAmountVisibility = () => {
    if (amount === false) {
      setAmount(true);
    } else {
      setAmount(false);
    }
  };
  //  chart data Array

  const chartData = dashboardData && [
    parseInt(dashboardData.invoice_details.pending),
    parseInt(dashboardData.invoice_details.approved),
    parseInt(dashboardData.invoice_details.sold),
    parseInt(dashboardData.invoice_details.closed),
    parseInt(dashboardData.invoice_details.rejected),
  ];
  const dataChart = {
    labels: ["Pending", "Approved", "Sold", "Closed", "Rejected"],
    datasets: [
      {
        label: "My First Dataset",
        data: chartData,
        backgroundColor: [
          "#565CD6",
          "#25BECE",
          "#62B758",
          "#B240B2",
          "#BF3030",
        ],
        cutout: "90%",
        circumference: 180,
        rotation: 270,
        hoverOffset: 5,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const total =
    dashboardData &&
    chartData[0] + chartData[1] + chartData[2] + chartData[3] + chartData[4];

  const loadData = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/seller_dashboard/${token}`,
        config
      );
      setIsLoading(false);

      console.log(data);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setDashboardData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  const activated = dashboardData?.user_details.verified;
  return (
    <>
      <ActivationStatusBar
        pending={dashboardData?.user_details?.providedVerificationDetails}
        activated={activated}
        invoicePush={invoiceUploader}
        loadData={loadData}
        title={`${dashboardData?.user_details.name} Dashboard`}
        leader="/seller-dashboard"
      />

      <div className="dashboard-app-holder">
        <div className="dashboard-first-half">
          <div className="dashboard-chart">
            {total ? (
              <div className="chart">
                <h1 className="invoice-header chart">Invoice Details</h1>
                <Doughnut
                  data={dataChart}
                  options={options}
                  className="doughnut"
                />
                <div className="chart-text">
                  <h4 className="invoice-total">{total}</h4>
                  <h5 className="invoice-label">Total Invoices</h5>
                </div>

                <ul className="invoice-color-panel">
                  <li className="color-status">
                    <span style={{ background: "#565CD6" }}></span>
                    pending
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#25BECE" }}></span>
                    Approved
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#62B758" }}></span>
                    Sold
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#B240B2" }}></span>
                    Closed
                  </li>
                  <li className="color-status">
                    <span style={{ background: "#BF3030" }}></span>
                    Rejected
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <NoInvoiceIcon />
                <p className="no-data-text">
                  You do not have any history for your Invoice details
                </p>
              </>
            )}
          </div>

          <div className="open-bid">
            {dashboardData && dashboardData.bidded_invoices.length ? (
              <>
                <PlaceHolderHeader text="Open Invoices" width="100%" />
                <div className="bid-table mobile-hide">
                  <table className="main-table">
                    <tbody>
                      <tr className="bid-header">
                        <th className="bid-name">Invoice Name</th>
                        {/* <th className="bid-topbid">Top Bid</th> */}
                        <th className="bid-amount">Amount</th>
                        <th>Due Date</th>
                        <th></th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-scroller">
                  <div className="table-data-holder mobile-hide">
                    {dashboardData &&
                      dashboardData.bidded_invoices.map((bid) => (
                        <OpenBidCard
                          key={bid["invoice_id "]}
                          id={bid["invoice_id "]}
                          topBid="7.8"
                          amount={formatAmount(bid.receivable_amount)}
                          dueDate={bid.due_date}
                          invoiceName={bid["invoice_name "]}
                        />
                      ))}
                  </div>
                  <div className="table-data-holder window-hide">
                    {dashboardData &&
                      dashboardData.bidded_invoices.map((bid) => (
                        <SmallOpenCard
                          key={bid["invoice_id "]}
                          id={bid["invoice_id "]}
                          topBid="7.8"
                          amount={formatAmount(bid.receivable_amount)}
                          dueDate={bid.due_date}
                          invoiceName={bid["invoice_name "]}
                          activated={activated ? true : false}
                        />
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <div className="no-data-container">
                <NoBidIcon />
                <p className="no-data-text">
                  You currently do not have any active or open Bids
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="dashboard-second-half">
          <div className="dashboard-balance-card">
            <BalanceCard
              onClick={handleAmountVisibility}
              icon={amount ? <HidePasswordIcon /> : <PasswordIcon />}
              money={
                amount
                  ? dashboardData &&
                    formatAmount(dashboardData.user_details.balance)
                  : "*****"
              }
              score={
                dashboardData &&
                parseInt(dashboardData.user_details.credit_score)
              }
              cashWithdrawal={fundWithdrawal}
              userDetails={dashboardData?.user_details}
              loadData={loadData}
            />
          </div>
          <div className="dashboard-transaction mobile-hide">
            <div className="dashboard-transaction-header mobile-hide">
              <PlaceHolderHeader text="Recent Transactions" width="100%" />
            </div>
            <div className="dashboard-transaction-card mobile-hide">
              {dashboardData && dashboardData.transaction?.length ? (
                dashboardData?.transaction.map((item) => (
                  <TransactionCard
                    key={item?.tx_id}
                    id={item?.tx_id}
                    currency="NGN"
                    title={item?.transaction_note}
                    amount={formatAmount(item?.amount)}
                    date={moment(item?.created_at).format("MMM Do, YYYY")}
                    time={moment(item?.created_at).format("hh:mm a")}
                    content={item?.tx_hash}
                    isDebit={item?.transaction_type === "Debit"}
                  />
                ))
              ) : (
                <div className="no-data-container">
                  <NoTransactionIcon />
                  <p className="no-data-text">No recent transactions</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="window-hide">
        {activated === true && (
          <button
            onClick={controlModal}
            className="mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>
        )}

        {activated === false && (
          <button
            style={{ backgroundColor: "rgba(86, 92, 214, 0.4)" }}
            className="mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>
        )}
        <MobileUpLoaderWithdrawModal
          controlModal={controlModal}
          modal={modal}
          fundWithdrawal={fundWithdrawal}
          invoiceUploader={invoiceUploader}
        />
      </div>
    </>
  );
};

export default DashBoard;
