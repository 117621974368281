export const LogoIcon = () => (
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.188 36.1016L17.375 33.3311V39.9999L22.188 37.2294C22.2871 37.1721 22.3694 37.0898 22.4266 36.9908C22.4837 36.8919 22.5138 36.7797 22.5138 36.6655C22.5138 36.5513 22.4837 36.4391 22.4266 36.3401C22.3694 36.2411 22.2871 36.1589 22.188 36.1016Z"
      fill="#31319B"
    />
    <path
      d="M11.585 36.6655L17.3756 39.9999V33.3311L11.585 36.6655Z"
      fill="#3C3CAA"
    />
    <path
      d="M5.79102 33.331L11.585 36.6654V29.9966L5.79102 33.331Z"
      fill="#4747C9"
    />
    <path d="M0 29.9967L5.79066 33.3311V26.6655L0 29.9967Z" fill="#565CD6" />
    <path d="M0 23.331L5.79066 26.6654V19.9966L0 23.331Z" fill="#4473B7" />
    <path d="M0 16.6655L5.79066 19.9999V13.3311L0 16.6655Z" fill="#209AB7" />
    <path d="M0 9.99995L5.79066 13.3311V6.66553L0 9.99995Z" fill="#4AD1A0" />
    <path
      d="M5.79102 6.66548L11.585 9.9999V3.33105L5.79102 6.66548Z"
      fill="#3EAF6E"
    />
    <path
      d="M11.5811 3.33442L17.375 6.66558V0L11.5811 3.33442Z"
      fill="#ABCC46"
    />
    <path
      d="M17.3721 6.66563L23.1627 10.0001V3.33447L17.3721 6.66563Z"
      fill="#E5AA33"
    />
    <path
      d="M23.1592 9.9936L28.9498 13.328V6.65918L23.1592 9.9936Z"
      fill="#D65F2B"
    />
    <path
      d="M28.9502 13.3281L34.7409 16.6592V9.99365L28.9502 13.3281Z"
      fill="#BF3030"
    />
    <path
      d="M28.9502 19.9868L34.7409 23.3212V16.6523L28.9502 19.9868Z"
      fill="#AA3F91"
    />
    <path
      d="M17.3721 20.0034L23.166 23.3345V16.6689L17.3721 20.0034Z"
      fill="#4542B7"
    />
    <path
      d="M28.9502 26.6459L34.7409 29.9771V23.3115L28.9502 26.6459Z"
      fill="#AB46D1"
    />
    <path
      d="M24.144 30.5443L28.9505 33.3116V26.646L24.144 29.41C24.0434 29.4669 23.9597 29.5493 23.9014 29.649C23.8432 29.7486 23.8125 29.8618 23.8125 29.9772C23.8125 30.0925 23.8432 30.2057 23.9014 30.3054C23.9597 30.405 24.0434 30.4874 24.144 30.5443Z"
      fill="#6D4499"
    />
    <path
      d="M17.3756 33.331L11.585 29.9966V36.6654L17.3756 33.331Z"
      fill="#4542B7"
    />
    <path
      d="M11.585 29.9967L5.79102 26.6655V33.3311L11.585 29.9967Z"
      fill="#5151E0"
    />
    <path
      d="M5.79066 26.6655L0 23.3311V29.9966L5.79066 26.6655Z"
      fill="#596ED8"
    />
    <path
      d="M5.79066 19.9999L0 16.6655V23.3311L5.79066 19.9999Z"
      fill="#2380AF"
    />
    <path
      d="M17.3717 19.9967L11.5811 16.6655V23.3311L17.3717 19.9967Z"
      fill="#4747C9"
    />
    <path
      d="M17.3756 20.0001V13.3345L11.585 16.6656L17.3756 20.0001Z"
      fill="#5151E0"
    />
    <path d="M17.3721 20V26.6656L23.166 23.3344L17.3721 20Z" fill="#3C3CAA" />
    <path
      d="M17.3721 20.0033L23.166 16.6689L17.3721 13.3345V20.0033Z"
      fill="#4747C9"
    />
    <path
      d="M17.3717 19.9966L11.5811 23.331L17.3717 26.6654V19.9966Z"
      fill="#4542B7"
    />
    <path
      d="M11.585 9.99995L5.79102 6.66553V13.3311L11.585 9.99995Z"
      fill="#3FBB97"
    />
    <path
      d="M17.375 6.66563L11.5811 3.33447V10.0001L17.375 6.66563Z"
      fill="#62B758"
    />
    <path
      d="M23.166 3.33442L17.3721 0V6.66558L23.166 3.33442Z"
      fill="#EACE49"
    />
    <path
      d="M28.9498 6.65929L23.1592 3.32812V9.99371L28.9498 6.65929Z"
      fill="#E07E24"
    />
    <path
      d="M34.7409 9.9936L28.9502 6.65918V13.328L34.7409 9.9936Z"
      fill="#D13D2A"
    />
    <path
      d="M34.7409 16.6591L28.9502 13.3247V19.9935L34.7409 16.6591Z"
      fill="#AF3869"
    />
    <path
      d="M34.7409 23.3115L28.9502 19.9771V26.6459L34.7409 23.3115Z"
      fill="#B240B2"
    />
    <path
      d="M34.7409 29.9772L28.9502 26.646V33.3116L34.7409 29.9772Z"
      fill="#8A44B2"
    />
    <path d="M5.79066 13.3312L0 10V16.6656L5.79066 13.3312Z" fill="#25BECE" />
  </svg>
);
