import { validateAmount } from "./validateAmount";
import { validateDate } from "./validateDate";
import { validateText } from "./validateText";

/**
 * validateInvoiceInfo checks if all fields in upload invoice form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateInvoiceInfo({ name, amount, dueDate, invoice }) {
  const n = validateText(name);
  const a = validateAmount(amount);
  const d = validateDate(dueDate);

  const v = {
    isValid: true,
  };

  if (!n.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      name: "Invoice Name is required",
    };
  }

  if (!a.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      amount: a.error,
    };
  }

  if (!d.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      dueDate: d.error,
    };
  }

  const date = new Date(dueDate);
  const difference = date.getTime() - Date.now();

  if (difference < 604800000) {
    v.isValid = false;
    v.error = {
      ...v.error,
      dueDate: "Due date must be within 7 days and above",
    };
  }

  if (invoice === "" || !invoice) {
    v.isValid = false;
    v.error = {
      invoice: "Please, upload an invoice",
    };
  }

  return v;
}
