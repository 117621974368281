import React, { useState } from "react";
import "../../../styles/vendor/vendor-index/vendor-balance-card.scss";
import NairaFiatDepositModal from "../../investor-component/dashboard/NairaFiatDepositModal";
import VendorDepositModalCard from "../VendorDepositModalCard";
import VendorFiatBankWithdrawalModal from "../VendorFiatBankWithdrawalModal";
import VendorWithdrawModalCard from "../VendorWithdrawModalCard";

export const VendorBalanceCard = (props) => {
  const { onClick, icon, money, score, loadData, email, balance } = props;

  const [modal, setModal] = useState(false);
  const [appear, setAppear] = useState(false);

  const controlAppear = () => {
    setAppear(!appear);
  };
  const controlModal = () => {
    setModal(!modal);
  };

  return (
    <div className="vendor-balance-container">
      <div className="vendor-balance-upper">
        <div className="vendor-upper-first">
          <div className="vendor-upper-first-left">
            <h5 className="vendor-balance-name">Quidroo Balance</h5>

            <h4 className="vendor-balance-figure">
              <span>₦{money}</span>
            </h4>
          </div>
          <button onClick={onClick} className="vendor-upper-first-right">
            {icon}
          </button>
        </div>
        <div className="vendor-upper-second mobile-hide">
          <button onClick={controlAppear} className="vendor-balance-withdrawal">
            Withdraw
          </button>
          <button onClick={controlModal} className="vendor-balance-deposit">
            Deposit
          </button>
        </div>
      </div>
      <div className="vendor-balance-lower">
        <h5 className="vendor-balance-credit-status">
          Credit Score:
          <span className="vendor-balance-score">{score}%</span>
        </h5>
      </div>

      <NairaFiatDepositModal
        modal={modal}
        email={email}
        controlModal={controlModal}
        loadData={loadData}
      />

      <VendorFiatBankWithdrawalModal
        controlModal={controlAppear}
        modal={appear}
        loadData={loadData}
        balance={balance}
      />

      {/* <VendorDepositModalCard
        controlModal={controlModal}
        modal={modal}
        loadData={loadData}
        email={email}
      />

      <VendorWithdrawModalCard
        loadData={loadData}
        controlModal={controlAppear}
        modal={appear}
        balance={balance}
      /> */}
    </div>
  );
};
