import React, { useState } from "react";
import NavigateCard from "./InvestorNavigateCard";
import {
  InvoiceIcon,
  ReportIcon,
  DashBoardIcon,
} from "../../icons/navigator/Index";
import LogOutCard from "./InvestorLogOutCard";

import "../../../styles/Navigator.scss";
import { LogOutIcon } from "../../icons/index";
import { useLocation } from "react-router-dom";
import { BidsIcon } from "../../icons/navigator/BIdsIcon";
import { useNavigate } from "react-router";

const NavigatorBottom = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate("/");
    localStorage.removeItem("token");
  };

  const tabs = [
    {
      route: "/investor/dashboard",
      icon: (
        <DashBoardIcon
          svgColor={
            location.pathname === "/investor/dashboard" ? "#3FBB97" : "#000"
          }
        />
      ),
      label: "Dashboard",
    },
    {
      route: "/investor/dashboard/bids",
      icon: (
        <BidsIcon
          svgColor={
            location.pathname === "/investor/dashboard/bids"
              ? "#3FBB97"
              : "#000"
          }
        />
      ),
      label: "Bids",
    },
    {
      route: "/investor/dashboard/invoice",
      icon: (
        <InvoiceIcon
          svgColor={
            location.pathname === "/investor/dashboard/invoice"
              ? "#3FBB97"
              : "#000"
          }
        />
      ),
      label: "Invoices",
    },
    {
      route: "/investor/dashboard/stats",
      icon: (
        <ReportIcon
          svgColor={
            location.pathname === "/investor/dashboard/stats"
              ? "#3FBB97"
              : "#000"
          }
        />
      ),
      label: "Stats",
    },
  ];

  return (
    <div className="side-bar">
      <div className="window-nav">
        {tabs.map((tab, index) => (
          <NavigateCard
            isActive={tab.route === location.pathname}
            key={index}
            icon={tab.icon}
            label={tab.label}
            to={tab.route}
            svgColor={tab.svgColor}
          />
        ))}
      </div>

      <div className="log-out-bar">
        <LogOutCard
          icon={<LogOutIcon svgColor="#000" />}
          onClick={handleLogOut}
          color="#000"
        />
      </div>
    </div>
  );
};

export default NavigatorBottom;
