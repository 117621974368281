export const USAFlag =()=>{
    return(
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H19.5732V11.9965H0V0Z" fill="white"/>
    <path d="M0 0H19.5732V1.57848H0V0Z" fill="#D22F27"/>
    <path d="M0 2.84082H19.5732V4.10361H0V2.84082Z" fill="#D22F27"/>
    <path d="M0 5.36523H19.5732V6.62802H0V5.36523Z" fill="#D22F27"/>
    <path d="M0 0H10.1023V6.62962H0V0Z" fill="#1E50A0"/>
    <path d="M0 7.89453H19.5732V9.15732H0V7.89453Z" fill="#D22F27"/>
    <path d="M1.42259 2.1313C1.72771 2.1313 1.97505 1.88396 1.97505 1.57884C1.97505 1.27372 1.72771 1.02637 1.42259 1.02637C1.11747 1.02637 0.870117 1.27372 0.870117 1.57884C0.870117 1.88396 1.11747 2.1313 1.42259 2.1313Z" fill="white"/>
    <path d="M3.94602 2.1313C4.25114 2.1313 4.49849 1.88396 4.49849 1.57884C4.49849 1.27372 4.25114 1.02637 3.94602 1.02637C3.6409 1.02637 3.39355 1.27372 3.39355 1.57884C3.39355 1.88396 3.6409 2.1313 3.94602 2.1313Z" fill="white"/>
    <path d="M6.46946 2.1313C6.77458 2.1313 7.02193 1.88396 7.02193 1.57884C7.02193 1.27372 6.77458 1.02637 6.46946 1.02637C6.16434 1.02637 5.91699 1.27372 5.91699 1.57884C5.91699 1.88396 6.16434 2.1313 6.46946 2.1313Z" fill="white"/>
    <path d="M8.99387 2.1313C9.299 2.1313 9.54634 1.88396 9.54634 1.57884C9.54634 1.27372 9.299 1.02637 8.99387 1.02637C8.68875 1.02637 8.44141 1.27372 8.44141 1.57884C8.44141 1.88396 8.68875 2.1313 8.99387 2.1313Z" fill="white"/>
    <path d="M7.73802 3.39498C8.04314 3.39498 8.29048 3.14763 8.29048 2.84251C8.29048 2.53739 8.04314 2.29004 7.73802 2.29004C7.4329 2.29004 7.18555 2.53739 7.18555 2.84251C7.18555 3.14763 7.4329 3.39498 7.73802 3.39498Z" fill="white"/>
    <path d="M5.21262 3.39498C5.51775 3.39498 5.76509 3.14763 5.76509 2.84251C5.76509 2.53739 5.51775 2.29004 5.21262 2.29004C4.9075 2.29004 4.66016 2.53739 4.66016 2.84251C4.66016 3.14763 4.9075 3.39498 5.21262 3.39498Z" fill="white"/>
    <path d="M2.68235 3.39498C2.98747 3.39498 3.23482 3.14763 3.23482 2.84251C3.23482 2.53739 2.98747 2.29004 2.68235 2.29004C2.37723 2.29004 2.12988 2.53739 2.12988 2.84251C2.12988 3.14763 2.37723 3.39498 2.68235 3.39498Z" fill="white"/>
    <path d="M1.42259 4.6567C1.72771 4.6567 1.97505 4.40935 1.97505 4.10423C1.97505 3.79911 1.72771 3.55176 1.42259 3.55176C1.11747 3.55176 0.870117 3.79911 0.870117 4.10423C0.870117 4.40935 1.11747 4.6567 1.42259 4.6567Z" fill="white"/>
    <path d="M3.94602 4.6567C4.25114 4.6567 4.49849 4.40935 4.49849 4.10423C4.49849 3.79911 4.25114 3.55176 3.94602 3.55176C3.6409 3.55176 3.39355 3.79911 3.39355 4.10423C3.39355 4.40935 3.6409 4.6567 3.94602 4.6567Z" fill="white"/>
    <path d="M6.46946 4.6567C6.77458 4.6567 7.02193 4.40935 7.02193 4.10423C7.02193 3.79911 6.77458 3.55176 6.46946 3.55176C6.16434 3.55176 5.91699 3.79911 5.91699 4.10423C5.91699 4.40935 6.16434 4.6567 6.46946 4.6567Z" fill="white"/>
    <path d="M8.99387 4.6567C9.299 4.6567 9.54634 4.40935 9.54634 4.10423C9.54634 3.79911 9.299 3.55176 8.99387 3.55176C8.68875 3.55176 8.44141 3.79911 8.44141 4.10423C8.44141 4.40935 8.68875 4.6567 8.99387 4.6567Z" fill="white"/>
    <path d="M7.73802 5.92427C8.04314 5.92427 8.29048 5.67692 8.29048 5.3718C8.29048 5.06668 8.04314 4.81934 7.73802 4.81934C7.4329 4.81934 7.18555 5.06668 7.18555 5.3718C7.18555 5.67692 7.4329 5.92427 7.73802 5.92427Z" fill="white"/>
    <path d="M5.21262 5.92427C5.51775 5.92427 5.76509 5.67692 5.76509 5.3718C5.76509 5.06668 5.51775 4.81934 5.21262 4.81934C4.9075 4.81934 4.66016 5.06668 4.66016 5.3718C4.66016 5.67692 4.9075 5.92427 5.21262 5.92427Z" fill="white"/>
    <path d="M2.68235 5.92427C2.98747 5.92427 3.23482 5.67692 3.23482 5.3718C3.23482 5.06668 2.98747 4.81934 2.68235 4.81934C2.37723 4.81934 2.12988 5.06668 2.12988 5.3718C2.12988 5.67692 2.37723 5.92427 2.68235 5.92427Z" fill="white"/>
    <path d="M0 10.4219H19.5732V12.0004H0V10.4219Z" fill="#D22F27"/>
    <path d="M0.25 0.25H19.3232V11.7465H0.25V0.25Z" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}