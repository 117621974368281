import React, { useEffect, useState } from "react";
import "../../../styles/investor/investor-invoice-styles/investor-invoice-card.scss";
import { convertTime } from "../../../util/convertTime";
import InvestorActiveBidEditorModal from "../dashboard/InvestorActiveBidEditorModal";
import { formatAmount } from "../../../util/formatAmount";

export const SmallMobileInvestorInvoiceCard = (props) => {
  const [modal, setModal] = useState(false);

  const [time, setTime] = useState("");
  const [timeDifference, setTimeDifference] = useState(
    convertTime(time).difference
  );

  const controlModal = () => {
    setModal(!modal);
  };

  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));
  //   const onModalClose = () => {
  //     setModal(false);
  //     setModalData(undefined);
  //   };
  useEffect(() => {
    const { difference } = convertTime(timeRemaining);
    setTimeDifference(difference);
    if (difference <= 0) {
      setTime("Bid closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(timeRemaining).text);
      }, [1000]);

      return () => clearTimeout(timer);
    }
  }, [time]);

  const { creditScore, invoiceName, amount, timeRemaining, vendor, amountUSD, isBidded } = props;

  const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  return (
    <div className="mobile-investor-invoice-container">
      <div className="mobile-first-card-invoice" style={{ width: "75%" }}>
        <h2 className="mobile-invoice-name-invoice">{shortInvoiceName}</h2>
        <h4 className="mobile-invoice-top-invoice">
          Credit Score:
          <span className="big-invoice">{creditScore}%</span>
        </h4>
        <h2 className="mobile-invoice-small-date">{time}</h2>
        <h4 className="mobile-card-your-invoice">
          Vendor:
          <span className="big-invoice">{vendor}</span>
        </h4>
      </div>
      <div className="mobile-second-card-invoice" style={{ width: "25%" }}>
      
      <h2 className="mobile-invoice-amount-invoice">{userCurrency === "naira" ? `₦`:`$` }{amount}  </h2>
        {/* {userCurrency === "naira" ? 
        <h2 className="mobile-invoice-amount-invoice">₦{amount}  </h2>
        :
        <h2 className="mobile-invoice-amount-invoice">${amountUSD}  </h2>
        } */}
        {isBidded ?
            <button
              className="bid-editor-btn inactive">
              Bidded
            </button>
          :
          <button
          onClick={() => (timeDifference > 0 ? controlModal() : null)}
          className={`mobile-invoice-editor-btn ${
            timeDifference <= 0 ? "inactive" : ""
          }`}
        >
          Bid
        </button>
          }
      </div>

      <InvestorActiveBidEditorModal controlModal={controlModal} modal={modal} />
    </div>
  );
};
