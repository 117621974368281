import React, { useState } from "react";
// import NavigateCard from "./InvestorNavigateCard";
import {
  InvoiceIcon,
  DashBoardIcon,
  AdminUserIcon,
  ReportIcon
} from "../../icons/navigator/Index";

import "../../../styles/Navigator.scss";
import { LogOutIcon } from "../../icons/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import AdminLogOutCard from "./AdminLogOutCard";
import AdminNavigateCard from "./AdminNavigateCard";
// import { BidsIcon } from "../../icons/navigator/BIdsIcon";

const AdminNavigatorBottom = () => {
  const [logout, setLogout] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate("/admin");
    localStorage.removeItem("token");
  };

  const tabs = [
    {
      route: "/admin/dashboard",
      icon: (
        <DashBoardIcon
          svgColor={
            location.pathname === "/admin/dashboard" ? " #4473B7" : "#818181"
          }
        />
      ),
      label: "Dashboard",
      onClick: function () {
        setLogout(false);
      },
    },

    {
      route: "/admin/dashboard/invoice",
      icon: (
        <InvoiceIcon
          svgColor={
            location.pathname === "/admin/dashboard/invoice"
              ? " #4473B7"
              : "#818181"
          }
        />
      ),
      label: "Invoice",
      onClick: function () {
        setLogout(false);
      },
    },
    {
      route: "/admin/dashboard/users",
      icon: (
        <AdminUserIcon
          svgColor={location.pathname === "/admin/dashboard/users" ? " #4473B7" : "#818181"}
        />
      ),
      label: "Users",
      onClick: function () {
        setLogout(false);
      },
    },
    {
      route: "/admin/dashboard/financial",
      icon: (
        <ReportIcon
          svgColor={
            location.pathname === "/admin/dashboard/financial"
              ? " #4473B7"
              : "#818181"
          }
        />
      ),
      label: "Financial",
      onClick: function () {
        setLogout(false);
      },
    },
  ];

  return (
    <div className="side-bar ">
      <div className="window-nav-vendor">
        {tabs.map((tab, index) => (
          <AdminNavigateCard
            isActive={tab.route === location.pathname}
            key={index}
            icon={tab.icon}
            to={tab.route}
            label={tab.label}
            onClick={tab.onClick}
            svgColor={tab.svgColor}
          />
        ))}
      </div>

      <div className="log-out-bar">
        <AdminLogOutCard
          icon={<LogOutIcon svgColor={logout ? " #4473B7" : "#818181"} />}
          onClick={handleLogOut}
          color={logout ? " #4473B7" : "#818181"}
          ground={logout ? "#EBF3FE" : null}
        />
      </div>
    </div>
  );
};

export default AdminNavigatorBottom;
