/**
 * validatePostalCode checks to see if string is  valid postal code.
 *
 * @param postalCode
 */

export const validatePostalCode = (postalCode) => {
  if (!postalCode.trim().length) {
    return {
      isValid: false,
      error: "Please, enter a postal code.",
    };
  }

  if (/^\d{6}(-\d{4})?$/.test(postalCode)) {
    return {
      isValid: true,
    };
  }

  return {
    isValid: false,
    error: "Please, enter a valid postal code.",
  };
};
