import React, { useState } from "react";
import "../../../styles/admin/admin-financial/admin-financial-confirmation-card.scss";
import picHolder from "../../../assest/blank-profile-picture.png";
import { AdminFinancialTransactionDummyIcon } from "../../icons";
import { AdminConfirmationModal } from "../admin-invoice/AdminConfirmationModal";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";
import { AdminSellerDetailModal } from "../AdminSellerDetailModal";
import { AdminInvestorDetailModal } from "../AdminInvestorDetailModal";
import { AdminInvoiceDetailNotifyModal } from "../admin-invoice/AdminInvoiceDetailNotifyModal";
import { AnimatePresence } from "framer-motion";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import { formatAmount } from "../../../util/formatAmount";

const AdminFinancialConfirmationCard = (props) => {
  const {
    invoiceName,
    url,
    id,
    loadData,
    // imageTitle,
    // image,
    referenceCode,
    requestAmount,
    description,
    userName,
    userType,
  } = props;

  const [approval, setApproval] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(false);
  const [sellerDetail, setSellerDetail] = useState(false);
  const [investorDetail, setInvestorDetail] = useState(false);
  const [detailNotify, setDetailNotify] = useState(false);

  const [vendorDetailsData, setVendorDetailsData] = useState();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isVendorLoading, setIsVendorLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggleApproval = () => {
    setApproval(!approval);
  };

  const toggleVendorDetail = () => {
    setVendorDetail(!vendorDetail);
  };

  const toggleSellerDetail = () => {
    setSellerDetail(!sellerDetail);
  };

  const toggleInvestorDetail = () => {
    setInvestorDetail(!investorDetail);
  };

  const toggleDetailNotify = () => {
    setDetailNotify(!detailNotify);
  };

  const handleConfirmation = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setApproval(false);
      const body = {
        confirmation_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_upload_confirmation/${token}`,
        body,
        config
      );

      console.log(data);

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      } else {
        setIsLoading(false);
        setError("Something went wrong with confirming the request");
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with confirming the request");
    }
  };

  const onVendorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setVendorDetail(true);

    try {
      setIsVendorLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_vendor_detail/${referenceCode}`,
        config
      );
      console.log(data);
      setIsVendorLoading(false);

      if (data.success) setVendorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onVendorDetailClose = () => {
    setVendorDetail(false);
    setVendorDetailsData(undefined);
  };

  return (
    <>
      <tr className="admin-financial-confirmation-cards-contain">
        <td>
          <span className="admin-financial-confirmation-first-half">
            <a href={url} target="_blank" rel="noreferrer noopener">
              <span className="admin-dummy-icon-holder">
                <AdminFinancialTransactionDummyIcon />
              </span>
            </a>
          </span>
          <span className="admin-financial-confirmation-second-half">
            <h3
              className="admin-invoice-requester-name"
              onClick={toggleDetailNotify}
            >
              {invoiceName}
            </h3>
            <h3 className="referenceText">{referenceCode}</h3>
          </span>
        </td>
        <td>₦{requestAmount}</td>
        {/* <td>{description}</td> */}
        <td>
          {userType === "seller" ? (
            <span
              className="admin-financial-user-name _seller"
              onClick={toggleSellerDetail}
            >
              {userName}
            </span>
          ) : userType === "investor" ? (
            <span
              className="admin-financial-user-name _investor"
              onClick={toggleInvestorDetail}
            >
              {userName}
            </span>
          ) : (
            <span
              className="admin-financial-user-name _vendor"
              onClick={onVendorDetailOpen}
            >
              {userName}
            </span>
          )}
        </td>
        <td>
          <button
            className="admin-invoice-bidder-btn active-admin-bidder-btn"
            onClick={toggleApproval}
          >
            confirm
          </button>
        </td>
      </tr>
      <AdminConfirmationModal
        toggleChange={toggleApproval}
        modal={approval}
        topic="Confirmation"
        statement="Would you like to Approve/Authorize this transaction?"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleConfirmation}
      />

      <AdminVendorDetailModal
        onClose={onVendorDetailClose}
        modal={vendorDetail}
        vendorName={
          vendorDetailsData?.account_name ?? vendorDetailsData?.companyName
        }
        creditScore={vendorDetailsData?.credit_score}
        vendorPhone={vendorDetailsData?.phone}
        emailAddress={vendorDetailsData?.email}
        accountNumber={vendorDetailsData?.account_no}
        bankName={vendorDetailsData?.bank}
        memoNumber={vendorDetailsData?.memo_id}
        vendorBalance={formatAmount(vendorDetailsData?.wallet_balance)}
        accountName={vendorDetailsData?.account_name}
        loading={isVendorLoading}
        verified={vendorDetailsData?.verified}
      />

      <AdminInvestorDetailModal
        onClose={toggleInvestorDetail}
        modal={investorDetail}
        investorName="Shining Star"
        investorAddress="52 Odalume, Oshodi Lagos"
        investorCredit={80}
        phoneNumber="001122332233"
        emailAddress="emailAddress@email.com"
        investorBalance="12,000,000"
        country="U.S.A"
        passportNumber="1234567AA"
        typeBusiness="Sole Proprietorship"
        numberStaff="11 - 50"
        image="https://images.pexels.com/photos/7413949/pexels-photo-7413949.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        accountNumber="001122112233"
        accountName="James John Jonas"
        bankName="bank of America"
        memoNumber="12233454433221"
        passportUrl="https://images.pexels.com/photos/5405597/pexels-photo-5405597.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      />

      <AdminSellerDetailModal
        onClose={toggleSellerDetail}
        modal={sellerDetail}
        sellerAddress="52 Odalume, Oshodi Lagos"
        sellerName="Shining Star"
        typeBusiness="Sole Proprietorship"
        annualRevenue="1,000,000 - 5,000,000"
        phoneNumber="08012345678"
        country="Nigeria"
        emailAddress="quidroomail@gmail.com"
        image="https://images.pexels.com/photos/4349736/pexels-photo-4349736.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        cacNumber="123456789AD"
        ninNumber="123451234512345"
        accountNumber="001122112233"
        accountName="James John Jonas"
        bankName="bank of America"
        bankVerificationNumber="12121212121112133"
        memoNumber="12233454433221"
        creditScore={80}
        numberStaff="6-15"
        sellerBalance="12,000,000"
      />

      <AdminInvoiceDetailNotifyModal
        onClose={toggleDetailNotify}
        modal={detailNotify}
        invoiceAmount={requestAmount}
        invoiceDate="Dec 6th, 2021"
        invoiceName={invoiceName}
        vendorName="Coca cola"
        sellerName="Crown-fit-Hotel"
        topBidder="Cross Roads Enterprises Plc..."
        //  status={status}
        countDown="48 : 11 : 35"
      />

      <AnimatePresence>
        {success && (
          <Toolkit text="You have successfully confirmed the payment request" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};

export default AdminFinancialConfirmationCard;
