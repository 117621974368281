import React from 'react'

export const AdminVerificationIcon = () => {
  return (
    <svg 
        width="15" 
        height="9" 
        viewBox="0 0 15 9" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M14.9998 0.998057C15.0053 0.724074 14.8915 0.491508 14.6586 0.300148C14.4202 0.104517 14.1359 0.00452552 13.8056 0.000146264C13.4643 -0.00422134 13.1746 0.0892266 12.9363 0.280581L4.78789 6.61372L2.12322 4.23309C1.89573 4.03309 1.6141 3.92654 1.27827 3.91352C0.942519 3.89614 0.650032 3.97876 0.40095 4.16139C0.151798 4.33964 0.0190518 4.56358 0.00282702 4.83319C-0.018843 5.1027 0.0840671 5.33974 0.311563 5.54412L3.82113 8.68134C3.96738 8.81173 4.14881 8.90308 4.36542 8.95523C4.57668 9.00746 4.79056 9.01401 5.00726 8.97479C5.22387 8.93139 5.41346 8.84877 5.57594 8.72701L14.6342 1.68294C14.8725 1.49168 14.9944 1.26337 14.9998 0.998146L14.9998 0.998057Z" 
        fill="#4473B7"
    />
    </svg>
  )
}
