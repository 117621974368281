import React, { useEffect, useState } from "react";
import "../../../styles/investor/bids-styles/bid-invoice-card.scss";
import { convertTime } from "../../../util/convertTime";
import { formatAmount } from "../../../util/formatAmount";
import InvestorActiveBidEditorModal from "../dashboard/InvestorActiveBidEditorModal";
// import { MobileInvoiceDetailModal } from "./MobileInvoiceDetailModal";

const BidsInvoiceCard = (props) => {
  const {
    invoiceName,
    amount,
    status,
    yourBid,
    topBid,
    timeRemaining,
    url,
    ROI,
    loadBids,
    id,
    originalAmount,
    maxPayable,
    yourBidUSD,
    top_bidUSD
  } = props;
// console.log(maxPayable)
  const [modal, setModal] = useState(false);
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  const [time, setTime] = useState("");
  const [timeDifference, setTimeDifference] = useState(
    convertTime(time).difference
  );
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const { difference } = convertTime(timeRemaining);
    setTimeDifference(difference);
    if (difference <= 0) {
      setTime("Bid closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(timeRemaining).text);
      }, [1000]);

      return () => clearTimeout(timer);
    }
  }, [time]);

  return (
    <>
      <tr className="bid-invoice-cards">
        <td>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {invoiceName}
          </a>
        </td>
        <td>{userCurrency === "naira" ? `₦`:`$` }{formatAmount(amount)} </td>
        <td
          style={{
            color: topBid > 5 ? "black" : topBid < 4.5 ? "red" : "#3FBB97",
          }}
        >
          {userCurrency === "naira" ? `₦`:`$` }{formatAmount(topBid)} 
        </td>

        <td
          style={{
            color: yourBid > 5 ? "black" : yourBid < 4.5 ? "red" : "#3FBB97",
          }}
        >
          {userCurrency === "naira" ? `₦`:`$` }{formatAmount(yourBid)}
        </td>
        <td>{time}</td>
        <td>
          <button
            className={`bid-editor-btn ${
              timeDifference <= 0 ? "inactive" : ""
            }`}
            onClick={toggleModal}
          >
            Edit Bid
          </button>
        </td>
      </tr>

      <InvestorActiveBidEditorModal
        controlModal={toggleModal}
        invoiceAmount={originalAmount}
        maxPayable={maxPayable}
        url={url}
        modal={modal}
        ROI={ROI} 
        id={id}
        // originalAmount={originalAmount}
        loadInvestor={loadBids}
      />
    </>
  );
};

export default BidsInvoiceCard;
