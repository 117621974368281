import React, { useState } from "react";
import dummy from "../../assest/blank-profile-picture.png"
import "../../styles/admin/admin-top-bar.scss";
import { 
  CloseIcon,
      DownwardArrowIcon, 
      ProfileIcon, 
      ViewPhotoIcon 
    } from "../icons";

import { MainLogo } from "../icons/logo/index";
import { AnimatePresence, motion } from "framer-motion";
import AdminProfile from "../../pages/admin/admin-profile-page";

export const AdminTopBar = ({ show }) => {

const [hide, setHide]=useState(false);
const [open, setOpen]=useState(false);
const [appear, setAppear]=useState(false);
const [image, setImage] = useState("")

const toggleHide =()=>{
    setHide(!hide)
};

const toggleOpen =()=>{
  setOpen(!open)
};

const toggleAppear =()=>{
  setAppear(!appear)
};

  return (
    <div className="admin-top-container-case " >
      <div className="admin-top-logo-aside">{show === true && <MainLogo />}</div>
      <div className="admin-top-image-logo-profile" onClick={toggleHide} >
         {
            image? 
          (
            <img src={image} alt="profile-id" className="admin-top-profile-id"/>
            )
          :
          (
            <img src={dummy} alt="" className="admin-top-profile-id"/>
            )}

        <span className="down-presser" >
          <DownwardArrowIcon/>
        </span>

    <AnimatePresence>
        {hide && (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="admin-top-profile-button-container"
          >
            <span className="admin-top-btn-holder" onClick={toggleAppear}>
              <ViewPhotoIcon />
              <h4 className="admin-top-description-name">View Photo</h4>
            </span>
            <span className="admin-top-btn-holder" onClick={toggleOpen}>
              <ProfileIcon />
              <h4 className="admin-top-description-name">Profile</h4>
            </span>
          </motion.section>
        )}
      </AnimatePresence>


      <AnimatePresence>
        {appear && (
          <motion.div
            className="admin-top-view-photo-container"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "just" }}
          >
                <div className='profile-close'>
                  <span onClick={toggleAppear}> 
                   <CloseIcon
                    color="#D13D2A"
                  />
                 </span>
                </div>
             <span className="admin-top- photo-case-container">
          {image?
           (
            <img 
                src={image} 
                alt="profile-id" 
                style={{width:"100%", height: "auto"}}
            />
            )
            :
            (
            <img src={dummy} alt="" />
            )}
            </span>
          
          </motion.div>
        )}
      </AnimatePresence>

        <AnimatePresence>
                {open &&  (
                    <AdminProfile
                        toggleOpen={toggleOpen}
                        image={image}
                        setImage={setImage}
                    />
            
                )}
        </AnimatePresence>

      </div>
     

    </div>
  );
};
