import React, { useState } from "react";
import "../../../styles/seller/dashboard/balance-card.scss";
import NairaFiatDepositModal from "../../investor-component/dashboard/NairaFiatDepositModal";
import { AccountWithdrawalModal } from "./AccountWithdrawModal";

export const BalanceCard = (props) => {
  const { onClick, icon, money, score, userDetails, loadData } = props;

  const [modal, setModal] = useState(false);

  const toggleChange = () => {
    setModal(!modal);
  };

  return (
    <div className="balance-container">
      <div className="balance-upper">
        <div className="upper-first">
          <div className="upper-first-left">
            <h5 className="balance-name">Quidroo Balance</h5>

            <h4 className="balance-figure">
              <span>₦{money}</span>
            </h4>
          </div>
          <button onClick={onClick} className="upper-first-right">
            {icon}
          </button>
        </div>
        <div className="upper-second mobile-hide">
          <button onClick={toggleChange} className="balance-withdrawal">
            Withdraw
          </button>
        </div>
      </div>
      <div className="balance-lower">
        <h5 className="balance-credit-status">
          Credit Score:
          <span className="balance-score">{score}%</span>
        </h5>
      </div>
      <AccountWithdrawalModal
        controlModal={toggleChange}
        modal={modal}
        userDetails={userDetails}
        loadData={loadData}
      />
    </div>
  );
};
