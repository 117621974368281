import React from "react"

export const BidsIcon =({svgColor})=>{
    return(
        <svg 
            width="30" 
            height="30" 
            viewBox="0 0 30 30" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M16.2503 6.87525C16.5941 6.87525 16.8753 6.59405 16.8753 6.25033V2.50024C16.8753 2.15651 16.5941 1.87531 16.2503 1.87531H13.669C13.3938 0.799907 12.4126 0 11.2501 0C10.0877 0 9.10642 0.799907 8.83126 1.87504H6.24993C5.9062 1.87504 5.625 2.15625 5.625 2.49997V6.25006C5.625 6.59378 5.9062 6.87498 6.24993 6.87498L16.2503 6.87525Z" 
            fill={svgColor}
        />
        <path 
            d="M17.6756 26.3069L17.2068 27.5508C16.9068 28.3007 16.2256 28.7695 15.4505 28.7695C15.2256 28.7695 15.0129 28.7321 14.8068 28.657L9.28154 26.5758C8.81277 26.3883 8.45651 26.0571 8.25635 25.6257H3.75029C3.40657 25.6257 3.12536 25.3445 3.12536 25.0008C3.12536 24.6571 3.40657 24.3759 3.75029 24.3759H8.1253C8.13786 24.3071 8.15643 24.2383 8.18154 24.1758L8.66286 22.9071C8.80673 22.5071 9.0691 22.1508 9.40029 21.8759L3.75031 21.8757C3.40659 21.8757 3.12539 21.5945 3.12539 21.2508C3.12539 20.907 3.40659 20.6258 3.75031 20.6258H10.5627L11.5066 18.1259L3.74991 18.1256C3.40619 18.1256 3.12498 17.8444 3.12498 17.5007C3.12498 17.157 3.40619 16.8758 3.74991 16.8758H11.4563C11.3065 16.3696 11.325 15.8257 11.5189 15.3133L11.8751 14.3755H3.75017C3.40645 14.3755 3.12524 14.0943 3.12524 13.7506C3.12524 13.4069 3.40645 13.1257 3.75017 13.1257H12.7819C13.0693 12.9507 13.4005 12.8507 13.7505 12.8507C13.9692 12.8507 14.1882 12.8881 14.4006 12.9695L14.8131 13.1254H18.7507C19.0945 13.1254 19.3757 13.4066 19.3757 13.7503C19.3757 14.094 19.0945 14.3753 18.7507 14.3753H18.1381L19.9132 15.0441C20.3946 15.2191 20.782 15.5942 20.9758 16.0567C21.1571 16.463 21.1819 16.9816 21.0132 17.4442L20.5381 18.7066C20.4006 19.0755 20.1819 19.3941 19.9069 19.6567L22.5008 20.5257V4.37493C22.5008 4.0312 22.2196 3.75 21.8759 3.75H18.1258V6.24997C18.1258 7.28111 17.2819 8.12501 16.2507 8.12501H6.25006C5.21892 8.12501 4.37501 7.28111 4.37501 6.24997V3.75H0.624925C0.281203 3.75 0 4.0312 0 4.37493V29.3753C0 29.719 0.281203 30.0002 0.624925 30.0002H21.8752C22.2189 30.0002 22.5001 29.719 22.5001 29.3753V27.1314L17.8251 25.175C17.8628 25.5504 17.8128 25.9378 17.6752 26.3066L17.6756 26.3069Z" 
            fill={svgColor}
        />
        <path 
            d="M28.6071 23.9004L17.5878 20.194L18.0192 19.0626C18.0817 19.0689 18.1379 19.0752 18.2005 19.0752C18.3755 19.0752 18.5505 19.0378 18.7129 18.969C19.0192 18.8251 19.2504 18.5816 19.3692 18.269L19.838 17.0189C19.8942 16.8627 19.8879 16.6877 19.8191 16.5375C19.7566 16.3877 19.6253 16.2689 19.4754 16.2127L13.9628 14.1375C13.6439 14.0188 13.2816 14.1812 13.1629 14.5001L12.6878 15.7565C12.5691 16.0691 12.5816 16.4065 12.719 16.7066C12.8189 16.9316 12.9816 17.1128 13.1814 17.2379L11.1751 22.5504C10.9438 22.5193 10.7063 22.5504 10.4814 22.6503C10.1813 22.7879 9.94383 23.0378 9.83135 23.3503L9.3563 24.6005C9.30006 24.7566 9.30634 24.9253 9.37514 25.0755C9.44393 25.2317 9.56897 25.3441 9.72514 25.4067L15.2315 27.4818C15.3003 27.5069 15.3754 27.5192 15.4502 27.5192C15.7003 27.5192 15.9378 27.363 16.0377 27.113L16.5065 25.8628C16.7189 25.3065 16.5002 24.6878 16.0189 24.3752L16.444 23.2438L27.1692 27.7313C27.4193 27.8375 27.688 27.8875 27.9506 27.8875C28.2255 27.8875 28.507 27.8313 28.7631 27.7188C29.2818 27.4938 29.6695 27.0813 29.8694 26.5625V26.5563C30.0632 26.0375 30.0444 25.4689 29.8006 24.9624C29.5631 24.4568 29.1382 24.0754 28.607 23.9004L28.6071 23.9004Z"
            fill={svgColor}
        />
        </svg>
    )
}