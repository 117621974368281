import React, { useState } from "react";
import "../../../styles/admin/admin-financial/admin-financial-payment-card.scss";
import picHolder from "../../../assest/blank-profile-picture.png";
import { AdminConfirmationModal } from "../admin-invoice/AdminConfirmationModal";
import { AdminInvoiceDetailNotifyModal } from "../admin-invoice/AdminInvoiceDetailNotifyModal";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { AnimatePresence } from "framer-motion";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";
import { formatAmount } from "../../../util/formatAmount";

const AdminFinancialPaymentCard = (props) => {
  const {
    invoiceName,
    url,
    id,
    loadData,
    requestDate,
    imageTitle,
    image,
    referenceCode,
    requestAmount,
    payeeId,
    description,
  } = props;

  const [approval, setApproval] = useState(false);
  const [detailNotify, setDetailNotify] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [vendorDetailsData, setVendorDetailsData] = useState();
  const [isVendorLoading, setIsVendorLoading] = useState(false);

  const toggleApproval = () => {
    setApproval(!approval);
  };

  const toggleDetailNotify = () => {
    setDetailNotify(!detailNotify);
  };

  const handleConfirmation = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setApproval(false);
      const body = {
        request_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_payment_request_confirmation/${token}`,
        body,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with confirming the request");
    }
  };

  const onVendorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setDetailNotify(true);

    try {
      setIsVendorLoading(true);

      const body = {
        user_id: payeeId,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_payment_request_user_details/${token}`,
        body,
        config
      );
      setIsVendorLoading(false);

      if (data.success) setVendorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onVendorDetailClose = () => {
    setDetailNotify(false);
    setVendorDetailsData(undefined);
  };

  return (
    <>
      <tr className="admin-financial-payment-cards-contain">
        <td>
          <span className="admin-financial-payment-first-half">
            <img
              src={image ?? picHolder}
              alt={imageTitle}
              className="imageControl"
            />
          </span>
          <span className="admin-financial-payment-second-half">
            <h3
              onClick={onVendorDetailOpen}
              className="admin-invoice-requester-name"
            >
              {invoiceName}
            </h3>
            <h3 className="referenceText">{referenceCode}</h3>
          </span>
        </td>
        <td>₦{requestAmount}</td>
        {/* <td>{description}</td> */}
        <td>{requestDate}</td>
        <td>
          <button
            className="admin-invoice-bidder-btn active-admin-bidder-btn"
            onClick={toggleApproval}
          >
            Confirm
          </button>
        </td>
      </tr>
      <AdminConfirmationModal
        toggleChange={toggleApproval}
        modal={approval}
        topic="Confirmation"
        statement="Would you like to Approve/Authorize this transaction?"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleConfirmation}
      />

      <AdminVendorDetailModal
        onClose={onVendorDetailClose}
        modal={detailNotify}
        vendorName={
          vendorDetailsData?.account_name ??
          vendorDetailsData?.companyName ??
          vendorDetailsData?.name
        }
        creditScore={vendorDetailsData?.credit_score}
        isUser
        vendorPhone={vendorDetailsData?.phone}
        emailAddress={vendorDetailsData?.email}
        accountNumber={vendorDetailsData?.account_no}
        bankName={vendorDetailsData?.bank}
        memoNumber={vendorDetailsData?.memo_id}
        vendorBalance={formatAmount(vendorDetailsData?.wallet_balance)}
        accountName={vendorDetailsData?.account_name}
        loading={isVendorLoading}
        verified={vendorDetailsData?.verified}
      />

      {/* <AdminInvoiceDetailNotifyModal
        onClose={toggleDetailNotify}
        modal={detailNotify}
        invoiceAmount={requestAmount}
        invoiceDate="Dec 6th, 2021"
        invoiceName={invoiceName}
        vendorName="Coca cola"
        sellerName="Crown-fit-Hotel"
        topBidder="Cross Roads Enterprises Plc..."
        //  status={status}
        countDown="48 : 11 : 35"
      /> */}

      <AnimatePresence>
        {success && (
          <Toolkit text="You have successfully confirmed the payment request" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};

export default AdminFinancialPaymentCard;
