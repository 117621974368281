import React from 'react'

export const AdminExpandDownIcon = () => {


  return (
    <svg 
        width="19" 
        height="19" 
        viewBox="0 0 19 19" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M10.9411 7.70801L16.727 7.70801C16.9568 7.70801 17.169 7.58546 17.2838 7.38657C17.3986 7.18768 17.3986 6.94258 17.2838 6.74369C17.169 6.5448 16.9568 6.42225 16.727 6.42225L12.4932 6.42225L17.8243 1.09078C17.982 0.927474 18.042 0.693148 17.982 0.474165C17.9219 0.25533 17.7509 0.0842872 17.532 0.0241504C17.313 -0.0358319 17.0787 0.0241504 16.9154 0.181858L11.5839 5.51297V1.27916C11.5839 1.04942 11.4614 0.837188 11.2625 0.722389C11.0636 0.607589 10.8185 0.607589 10.6196 0.722389C10.4207 0.837188 10.2982 1.04942 10.2982 1.27916V7.06506C10.2989 7.23539 10.3668 7.39841 10.4872 7.51893C10.6077 7.63932 10.7707 7.7072 10.941 7.70792L10.9411 7.70801Z" 
        fill="#081B33"
    />
    <path 
        d="M1.92912 18.0025H7.71502C8.22631 18.002 8.71666 17.7986 9.07811 17.437C9.43973 17.0755 9.64307 16.5852 9.64365 16.0739V10.288C9.64308 9.77672 9.43974 9.28637 9.07811 8.92492C8.71663 8.5633 8.22627 8.35996 7.71502 8.35938H1.92912C1.41783 8.35995 0.927481 8.56329 0.566037 8.92492C0.204416 9.2864 0.00107606 9.77676 0.000488281 10.288V16.0739C0.00106228 16.5852 0.204402 17.0755 0.566037 17.437C0.927511 17.7986 1.41787 18.002 1.92912 18.0025ZM1.28624 10.288C1.28653 10.1175 1.35426 9.95423 1.4748 9.8337C1.59534 9.71316 1.75865 9.64542 1.92911 9.64514H7.71501C7.88549 9.64543 8.04879 9.71316 8.16933 9.8337C8.28986 9.95423 8.3576 10.1175 8.35789 10.288V16.0739C8.3576 16.2444 8.28986 16.4077 8.16933 16.5282C8.04879 16.6488 7.88548 16.7165 7.71501 16.7168H1.92911C1.75864 16.7165 1.59534 16.6488 1.4748 16.5282C1.35427 16.4077 1.28653 16.2444 1.28624 16.0739V10.288Z" 
        fill="#081B33"
    />
    </svg>

  )
}
