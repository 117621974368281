export const NoBidIcon = () => {
  return (
    <svg width="59" height="70" viewBox="0 0 59 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.7534 70H7.69409V67.4352H39.7534V70ZM12.8236 63.4673C12.8247 62.4798 13.218 61.5323 13.9165 60.8338C14.6149 60.1354 15.5623 59.7421 16.5499 59.741H30.8976C31.8851 59.7421 32.8326 60.1354 33.5311 60.8338C34.2295 61.5323 34.6228 62.4797 34.6239 63.4673V64.8705H12.8236V63.4673Z" fill="#414141"/>
      <path d="M56.0403 48.0516L52.7268 51.3651L51.374 49.9339L54.6875 46.6204L56.0403 48.0516ZM38.8477 28.0569L33.7182 33.1864L31.419 35.486L19.126 23.1929L28.8543 13.4646L41.1473 25.7577L38.8477 28.0569ZM34.6251 0L53.3302 18.7051L50.0137 22.0215L31.3086 3.31644L34.6251 0ZM45.6358 21.2698L42.9605 23.9451L30.6675 11.652L33.3427 8.97676L45.6358 21.2698ZM24.366 47.6691L5.66089 28.9641L8.97733 25.6476L27.6824 44.3527L24.366 47.6691ZM14.6376 27.6817L17.3129 25.0064L29.6059 37.2995L26.9307 39.9747L14.6376 27.6817ZM39.7282 30.8041L52.9252 44.7561L49.6117 48.0714L36.4198 34.1193L39.7282 30.8041ZM58.1709 53.6171C57.7078 54.0454 57.1038 54.2892 56.4729 54.303C55.8346 54.2932 55.2283 54.0224 54.7956 53.553L54.4904 53.2301L57.8039 49.9166L58.2259 50.3643V50.3637C58.6432 50.8057 58.8705 51.3936 58.8602 52.0017C58.8499 52.6096 58.6026 53.189 58.1709 53.6172L58.1709 53.6171Z" fill="#414141"/>
      <path d="M12.8235 46.917H15.3882V52.0465H12.8235V46.917Z" fill="#414141"/>
      <path d="M0 59.741H5.12949V62.3057H0V59.741Z" fill="#414141"/>
      <path d="M3.04688 51.9926L4.86052 50.179L8.70823 54.0267L6.89458 55.8403L3.04688 51.9926Z" fill="#414141"/>
    </svg>

  );
};
