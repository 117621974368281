import { Outlet } from "react-router";
import "../../styles/seller/dashboard/dash-board.scss";
import VendorNavigatorBottom from "../navigator/vendor-navigator/VendorNavigatorBottom";
import { VendorTopBar } from "./VendorTopBar";


export const VendorLayout = () => (
  <div className="main-holder">
    <VendorTopBar show />
    <div className="display-container">
      <div className="side-bar-holder">
        <VendorNavigatorBottom/>
      </div>

      <div className="pages">
        <Outlet />

        <div className="bottom-bar-holder">
          <VendorNavigatorBottom/>
        </div>
      </div>
    </div>
  </div>
);