export const convertDate = (date) => {
  switch (date) {
    case "Today":
      return 0;
    case "Last 7 Days":
      return 1;
    case "Last 30 Days":
      return 30;
    case "Last 3 Months":
      return 90;
    case "Last 6 Months":
      return 180;

    default:
      return null;
  }
};
