import { AnimatePresence, motion } from "framer-motion";
import "../../../styles/investor/investor-dashboard-styles/investor-activation-success-modal.scss";
import { FadeTransition } from "../../FadeTransition";
import { ActivationSuccessIcon } from "../../icons/ActivationSuccessIcon";

export const InvestorActivationSuccessModal = (props) => {
  const { modal, onLoad, title, desc } = props;

  return (
    <AnimatePresence>
      {modal && (
        <FadeTransition>
          <div className="investor-verification-success-modal">
            <div className="investor-verification-success-overlay">
              <motion.div
                className="investor-activation-success-modal"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ type: "spring" }}
              >
                <div className="investor-activation-holder">
                  <ActivationSuccessIcon bgColor="#3FBB97" />

                  <h2 className="investor-success-title">
                    {title ?? "Verification Data Uploaded successfully"}
                  </h2>

                  <span className="investor-success-word">
                    {desc ??
                      "Your Account and Financial detail's have been recieved successfully and your account would be activated within 24 hours."}
                  </span>
                </div>

                <span className="investor-success-close">
                  To load your Dashboard
                  <span onClick={onLoad} className="investor-close-btn">
                    Click here
                  </span>
                </span>
              </motion.div>
            </div>
          </div>
        </FadeTransition>
      )}
    </AnimatePresence>
  );
};
