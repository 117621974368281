import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../config";
import "../../styles/vendor/vendor-fiat-bank-withdrawal-modal.scss";
import { validateAccountDetails } from "../../util/validate/validateAccountDetails";
import { validateAmount } from "../../util/validate/validateAmount";
import { ModalLoader } from "../general-component/ModalLoader";
import { Toolkit } from "../general-component/Toolkit";
import { CloseIcon, LeftArrowIcon } from "../icons";
import { InvestorActivationSuccessModal } from "../investor-component/dashboard/InvestorActivationSuccessModal";

const VendorFiatBankWithdrawalModal = (props) => {
  const { controlModal, modal, loadData, balance } = props;

  const [acctName, setAcctName] = useState("");
  const [acctNo, setAcctNo] = useState("");
  const [bank, setBank] = useState("");
  const [amount, setAmount] = useState("");

  const [acctNameError, setAcctNameError] = useState(null);
  const [acctNoError, setAcctNoError] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [bankError, setBankError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states

    const { isValid: acctValid, error: acctError } = validateAccountDetails({
      accountNo: acctNo.trim(),
      accountName: acctName.trim(),
      bank: bank.trim(),
    });
    if (!acctValid) {
      if (acctError?.accountNo) setAcctNoError(acctError.accountNo);
      if (acctError?.accountName) setAcctNameError(acctError.accountName);
      if (acctError?.bank) setBankError(acctError.bank);
      return;
    }

    const { isValid, error } = validateAmount(amount.trim());

    if (!isValid) {
      setAmountError(error);
    } else {
      const amountNo = parseInt(amount.trim());

      if (amountNo < 3000) {
        setAmountError("You cannot withdraw less than 3000NGN");
        return;
      }

      if (amountNo > balance) {
        setAmountError("You have insufficient balance");
        return;
      }
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);
        const body = {
          amount: amount.trim(),
          acc_no: acctNo.trim(),
          acc_name: acctName.trim(),
          bank: bank.trim(),
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${BACKEND_URL}/withdrawalNG2/${token}`,
          body,
          config
        );
        console.log(data);

        if (!data.success) {
          setIsLoading(false);
          setError(true);
          setTimeout(() => setError(false), 4000);
        }

        if (data.success && data.status === 200) {
          setSuccess(true);
          setAcctName("");
          setAcctNo("");
          setBank("");
          setAmount("");
        }
      } catch (error) {
        setIsLoading(false);
        console.error(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };
  return (
    <>
      {modal && (
        <div className="vendor-bank-withdraw-modal">
          <div onClick={controlModal} className="vendor-bank-withdraw-overlay">
            <div
              className="vendor-bank-withdrawal-case"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="vendor-bank-withdraw-head">
                {/* <span className="left-arrow" onClick={controlModal}>
                  <LeftArrowIcon />
                </span> */}
                <div />
                <h3 className="vendor-bank-withdraw-heading">Bank Account</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>
              {/* <div className="crypto-long-input"> */}

              <form onSubmit={handleSubmit}>
                <span className="vendor-bank-input-case">
                  <label className="vendor-bank-input-title">
                    Account Number
                  </label>
                  <input
                    type="text"
                    className="vendor-bank-edit"
                    placeholder="Enter Account Number"
                    value={acctNo}
                    onChange={(evt) => setAcctNo(evt.target.value)}
                    maxLength={10}
                    onFocus={() => acctNoError && setAcctNoError(null)}
                  />
                  {acctNoError && (
                    <div className="error-text">{acctNoError}</div>
                  )}
                </span>

                {/* </div> */}
                <span className="vendor-bank-input-case">
                  <label className="vendor-bank-input-title">
                    Account Name
                  </label>
                  <input
                    type="text"
                    className="vendor-bank-edit"
                    placeholder="Enter Account Name"
                    value={acctName}
                    onChange={(evt) => setAcctName(evt.target.value)}
                    onFocus={() => acctNameError && setAcctNameError(null)}
                  />
                  {acctNameError && (
                    <div className="error-text">{acctNameError}</div>
                  )}
                </span>
                <span className="vendor-bank-input-case">
                  <label className="vendor-bank-input-title">Bank</label>
                  <input
                    type="text"
                    className="vendor-bank-edit"
                    placeholder="Enter Bank Name"
                    value={bank}
                    onChange={(evt) => setBank(evt.target.value)}
                    onFocus={() => bankError && setBankError(null)}
                  />
                  {bankError && <div className="error-text">{bankError}</div>}
                </span>
                <span className="vendor-bank-input-case">
                  <label className="vendor-bank-input-title">Amount</label>
                  <input
                    type="text"
                    className="vendor-bank-edit"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(evt) => setAmount(evt.target.value)}
                    onFocus={() => amountError && setAmountError(null)}
                  />
                  {amountError && (
                    <div className="error-text">{amountError}</div>
                  )}
                </span>

                <div className="vendor-bank-btn-case">
                  <button className="vendor-bank-btn">Proceed</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isLoading && <ModalLoader />}

      <InvestorActivationSuccessModal
        modal={success}
        onLoad={() => {
          controlModal();
          setSuccess(false);
          loadData();
        }}
        title="Withdrawal Successful"
        desc="You have successfully withdrawn your account. The amount will be transferred to your bank account"
      />

      <AnimatePresence>
        {error && (
          <Toolkit text="Error occurred! Could not complete withdrawal" />
        )}
      </AnimatePresence>
    </>
  );
};

export default VendorFiatBankWithdrawalModal;
