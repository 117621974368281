export const AdminsIcon = () => {
  return (
    <svg
      width="72"
      height="90"
      viewBox="0 0 72 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9989 0C46.3665 0 54.7697 8.44768 54.7697 18.8702C54.7697 29.2918 46.3665 45.3038 35.9989 45.3038C25.6321 45.3038 17.2296 29.2911 17.2296 18.8702C17.2296 8.44768 25.6328 0 35.9989 0ZM35.9989 50.1218C55.8808 50.1218 72 59.0498 72 70.0609C72 81.072 55.8815 90 35.9989 90C16.117 90 0 81.072 0 70.0609C0 59.0498 16.1176 50.1218 35.9989 50.1218ZM41.9443 79.9651L38.0863 64.3508C38.0863 64.3508 40.1037 63.106 40.7759 60.5443C42.4007 54.3524 29.5981 54.3524 31.2229 60.5443C31.8951 63.1051 33.9126 64.3508 33.9126 64.3508L30.0546 79.9651H41.9443Z"
        fill="#8A44B2"
      />
    </svg>
  );
};
