import { useState } from "react";
import "../../../styles/investor/investor-profile-styles/display-input-editor.scss";

const DisplayInputEditor = (props) => {
  const { icon, onChange, nameLabel, nameTitle, value, onConfirm } = props;
  const [open, setOpen] = useState();

  const toggleOpen = () => {
    onConfirm();
    setOpen(!open);
  };

  return (
    <div className="display-input-editor-container">
      {open ? (
        <section className="first-show">
          <input
            type="text"
            className="editor-input"
            onChange={onChange}
            value={value}
          />

          <span className="confirm-presser" onClick={toggleOpen}>
            Confirm
          </span>
        </section>
      ) : (
        <section className="first-show">
          <span className="display-name">
            <h3 className="editor-label">{nameLabel}</h3>
            <h3 className="editor-name">{nameTitle}</h3>
          </span>

          {icon && (
            <span className="pencil-icon" onClick={toggleOpen}>
              {icon}
            </span>
          )}
        </section>
      )}
    </div>
  );
};

export default DisplayInputEditor;
