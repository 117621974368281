export const CameraIcon =()=>{
    return(
        <svg 
            width="43" 
            height="42" 
            viewBox="0 0 43 42" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <ellipse 
            cx="21.21" 
            cy="21" 
            rx="21.21" 
            ry="21" 
            fill="#F9F9F9"
        />
        <path 
            d="M23.5127 9.19971C23.9878 9.19967 24.4548 9.32256 24.8672 9.55619C25.2796 9.78983 25.6232 10.1261 25.864 10.5317L26.8505 12.1961H29.3606C29.8778 12.1961 30.3901 12.297 30.868 12.493C31.3459 12.689 31.7801 12.9762 32.1459 13.3384C32.5116 13.7005 32.8018 14.1305 32.9997 14.6036C33.1977 15.0768 33.2996 15.584 33.2996 16.0961V27.4961C33.2996 28.0083 33.1977 28.5154 32.9997 28.9886C32.8018 29.4617 32.5116 29.8917 32.1459 30.2538C31.7801 30.616 31.3459 30.9032 30.868 31.0992C30.3901 31.2952 29.8778 31.3961 29.3606 31.3961H12.9986C11.9539 31.3961 10.952 30.9852 10.2133 30.2538C9.47457 29.5224 9.05957 28.5305 9.05957 27.4961V16.0961C9.05957 15.0618 9.47457 14.0698 10.2133 13.3384C10.952 12.607 11.9539 12.1961 12.9986 12.1961H15.5195L16.58 10.4873C16.8239 10.094 17.1655 9.76909 17.5722 9.54367C17.979 9.31825 18.4373 9.19983 18.9034 9.19971H23.5127ZM21.1796 15.7961C19.7331 15.7961 18.3458 16.365 17.323 17.3777C16.3002 18.3904 15.7256 19.7639 15.7256 21.1961C15.7256 22.6283 16.3002 24.0018 17.323 25.0145C18.3458 26.0272 19.7331 26.5961 21.1796 26.5961C22.6261 26.5961 24.0133 26.0272 25.0361 25.0145C26.059 24.0018 26.6336 22.6283 26.6336 21.1961C26.6336 19.7639 26.059 18.3904 25.0361 17.3777C24.0133 16.365 22.6261 15.7961 21.1796 15.7961ZM21.1796 17.5961C21.6571 17.5961 22.1299 17.6892 22.571 17.8701C23.0121 18.0511 23.413 18.3162 23.7506 18.6505C24.0882 18.9848 24.3561 19.3817 24.5388 19.8184C24.7215 20.2552 24.8156 20.7233 24.8156 21.1961C24.8156 21.6689 24.7215 22.137 24.5388 22.5738C24.3561 23.0105 24.0882 23.4074 23.7506 23.7417C23.413 24.076 23.0121 24.3412 22.571 24.5221C22.1299 24.703 21.6571 24.7961 21.1796 24.7961C20.2152 24.7961 19.2904 24.4168 18.6085 23.7417C17.9266 23.0666 17.5436 22.1509 17.5436 21.1961C17.5436 20.2413 17.9266 19.3257 18.6085 18.6505C19.2904 17.9754 20.2152 17.5961 21.1796 17.5961Z" 
            fill="#081B33"
        />
        </svg>

    )
}