import React from "react";
import { useNavigate } from "react-router";
import { LeftArrowIcon } from "../components/icons";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import "../styles/index.scss";
import styles from "../styles/email-verification.module.scss";
import EmailConfirmation from "../components/icons/EmailConfirmation";
import { Link, useParams } from "react-router-dom";

const EmailVerified = (props) => {
  const navigate = useNavigate();
  let { name } = useParams();
 
  return (
    <div className="main-container">
      <span className={styles.topLogo}>
        <MainLogo />
      </span>

      <div className="page-card">
        <span className="mobile-logo">
          <MobileLogo />
        </span>

        <div className="card-button">
          <p className={styles.verifyLogo}>
            <EmailConfirmation svgColor="#3FBB97" />
          </p>

          <h2 className={styles.header}>Your email verified succesfully</h2>

          <p className={styles.statement}>
          
          Thank you <span className={styles.emailColor}>{name}</span> for registering on Quidroo. You have successfully verified your account.
          </p>

          <p className={styles.bottomLink}>
            To login
            <Link to="/signin" className={styles.signinLink}> Click here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
