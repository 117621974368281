import { AnimatePresence, motion } from "framer-motion";
import "../../../styles/seller/dashboard/activation-success-modal.scss";
import { FadeTransition } from "../../FadeTransition";
import { ActivationSuccessIcon } from "../../icons/ActivationSuccessIcon";

export const ActivationSuccessModal = (props) => {
  const { modal, onLoad, title, desc } = props;

  return (
    <AnimatePresence>
      {modal && (
        <FadeTransition>
          <div className="verification-success-modal">
            <div className="verification-success-overlay">
              <motion.div
                className="activation-success-modal"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ type: "spring" }}
              >
                <div className="activation-holder">
                  <ActivationSuccessIcon />

                  <h2 className="success-title">{title}</h2>

                  <span className="success-word">{desc}</span>
                </div>

                <span className="success-close">
                  To load your Dashboard
                  <span onClick={onLoad} className="close-btn">
                    Click here
                  </span>
                </span>
              </motion.div>
            </div>
          </div>
        </FadeTransition>
      )}
    </AnimatePresence>
  );
};
