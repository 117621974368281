export const NigeriaFlag =()=>{
    return(
        <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H19.5747V12H0V0Z" fill="white"/>
        <path d="M0 0H6.63015V12H0V0Z" fill="#186648"/>
        <path d="M12.9482 0H19.5784V12H12.9482V0Z" fill="#186648"/>
        <path d="M0.25 0.25H19.3247V11.75H0.25V0.25Z" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    )
}