import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/investor/investor-dashboard-styles/crypto-deposit-modal.scss";
import { validateUSDCDeposit } from "../../../util/validate/validateUSDCDeposit";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import {
  AutoCopyIcon,
  CloseIcon,
  LeftArrowIcon,
  CryptoDepositIcon,
} from "../../icons/index";
import { InvestorActivationSuccessModal } from "./InvestorActivationSuccessModal";

// const shortText = "1088330687";
// const shortLink = "GBH4TZYZ4IRCPO44CBOLFUHULU2WGALXTAVESQA6432MBJMABBB4GIYI";
const CryptoDepositModal = (props) => {
  const { controlModal, modal, loadData, memo_field, wallet_address } = props;

  const [copyAchieved, setCopyAchieved] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [amount, setAmount] = useState("");
  const [hash, setHash] = useState("");

  const [success, setSuccess] = useState(false);

  const [amountError, setAmountError] = useState(null);
  const [hashError, setHashError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const inputArea = useRef(null);
  const textArea = useRef(null);

  function copyLink(e) {
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess(null);
    }, 2000);
  }

  function copyText(e) {
    document.execCommand("copy");
    e.target.focus();
    setCopyAchieved("Copied!");
    setTimeout(() => {
      setCopyAchieved(null);
    }, 1000);
  }

  const handleConfirm = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateUSDCDeposit({
      amount: amount.trim(),
      hash: hash.trim(),
    });

    if (!isValid) {
      if (error?.amount) {
        setAmountError(error.amount);
      }

      if (error?.hash) {
        setHashError(error.hash);
      }
    } else {
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);

        const body = {
          amount: amount.trim(),
          hash: hash.trim(),
          memo: memo_field,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/confirmPaymentUSD/${token}`,
          body,
          config
        );

        setIsLoading(false);
        // console.log(data, "response from confirm deposit")
        if (!data.success && data.status === 202) setHashError(data.message);

        if (data.success && data.status === 200) {
          setSuccess(true);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error
        );
      }
    }
  };

  return (
    <>
      <AnimatePresence>
        {modal && (
          <div className="investor-crypto-deposit-modal">
            <div
              onClick={controlModal}
              className="investor-crypto-deposit-overlay"
            >
              <div
                className="crypto-deposition-case"
                onClick={(evt) => evt.stopPropagation()}
              >
                <div className="investor-crypto-deposit-head">
                  {/* <span className="left-arrow" onClick={controlModal}>
                    <LeftArrowIcon />
                  </span> */}
                  <div />
                  <h3 className="crypto-deposit-heading">Crypto (USDC)</h3>

                  <span onClick={controlModal}>
                    <CloseIcon color="#D13D2A" />
                  </span>
                </div>
                {/* <span className="bar-code">
                <CryptoDepositIcon />
              </span> */}

                <div className="crypto-deposit-wallet-address">
                  <span className="left-section">
                    <h3 className="wallet-address-title">Wallet Address</h3>

                    <p className="crypto-deposit-address">
                      <div className="shorten-text">{wallet_address}</div>
                    </p>
                    {copySuccess && (
                      <motion.span
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -30 }}
                        className="alert"
                      >
                        {copySuccess}
                      </motion.span>
                    )}
                  </span>
                  <div className="right-section-container">
                    <span onClick={copyLink} className="right-section">
                      <AutoCopyIcon />
                    </span>
                  </div>
                </div>

                <div className="crypto-deposit-memo-address">
                  <span className="left-section">
                    <h3 className="wallet-address-title">Memo ID</h3>
                    <p className="crypto-deposit-address">
                      {memo_field && (
                        <textarea
                          ref={textArea}
                          type="text"
                          className="short-address"
                          value={memo_field}
                          disabled
                        />
                      )}
                    </p>
                    {copyAchieved && (
                      <motion.span
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -30 }}
                        className="alert"
                      >
                        {copyAchieved}
                      </motion.span>
                    )}
                  </span>
                  <span onClick={copyText} className="right-section">
                    <AutoCopyIcon />
                  </span>
                </div>

                <form onSubmit={handleConfirm}>
                  <div className="crypto-deposit-wallet-address">
                    <span className="left-section-withdraw">
                      <h3 className="wallet-address-title">Transaction Hash</h3>
                      <p className="crypto-withdraw-address">
                        <input
                          type="text"
                          className="input-text-deposit"
                          value={hash}
                          onChange={(e) => setHash(e.target.value)}
                          placeholder="E.g  931f790528578aeda7f8f8956c1241205cb8c84eb82e5..."
                          onFocus={() => hashError && setHashError(null)}
                        />
                        {hashError && (
                          <div className="error-text">{hashError}</div>
                        )}
                      </p>
                    </span>
                  </div>

                  {/* <div className="crypto-deposit-memo-address">
                <span className="left-section">
                  <h3 className="wallet-address-title">Transaction Hash</h3>
                  <p className="crypto-deposit-address">
                    <div className="short-address">{shortText}</div>
                  </p>
                  {copyAchieved && (
                    <motion.span
                      initial={{ opacity: 0, y: -30 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -30 }}
                      className="alert"
                    >
                      {copyAchieved}
                    </motion.span>
                  )}
                </span>
                <span onClick={copyText} className="right-section">
                  <AutoCopyIcon />
                </span>
              </div> */}

                  <div className="crypto-deposit-wallet-address">
                    <span className="left-section-withdraw">
                      <h3 className="wallet-address-title">Amount</h3>
                      <p className="crypto-withdraw-address">
                        <input
                          type="text"
                          className="input-text-deposit"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="E.g  300,000"
                          onFocus={() => amountError && setAmountError(false)}
                        />
                        {amountError && (
                          <div className="error-text">{amountError}</div>
                        )}
                      </p>
                    </span>
                  </div>

                  <div className="crypto-deposit-btn-case">
                    <button className="crypto-deposit-btn">
                      Confirm Payment
                    </button>

                    <p className="page-statement">
                      Please do not click on this button until you have made a
                      successfull transaction.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </AnimatePresence>
      {isLoading && <ModalLoader />}

      <AnimatePresence>
        {success && <Toolkit text="Amount Confirmed Successfully" />}
      </AnimatePresence>

      <InvestorActivationSuccessModal
        modal={success}
        onLoad={() => {
          controlModal();
          setSuccess(false);
          loadData();
        }}
        title="Deposit Successful"
        desc="You have successfully deposited into your account. The amount has been added to your Quidroo Wallet"
      />
    </>
  );
};

export default CryptoDepositModal;
