import React, {useState} from "react";
import { CloseIcon } from "../icons/index";
import "../../styles/vendor/mobile-vendor-deposit-withdraw-modal.scss";
import VendorDepositModalCard from "./VendorDepositModalCard";
import VendorWithdrawModalCard from "./VendorWithdrawModalCard";

export const MobileVendorDepositWithdrawModal = (props) => {
  const { controlModal, modal, } = props;
  const[show,setShow]=useState(false)
  const[appear,setAppear]=useState(false)

  const controlAppear =()=>{
    setAppear(!appear)
  }

  const controlShow =()=>{
    setShow(!show)
  }


  return (
    <>
      {modal && (
        <div className="vendor-mobile-wd-modal">
        <div onClick={controlModal} className="vendor-mobile-wd-overlay"></div>
        <div className="vendor-mobile-wd-modal-card">
          <span onClick={controlModal} className="close-modal-presser">
            <CloseIcon />
          </span>
          <div className="vendor-deposit-withdraw-container">
            <button onClick={controlAppear} className="vendor-withdrawal-presser">
              Withdraw
            </button>
            <button
              onClick={controlShow}
              className="vendor-deposit-presser"
            >
              Deposit
            </button>
          </div>
        </div>
      </div>
      )}
    <VendorDepositModalCard
      controlModal={controlShow}
      modal={show}
    
    />

    <VendorWithdrawModalCard
      controlModal={controlAppear}
      modal={appear}
    
    />
    </>
  );
};