/**
 * validateDate checks to see if string is valid date (YYYY-MM-DD).
 *
 * @param date
 */

export const validateDate = (date) => {
  const reg = /^\d{4}[\-](0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])$/;
  if (!reg.test(date)) {
    return {
      isValid: false,
      error: "Please, enter a valid date.",
    };
  }

  const split = date.split("-");

  // Extract the string into month, date and year
  let year = parseInt(split[0]);
  let month = parseInt(split[1]);
  let day = parseInt(split[2]);

  // Create list of days of a month
  let listOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month == 1 || month > 2) {
    if (day > listOfDays[month - 1]) {
      return {
        isValid: false,
        error: `This month has only ${listOfDays[month - 1]} days.`,
      };
    }
  } else if (month == 2) {
    let leapYear = false;
    if ((!(year % 4) && year % 100) || !(year % 400)) {
      leapYear = true;
    }
    if (!leapYear && day >= 29) {
      return {
        isValid: false,
        error: `February has 28 days for the particular year`,
      };
    } else if (leapYear && day > 29) {
      return {
        isValid: false,
        error: `February has 29 days for the particular year`,
      };
    }
  }

  return {
    isValid: true,
  };
};
