import React, { useState } from "react";
import "../../../styles/seller/dashboard/account-withdraw-modal.scss";
import { CloseIcon, LeftArrowIcon } from "../../../components/icons";
import { validateAmount } from "../../../util/validate/validateAmount";
import { BACKEND_URL } from "../../../config";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";
import { validateAccountDetails } from "../../../util/validate/validateAccountDetails";
import { ActivationSuccessModal } from "./ActivationSuccessModal";

export const AccountWithdrawalModal = (props) => {
  const { controlModal, modal, userDetails, loadData } = props;

  const [acctName, setAcctName] = useState("");
  const [acctNo, setAcctNo] = useState("");
  const [bank, setBank] = useState("");
  const [amount, setAmount] = useState("");

  const [acctNameError, setAcctNameError] = useState(null);
  const [acctNoError, setAcctNoError] = useState(null);
  const [bankError, setBankError] = useState(null);
  const [amountError, setAmountError] = useState(null);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (
      !userDetails?.account_name &&
      !userDetails?.account_no &&
      !userDetails?.bank
    ) {
      const { isValid, error } = validateAccountDetails({
        accountNo: acctNo.trim(),
        accountName: acctName.trim(),
        bank: bank.trim(),
      });
      if (!isValid) {
        if (error?.accountNo) setAcctNoError(error.accountNo);
        if (error?.accountName) setAcctNameError(error.accountName);
        if (error?.bank) setBankError(error.bank);
        return;
      }
    }

    const { isValid, error } = validateAmount(amount.trim());

    if (!isValid) {
      setAmountError(error);
    } else {
      const amountNo = parseInt(amount.trim());

      if (amountNo < 3000) {
        setAmountError("You cannot withdraw less than 3000NGN");
        return;
      }

      if (amountNo > userDetails?.balance) {
        setAmountError("You have insufficient balance");
        return;
      }
      const token = localStorage.getItem("token");

      try {
        setIsLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const body = {
          amount,
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/withdrawalNG/${token}`,
          body,
          config
        );

        if (data.success) {
          setIsLoading(false);
          setSuccess(true);
          controlModal();
          setAcctName("");
          setAcctNo("");
          setBank("");
          setAmount("");
        }

        if (data.status === 201) {
          setError(data.message);
          setTimeout(() => setError(null), 2500);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <>
      {modal && (
        <div className="account-withdraw-modal">
          <div
            onClick={() => {
              controlModal();
            }}
            className="account-withdraw-overlay"
          >
            <form
              className="account-withdraw-case"
              onClick={(evt) => evt.stopPropagation()}
              onSubmit={handleSubmit}
            >
              <div className="account-withdraw-head">
                <span />

                <h3 className="account-withdraw-heading">Bank Account</h3>

                <span
                  onClick={() => {
                    controlModal();
                  }}
                >
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>
              {/* <div className="crypto-long-input"> */}
              <span className="account-withdraw-input-case">
                <label className="account-withdraw-input-title">
                  Account Number
                </label>
                <input
                  type="text"
                  className="account-withdraw-edit"
                  placeholder="Enter Account Number"
                  value={userDetails?.account_no ?? acctNo}
                  onChange={(evt) =>
                    !userDetails?.account_no && setAcctNo(evt.target.value)
                  }
                  maxLength={10}
                  onFocus={() => acctNoError && setAcctNoError(null)}
                  disabled={userDetails?.account_no ? true : false}
                />
                {acctNoError && <div className="error-text">{acctNoError}</div>}
              </span>
              <span className="account-withdraw-input-case">
                <label className="account-withdraw-input-title">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="account-withdraw-edit"
                  placeholder="Enter Bank Name"
                  value={userDetails?.bank ?? bank}
                  onChange={(evt) =>
                    !userDetails?.bank && setBank(evt.target.value)
                  }
                  onFocus={() => bankError && setBankError(null)}
                  disabled={userDetails?.bank ? true : false}
                />
                {bankError && <div className="error-text">{bankError}</div>}
              </span>

              {/* </div> */}
              <span className="account-withdraw-input-case">
                <label className="account-withdraw-input-title">
                  Account Name
                </label>
                <input
                  type="text"
                  className="account-withdraw-edit"
                  placeholder="Enter Account Name"
                  value={userDetails?.account_name ?? acctName}
                  onChange={(evt) =>
                    !userDetails?.account_name && setAcctName(evt.target.value)
                  }
                  onFocus={() => acctNameError && setAcctNameError(null)}
                  disabled={userDetails?.account_name ? true : false}
                />
                {acctNameError && (
                  <div className="error-text">{acctNameError}</div>
                )}
              </span>
              <span className="account-withdraw-input-case">
                <label className="account-withdraw-input-title">Amount</label>
                <input
                  type="text"
                  className="account-withdraw-edit"
                  placeholder="Enter Amount"
                  onFocus={() => amountError && setAmountError(null)}
                  onChange={(evt) => setAmount(evt.target.value)}
                  value={amount}
                />
                {amountError && <div className="error-text">{amountError}</div>}
              </span>
              <div className="account-withdraw-btn-case">
                <button className="account-withdraw-btn">Proceed</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isLoading && <ModalLoader />}

      <AnimatePresence>{error && <Toolkit text={error} />}</AnimatePresence>
      <ActivationSuccessModal
        modal={success}
        onLoad={() => {
          setSuccess(false);
          loadData();
        }}
        title="Withdrawal Successful"
        desc="You have successfully withdrawn from your account. The amount will be transferred to your bank account"
      />
    </>
  );
};
