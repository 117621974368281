/**
 * convertTime converts time from the database
 *
 * Returns string in dd : hr : min : sec format
 *
 */

export function convertTime(time) {
  const date = Date.now();
  const dataDate = new Date(time);

  const difference = dataDate.getTime() - date;
  const days = Math.floor(difference / (24 * 60 * 60 * 1000));
  const daysms = difference % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = difference % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = difference % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  const pad = function (n) {
    return n < 10 ? "0" + n : n;
  };
  return {
    difference,
    days: pad(days),
    hrs: pad(hours),
    mins: pad(minutes),
    sec: pad(sec),
    text: `${pad(days)} : ${pad(hours)} : ${pad(minutes)} : ${pad(sec)}`,
  };
}
