import React from 'react'

export const ReceiptUploadIcon = () => {
  return (
    <svg 
        width="47" 
        height="42" 
        viewBox="0 0 47 42" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
<path 
    d="M26.8991 27.8088H38.6404V1H1V27.8088H14.6745" 
    stroke="#8A44B2" 
    stroke-width="1.5" 
    stroke-miterlimit="3" 
    stroke-linecap="round" 
    stroke-linejoin="round" 
    stroke-dasharray="7 7"
/>
<path 
    d="M20.4742 33.8644V11.6611L13.8218 18.3989" 
    stroke="#8A44B2" 
    stroke-width="1.5" 
    stroke-miterlimit="3" 
    stroke-linecap="round" 
    stroke-linejoin="round"
/>
<path 
    d="M27.0981 18.3989L20.4741 11.6611" 
    stroke="#8A44B2" 
    stroke-width="1.5" 
    stroke-miterlimit="3" 
    stroke-linecap="round" 
    stroke-linejoin="round"
/>
<path 
    d="M7.65234 31.9877V40.9998H45.3496V14.1904H42.3361" 
    stroke="#8A44B2" 
    stroke-width="1.5" 
    stroke-miterlimit="3" 
    stroke-linecap="round" 
    stroke-linejoin="round" 
    stroke-dasharray="7 7"
/>
</svg>

  )
}
