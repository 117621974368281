import "../../../styles/investor/stats-styles/stat-card.scss";
import { InterestAmountCardIcon } from "../../icons/index.jsx";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const CreditScoreCard = ({ score, dti }) => {
  return (
    <div className="stat-card-container" style={{ background: "#A1BB3F" }}>
      <div className="stat-svg-container">
        <div className="first-part">
          <span className="top-section">
            <InterestAmountCardIcon />
          </span>
          <span className="bottom-section">
            <h3 className="header-text">Credit Score</h3>
            <h3 className="digit">{score}</h3>
          </span>
        </div>
        <div className="second-part">
          <div className="second-title">
            DTI:
            <span>{dti}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditScoreCard;
