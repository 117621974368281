import React from 'react';
import "../../../styles/seller/report/report-invoice-card.scss"



export const MobileReportInvoiceCard = (props) => {
    const{
        invoiceName,
        amount,
        dueDate,
        yourBid,
        payAmount,
        
    }=props

    const shortInvoiceName= invoiceName.substring(0, 17) + "...";
  return (  
        <div className='mobile-report-invoice-container' >
                <div className="mobile-first-card-report" style={{width:"75%"}}>
                    <h2 className='mobile-invoice-name-report'>
                        {shortInvoiceName}
                    </h2>
                    <h4 className='mobile-invoice-top-report' >
                       Your Bid: 
                       <span className='big-report' 
                        style={{
                            color: yourBid > 5 ? "black" : yourBid < 4.5 ? "red" : "#565CD6",
                            marginLeft: "10px"
                        }}
                       >
                           {yourBid}%
                        </span>
                    </h4>
                    
                    <h4 className='mobile-card-your-report' >
                    Pay Amount: 
                       <span className='big-report' style={{marginLeft: "10px"}}>
                           { payAmount}%
                       </span>
                    </h4>
                </div>
                <div className="mobile-second-card-report" style={{width:"25%"}}>
                    <h2 className='mobile-invoice-amount-report'>
                        {amount}
                    </h2>
                    <h2 className='mobile-invoice-date'>
                        {dueDate}
                    </h2>
                </div>

        </div>
  )
}
