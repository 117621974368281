import axios from "axios";
import React, { useState, useEffect } from "react";
import { PageLoader } from "../../components/general-component/PageLoader";
import {
  DownwardArrowIcon,
  PlusIcon,
  UpwardArrowIcon,
} from "../../components/icons";
import { MobileDepositWithdrawModal } from "../../components/investor-component/dashboard/MobileDepositWithdrawModal";
import { ActivationStatusBar } from "../../components/seller-component/ActivationStatusBar";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import CreditScoreCard from "../../components/seller-component/report/CreditScoreCard";
import { MobileReportInvoiceCard } from "../../components/seller-component/report/MobileReportInvoiceCard";
import ReportCard from "../../components/seller-component/report/ReportCard";
import ReportInvoiceCard from "../../components/seller-component/report/ReportInvoiceCard";
import TotalInvoicesCard from "../../components/seller-component/report/TotalInvoicesCard";
import TotalSalesCard from "../../components/seller-component/report/TotalSalesCard";
import { BACKEND_URL } from "../../config";
import "../../styles/seller/report/report-page.scss";
import { formatAmount } from "../../util/formatAmount";

// const invoiceCardData = [
//   {
//     amount: "150,000.00",
//     payAmount: "₦150,000.00",
//     dueDate: "48:11:35",
//     yourStat: "4.5",
//     invoiceName: "Road Project Supplies Invoice",
//   },

const reportCardData = [
  {
    header: "Total Sales",
    digit: "₦49,346,850.00",
    percent: 77,
  },
  {
    header: "Total Invoices",
    digit: "112",
    percent: 77,
  },
  {
    header: "Credit Score",
    digit: "₦693,850.00",
    percent: 77,
  },
];

const Report = () => {
  const [modal, setModal] = useState(false);
  const [high, setHigh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState();

  const controlModal = () => {
    setModal(!modal);
  };

  const toggleControl = () => {
    setHigh(!high);
  };

  const loadReport = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/seller_report_page/${token}`,
        config
      );
      setIsLoading(false);

      console.log(data);

      if (data.success) setReportData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadReport();
  }, []);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <section>
        <ActivationStatusBar
          activated={reportData?.user_details.verified}
          pending={reportData?.user_details?.providedVerificationDetails}
          loadData={loadReport}
        />
      </section>
      <div className="report-container">
        <div className="report-first-half">
          <h1 className="first-half-header">Reports</h1>
          <div className="report-card-holder">
            {/* {reportCardData.map((report, index) => (
              <ReportCard
                key={index}
                header={report.header}
                digit={report.digit}
                percent={report.percent}
              />
            ))} */}

            <TotalSalesCard
              amount={formatAmount(reportData?.stat_details.totalSales)}
            />
            <TotalInvoicesCard
              invoices={reportData?.stat_details.totalInvoices}
              percent={Math.round(reportData?.stat_details.totalInvoicePercent)}
            />
            <CreditScoreCard
              score={reportData?.stat_details.creditScore}
              dti={reportData?.stat_details.averageDTI?.toFixed(1)}
            />
          </div>
        </div>

        <div className="report-second-half">
          <div className="admin-financial-second-half-header mobile-hide">
            <span className="report-filter mobile-hide">
              <p className="report-filter mobile-hide">Sold Invoices</p>
            </span>
            <table className="report-invoice-table">
              <tbody>
                <tr className="report-invoice-header table">
                  <th className="invoice-name">Invoice Name</th>
                  <th className="report-amount">Amount</th>
                  <th className="report-your">Your reports</th>
                  <th className="report-pay">Pay Amount</th>
                  <th className="report-time">Due Date</th>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className={
              high
                ? "report-invoice-scroll-long scroll"
                : "report-invoice-scroll scroll"
            }
          >
            {reportData?.soldInvoices?.length ? (
              <div className="report-invoice-data-holder mobile-hide">
                {reportData?.soldInvoices?.map((invoice, index) => (
                  <ReportInvoiceCard
                    key={invoice?.invoice_id}
                    amount={formatAmount(invoice?.invoice_amount)}
                    payAmount={formatAmount(invoice?.receivable_amount)}
                    dueDate={invoice?.due_date}
                    yourBid={invoice?.pay_rate.toFixed(2)}
                    invoiceName={invoice["invoice_name "]}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-invoice">
                <InvoiceEmptyIcon />
                <p>You currently do not have any Investment </p>
              </div>
            )}
            {reportData?.soldInvoices?.length ? (
              <div className="window-hide">
                {reportData?.soldInvoices?.map((invoice, index) => (
                  <MobileReportInvoiceCard
                    key={invoice?.invoice_id}
                    amount={formatAmount(invoice?.invoice_amount)}
                    payAmount={formatAmount(invoice?.receivable_amount)}
                    dueDate={invoice?.due_date}
                    yourBid={invoice?.pay_rate.toFixed(2)}
                    invoiceName={invoice["invoice_name "]}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-invoice">
                <InvoiceEmptyIcon />
                <p>You currently do not have any Investment </p>
              </div>
            )}
          </div>
          {high ? (
            <button className="report-control-btn" onClick={toggleControl}>
              Show Less
              <span className="sizer">
                <UpwardArrowIcon color="#565CD6" />
              </span>
            </button>
          ) : (
            <button className="report-control-btn" onClick={toggleControl}>
              Show More
              <span className="sizer">
                <DownwardArrowIcon color="#565CD6" />
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="window-hide">
        <button
          onClick={controlModal}
          className="mobile-uploader-btn window-hide"
        >
          <PlusIcon />
        </button>

        <MobileDepositWithdrawModal controlModal={controlModal} modal={modal} />
      </div>
    </>
  );
};

export default Report;
