/**
 * validateAccountNumber checks to see if string is  valid account number.
 *
 * @param accountNo
 */

export const validateAccountNumber = (accountNo) => {
  if (!accountNo.trim().length) {
    return {
      isValid: false,
      error: "Please, enter an account number.",
    };
  }

  if (!/\D/.test(accountNo)) {
    return {
      isValid: true,
    };
  }

  return {
    isValid: false,
    error: "Please, enter a valid account number.",
  };
};
