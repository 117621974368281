import { Outlet } from "react-router";
import "../../styles/seller/dashboard/dash-board.scss";
import { AdminTopBar } from "./AdminTopBar";
import AdminNavigatorBottom from "../navigator/admin-navigator/AdminNavigatorBottom";

export const AdminLayout = () => (
  <div className="main-holder">
    <AdminTopBar show />
    <div className="display-container">
      <div className="side-bar-holder">
        <AdminNavigatorBottom />
      </div>

      <div className="pages">
        <Outlet />

        <div className="bottom-bar-holder">
          <AdminNavigatorBottom />
        </div>
      </div>
    </div>
  </div>
);