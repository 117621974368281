import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/admin/admin-users/admin-user-vendor-verification-card.scss";
import { formatAmount } from "../../../util/formatAmount";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { AdminVerificationIcon } from "../../icons";
import { AdminConfirmationModal } from "../admin-invoice/AdminConfirmationModal";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";
// import { VendorConfirmationModal } from "../vendor-invoice/VendorConfirmationModal";
// import VendorPayNowModalCard from "../vendor-invoice/VendorPayNowModalCard";

const AdminUserVendorVerificationCard = (props) => {
  const {
    vendorName,
    vendorPhone,
    emailAddress,
    creditScore,
    isVerified,
    url,
    id,
    loadData,
  } = props;

  const [vendorDetail, setVendorDetail] = useState(false);

  const [verified, setVerified] = useState(false);
  const [unVerified, setUnVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unverifySuccess, setUnverifySuccess] = useState(false);
  const [error, setError] = useState(null);

  const [isVendorLoading, setIsVendorLoading] = useState(false);
  const [vendorDetailsData, setVendorDetailsData] = useState();

  const toggleUnverified = () => {
    setUnVerified(!unVerified);
  };

  const toggleVerified = () => {
    setVerified(!verified);
  };

  const toggleUnVerified = () => {
    setUnVerified(!unVerified);
  };

  // VENDOR APPROVAL FUNCTION
  const handleConfirmation = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setVerified(false);
      const body = {
        user_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_user_verify/${token}`,
        body,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with verifying");
    }
  };

  const handleUnverify = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setUnVerified(false);
      const body = {
        user_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_user_unverify/${token}`,
        body,
        config
      );

      if (data.success) {
        setUnverifySuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setUnverifySuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with unverifying the vendor");
    }
  };

  const onVendorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setVendorDetail(true);

    try {
      setIsVendorLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_vendor_detail/${id}`,
        config
      );
      console.log(data);
      setIsVendorLoading(false);

      if (data.success) setVendorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onVendorDetailClose = () => {
    setVendorDetail(false);
    setVendorDetailsData(undefined);
  };

  const scoreBg = () => {
    if (creditScore >= 70) {
      return "#E5FFF7";
    } else if (creditScore >= 60) {
      return "#9cd2f1";
    } else if (creditScore >= 45) {
      return "#FFEBD8";
    } else if (creditScore >= 20) {
      return "#f2f4e0";
    } else if (creditScore < 20) {
      return "#FFDEDE";
    }
  };

  return (
    <>
      <tr className="admin-user-vendor-verify-invoice-cards">
        <td onClick={onVendorDetailOpen}>{vendorName}</td>
        <td>
          <span
            className="admin-indicator"
            style={{ backgroundColor: scoreBg(creditScore) }}
          >
            {creditScore}%
          </span>
        </td>
        <td>{emailAddress}</td>
        <td>{vendorPhone}</td>

        {/* <td>{payAmount}</td> */}
        {/* <td>{dueDate}</td> */}
        <td>
          {isVerified === false ? (
            <button
              className="admin-invoice-btn active-admin-btn"
              onClick={toggleVerified}
            >
              Verify
            </button>
          ) : (
            <button
              className="admin-user-verified-btn"
              onClick={toggleUnverified}
            >
              <AdminVerificationIcon />
            </button>
          )}
        </td>
      </tr>
      <AdminVendorDetailModal
        onClose={onVendorDetailClose}
        modal={vendorDetail}
        vendorName={
          vendorDetailsData?.account_name ?? vendorDetailsData?.companyName
        }
        creditScore={vendorDetailsData?.credit_score}
        vendorPhone={vendorDetailsData?.phone}
        emailAddress={vendorDetailsData?.email}
        accountNumber={vendorDetailsData?.account_no}
        bankName={vendorDetailsData?.bank}
        memoNumber={vendorDetailsData?.memo_id}
        vendorBalance={formatAmount(vendorDetailsData?.wallet_balance)}
        accountName={vendorDetailsData?.account_name}
        loading={isVendorLoading}
        verified={vendorDetailsData?.verified}
      />

      <AdminConfirmationModal
        toggleChange={toggleVerified}
        modal={verified}
        topic="Confirmation"
        statement="Would you like to Approve/Authorize this Vendor"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleConfirmation}
      />

      <AdminConfirmationModal
        toggleChange={toggleUnverified}
        modal={unVerified}
        topic="Confirmation"
        statement="Would you like to unauthorize/disactivate this vendor"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleUnverify}
      />

      <AnimatePresence>
        {success && (
          <Toolkit text="You have successfully verified the vendor" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {unverifySuccess && (
          <Toolkit text="You have successfully unverified the vendor" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};

export default AdminUserVendorVerificationCard;
