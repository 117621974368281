import "./styles/App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashBoard from "./pages/dashboard";

import SelectionPage from "./pages/signup";
import Report from "./pages/dashboard/report";
import Profile from "./pages/dashboard/profile";
import Invoice from "./pages/dashboard/invoice";
import ForgetPassword from "./pages/forgot-password";
import InvestorRegisterPage from "./pages/signup/investor";
import SellerRegisterPage from "./pages/signup/sme";
import VendorRegisterPage from "./pages/signup/vendor";
import LoginPage from "./pages/signin";
import EmailVerification from "./pages/email-verification";
import EmailVerified from "./pages/email-verified";
import EmailUnverified from "./pages/email-unverified";
import ChangePasswordPage from "./pages/change-password";
import InvestorIndex from "./pages/investor-dashboard/investor-index";
import InvestorBids from "./pages/investor-dashboard/investor-bids";
import InvestorStats from "./pages/investor-dashboard/investor-stats";
import InvestorInvoice from "./pages/investor-dashboard/investor-invoice";
import {
  Layout,
  InvestorLayout,
  VendorLayout,
  AdminLayout,
} from "./components/general-component/index";
import ResetLink from "./pages/reset-link";
import ResetSuccess from "./pages/reset-success";
import VendorIndex from "./pages/vendor/vendor-index-page";
import VendorInvoice from "./pages/vendor/vendor-invoice-page";
import VendorTransaction from "./pages/vendor/vendor-transaction-page";
import AdminLoginPage from "./pages/admin/admin-login-page";
import AdminDashBoardPage from "./pages/admin/admin-index-page";
import AdminInvoicePage from "./pages/admin/admin-invoice-page";
import AdminFinancialPage from "./pages/admin/admin-financial-page";
import AdminUserPage from "./pages/admin/admin-users-page";

function App() {
  return (
    <Router>
      {/* <NavigatorBottom/> */}
      <Routes>
        {/* <Route path="/DashBoard" component={DashBoard} />
          <Route path="/SignIn" component={SignIn} />
          <Route path="/SignUp" component={SignUp} /> */}
        <Route path="/signup" element={<SelectionPage />} />
        <Route path="signin" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgetPassword />} />
        <Route path="email-verification" element={<EmailVerification />} />
        <Route path="email-verified/:name" element={<EmailVerified />} />
        <Route
          path="change-password/:user_id"
          element={<ChangePasswordPage />}
        />
        <Route path="email-unverified" element={<EmailUnverified />} />
        <Route path="reset-link" element={<ResetLink />} />
        <Route path="reset-success" element={<ResetSuccess />} />

        <Route path="signup/investor" element={<InvestorRegisterPage />} />
        <Route path="signup/seller" element={<SellerRegisterPage />} />

        <Route path="signup/vendor" element={<VendorRegisterPage />} />

        {/* seller pages-dashboard */}
        <Route path="seller/dashboard" element={<Layout />}>
          <Route index element={<DashBoard />} />
          <Route path="report" element={<Report />} />
          <Route path="profile" element={<Profile />} />
          <Route path="invoice" element={<Invoice />} />
        </Route>

        {/* investor page-dashboard  */}
        <Route path="investor/dashboard" element={<InvestorLayout />}>
          <Route index element={<InvestorIndex />} />
          <Route path="bids" element={<InvestorBids />} />
          <Route path="stats" element={<InvestorStats />} />
          <Route path="invoice" element={<InvestorInvoice />} />
        </Route>

        {/* Vendor page-dashboard  */}
        <Route path="vendor/dashboard" element={<VendorLayout />}>
          <Route index element={<VendorIndex />} />
          {/* <Route path="bids" element={<InvestorBids />} /> */}
          <Route path="transaction" element={<VendorTransaction />} />
          <Route path="invoice" element={<VendorInvoice />} />
        </Route>

        {/* Admin page-dashboard  */}
        <Route path="admin/dashboard" element={<AdminLayout />}>
          <Route index element={<AdminDashBoardPage />} />
          <Route path="invoice" element={<AdminInvoicePage />} />
          <Route path="users" element={<AdminUserPage/>} />
          <Route path="financial" element={<AdminFinancialPage/>} />
        </Route>

        <Route path="/admin" element={<AdminLoginPage />} />
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
