import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/investor/investor-invoice-styles/investor-invoice-card.scss";
import { convertTime } from "../../../util/convertTime";
import { formatAmount } from "../../../util/formatAmount";
import { InvestorActivationModalForm } from "../dashboard/InvestorActivationFormModal";
import { InvestorInvoiceDetailModal } from "../dashboard/InvestorInvoiceDetailModal";

// import { MobileInvoiceDetailModal } from "./MobileInvoiceDetailModal";

const InvestorInvoiceCard = (props) => {
  const {
    invoiceName,
    amount,
    amountUSD,
    vendor,
    creditScore,
    timeRemaining,
    url,
    id,
    payRate,
    loadInvestor,
    verified,
    maxPayable,
    isBidded
  } = props;

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState();
  const [activateModal, setActivateModal] = useState(false);

  const [time, setTime] = useState("");
  const [timeDifference, setTimeDifference] = useState(
    convertTime(time).difference
  );

  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  const onModalOpen = async () => {
    const token = localStorage.getItem("token");

    setModal(true);

    console.log(verified, "dsfds");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/invoice_details/${id}`,
        config
      );

      setIsLoading(false);
      console.log(data);

      if (data.success) setModalData(data.invoice_details[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const onModalClose = () => {
    setModal(false);
    setModalData(undefined);
  };
  useEffect(() => {
    const { difference } = convertTime(timeRemaining);
    setTimeDifference(difference);
    if (difference <= 0) {
      setTime("Bid closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(timeRemaining).text);
      }, [1000]);

      return () => clearTimeout(timer);
    }
  }, [time]);

  return (
    <>
      <tr className="invoice-page-cards">
        <td>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {invoiceName}
          </a>
        </td>
        <td>{userCurrency === "naira" ? `₦`:`$` }{amount} </td> 
        <td>{creditScore}%</td>
        <td>{time}</td>
        <td>{vendor}</td>
        <td>

          {isBidded ?
            <button
              className="bid-editor-btn inactive">
              Bidded
            </button>
          :
          <button
            className={`bid-editor-btn ${
              timeDifference <= 0 ? "inactive" : ""
            }`}
            onClick={() => (timeDifference > 0 ? onModalOpen() : null)}
          >
            Bid
          </button>
          }
          
        </td>
      </tr>

      <InvestorInvoiceDetailModal
        loading={isLoading}
        onClose={onModalClose}
        modal={modal}
        id={id}
        dueDate={moment(modalData?.dueDate).format("MMM Do, YYYY")}
        transPrice={amount}
        transName={modalData?.invoice_name}
        dueTime={modalData?.bid_close_time}
        ROI={modalData?.max_discount.toFixed(2)}
        credit={modalData?.seller_credit_score}
        bidderNumber={modalData?.no_of_bidders}
        sellerName={modalData?.seller_name}
        vendorPhoneNumber={modalData?.vendor_phone}
        payRate={payRate}
        invoiceURL={modalData?.invoice_url}
        vendorEmail={modalData?.vendor_email}
        // maxPayable={formatAmount(modalData?.payable_amount_by_investor)}
        maxPayable={formatAmount(modalData?.payable_amount_by_investor)}
        maxPayable_={userCurrency === "naira" ? modalData?.payable_amount_by_investor :modalData?.payable_amount_by_investor_USD}
        maxPayableUSD={formatAmount(modalData?.payable_amount_by_investor_USD)}
        // maxPayableUSD_={modalData?.payable_amount_by_investor_USD}
        invoiceAmount={modalData?.invoice_amount}
        invoiceAmountUSD={modalData?.invoice_amount_USD}
        // invoiceAmount={modalData?.invoice_amount}
        vendorName={modalData?.vendor_name}
        invoiceName={invoiceName}
        loadInvestor={loadInvestor}
        onActivate={() => {
          setModal(false);
          setActivateModal(true);
        }}
        verified={verified}
      />
      <InvestorActivationModalForm
        modal={activateModal}
        onClose={() => setActivateModal(false)}
      />
    </>
  );
};

export default InvestorInvoiceCard;

