import React, { useState } from "react";
import "../../../styles/admin/admin-invoice/admin-invoice-card.scss";
import { AdminInvoiceDetailNotifyModal } from "./AdminInvoiceDetailNotifyModal";
import { AdminInvoiceDetailApprovalModal } from "./AdminInvoiceDetailApprovalModal";
import { AdminSellerDetailModal } from "../AdminSellerDetailModal";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { formatAmount } from "../../../util/formatAmount";
import moment from "moment";

const AdminInvoiceCard = (props) => {
  const {
    invoiceName,
    price,
    seller,
    vendor,
    status,
    url,
    id,
    loadData,
    sellerId,
    vendorId,
  } = props;

  console.log(props.id)
  const [approveDetail, setApproveDetail] = useState(false);
  const [detailNotify, setDetailNotify] = useState(false);
  const [sellerDetail, setSellerDetail] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(false);

  /** loading states */
  const [isLoading, setIsLoading] = useState(false);
  const [isVendorLoading, setIsVendorLoading] = useState(false);
  const [isSellerLoading, setIsSellerLoading] = useState(false);

  /** data */
  const [detailsData, setDetailsData] = useState();
  const [vendorDetailsData, setVendorDetailsData] = useState();
  const [sellerDetailsData, setSellerDetailsData] = useState();

  const fetchInvoiceDetails = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_invoice_details/${id}`,
        config
      );
      setIsLoading(false);

      if (data.success) setDetailsData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const onDetailOpen = async () => {
    setDetailNotify(true);
    fetchInvoiceDetails();
  };

  const onApproveDetailOpen = () => {
    setApproveDetail(true);
    fetchInvoiceDetails();
  };

  const onApproveDetailClose = () => {
    setApproveDetail(false);
    setDetailsData(undefined);
  };

  const onDetailClose = () => {
    setDetailNotify(false);
    setDetailsData(undefined);
  };

  const onVendorDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setVendorDetail(true);

    try {
      setIsVendorLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_vendor_detail/${vendorId}`,
        config
      );
      console.log(data);
      setIsVendorLoading(false);

      if (data.success) setVendorDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onVendorDetailClose = () => {
    setVendorDetail(false);
    setVendorDetailsData(undefined);
  };

  const onSellerDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setSellerDetail(true);

    try {
      setIsSellerLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_seller_detail/${sellerId}`,
        config
      );
      setIsSellerLoading(false);

      if (data.success) setSellerDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onSellerDetailClose = () => {
    setSellerDetail(false);
    setSellerDetailsData(undefined);
  };
  return (
    <>
      <tr className="admin-invoice-cards">
      <td><a href={url} target="_blank">{invoiceName}</a></td>
        <td>₦{price}</td>
        <td>
          <span
            className="admin-indicator pending"
            style={{ display: status === "pending" ? "flex" : "none" }}
          >
            Pending
          </span>

          <span
            className="admin-indicator approved"
            style={{ display: status === "approved" ? "flex" : "none" }}
          >
            Approved
          </span>

          <span
            className="admin-indicator sold"
            style={{ display: status === "sold" ? "flex" : "none" }}
          >
            Sold
          </span>

          <span
            className="admin-indicator closed"
            style={{ display: status === "closed" ? "flex" : "none" }}
          >
            Closed
          </span>

          <span
            className="admin-indicator rejected"
            style={{ display: status === "rejected" ? "flex" : "none" }}
          >
            Rejected
          </span>

          <span
            className="admin-indicator due"
            style={{ display: status === "due" ? "flex" : "none" }}
          >
            Due
          </span>
        </td>
        <td onClick={onSellerDetailOpen}>{seller}</td>
        <td onClick={onVendorDetailOpen}>{vendor}</td>
        <td>
          {status === "pending" ? (
            <button
              className="admin-invoice-btn active-admin-btn"
              onClick={onApproveDetailOpen}
            >
              Detail
            </button>
          ) : status === "due" ? (
            <button
              className="admin-invoice-btn active-admin-btn"
              onClick={onDetailOpen}
            >
              Notify
            </button>
          ) : status === "sold" || "closed" || "approved" ? (
            <button
              className="admin-invoice-btn active-admin-btn"
              onClick={onDetailOpen}
            >
              Detail
            </button>
          ) : (
            <button disabled className="admin-invoice-btn inactive-admin-btn">
              Rejected
            </button>
          )}
        </td>
      </tr>

      {/* Approval-Button */}
      <AdminInvoiceDetailApprovalModal
        onClose={onApproveDetailClose}
        invoiceName={detailsData?.invoice_details?.[0]?.["invoice_name "]}
        vendorName={detailsData?.invoice_details?.[0]?.vendor_name}
        sellerName={detailsData?.invoice_details?.[0]?.seller_name}
        bidderNumber={detailsData?.invoice_details?.[0]?.no_of_bidders}
        invoiceAmount={formatAmount(
          detailsData?.invoice_details?.[0]?.invoice_amount
        )}
        maxPayable={formatAmount(
          detailsData?.invoice_details?.[0]?.payable_amount_by_investor
        )}
        vendorContact={detailsData?.invoice_details?.[0]?.vendor_contact_name}
        credit={detailsData?.invoice_details?.[0]?.seller_credit_score}
        dueDate={detailsData?.invoice_details?.[0]?.due_date}
        dueTime={detailsData?.invoice_details?.[0]?.bid_close_time}
        id={detailsData?.invoice_details?.[0]?.id}
        loading={isLoading}
        modal={approveDetail}
        loadData={loadData}
        setterFalse={onApproveDetailClose}
      />

      {/*Deactivate ===== Detail-Button */}
      <AdminInvoiceDetailNotifyModal
        onClose={onDetailClose}
        modal={detailNotify}
        invoiceAmount={formatAmount(
          detailsData?.invoice_details[0].invoice_amount
        )}
        invoiceDate={moment(detailsData?.invoice_details[0].due_date).format(
          "MMM Do, YYYY"
        )}
        id={detailsData?.invoice_details[0].id}
        loadData={loadData}
        invoiceName={invoiceName}
        vendorName={vendor}
        sellerName={seller}
        status={status}
        countDown={detailsData?.invoice_details[0].bid_close_time}
        bidders={detailsData?.bidders}
        invoiceURL={url}
        loading={isLoading}
      />

      <AdminSellerDetailModal
        onClose={onSellerDetailClose}
        modal={sellerDetail}
        sellerAddress={sellerDetailsData?.address}
        sellerName={sellerDetailsData?.companyName}
        typeBusiness={sellerDetailsData?.business_type}
        annualRevenue={sellerDetailsData?.revenue}
        phoneNumber={sellerDetailsData?.phone}
        country={sellerDetailsData?.country}
        emailAddress={sellerDetailsData?.email}
        creditScore={sellerDetailsData?.credit_score}
        numberStaff={sellerDetailsData?.staffNo}
        ninNumber={sellerDetailsData?.nin}
        bankVerificationNumber={sellerDetailsData?.bvn}
        cacNumber={sellerDetailsData?.cac}
        bankName={sellerDetailsData?.bank}
        accountName={sellerDetailsData?.account_name}
        accountNumber={sellerDetailsData?.account_no}
        memoNumber={sellerDetailsData?.memo_id}
        femaleCofounder={sellerDetailsData?.femaleCofounder}
        loading={isSellerLoading}
        cacUrl={sellerDetailsData?.cac_url}
      />

      <AdminVendorDetailModal
        onClose={onVendorDetailClose}
        modal={vendorDetail}
        vendorName={
          vendorDetailsData?.account_name ?? vendorDetailsData?.companyName
        }
        creditScore={vendorDetailsData?.credit_score}
        vendorPhone={vendorDetailsData?.phone}
        emailAddress={vendorDetailsData?.email}
        accountNumber={vendorDetailsData?.account_no}
        bankName={vendorDetailsData?.bank}
        memoNumber={vendorDetailsData?.memo_id}
        vendorBalance={formatAmount(vendorDetailsData?.wallet_balance)}
        accountName={vendorDetailsData?.account_name}
        loading={isVendorLoading}
        verified={vendorDetailsData?.verified}
      />
    </>
  );
};

export default AdminInvoiceCard;
