import React, { useState } from "react";
import { SearchBlackIcon, SearchWhiteIcon } from "../../icons/index";
import { FilterPlus } from "../../icons";
import "../../../styles/investor/investor-invoice-styles/search-filter.scss";
import { AnimatePresence, motion } from "framer-motion";
import FilterOptionDropdown from "../../seller-component/invoice/FilterOptionDropdown";
import { FadeTransition } from "../../FadeTransition";
import MobileFilterOptionDropdown from "../../seller-component/invoice/MobileFilterOptionDropdown";

const InvoiceSearchFilter = (props) => {
  const { selected, setSelected, option, handleSubmit, onInputChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchAppear, setSearchAppear] = useState(false);

  const toggleSearch = () => {
    setSearchAppear(!searchAppear);
  };

  function toggler() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="filter-container">
      {/* {!searchAppear && (
        <span className="search-bg" onClick={toggleSearch}>
          <SearchBlackIcon />
        </span>
      )} */}
      {/* <form onSubmit={handleSubmit}> */}
      <AnimatePresence>
        {searchAppear && (
          <motion.input
            className="search-input"
            initial={{ width: 0 }}
            animate={{ width: "90%", maxWidth: 240 }}
            exit={{ width: 0 }}
            transition={{ type: "just" }}
            onChange={onInputChange}
          />
        )}
      </AnimatePresence>
      {/* {isOpen && (
            <div className="search-dropdown-content">
              {option.map((option) => (
                <div
                  onClick={() => {
                    setSelected(option);
                    setIsOpen(false);
                  }}
                  className="search-dropdown-item"
                >
                  {option}
                </div>
              ))}
            </div>
          )} */}
      <span
        className={`search-bg${searchAppear ? " green" : ""}`}
        onClick={toggleSearch}
      >
        <SearchWhiteIcon fill={searchAppear ? "#fff" : "#000"} />
      </span>
      {/* </form> */}

      <AnimatePresence>
        {searchAppear && (
          <FadeTransition>
            <FilterOptionDropdown
              option={option}
              selected={selected}
              setSelected={setSelected}
            />

            <MobileFilterOptionDropdown
              option={option}
              selected={selected}
              setSelected={setSelected}
            />
          </FadeTransition>
        )}
      </AnimatePresence>
    </div>
  );
};

export default InvoiceSearchFilter;
