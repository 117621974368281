import React from 'react'

export const TotalBidCardIcon = () => {
  return (
    <svg 
        width="50" 
        height="50" 
        viewBox="0 0 50 50" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >
    <rect 
        width="50" 
        height="50" 
        rx="8" 
        fill="#F3F3F3"
    />
    <path 
        d="M21.8193 29.8535H21.8224V30.9363C21.8224 31.1116 21.8668 31.2777 21.9331 31.435H21.8193C21.2777 31.435 20.8359 31.0807 20.8359 30.6444C20.8359 30.2086 21.2777 29.8535 21.8193 29.8535V29.8535Z" 
        fill="#593FBB"
    />
    <path 
        d="M20.8359 32.8318C20.8359 32.3957 21.2774 32.04 21.8193 32.04H22.4049C22.7507 32.3112 23.221 32.4798 23.7407 32.4798C24.2599 32.4798 24.7306 32.3118 25.0765 32.04H26.129C26.6706 32.04 27.1116 32.3955 27.1116 32.8318C27.1116 33.2679 26.6709 33.6223 26.129 33.6223H21.8168C21.2777 33.6221 20.836 33.2679 20.836 32.8318H20.8359Z" 
        fill="#593FBB"
    />
    <path 
        d="M25.5547 31.4355C25.6218 31.2777 25.6654 31.1121 25.6654 30.9369V30.9229C25.7913 31.0981 25.92 31.2698 26.0625 31.4361H25.5547L25.5547 31.4355Z" 
        fill="#593FBB"
    />
    <path 
        d="M26.1315 34.2295C26.6731 34.2295 27.1141 34.5849 27.1141 35.0213C27.1141 35.4573 26.6734 35.8118 26.1315 35.8118H21.8193C21.2777 35.8118 20.8359 35.4575 20.8359 35.0213C20.8359 34.5852 21.2774 34.2295 21.8193 34.2295H26.1315Z" 
        fill="#593FBB"
    />
    <path 
        d="M20.8359 37.2088C20.8359 36.7727 21.2774 36.417 21.8193 36.417H26.1315C26.6731 36.417 27.1141 36.7724 27.1141 37.2088C27.1141 37.6448 26.6734 37.9993 26.1315 37.9993H21.8193C21.2777 37.9991 20.8359 37.6448 20.8359 37.2088Z" 
        fill="#593FBB"
    />
    <path 
        d="M35.9992 37.0948H33.9022C33.1805 37.0948 32.4804 37.2131 31.8227 37.4491L31.4211 37.5923C30.6631 37.861 29.86 37.9987 29.0311 37.9987H27.5618C27.7548 37.7736 27.8686 37.5025 27.8686 37.2082C27.8686 36.763 27.6034 36.3679 27.1996 36.112C27.6035 35.8561 27.8686 35.4652 27.8686 35.0182C27.8686 34.573 27.6034 34.1792 27.1996 33.9232C27.5606 33.6957 27.8007 33.3578 27.8504 32.972C28.8164 33.5913 29.9352 34.0634 31.1701 34.3248C31.2016 34.3321 31.2342 34.3358 31.2657 34.3358C31.433 34.3358 31.5851 34.246 31.629 34.1095C31.6817 33.947 31.5619 33.7813 31.3616 33.7388C28.6928 33.1733 26.5681 31.587 25.6624 29.4899V29.3002C25.6624 29.1334 25.4937 28.9969 25.2858 28.9969C25.0779 28.9969 24.9092 29.1334 24.9092 29.3002V30.935C24.9092 31.4531 24.3862 31.8734 23.7436 31.8734C23.1008 31.8734 22.5781 31.453 22.5781 30.935V28.1196C22.5781 27.4979 23.2058 26.9932 23.9765 26.9932H27.4168C28.3774 26.9932 29.2326 27.4555 29.5964 28.1718C30.4802 29.9111 32.5591 31.0357 34.8932 31.0357H35.9984V37.0949L35.9992 37.0948Z" 
        fill="#593FBB"
    />
    <path 
        d="M31.7267 12H17.4224C16.0873 12 15 12.8748 15 13.9503V22.2787C15 23.3529 16.0865 24.2272 17.4224 24.2272H24.0853V26.2449H25.0649V24.2272H31.7272C33.0622 24.2272 34.1495 23.3536 34.1495 22.2787V13.9503C34.1495 12.8746 33.0622 12 31.7272 12H31.7267ZM21.7209 19.0113C21.7209 19.2284 21.5017 19.4049 21.2313 19.4049H19.0024C18.732 19.4049 18.5128 19.2284 18.5128 19.0113V17.2168C18.5128 16.999 18.732 16.8225 19.0024 16.8225H21.2313C21.5017 16.8225 21.7209 16.999 21.7209 17.2168V19.0113ZM26.1791 19.0113C26.1791 19.2284 25.9599 19.4049 25.6895 19.4049H23.4606C23.1902 19.4049 22.9709 19.2284 22.9709 19.0113V17.2168C22.9709 16.999 23.1902 16.8225 23.4606 16.8225H25.6895C25.9599 16.8225 26.1791 16.999 26.1791 17.2168V19.0113ZM30.6366 19.0113C30.6366 19.2284 30.4174 19.4049 30.1469 19.4049H27.9181C27.6476 19.4049 27.4284 19.2284 27.4284 19.0113V17.2168C27.4284 16.999 27.6476 16.8225 27.9181 16.8225H30.1469C30.4174 16.8225 30.6366 16.999 30.6366 17.2168V19.0113Z" 
        fill="#593FBB"
    />
    </svg>

  )
}
