import React, {useState} from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/investor/investor-dashboard-styles/mobile-deposit-withdraw-modal.scss";
import InvestorDepositModalCard from "./InvestorDepositModalCard";
import InvestorWithdrawModalCard from "./investorwithdrawModalCard";

export const MobileDepositWithdrawModal = (props) => {
  const { controlModal, modal } = props;
  const[show,setShow]=useState(false)
  const[appear,setAppear]=useState(false)

  const controlAppear =()=>{
    setAppear(!appear)
  }

  const controlShow =()=>{
    setShow(!show)
  }


  return (
    <>
      {modal && (
        <div className="in-mobile-wd-modal">
          <div onClick={controlModal} className="in-mobile-wd-overlay"></div>
          <div className="investor-mobile-wd-modal-card">
            <span onClick={controlModal} className="close-modal-presser">
              <CloseIcon />
            </span>
            <div className="in-deposit-withdraw-container">
              <button onClick={controlAppear} className="investor-withdrawal-presser">
                Withdraw
              </button>
              <button
                onClick={controlShow}
                className="investor-deposit-presser"
              >
                Deposit
              </button>
            </div>
          </div>
        </div>
      )}
    <InvestorDepositModalCard
      controlModal={controlShow}
      modal={show}
    
    />

    <InvestorWithdrawModalCard
      controlModal={controlAppear}
      modal={appear}
    
    />
    </>
  );
};