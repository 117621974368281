import axios from "axios";
import { AnimateSharedLayout, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import AdminUserInvestorVerificationCard from "../../components/admin-component/admin-user/AdminUserInvestorVerificationCard";
import AdminUserSellerVerificationCard from "../../components/admin-component/admin-user/AdminUserSellerVerificationCard";
import AdminUserVendorVerificationCard from "../../components/admin-component/admin-user/AdminUserVendorVerificationCard";
import { AdminSearchFilter } from "../../components/admin-component/AdminSearchFilter";
import { AdminStatusBar } from "../../components/admin-component/AdminStatusBar";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { NoInvoiceIcon, SearchBlackIcon } from "../../components/icons";
import { BACKEND_URL } from "../../config";
import "../../styles/admin/admin-users/admin-user-index.scss";
import { formatAmount } from "../../util/formatAmount";

const filterStatus = ["All", "Verified", "Unverified"];
const users = ["investor", "seller", "vendor"];

const AdminUserPage = () => {
  const [selector, setSelector] = useState("All");
  const [pageSetter, setPageSetter] = useState("investor");
  const [searchFilter, setSearchFilter] = useState("");

  const [usersData, setUsersData] = useState();
  const [expire, setExpire] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);

  const [activeUser, setActiveUser] = useState("all_investors");

  const loadData = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_users/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setUsersData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setActiveUser(`${selector.toLowerCase()}_${pageSetter}s`);
  }, [selector, pageSetter]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");

      try {
        setIsContentLoading(true);

        const body = {
          search: searchFilter,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/admin_user_search`,
          body,
          config
        );
        setIsContentLoading(false);
        console.log(data);

        if (data.status === 202 && data.message === "Signature has expired")
          setExpire(true);

        if (data.success) setUsersData(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [searchFilter]);

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired isAdmin />;

  return (
    <>
      <AdminStatusBar
        //  activated={dashboardData?.user_details.verified}
        activated={true}
        loadData={loadData}
      />
      <div className="admin-user-page-container">
        <div className="admin-user-first-half-filter">
          <span className="admin-user-filter admin-input-first mobile-hide">
            <div className="admin-user-filter-page mobile-hide">
              <span className="admin-user-page-holder">
                <AnimateSharedLayout>
                  {users.map((user, index) => (
                    <button
                      key={index}
                      onClick={() => setPageSetter(user)}
                      className={
                        pageSetter === user
                          ? "admin-user-page-change-btn admin-active-page"
                          : "admin-user-page-change-btn"
                      }
                    >
                      {pageSetter === user && (
                        <motion.span layoutId="user-active" />
                      )}
                      {user.charAt(0).toUpperCase() + user.slice(1)}s
                    </button>
                  ))}
                </AnimateSharedLayout>
              </span>
            </div>
            <div className="admin-user-filter-btn-holder">
              <AnimateSharedLayout>
                {filterStatus?.map((filter, i) => (
                  <button
                    key={i}
                    onClick={() => setSelector(filter)}
                    className={
                      selector === filter
                        ? "admin-user-btn admin-user-active-status"
                        : "admin-user-btn"
                    }
                  >
                    {selector === filter && (
                      <motion.span layoutId="filter-active" />
                    )}
                    {filter}
                  </button>
                ))}
              </AnimateSharedLayout>
            </div>
            <div className="admin-user-search-filter-container">
              <AdminSearchFilter
                search={searchFilter}
                setSearch={setSearchFilter}
              />
            </div>
          </span>
        </div>

        {pageSetter === "investor" && (
          <>
            <div className="admin-user-second-half mobile-hide">
              <table className="admin-user-page-table">
                <tbody>
                  <tr className="admin-user-investor-page-header table">
                    <th>Investor Name</th>
                    <th>Country</th>
                    <th>Email Address</th>
                    <th>Wallet Balance</th>
                    {/* <th>Investor Phone</th> */}
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="admin-user-scroll">
              {isContentLoading ? (
                <PageLoader isSub />
              ) : (
                <>
                  {usersData?.[activeUser]?.length ? (
                    <div className="admin-user-data-holder mobile-hide">
                      {usersData?.[activeUser]?.map((detail, index) => ( 
                        <AdminUserInvestorVerificationCard
                          investorName={detail?.investor_name}
                          country={detail?.country ?? "Nil"}
                          emailAddress={detail?.email}
                          // investorPhone={detail.investorPhone}
                          walletBalance={formatAmount(detail?.wallet_balance)}
                          isVerified={detail?.isVerified}
                          key={detail?.investor_id}
                          id={detail?.investor_id}
                          loadData={loadData}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="empty-invoice">
                      <NoInvoiceIcon />
                      <p>You currently do not have any Invoices </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {pageSetter === "seller" && (
          <>
            <div className="admin-user-second-half mobile-hide">
              <table className="admin-user-page-table">
                <tbody>
                  <tr className="admin-user-page-header table">
                    <th>Seller Name</th>
                    <th>Business Type</th>
                    <th>Credit Score</th>
                    <th>Wallet Balance</th>
                    <th>Female Cofounder</th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="admin-user-scroll">
              {usersData?.[activeUser]?.length ? (
                <div className="admin-user-data-holder mobile-hide">
                  {usersData?.[activeUser]?.map((detail, index) => (
                    <AdminUserSellerVerificationCard
                      sellerName={detail?.seller_name}
                      businessType={detail?.business_type ?? "Nil"}
                      creditScore={detail?.credit_score}
                      walletBalance={formatAmount(detail?.wallet_balance)}
                      femaleCofounder={detail?.femaleCofounder ? "YES" : "NO"}
                      isVerified={detail?.isVerified}
                      key={detail?.seller_id}
                      id={detail?.seller_id}
                      loadData={loadData}
                    />
                  ))}
                </div>
              ) : (
                <div className="empty-invoice">
                  <NoInvoiceIcon />
                  <p>You currently do not have any Invoices </p>
                </div>
              )}
            </div>
          </>
        )}

        {pageSetter === "vendor" && (
          <>
            <div className="admin-user-second-half mobile-hide">
              <table className="admin-user-page-table">
                <tbody>
                  <tr className="admin-user-vendor-page-header table">
                    <th>Vendor Name</th>
                    <th>Credit Score</th>
                    <th>Email Address</th>
                    <th>Vendor Phone</th>
                    {/* <th>Due Date</th> */}
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="admin-user-scroll">
              {usersData?.[activeUser]?.length ? (
                <div className="admin-user-data-holder mobile-hide">
                  {usersData?.[activeUser]?.map((detail, index) => (
                    <AdminUserVendorVerificationCard
                      vendorName={detail?.vendor_name}
                      emailAddress={detail?.email}
                      creditScore={detail?.credit_score}
                      vendorPhone={detail?.phone}
                      // dueDate={detail.dueDate}
                      isVerified={detail?.isVerified}
                      key={detail?.vendor_id}
                      id={detail?.vendor_id}
                      loadData={loadData}
                    />
                  ))}
                </div>
              ) : (
                <div className="empty-invoice">
                  <NoInvoiceIcon />
                  <p>You currently do not have any Invoices </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminUserPage;
