import React, { useState } from "react";
import "../../../styles/vendor/vendor-index/vendor-invoice-card.scss";
import { VendorConfirmationModal } from "../vendor-invoice/VendorConfirmationModal";
import VendorPayNowModalCard from "../vendor-invoice/VendorPayNowModalCard";

export const MobileVendorInvoiceCard = (props) => {
  const { price, dueDate, seller, status, invoiceName, url, id, loadData } =
    props;

  const [mobileConfirm, setMobileConfirm] = useState(false);
  const [payNow, setPayNow] = useState(false);

  const toggleConfirmationMobile = () => {
    setMobileConfirm(!mobileConfirm);
  };

  const togglePayNowCard = () => {
    setPayNow(!payNow);
  };

  const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  const sellerInvoiceName = seller.substring(0, 17) + "...";
  return (
    <div className="mobile-vendor-invoice-container">
      <div className="mobile-first-card-vendor" style={{ width: "70%" }}>
        <h2 className="mobile-invoice-name-vendor">{shortInvoiceName}</h2>
        <h4 className="mobile-invoice-top-vendor">
          Seller:
          <span className="mobile-invoice-amount-vendor">
            {sellerInvoiceName}
          </span>
        </h4>
        <h4>
          <span
            className="indicator pending"
            style={{ display: status === "pending" ? "flex" : "none" }}
          >
            Pending
          </span>

          <span
            className="indicator approved"
            style={{ display: status === "approved" ? "flex" : "none" }}
          >
            Approved
          </span>

          <span
            className="indicator sold"
            style={{ display: status === "sold" ? "flex" : "none" }}
          >
            Sold
          </span>

          <span
            className="indicator closed"
            style={{ display: status === "closed" ? "flex" : "none" }}
          >
            Closed
          </span>

          <span
            className="indicator due"
            style={{ display: status === "due" ? "flex" : "none" }}
          >
            Due
          </span>
        </h4>
      </div>
      <div className="mobile-second-card-vendor" style={{ width: "30%" }}>
        <h4 className="mobile-card-your-vendor">
          <span className="big-vendor">{price}</span>
        </h4>
        <h2 className="mobile-invoice-date-vendor">{dueDate}</h2>
        <h4>
          {status === "pending" ? (
            <button
              className="vendor-invoice-btn active-vendor-btn"
              onClick={toggleConfirmationMobile}
            >
              Confirm
            </button>
          ) : status === "due" ? (
            <button
              className="vendor-invoice-btn active-vendor-btn"
              onClick={togglePayNowCard}
            >
              Pay Now
            </button>
          ) : (
            <button disabled className="vendor-invoice-btn inactive-vendor-btn">
              Confirm
            </button>
          )}
        </h4>
      </div>

      <VendorConfirmationModal
        toggleChange={toggleConfirmationMobile}
        modal={mobileConfirm}
        url={url}
        id={id}
        loadData={loadData}
      />

      <VendorPayNowModalCard
        controlModal={togglePayNowCard}
        modal={payNow}
        price={price}
      />
    </div>
  );
};
