import { useEffect, useState } from "react";
import "../../styles/seller/invoice/invoice-pages.scss";
import { ActivationStatusBar } from "../../components/seller-component/ActivationStatusBar";
import InvoiceCard from "../../components/seller-component/invoice/InvoiceCard";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import FilterOptionDropdown from "../../components/seller-component/invoice/FilterOptionDropdown";
import MobileFilterOptionDropdown from "../../components/seller-component/invoice/MobileFilterOptionDropdown";
import { MobileInvoiceCard } from "../../components/seller-component/invoice/MobileInvoiceCard";
import { PlusIcon } from "../../components/icons/index";
import { MobileUpLoaderWithdrawModal } from "../../components/seller-component/dashboard-component";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { convertStatus } from "../../util/convertStatus";
import { convertDate } from "../../util/convertDate";
import { formatAmount } from "../../util/formatAmount";
import { PageLoader } from "../../components/general-component/PageLoader";

const invoiceStatus = [
  "Pending Invoices",
  "Approved Invoices",
  "Sold Invoices",
  "Closed Invoices",
  "Rejected Invoices",
];
const invoiceDueDate = [
  "Today",
  "Last 7 Days",
  "Last 30 days",
  "Last 3 Months",
  "Last 6 Months",
];

const Invoice = () => {
  // const [activated, setActivated] = useState(false);
  const [filterStatus, setFilterStatus] = useState("Status");
  const [filterDate, setFilterDate] = useState("Due Date");
  const [modal, setModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState();

  const controlModal = () => {
    setModal(!modal);
  };

  // invoice uploader control
  const invoiceUploader = () => {
    console.log("where is invoice");
  };

  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };

  // change filter button style based on state
  const getStyle = () => {
    switch (filterStatus) {
      case "Pending Invoices":
        return { background: "#EAEBFF", border: "transparent" };

      case "Approved Invoices":
        return { background: "#E1FCFF", border: "transparent" };

      case "Sold Invoices":
        return { background: "#E0FFED", border: "transparent" };

      case "Closed Invoices":
        return { background: "#F7E1FF", border: "transparent" };

      case "Rejected Invoices":
        return { background: "#F6E0E0", border: "transparent" };

      default:
        return { background: "#fff", border: "1px solid #dadce0" };
    }
  };

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        if (filterStatus === "Status" && filterDate === "Due Date") {
          const { data } = await axios.get(
            `${BACKEND_URL}/seller_invoice_page/${token}`,
            config
          );
          console.log(data);
          setIsLoading(false);

          if (data.success) setInvoiceData(data);
        } else {
          const status = convertStatus(filterStatus);
          const date = convertDate(filterDate);
          const body = {
            status: status ?? "",
            date: date ?? "",
          };

          // if (status && date) {
          //   body = { status, date };
          // } else if (status && !date) {
          //   body = { status };
          // } else if (!status && date) {
          //   body = { date };
          // }

          const { data } = await axios.post(
            `${BACKEND_URL}/seller_invoice_search/${token}`,
            body,
            config
          );

          setIsLoading(false);

          if (data.success) setInvoiceData(data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [filterStatus, filterDate]);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <ActivationStatusBar
        activated={invoiceData?.user_details.verified}
        title="Invoices"
        pending={invoiceData?.user_details?.providedVerificationDetails}
      />
      <div className="invoice-container">
        <div className="first-half-filter">
          <span className="filter input-first mobile-hide">
            <p className="filter mobile-hide">Filters</p>
            <FilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              style={getStyle()}
              option={invoiceStatus}
            />
          </span>
          <span className="filter input-second mobile-hide">
            <FilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
            />
          </span>

          <span className=" mobile-input-filter window-hide">
            <MobileFilterOptionDropdown
              selected={filterStatus}
              setSelected={setFilterStatus}
              option={invoiceStatus}
              width="154px"
            />

            <MobileFilterOptionDropdown
              selected={filterDate}
              setSelected={setFilterDate}
              option={invoiceDueDate}
              width="154px"
            />
          </span>
        </div>

        {invoiceData && invoiceData.length ? (
          <div className="second-half mobile-hide">
            <table className="invoice-table">
              <tbody>
                <tr className="invoice-header table">
                  <th className="invoice-name">Invoice Name</th>
                  <th className="invoice-amount">Amount</th>
                  <th className="invoice-rate">Pay Rate</th>
                  <th className="invoice-payAmount">Pay Amount</th>
                  <th className="invoice-vendor">Vendor</th>
                  <th className="due-date">Status</th>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div></div>
        )}

        <div className="invoice-scroll">
          {/* {console.log(invoiceData, "inposds")} */}
          {invoiceData?.invoices && invoiceData?.invoices.length ? (
            <div className="invoice-data-holder mobile-hide">
              {invoiceData?.invoices.map((invoice, index) => (
                // console.log(invoice, index)
                <InvoiceCard
                  key={invoice["invoice_id "]}
                  id={invoice["invoice_id "]}
                  amount={formatAmount(invoice.invoice_amount)}
                  payRate={invoice.rate.toFixed(2)}
                  dueDate={invoice.due_date}
                  vendor={invoice.vendor}
                  payAmount={formatAmount(invoice.receivable_amount)}
                  invoiceName={invoice["invoice_name "]}
                  status={invoice.invoice_state}
                />
              ))}
            </div>
          ) : (
            <div className="empty-invoice">
              <InvoiceEmptyIcon />
              <p>You currently do not have any Invoices </p>
            </div>
          )}
          {invoiceData?.invoices && invoiceData?.invoices.length ? (
            <div className="window-hide">
              {invoiceData.invoices.map((invoice, index) => (
                <MobileInvoiceCard
                  key={invoice["invoice_id "]}
                  id={invoice["invoice_id "]}
                  amount={formatAmount(invoice.invoice_amount)}
                  payRate={invoice.rate.toFixed(2)}
                  dueDate={invoice.due_date}
                  vendor={invoice.vendor}
                  payAmount={formatAmount(invoice.receivable_amount)}
                  invoiceName={invoice["invoice_name "]}
                  status={invoice.invoice_state}
                />
              )
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="window-hide">
          <button
            onClick={controlModal}
            className="mobile-uploader-btn window-hide"
          >
            <PlusIcon />
          </button>

          <MobileUpLoaderWithdrawModal
            controlModal={controlModal}
            modal={modal}
            fundWithdrawal={fundWithdrawal}
            invoiceUploader={invoiceUploader}
          />
        </div>
      </div>
    </>
  );
};

export default Invoice;
