import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/selectioncard.module.scss";

const SelectionCard = ({ title, body, icon, leader }) => {
  return (
    <Link to={leader} className={styles.cardMain}>
      <span className="">{icon}</span>

      <h4 className={styles.cardHeader}>{title}</h4>

      <p className={styles.cardStatement}>{body}</p>
    </Link>
  );
};

export default SelectionCard;
