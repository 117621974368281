import axios from "axios";
import React, { useState, useEffect } from "react";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import {
  DownwardArrowIcon,
  PlusIcon,
  UpwardArrowIcon,
} from "../../components/icons";
import { MobileDepositWithdrawModal } from "../../components/investor-component/dashboard/MobileDepositWithdrawModal";
import { InvestorStatusBar } from "../../components/investor-component/InvestorStatusBar";
import InterestAmountCard from "../../components/investor-component/stats/InterestAmountCard";
import { MobileStatInvestmentInvoiceCard } from "../../components/investor-component/stats/MobileStatInvestmentInvoiceCard";
import StatCard from "../../components/investor-component/stats/StatCard";
import StatInvestmentInvoiceCard from "../../components/investor-component/stats/StatInvestmentInvoiceCard";
import TotalBidsCard from "../../components/investor-component/stats/TotalBidsCard";
import TotalInvestmentCard from "../../components/investor-component/stats/TotalInvestmentCard";
import { InvoiceEmptyIcon } from "../../components/seller-component/invoice/InvoiceEmptyIcon";
import { BACKEND_URL } from "../../config";
import "../../styles/investor/stats-styles/stat-index.scss";
import { formatAmount } from "../../util/formatAmount";

const invoiceCardData = [
  {
    amount: "150,000.00",
    payAmount: "₦150,000.00",
    dueDate: "48:11:35",
    yourStat: "4.5",
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    amount: "150,000.00",
    payAmount: "₦150,000.00",
    dueDate: "48:11:35",
    yourStat: "9.5",
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    amount: "150,000.00",
    payAmount: "₦150,000.00",
    dueDate: "48:11:35",
    yourStat: "4.5",
    invoiceName: "Road Project Supplies Invoice",
  },
];

const statCardData = [
  {
    header: "Total Investment",
    digit: "49,346,850.00",
    percent: 77,
  },
  {
    header: "Total Bids",
    digit: "112",
    percent: 77,
  },
  {
    header: "Interest Amount",
    digit: "693,850.00",
    percent: 77,
  },
];

const InvestorStats = () => {
  const [modal, setModal] = useState(false);
  const [high, setHigh] = useState(false);

  const [statData, setStatData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [expire, setExpire] = useState(false);
  const [empty, setEmpty] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  const toggleControl = () => {
    setHigh(!high);
  };

  const loadStats = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/investor_stats_page/${token}`,
        config
      );
      setIsLoading(false);
      

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.status === 202 && data.message === "division by zero")
        setEmpty(true);

      if (data.success) setStatData(data);
    } catch (error) {
      console.error(error);
    }
  };
  ;
  useEffect(() => {
    loadStats();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  if (empty)
    return (
      <div className="session-container">
        <h1>You currently do not have any data</h1>
      </div>
    );

  return (
    <>
      <section>
        <InvestorStatusBar
          ownerName={`Stats`}
          activated={statData?.verified}
          pending={statData?.providedVerificationDetails}
          loadData={loadStats}
        />
      </section>
      <div className="stat-container">
        <div className="stat-first-half">
          <h1 className="first-half-header">Stats</h1>
          <div className="stat-card-holder">
            {/* {statCardData.map((stat, index) => (
              <StatCard
                key={index}
                header={stat.header}
                digit={stat.digit}
                percent={stat.percent}
              />
            ))} */}
            <TotalInvestmentCard
              amount={formatAmount(statData?.stat_details.totalInvestment)}
            />
            <TotalBidsCard
              bids={statData?.stat_details.totalBids}
              percent={Math.round(statData?.stat_details.totalBidsPercent)}
            />
            <InterestAmountCard
              interest={formatAmount(
                Math.abs(statData?.stat_details.accruedInterest)
              )}
              percent={Math.round(
                Math.abs(statData?.stat_details.accruedInterestPercent)
              )}
            />
          </div>
        </div>

        <div className="stat-second-half">
          <div className="stat-second-half-header mobile-hide">
            <span className="stat-filter mobile-hide">
              <p className="stat-filter mobile-hide">Open Investments</p>
            </span>
            <table className="stat-invoice-table">
              <tbody>
                <tr className="stat-invoice-header table">
                  <th className="invoice-name">Invoice Name</th>
                  <th className="stat-amount">Amount</th>
                  <th className="stat-your">Profit</th>
                  <th className="stat-pay">Pay Amount</th>
                  <th className="stat-time">Due Date</th>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className={
              high
                ? "stat-invoice-scroll scroll"
                : "stat-invoice-scroll-long scroll"
            }
          >
            {statData?.openInvestments.length ? (
              <div className="stat-invoice-data-holder mobile-hide">
                {statData?.openInvestments.map((invoice, index) => (
                  <StatInvestmentInvoiceCard
                    key={invoice?.id}
                    amount={formatAmount(invoice?.invoice_amount)}
                    payAmount={formatAmount(invoice?.payable_amount)}
                    profit={invoice?.invoice_amount - invoice?.payable_amount}
                    dueDate={invoice?.due_date}
                    yourBid={invoice?.user_bid}
                    invoiceName={invoice["invoice_name "]}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-invoice">
                <InvoiceEmptyIcon />
                <p>You currently do not have any Investment </p>
              </div>
            )}
            {statData?.openInvestments.length ? (
              <div className="window-hide">
                {statData?.openInvestments.map((invoice, index) => (
                  <MobileStatInvestmentInvoiceCard
                    key={invoice?.id}
                    amount={formatAmount(invoice?.invoice_amount)}
                    payAmount={formatAmount(invoice?.payable_amount)}
                    profit={invoice?.invoice_amount - invoice?.payable_amount}
                    dueDate={invoice?.due_date}
                    yourBid={invoice?.user_bid}
                    invoiceName={invoice["invoice_name "]}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-invoice">
                <InvoiceEmptyIcon />
                <p>You currently do not have any Investment </p>
              </div>
            )}
          </div>
          {high ? (
            <button className="stat-control-btn" onClick={toggleControl}>
              Show More
              <span className="sizer">
                <DownwardArrowIcon color="#3FBB97" />
              </span>
            </button>
          ) : (
            <button className="stat-control-btn" onClick={toggleControl}>
              Show Less
              <span className="sizer">
                <UpwardArrowIcon color="#3FBB97" />
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="window-hide">
        <button
          onClick={controlModal}
          className="investor-mobile-uploader-btn window-hide"
        >
          <PlusIcon />
        </button>

        <MobileDepositWithdrawModal controlModal={controlModal} modal={modal} />
      </div>
    </>
  );
};

export default InvestorStats;
