import React from 'react'

export const FiatDepositIcon = ({svgColor}) => {
  return (
    <svg 
            width="48" 
            height="40" 
            viewBox="0 0 48 40" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
    >
      <path 
            d="M38.2229 33.8475C37.9822 34.0568 37.6469 34.0152 37.3434 34.0152H35.8252C34.8095 34.0152 33.804 34.0258 32.7883 34.0152C32.38 34.0046 31.8983 33.8267 32.0447 33.303C32.1598 32.8632 32.5893 32.8946 32.9454 32.8946H36.0765H37.5633C37.6469 32.8946 37.7309 32.8841 37.8145 32.8946C38.3065 32.9469 38.5578 33.46 38.2229 33.8475C37.7831 34.35 38.5162 35.0936 38.9665 34.5911C39.6575 33.8059 39.5633 32.5805 38.5998 32.0465C38.1703 31.8058 37.6889 31.8475 37.2174 31.8475H33.1755C32.6937 31.8475 32.2226 31.8161 31.7828 32.0673C30.8613 32.6013 30.7357 33.9103 31.5001 34.633C31.9296 35.0414 32.4636 35.0626 33.0082 35.0626H34.9454H37.2072C37.8668 35.0626 38.4219 35.0626 38.9559 34.5914C39.469 34.1407 38.7254 33.4077 38.2229 33.8474L38.2229 33.8475Z" 
            fill={svgColor}
      />
      <path 
            d="M28.2229 33.8475C27.9822 34.0568 27.6469 34.0152 27.3434 34.0152H25.8252C24.8095 34.0152 23.804 34.0258 22.7883 34.0152C22.38 34.0046 21.8983 33.8267 22.0447 33.303C22.1598 32.8632 22.5893 32.8946 22.9454 32.8946H26.0765H27.5633C27.6469 32.8946 27.7309 32.8841 27.8145 32.8946C28.3065 32.9469 28.5684 33.46 28.2229 33.8475C27.7831 34.35 28.5162 35.0936 28.9665 34.5911C29.6575 33.8059 29.5633 32.5805 28.5998 32.0465C28.1703 31.8058 27.6889 31.8475 27.2174 31.8475H23.1755C22.6937 31.8475 22.2226 31.8161 21.7828 32.0673C20.8613 32.6013 20.7357 33.9103 21.5001 34.633C21.9296 35.0311 22.4636 35.0626 23.0082 35.0626H24.9454H27.2072C27.8668 35.0626 28.4219 35.0626 28.9559 34.5914C29.469 34.1407 28.7254 33.4077 28.2229 33.8474L28.2229 33.8475Z" 
            fill={svgColor}
      />
      <path 
            d="M28.2229 33.8475C27.9822 34.0568 27.6469 34.0152 27.3434 34.0152H25.8252C24.8095 34.0152 23.804 34.0258 22.7883 34.0152C22.38 34.0046 21.8983 33.8267 22.0447 33.303C22.1598 32.8632 22.5893 32.8946 22.9454 32.8946H26.0765H27.5633C27.6469 32.8946 27.7309 32.8841 27.8145 32.8946C28.3065 32.9469 28.5684 33.46 28.2229 33.8475C27.7831 34.35 28.5162 35.0936 28.9665 34.5911C29.6575 33.8059 29.5633 32.5805 28.5998 32.0465C28.1703 31.8058 27.6889 31.8475 27.2174 31.8475H23.1755C22.6937 31.8475 22.2226 31.8161 21.7828 32.0673C20.8613 32.6013 20.7357 33.9103 21.5001 34.633C21.9296 35.0311 22.4636 35.0626 23.0082 35.0626H24.9454H27.2072C27.8668 35.0626 28.4219 35.0626 28.9559 34.5914C29.469 34.1407 28.7254 33.4077 28.2229 33.8474L28.2229 33.8475Z" 
            fill={svgColor}
      />
      <path 
            d="M19.2229 33.8475C18.9822 34.0568 18.6469 34.0152 18.3434 34.0152H16.8252C15.8095 34.0152 14.804 34.0258 13.7883 34.0152C13.38 34.0046 12.8983 33.8267 13.0447 33.303C13.1598 32.8632 13.5893 32.8946 13.9454 32.8946H17.0765H18.5633C18.6469 32.8946 18.7309 32.8841 18.8145 32.8946C19.3171 32.9469 19.5684 33.46 19.2229 33.8475C18.7831 34.35 19.5162 35.0936 19.9665 34.5911C20.6575 33.8059 20.5633 32.5805 19.5998 32.0465C19.1703 31.8058 18.6889 31.8475 18.2174 31.8475H14.1755C13.6937 31.8475 13.2226 31.8161 12.7828 32.0673C11.8613 32.6013 11.7357 33.9103 12.5001 34.633C12.9296 35.0311 13.4636 35.0626 14.0082 35.0626H15.9454H18.2072C18.8668 35.0626 19.4219 35.0626 19.9559 34.5914C20.4796 34.1407 19.736 33.4077 19.2229 33.8474L19.2229 33.8475Z" 
            fill={svgColor}
      />
      <path 
            d="M19.2229 33.8475C18.9822 34.0568 18.6469 34.0152 18.3434 34.0152H16.8252C15.8095 34.0152 14.804 34.0258 13.7883 34.0152C13.38 34.0046 12.8983 33.8267 13.0447 33.303C13.1598 32.8632 13.5893 32.8946 13.9454 32.8946H17.0765H18.5633C18.6469 32.8946 18.7309 32.8841 18.8145 32.8946C19.3171 32.9469 19.5684 33.46 19.2229 33.8475C18.7831 34.35 19.5162 35.0936 19.9665 34.5911C20.6575 33.8059 20.5633 32.5805 19.5998 32.0465C19.1703 31.8058 18.6889 31.8475 18.2174 31.8475H14.1755C13.6937 31.8475 13.2226 31.8161 12.7828 32.0673C11.8613 32.6013 11.7357 33.9103 12.5001 34.633C12.9296 35.0311 13.4636 35.0626 14.0082 35.0626H15.9454H18.2072C18.8668 35.0626 19.4219 35.0626 19.9559 34.5914C20.4796 34.1407 19.736 33.4077 19.2229 33.8474L19.2229 33.8475Z" 
            fill={svgColor}
      />
      <path 
            d="M10.1168 25.4798C10.0854 25.8359 9.69791 25.7731 9.44663 25.7731H8.10623H4.81807C4.57739 25.7731 4.29433 25.8151 4.05364 25.7731C3.72895 25.7103 3.75014 25.4696 3.75014 25.2077V22.2336V20.6313C3.75014 20.3486 3.70814 20.0239 3.75014 19.7518C3.83378 19.2073 4.97544 19.4271 5.38384 19.4271H8.69285C9.03836 19.4271 9.37364 19.4063 9.71919 19.4271C9.98106 19.4377 10.0961 19.5634 10.1067 19.8147C10.2115 21.689 10.1169 23.5948 10.1169 25.4797C10.1169 26.1499 11.164 26.1499 11.164 25.4797C11.164 23.5842 11.2268 21.6678 11.164 19.7727C11.1432 19.1131 10.7765 18.5371 10.0957 18.3903C9.81301 18.3275 9.48832 18.3589 9.19504 18.3589H5.47735C4.59788 18.3589 3.3725 18.139 2.86995 19.0605C2.68149 19.406 2.7129 19.7935 2.7129 20.1704V21.8147V25.2285C2.7129 25.7208 2.79654 26.1708 3.19463 26.5061C3.54014 26.7993 3.94847 26.8202 4.36739 26.8202H8.28377C9.41491 26.8202 11.0167 27.0609 11.1531 25.4798C11.2166 24.8096 10.1694 24.8198 10.1169 25.4798L10.1168 25.4798Z" 
            fill={svgColor}
      />
      <path 
            d="M7.69853 32.8946C7.61489 32.8841 7.78216 32.8946 7.69853 32.8946C7.69853 32.8946 10.1382 35.0936 10.5886 34.5911C11.2796 33.8059 11.1853 32.5805 10.2219 32.0465C9.79234 31.8058 9.31098 31.8475 8.83948 31.8475H4.79753C4.3158 31.8475 3.84469 31.8161 3.40487 32.0673C2.48339 32.6013 2.35778 33.9103 3.12218 34.633C3.5517 35.0414 4.08569 35.0626 4.63023 35.0626H6.56743H8.82925C9.48886 35.0626 10.044 35.0626 10.578 34.5914C11.0911 34.1407 7.69853 32.8946 7.69853 32.8946Z" 
            fill={svgColor}
      />
      <path 
            d="M17.0765 32.8946C16.9928 32.8841 17.1601 32.8946 17.0765 32.8946C17.0765 32.8946 19.5162 35.0936 19.9665 34.5911C20.6575 33.8059 20.5633 32.5805 19.5998 32.0465C19.1703 31.8058 18.6889 31.8475 18.2174 31.8475H14.1755C13.6937 31.8475 13.2226 31.8161 12.7828 32.0673C11.8613 32.6013 11.7357 33.9103 12.5001 34.633C12.9296 35.0414 13.4636 35.0626 14.0082 35.0626H15.9454H18.2072C18.8668 35.0626 19.4219 35.0626 19.9559 34.5914C20.469 34.1407 17.0765 32.8946 17.0765 32.8946Z" 
            fill={svgColor}
      />
      <path 
            d="M26.0765 32.8946C25.9928 32.8841 26.1601 32.8946 26.0765 32.8946C26.0765 32.8946 28.5162 35.0936 28.9665 34.5911C29.6575 33.8059 29.5633 32.5805 28.5998 32.0465C28.1703 31.8058 27.6889 31.8475 27.2174 31.8475H23.1755C22.6937 31.8475 22.2226 31.8161 21.7828 32.0673C20.8613 32.6013 20.7357 33.9103 21.5001 34.633C21.9296 35.0414 22.4636 35.0626 23.0082 35.0626H24.9454H27.2072C27.8668 35.0626 28.4219 35.0626 28.9559 34.5914C29.469 34.1407 26.0765 32.8946 26.0765 32.8946Z" 
            fill={svgColor}
      />
      <path 
            d="M36.0765 32.8946C35.9928 32.8841 36.1601 32.8946 36.0765 32.8946C36.0765 32.8946 38.5162 35.0936 38.9665 34.5911C39.6575 33.8059 39.5633 32.5805 38.5998 32.0465C38.1703 31.8058 37.6889 31.8475 37.2174 31.8475H33.1755C32.6937 31.8475 32.2226 31.8161 31.7828 32.0673C30.8613 32.6013 30.7357 33.9103 31.5001 34.633C31.9296 35.0414 32.4636 35.0626 33.0082 35.0626H34.9454H37.2072C37.8668 35.0626 38.4219 35.0626 38.9559 34.5914C39.469 34.1407 36.0765 32.8946 36.0765 32.8946Z" 
            fill={svgColor}
      />
      <path 
            d="M38.6324 12.1911H28.9665H11.2793H4.745C3.66649 12.1911 2.14789 11.9712 1.20561 12.5786C-0.344415 13.5629 0.0430441 15.7934 0.0430441 17.3643V25.5008V33.9726V36.664C0.0430441 37.2714 0.00104245 37.8682 0.242093 38.4339C0.96452 40.141 2.69241 39.9628 4.18989 39.9628H10.2951H27.9196C31.4696 39.9628 35.0299 40.0464 38.5803 39.9628C39.9941 39.9314 41.0833 38.8843 41.1249 37.4598C41.1355 37.0095 41.1249 36.5592 41.1249 36.1194V28.9252C41.1249 24.2232 41.1563 19.522 41.1249 14.82C41.1146 13.3748 40.0985 12.2436 38.6326 12.1914C37.9624 12.1706 37.9624 13.2178 38.6326 13.2385C39.4598 13.2699 40.0464 13.8879 40.0778 14.7046V15.1235V17.3646V25.2393V33.5749C40.0778 34.8733 40.13 36.1823 40.0778 37.481C40.015 39.2297 38.0777 38.9262 36.8839 38.9262H30.8729H13.7412C10.0655 38.9262 6.38982 38.947 2.71392 38.9262C1.83445 38.9262 1.13286 38.3923 1.10144 37.4602C1.09085 37.0098 1.10144 36.5493 1.10144 36.0989V29.0302V14.9559C1.10144 14.0552 1.56236 13.2802 2.55684 13.2386C2.83952 13.228 3.13281 13.2386 3.4155 13.2386H8.53642H25.3437H37.9623H38.6325C39.3024 13.2382 39.3024 12.1911 38.6322 12.1911H38.6324Z" 
            fill={svgColor}
      />
      <path 
            d="M40.7372 28.1187C42.0462 27.9094 43.3552 27.6998 44.6642 27.4905C45.4286 27.3648 46.1094 27.1764 46.6328 26.5482C47.1251 25.9408 47.261 25.2184 47.1459 24.4642C46.8424 22.4014 46.4863 20.3382 46.1617 18.2754C45.6171 14.851 45.0726 11.4165 44.528 7.99244C44.2139 6.01326 43.9414 4.02352 43.5857 2.0549C43.3136 0.5995 41.9834 -0.175511 40.5802 0.0337386C40.0564 0.117376 39.5331 0.201382 39.0093 0.285016C36.5377 0.683115 34.0667 1.07026 31.6057 1.46834C28.1813 2.01289 24.7467 2.55741 21.3227 3.10204C18.0451 3.62578 14.7672 4.14913 11.4894 4.66234C9.52082 4.97644 7.55193 5.29054 5.57274 5.60463C4.73491 5.74085 3.97046 5.96072 3.44715 6.71455C2.95482 7.42675 2.976 8.18061 3.11187 8.98703C3.23751 9.76205 3.36315 10.5473 3.48878 11.3224C3.57242 11.8355 3.65642 12.3487 3.72947 12.8618C3.83429 13.5214 4.83939 13.2387 4.73495 12.5791C4.5359 11.3121 4.32662 10.0448 4.12757 8.77784C4.01252 8.04483 4.03335 7.30122 4.77695 6.88234C5.00741 6.7567 5.2481 6.7147 5.49938 6.67306C7.08044 6.41119 8.67254 6.17051 10.2536 5.91923C16.61 4.90355 22.9769 3.89807 29.3329 2.88239C32.0973 2.44266 34.8622 2.00292 37.6266 1.56284C38.4432 1.4372 39.2603 1.30097 40.0769 1.17534C40.4224 1.12311 40.7785 1.02888 41.124 1.02888C42.3387 1.01829 42.548 2.0972 42.6948 3.01863C42.8519 4.0343 43.0195 5.05008 43.1766 6.07632C43.6897 9.27023 44.1922 12.4748 44.7055 15.6683C45.135 18.3597 45.5641 21.0614 45.9936 23.7527C46.0984 24.4123 46.3289 25.1559 45.9414 25.7633C45.5433 26.3813 44.8731 26.4229 44.2241 26.5277L41.8366 26.9046C41.3863 26.9781 40.9257 27.0511 40.4753 27.1245C39.7949 27.2184 40.0776 28.2239 40.7372 28.1191L40.7372 28.1187Z" 
            fill={svgColor}
      />
      </svg>

  )
}
