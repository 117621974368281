import { CloseIcon } from "../../icons/index";
import "../../../styles/vendor/vendor-invoice/vendor-receipt-uploader-modal.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UploadInvoiceFile } from "../../icons/UploadInvoiceFile";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { BACKEND_URL } from "../../../config";
import axios from "axios";

export const VendorReceiptUploader = ({
  toggleChange,
  modal,
  price,
  closeUnder,
  url,
  id,
  loadData,
  invoiceName,
  closeAll,
}) => {
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileError, setUploadFileError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const formdata = new FormData();
      formdata.append("payment_file", uploadFile, `${invoiceName} payment`);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = formdata;

      const { data } = await axios.post(
        `${BACKEND_URL}/vendor_payment_upload/${token}/${id}`,
        body,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          closeAll();
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition>
            <div className="vendor-receipt-modal">
              <div onClick={toggleChange} className="vendor-receipt-overlay">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  className="vendor-receipt-modal-card"
                  onClick={(evt) => evt.stopPropagation()}
                  transition={{ type: "spring" }}
                >
                  <>
                    <div className="vendor-receipt-modal-head">
                      <h3 className="vendor-receipt-modal-header">
                        Uploader An Evident of Payment
                      </h3>
                      <span
                        onClick={() => {
                          toggleChange();
                          closeUnder();
                        }}
                        className="vendor-receipt-close"
                      >
                        <CloseIcon color="#D13D2A" />
                      </span>
                    </div>
                    <div className="vendor-receipt-uploader">
                      <p className="receipt-statement-holder ">
                        Upload a Document with visible transaction number to
                        validate the payment of{" "}
                        <span className="effect-color">N{price}</span>
                      </p>

                      <div className="row four">
                        <span className="upload-form-carrier">
                          <h4 className="file-header">Upload File</h4>
                          <label for="upload-file">
                            <span className="file-placeholder-carrier">
                              {uploadFile ? (
                                <p className="file-placeholder">
                                  {uploadFile.name}
                                  <span className="shift-present">
                                    <UploadInvoiceFile color="#565cd6" />
                                  </span>
                                </p>
                              ) : (
                                <p className="file-placeholder">
                                  File type - (png, jpeg, docx, xls, pdf, etc.){" "}
                                  <span className="shift">
                                    <UploadInvoiceFile />
                                  </span>
                                </p>
                              )}
                            </span>

                            <input
                              className="file-input"
                              name="Upload File"
                              // value={uploadFile}
                              onChange={(evt) => {
                                if (evt.target.files) {
                                  const file = evt.target.files[0];
                                  if (file.size > 2621440) {
                                    setUploadFileError(
                                      "File should not exceed 2.5mb"
                                    );
                                  } else {
                                    setUploadFile(evt.target.files[0]);
                                  }
                                }
                              }}
                              onClick={() =>
                                uploadFileError && setUploadFileError(null)
                              }
                              type="file"
                              id="upload-file"
                            />
                            {uploadFileError && (
                              <div className="error-text">
                                {uploadFileError}
                              </div>
                            )}
                          </label>
                        </span>
                      </div>
                      <button
                        onClick={handleSubmit}
                        className="vendor-receipt-uploader-btn"
                      >
                        Send
                      </button>
                    </div>
                    <p className="last-statement">
                      To preview Invoice,
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="vendor-invoice-confirm-link"
                      >
                        Click here
                      </a>
                    </p>
                  </>
                  {/* )} */}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {success && <Toolkit text="Payment uploaded successfully" />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};

