import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/admin/admin-invoice/admin-invoice-detail-notify-modal.scss";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { AdminNotifyInvoiceCard } from "./AdminNotifyInvoiceCard";
import { AdminSellerDetailModal } from "../AdminSellerDetailModal";
import { AdminVendorDetailModal } from "../AdminVendorDetailModal";
import { formatAmount } from "../../../util/formatAmount";
import { PageLoader } from "../../general-component/PageLoader";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { AdminConfirmationModal } from "./AdminConfirmationModal";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { convertTime } from "../../../util/convertTime";

const invoiceCardData = [
  {
    index: 1,
    invoicePrice: "150,000.00",
    status: "due",
    dueDate: "26-02-2022",
    bidTime: "01:45:20",
    bidRate: 3.76,
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    index: 2,
    invoicePrice: "150,000.00",
    status: "due",
    dueDate: "26-02-2022",
    bidTime: "01:45:20",
    bidRate: 4.5,
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    index: 3,
    invoicePrice: "150,000.00",
    status: "due",
    dueDate: "26-02-2022",
    bidTime: "01:45:20",
    bidRate: 3.74,
    invoiceName: "Road Project Supplies Invoice",
  },
  {
    index: 4,
    invoicePrice: "150,000.00",
    status: "due",
    dueDate: "26-02-2022",
    bidTime: "01:45:20",
    bidRate: 4.5,
    invoiceName: "Road Project Supplies Invoice",
  },
];

export const AdminInvoiceDetailNotifyModal = ({
  modal,
  invoiceAmount,
  onClose,
  invoiceURL,
  invoiceDate,
  invoiceName,
  vendorName,
  sellerName,
  topBidder,
  status,
  countDown,
  bidders,
  loading,
  loadData,
  id,
}) => {
  const [sellerDetail, setSellerDetail] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(false);

  const [confirmBidClose, setConfirmBidClose] = useState(false);
  const [confirmPayInvestor, setConfirmPayInvestor] = useState(false);
  const [closeSuccess, setCloseSuccess] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const [error, setError] = useState(null);

  const [time, setTime] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (convertTime(countDown).difference <= 0) { 
      setTime("Bid Closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(countDown));
      }, [1000]);

      return () => clearTimeout(timer);
    }
  }, [time]);

  const handleClose = async () => {
    if (bidders.length <= 0){
      setError("Sorry, there are no bidders yet!");
    }
    else{
      const token = localStorage.getItem("token");
      try {
        setIsLoading(true);
        setConfirmBidClose(false);
        const body = {
          invoice_id: id,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/single_close_bid/${token}`,
          body,
          config
        );
        console.log(body, "bodyy")
          console.log(data)
        if (!data.success) {
          setError(data.message);
          setIsLoading(false);
          return;
        }

        setCloseSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setCloseSuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setError("Something went wrong with closing this bid");
      }
    }
    
  };

  const handlePayInvestor = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setConfirmPayInvestor(false);
      const body = {
        invoice_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/single_pay_investor/${token}`,
        body,
        config
      );

      console.log(data);
      if (!data.success) {
        setError(data.message);
        setIsLoading(false);
        return;
      }

      setPaySuccess(true);
      setTimeout(() => {
        setIsLoading(false);
        setPaySuccess(false);
      }, 2000);
      setTimeout(() => {
        loadData();
      }, 2500);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with paying this investor");
    }
  };

  const toggleSellerDetail = () => {
    setSellerDetail(!sellerDetail);
  };

  const toggleVendorDetail = () => {
    setVendorDetail(!vendorDetail);
  };

  const topInvestor = bidders?.filter((bidder) => bidder.potential_bid_winner);

  useEffect(() => {
    if (convertTime(countDown).difference <= 0) {
      setTime("Bid closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(countDown).text);
      }, [1000]);

      return () => clearTimeout(timer);
    }
  });

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="admin-invoice-detail-notify-modal">
              <div
                onClick={onClose}
                className="admin-invoice-detail-notify-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="admin-detail-invoice-modal-container"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {loading ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="admin-modal-invoice-head">
                        <h3>Invoice Details</h3>
                        <span onClick={onClose}>
                          <CloseIcon color="#D13D2A" />
                        </span>
                      </div>

                      <div className="admin-detail-notify-top">
                        <section className="admin-first-holder-left">
                          <h2 className="large-text-header">{invoiceName}</h2>

                          <span className="double-content-case">
                            <h3 className="large-text">₦{invoiceAmount}</h3>

                            <h5 className="small-text right">
                              Due date:
                              <h3 className="large-text date">{invoiceDate}</h3>
                            </h5>
                          </span>

                          <span className="double-content-case">
                            <h5 className="small-text">
                              Vendor:
                              <h3 className="vendor-text">{vendorName}</h3>
                            </h5>

                            <h5 className="small-text right">
                              Seller:
                              <h3 className="seller-text">{sellerName}</h3>
                            </h5>
                          </span>

                          <span className="double-content-case">
                            <h3>
                              <span
                                className="admin-indicator approved"
                                style={{
                                  display:
                                    status === "approved" ? "flex" : "none",
                                }}
                              >
                                Approved
                              </span>
                              <span
                                className="admin-indicator due"
                                style={{
                                  display: status === "due" ? "flex" : "none",
                                }}
                              >
                                Due
                              </span>

                              <span
                                className="admin-indicator sold"
                                style={{
                                  display: status === "sold" ? "flex" : "none",
                                }}
                              >
                                Sold
                              </span>

                              <span
                                className="admin-indicator closed"
                                style={{
                                  display:
                                    status === "closed" ? "flex" : "none",
                                }}
                              >
                                Closed
                              </span>
                            </h3>

                            {topInvestor?.length ? (
                              <h5 className="small-text right">
                                {status === "closed"
                                  ? "Bid Winner"
                                  : "Top Bidder"}
                                :
                                <h3 className="large-text-top-investor">
                                  {topInvestor?.[0]?.investor_name}
                                </h3>
                              </h5>
                            ) : (
                              ""
                            )}
                          </span>
                        </section>

                        <section className="admin-first-holder-right">
                          {status === "sold" ? (
                            <button
                              className="admin-invoice-btn active-admin-btn"
                              // onClick={toggleDetailNotify}
                            >
                              Notify
                            </button>
                          ) : status === "due" ? (
                            <button
                              className="admin-invoice-btn active-admin-btn"
                              onClick={() => setConfirmPayInvestor(true)}
                            >
                              Pay Investor
                            </button>
                          ) : status === "approved" ? (
                            <button
                              className="admin-invoice-btn active-admin-btn"
                              onClick={() => setConfirmBidClose(true)}
                            >
                              Close Bid
                            </button>
                          ) : null}

                          <h3 className="large-text count-down">
                            {status === "approved" && 
                            <div className="new-count-down-time">
                            {time === "Bid Closed" ? (
                              time
                            ) : (
                              <>
                                <span className="hour-panel">
                                  <h5 className="label-panel">Days</h5>
                                  <h1 className="timed-digit">{time.days} :</h1>
                                </span>
                
                                <span className="minute-panel">
                                  <h5 className="label-panel">Hrs</h5>
                                  <h1 className="timed-digit">{time.hrs} :</h1>
                                </span>
                
                                <span className="minute-panel">
                                  <h5 className="label-panel">Mins</h5>
                                  <h1 className="timed-digit">{time.mins} :</h1>
                                </span>
                
                                <span className="seconds-panel">
                                  <h5 className="label-panel">Sec</h5>
                                  <h1 className="timed-digit">{time.sec}</h1>
                                </span>
                              </>
                            )}
                          </div>
                            
                            }
                          </h3>
                        </section>
                      </div>

                      <div className="admin-detail-notify-bottom">
                        <div className="admin-detail-notify-bottom-first mobile-hide">
                          <p className="admin-table-header mobile-hide">
                            Bidders List
                          </p>

                          {invoiceCardData?.length ? (
                            <table className="admin-invoice-page-table">
                              <tbody>
                                <tr className="admin-invoice-page-header table">
                                  <th>Investor Name</th>
                                  <th>Bid Rate</th>
                                  <th>Bid Amount</th>
                                  <th>Bid Time</th>
                                  <th>Bid Date</th>
                                </tr>
                              </tbody>
                            </table>
                          ) : null}
                        </div>

                        <div className="admin-invoice-full-scroller">
                          {bidders?.length ? (
                            <div className="admin-invoice-data-holder mobile-hide">
                              {bidders?.map((bidder) => (
                                <AdminNotifyInvoiceCard
                                  key={bidder?.id}
                                  id={bidder?.id}
                                  bidAmount={formatAmount(
                                    bidder?.bidded_amount
                                  )}
                                  isTop={topInvestor?.[0]?.id === bidder?.id}
                                  bidDate={bidder?.date}
                                  bidRate={bidder?.discount_rate.toFixed(2)}
                                  bidTime={bidder?.bid_time}
                                  invoiceName={bidder?.investor_name}
                                  // url={invoice?.invoice_url}
                                  // index={invoice.index}
                                />
                              ))}
                            </div>
                          ) : (
                            <div className="admin-empty-invoice-notify">
                              <p>No Bidders </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <p className="invest-preview-notify">
                        To preview Invoice,
                        <a
                          href={invoiceURL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Click here
                        </a>
                      </p>
                    </>
                  )}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>

      <AdminSellerDetailModal
        onClose={toggleSellerDetail}
        modal={sellerDetail}
        sellerAddress="52 Odalume, Oshodi Lagos"
        sellerName="Shining Star"
        typeBusiness="Sole Proprietorship"
        annualRevenue="1,000,000 - 5,000,000"
        phoneNumber="08012345678"
        country="Nigeria"
      />

      <AdminConfirmationModal
        toggleChange={() => setConfirmBidClose(false)}
        modal={confirmBidClose}
        topic="Confirmation"
        statement="Would you like to close this bid"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleClose}
      />

      <AdminConfirmationModal
        toggleChange={() => setConfirmPayInvestor(false)}
        modal={confirmPayInvestor}
        topic="Confirmation"
        statement={`Are you sure you want to pay ${topInvestor?.[0]?.investor_name}?`}
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handlePayInvestor}
      />

      <AdminVendorDetailModal
        onClose={toggleVendorDetail}
        modal={vendorDetail}
        vendorName="Shining Star"
        vendorAddress="52 Odalume, Oshodi Lagos"
        vendorCredit={80}
        phoneNumber="08012345678"
        annualRevenue="1,000,000 - 5,000,000"
        typeBusiness="Sole Proprietorship"
        numberStaff="11 - 50"
      />

      <AnimatePresence>
        {closeSuccess && (
          <Toolkit text="You have successfully closed the bid" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {paySuccess && (
          <Toolkit text="You have successfully paid the investor" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};
