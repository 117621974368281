export const MobileInvestor = () => {
  return (
    <svg
      width="32"
      height="35"
      viewBox="0 0 32 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7442 18.2394L22.49 18.1284L22.9644 18.0982C23.2114 18.0824 23.402 17.8936 23.4079 17.6588C23.4135 17.4312 23.2388 17.2347 23.0012 17.2019L22.4928 17.1313L20.7858 16.8943C20.7697 16.8923 20.7538 16.891 20.7381 16.891C20.5916 16.891 20.4607 16.9842 20.4286 17.118C20.374 17.3444 20.3277 17.6866 20.4168 18.0309V18.0311C20.4501 18.1598 20.5926 18.2481 20.7442 18.2395L20.7442 18.2394Z"
        fill="#3FBB97"
      />
      <path
        d="M6.03091 30.1134C5.05558 30.1134 4.26493 29.3633 4.26493 28.4381C4.26493 28.0518 4.40407 27.697 4.63565 27.4138C3.87387 26.4685 3.44147 25.5821 3.24238 25.1076L0.319793 29.0059C-0.37174 29.8525 -0.133603 32.095 2.97547 34.0681C6.0844 36.0412 7.36078 34.2679 7.36078 34.2679L9.61255 30.6422C8.70909 30.403 7.91932 30.0624 7.23382 29.6618C6.91848 29.9412 6.49626 30.1133 6.0308 30.1133L6.03091 30.1134Z"
        fill="#3FBB97"
      />
      <path
        d="M28.6632 21.0162C28.4812 20.8437 28.3811 20.6141 28.3811 20.37C28.3811 20.1259 28.4813 19.8965 28.6632 19.7237L30.5891 17.8967C28.9123 17.1946 27.5144 18.5346 27.5144 18.5346L24.4774 21.1235C24.3596 21.2242 24.2068 21.2798 24.0482 21.2798H18.0563C17.9613 21.2798 17.8697 21.2458 17.8 21.1846L16.1786 19.7628C16.0524 19.6522 16.1348 19.4521 16.3068 19.4521H20.6407C21.2517 19.4521 21.7951 19.1815 22.1481 18.7607L20.7887 18.8472C20.7659 18.8488 20.7432 18.8495 20.7208 18.8495C20.2809 18.8495 19.8974 18.5746 19.7946 18.1762C19.6991 17.8069 19.7023 17.4053 19.8039 16.9827C19.9137 16.5269 20.3861 16.2239 20.8802 16.2918L22.1299 16.4652C21.7766 16.0571 21.2407 15.7969 20.6407 15.7969H13.2651C13.2651 15.7969 9.78602 15.3241 6.08501 21.3165L3.68713 24.5151C3.69864 24.534 3.71309 24.5507 3.72031 24.5722C3.72767 24.5944 4.11966 25.7343 5.1315 26.9986C5.39519 26.8498 5.70223 26.7629 6.03134 26.7629C7.00666 26.7629 7.79731 27.513 7.79731 28.4382C7.79731 28.6983 7.73296 28.9436 7.62151 29.1631C8.28229 29.5481 9.04208 29.8732 9.91329 30.0907C9.9256 30.0937 9.93469 30.1017 9.94633 30.106L10.5265 29.172C12.377 26.176 15.9327 26.3221 15.9327 26.3221C22.1905 26.3221 25.2572 25.5183 25.2572 25.5183C25.2572 25.5183 27.5821 23.3186 29.3024 21.604C29.2827 21.5922 29.2612 21.5832 29.2445 21.5674L28.6632 21.0162Z"
        fill="#3FBB97"
      />
      <path
        d="M31.9222 17.8514C31.8703 17.8022 31.8021 17.7777 31.734 17.7777C31.6657 17.7777 31.5974 17.8022 31.5455 17.8515L29.1179 20.1545C29.0571 20.2121 29.0238 20.2885 29.0238 20.3699C29.0238 20.4512 29.0573 20.5276 29.1179 20.5851L29.4551 20.9052L31.3938 18.7873L31.9352 18.1957C32.0263 18.0961 32.0207 17.9448 31.9222 17.8514L31.9222 17.8514Z"
        fill="#3FBB97"
      />
      <path
        d="M21.2711 0C19.5235 0 17.8476 0.694142 16.6121 1.92952C15.3765 3.16511 14.6826 4.84057 14.683 6.58761C14.6834 8.33444 15.378 10.0098 16.6141 11.2447C17.8503 12.4795 19.5265 13.1728 21.2741 13.172C23.0217 13.1712 24.6974 12.4764 25.9323 11.2405C27.1673 10.0046 27.8604 8.32862 27.8592 6.58159C27.8568 4.83594 27.1618 3.16269 25.9267 1.9287C24.6915 0.694708 23.0173 0.00118331 21.2711 0ZM23.0454 9.11151C22.7069 9.43748 22.2546 9.61836 21.7845 9.61555H21.7214V10.6375C21.7214 10.8861 21.5198 11.0876 21.2711 11.0876C21.0224 11.0876 20.8208 10.8861 20.8208 10.6375V9.61555H19.8526C19.6039 9.61555 19.4023 9.41417 19.4023 9.16537C19.4023 8.91677 19.6039 8.7152 19.8526 8.7152H21.8025C22.0385 8.7146 22.2653 8.62456 22.4375 8.46319C22.5982 8.30904 22.6892 8.09621 22.6896 7.87354C22.6838 7.64182 22.5851 7.42216 22.4158 7.26377C22.2466 7.10561 22.0206 7.0218 21.789 7.03165H20.7533C20.2834 7.0413 19.8293 6.86324 19.4912 6.53706C19.1532 6.21109 18.959 5.76353 18.952 5.29386C18.9588 4.82238 19.1572 4.37401 19.5014 4.05148C19.8382 3.72913 20.2871 3.55006 20.7533 3.55187H20.8164V2.52532C20.8164 2.27673 21.0178 2.07515 21.2667 2.07515C21.5154 2.07515 21.717 2.27673 21.717 2.52532V3.55167H22.7077V3.55187C22.9564 3.55187 23.158 3.75324 23.158 4.00204C23.158 4.25064 22.9564 4.45222 22.7077 4.45222H20.7532C20.5182 4.45081 20.2921 4.54125 20.1228 4.70423C19.9616 4.85818 19.8705 5.0712 19.8707 5.29388C19.8763 5.5252 19.9752 5.74446 20.1447 5.902C20.3142 6.05955 20.5401 6.14216 20.7713 6.13131H21.798C22.268 6.12287 22.7224 6.30173 23.0603 6.62871C23.3982 6.95549 23.592 7.40347 23.5993 7.87351C23.59 8.34398 23.39 8.79073 23.0454 9.11146L23.0454 9.11151Z"
        fill="#3FBB97"
      />
    </svg>
  );
};
