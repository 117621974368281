import { useState } from "react";
import styles from "../styles/loginform.module.scss";
import "../styles/index.scss";
import { Link } from "react-router-dom";
import { MainLogo, MobileLogo } from "../components/icons/logo";
import { OptimizedInput } from "../components/form/OptimizedInput";
import { HidePasswordIcon, PasswordIcon } from "../components/icons";
import { validateSignIn } from "../util/validate/validateSignIn";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { useNavigate } from "react-router";

const LoginPage = () => {
  // error states
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [control, setControl] = useState(false);

  // loading state
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const toggleChange = () => {
    if (control === true) {
      setControl(false);
    } else {
      setControl(true);
    }
  };

  const handleSubmit = async (evt) => {
    if (!isLoading) {
      evt.preventDefault();

      // handle error states
      const { isValid, error } = validateSignIn({
        email: email.toLowerCase().trim(),
        password,
      });

      if (!isValid) {
        if (error?.email) {
          setEmailError(error.email);
        }

        if (error?.password) {
          setPasswordError(error.password);
        }
      } else {
        try {
          setIsLoading(true);
          const body = {
            email: email.toLowerCase().trim(),
            password,
          };
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const { data } = await axios.post(
            `${BACKEND_URL}/signin`,
            body,
            config
          );

          setIsLoading(false);

          if (!data.success) {
            if (data.message === "Wrong Password") {
              setPasswordError(data.message);
            } else {
              setEmailError(data.message);
            }
            return;
          }

          localStorage.setItem("token", data.token);
          if (data.role === "seller") navigate("/seller/dashboard");
          if (data.role === "investor") navigate("/investor/dashboard");
          if (data.role === "vendor") navigate("/vendor/dashboard");
        } catch (error) {
          const message =
            error.response && error.response.data.error
              ? error.response.data.error
              : error.error;

          if (message === "Wrong Password") {
            setPasswordError(message);
          } else {
            setEmailError(message);
          }
        }
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <span className={styles.topLogo}>
        <MainLogo />
      </span>

      <div className={styles.pageCard}>
        <span className={styles.mobileLogo}>
          <MobileLogo />
        </span>
        <h3 className={styles.pageTitle}>
          Login
          <Link className={styles.topLink} to="/forgot-password">
            Forgot Password?
          </Link>
        </h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.cardButton}>
            <section className={styles.cover}>
              <OptimizedInput
                title="Email Address"
                name="Email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                onFocus={() => emailError && setEmailError(null)}
              />
              {emailError && <div className="error-text">{emailError}</div>}

              <OptimizedInput
                title="Password"
                name="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onIconClick={toggleChange}
                type={control ? "text" : "password"}
                icon={control ? <HidePasswordIcon /> : <PasswordIcon />}
                onFocus={() => passwordError && setPasswordError(null)}
              />
              {passwordError && (
                <div className="error-text">{passwordError}</div>
              )}
            </section>
          </div>
          <div style={{ textAlign: "center" }}>
            <button className="signin-press" onClick={handleSubmit}>
              {isLoading ? <div className="loading" /> : "Log In"}
            </button>
          </div>
        </form>

        <p className={styles.bottomLink}>
          <Link to="/forgot-password"> Forgot Password? </Link>
        </p>

        <p className={styles.directMember}>
          New on Quidroo?
          <Link to="/signup">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
