import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/admin/admin-users/admin-user-seller-verification-card.scss";
import { ModalLoader } from "../../general-component/ModalLoader";
import { Toolkit } from "../../general-component/Toolkit";
import { AdminVerificationIcon } from "../../icons";
import { AdminConfirmationModal } from "../admin-invoice/AdminConfirmationModal";
import { AdminSellerDetailModal } from "../AdminSellerDetailModal";

const AdminUserSellerVerificationCard = (props) => {
  const {
    sellerName,
    walletBalance,
    femaleCofounder,
    businessType,
    creditScore,
    isVerified,
    url,
    id,
    loadData,
  } = props;

  const [sellerDetail, setSellerDetail] = useState(false);

  const [sellerDetailsData, setSellerDetailsData] = useState();
  const [isSellerLoading, setIsSellerLoading] = useState(false);

  const [verified, setVerified] = useState(false);
  const [unVerified, setUnVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unverifySuccess, setUnverifySuccess] = useState(false);
  const [error, setError] = useState(null);

  const toggleVerified = () => {
    setVerified(!verified);
  };

  const toggleUnverified = () => {
    setUnVerified(!unVerified);
  };

  // SELLER APPROVAL FUNCTION
  const handleConfirmation = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setVerified(false);
      const body = {
        user_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_user_verify/${token}`,
        body,
        config
      );

      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with verifying");
    }
  };

  const handleUnverify = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      setUnVerified(false);
      const body = {
        user_id: id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_user_unverify/${token}`,
        body,
        config
      );

      if (data.success) {
        setUnverifySuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setUnverifySuccess(false);
        }, 2000);
        setTimeout(() => {
          loadData();
        }, 2500);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong with unverifying the seller");
    }
  };

  const onSellerDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setSellerDetail(true);

    try {
      setIsSellerLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_seller_detail/${id}`,
        config
      );
      setIsSellerLoading(false);

      if (data.success) setSellerDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onSellerDetailClose = () => {
    setSellerDetail(false);
    setSellerDetailsData(undefined);
  };

  const scoreBg = () => {
    if (creditScore >= 70) {
      return "#E5FFF7";
    } else if (creditScore >= 60) {
      return "#9cd2f1";
    } else if (creditScore >= 45) {
      return "#FFEBD8";
    } else if (creditScore >= 20) {
      return "#f2f4e0";
    } else if (creditScore < 20) {
      return "#FFDEDE";
    }
  };

  return (
    <>
      <tr className="admin-user-seller-verify-invoice-cards">
        <td onClick={onSellerDetailOpen}>{sellerName}</td>
        <td>{businessType}</td>
        <td>
          <span
            className="admin-indicator"
            style={{ backgroundColor: scoreBg(creditScore) }}
          >
            {creditScore}%
          </span>
        </td>
        <td>₦{walletBalance}</td>
        <td>{femaleCofounder}</td>
        <td>
          {isVerified === false ? (
            <button
              className="admin-invoice-btn active-admin-btn"
              onClick={toggleVerified}
            >
              Verify
            </button>
          ) : (
            <button
              className="admin-user-seller-verified-btn"
              onClick={toggleUnverified}
            >
              <AdminVerificationIcon />
            </button>
          )}
        </td>
      </tr>
      <AdminSellerDetailModal
        onClose={onSellerDetailClose}
        modal={sellerDetail}
        sellerAddress={sellerDetailsData?.address}
        sellerName={sellerDetailsData?.companyName}
        typeBusiness={sellerDetailsData?.business_type}
        annualRevenue={sellerDetailsData?.revenue}
        phoneNumber={sellerDetailsData?.phone}
        country={sellerDetailsData?.country}
        emailAddress={sellerDetailsData?.email}
        creditScore={sellerDetailsData?.credit_score}
        numberStaff={sellerDetailsData?.staffNo}
        ninNumber={sellerDetailsData?.nin}
        bankVerificationNumber={sellerDetailsData?.bvn}
        cacNumber={sellerDetailsData?.cac}
        bankName={sellerDetailsData?.bank}
        accountName={sellerDetailsData?.account_name}
        accountNumber={sellerDetailsData?.account_no}
        memoNumber={sellerDetailsData?.memo_id}
        femaleCofounder={sellerDetailsData?.femaleCofounder}
        loading={isSellerLoading}
        cacUrl = {sellerDetailsData?.cac_url}
      />

      <AdminConfirmationModal
        toggleChange={toggleVerified}
        modal={verified}
        topic="Confirmation"
        statement="Would you like to Approve/Authorize this Seller"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleConfirmation}
      />

      <AdminConfirmationModal
        toggleChange={toggleUnverified}
        modal={unVerified}
        topic="Confirmation"
        statement="Would you like to unauthorize/disactivate this seller"
        noBgColor="#F3F3F3"
        noColor="#414141"
        yesBgColor="#C7E0FF"
        yesColor="#081B33"
        handleConfirm={handleUnverify}
      />

      <AnimatePresence>
        {success && (
          <Toolkit text="You have successfully verified the seller" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {unverifySuccess && (
          <Toolkit text="You have successfully unverified the seller" />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};

export default AdminUserSellerVerificationCard;
