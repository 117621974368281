import React, { useState } from "react";
import "../../styles/investor/investor-status-bar.scss";
import { InvestorActivationModalForm } from "./dashboard/InvestorActivationFormModal";

export const InvestorStatusBar = (props) => {
  const { activated, ownerName, loadData, pending } = props;
  const [modal, setModal] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  console.log(props, "prosps")
  return (
    <div className="investor-status-container">
      <div className="investor-status">
        {activated ? (
          <h2 className="owner-status">{ownerName}</h2>
        ) : pending ? (
          <p className="inactivated-status">
            Our agent is confirming your details. Your account will be verified
            within 24 hours
          </p>
        ) : (
          <p className="inactivated-status">
            Your account is deactivated,{" "}
            <span className="mobile-hide">Click here to </span>
            <span className="status-leader" onClick={controlModal}>
              Activate now
            </span>
          </p>
        )}
      </div>

      <InvestorActivationModalForm
        modal={modal}
        onClose={() => setModal(false)}
        loadData={loadData}
      />
    </div>
  );
};
