export const MainLogo = () => {
  return (
    <svg
      width="138"
      height="40"
      viewBox="0 0 138 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6803 25.2933V14.9935H54.7969V32.8781H50.9778V30.5671C50.5135 31.4042 49.8102 32.0849 48.9572 32.5228C47.896 33.0705 46.7117 33.3376 45.5174 33.2986C43.3943 33.2986 41.7594 32.6608 40.6129 31.3853C39.4663 30.1097 38.8984 28.4007 38.9093 26.2581V14.9935H43.0226V25.7073C43.0226 28.4192 44.1866 29.7751 46.5147 29.7751C47.8051 29.7751 48.8198 29.3905 49.5588 28.6213C50.2977 27.852 50.6716 26.7427 50.6803 25.2933Z"
        fill="#081B33"
      />
      <path
        d="M60.9703 11.9915C60.6274 12.0045 60.2854 11.9486 59.9647 11.8271C59.6439 11.7056 59.351 11.5209 59.1033 11.2842C58.8629 11.0494 58.6743 10.7673 58.5493 10.4559C58.4243 10.1444 58.3658 9.81044 58.3775 9.47519C58.3621 9.13169 58.4187 8.78878 58.5436 8.46827C58.6685 8.14776 58.8589 7.85668 59.1028 7.61355C59.3467 7.37042 59.6387 7.18057 59.9602 7.05608C60.2818 6.93158 60.6257 6.87518 60.9703 6.89044C61.3094 6.87984 61.6471 6.93857 61.9626 7.06302C62.2781 7.18747 62.5646 7.37499 62.8046 7.61404C63.0466 7.85847 63.2359 8.14969 63.3607 8.46977C63.4855 8.78985 63.5433 9.13201 63.5305 9.47519C63.5422 9.81044 63.4836 10.1444 63.3587 10.4559C63.2337 10.7673 63.045 11.0494 62.8046 11.2842C62.5624 11.5191 62.275 11.7028 61.9598 11.8243C61.6446 11.9458 61.3081 12.0027 60.9703 11.9915ZM58.8973 32.8748V14.9934H63.0106V32.8781L58.8973 32.8748Z"
        fill="#081B33"
      />
      <path
        d="M79.9871 17.3337V7.02734H84.1004V32.8748H80.2977V30.3129C79.0748 32.2881 77.0574 33.2757 74.2455 33.2757C71.6885 33.2757 69.6668 32.3859 68.1801 30.6062C66.6935 28.8266 65.9502 26.608 65.9502 23.9504C65.9502 21.2842 66.6935 19.0558 68.1801 17.2653C69.6668 15.4748 71.6885 14.5784 74.2455 14.5762C76.846 14.5762 78.7599 15.4954 79.9871 17.3337ZM80.1571 23.9504C80.1571 22.2707 79.7026 20.892 78.7936 19.8142C78.3284 19.3038 77.7613 18.896 77.1287 18.6169C76.4961 18.3378 75.8119 18.1936 75.1201 18.1936C74.4283 18.1936 73.7442 18.3378 73.1115 18.6169C72.4789 18.896 71.9118 19.3038 71.4466 19.8142C70.5245 20.9007 70.0635 22.2794 70.0635 23.9504C70.0139 25.4327 70.5053 26.8827 71.4466 28.0313C71.9104 28.5439 72.4771 28.9538 73.1099 29.2343C73.7427 29.5148 74.4276 29.6598 75.1201 29.6598C75.8127 29.6598 76.4975 29.5148 77.1303 29.2343C77.7631 28.9538 78.3298 28.5439 78.7936 28.0313C79.7252 26.8785 80.2092 25.4298 80.1571 23.9504Z"
        fill="#081B33"
      />
      <path
        d="M97.9284 14.8501V18.6767C97.6197 18.6322 97.3083 18.6093 96.9965 18.6083C93.9077 18.6083 92.3633 20.263 92.3633 23.5724V32.8749H88.25V14.9935H92.0527V17.4707C93.0663 15.6997 94.8287 14.8153 97.3398 14.8175L97.9284 14.8501Z"
        fill="#081B33"
      />
      <path
        d="M108.094 33.2888C105.327 33.2888 103.085 32.3816 101.368 30.5672C99.6645 28.7883 98.738 26.409 98.7912 23.9505C98.7385 21.4875 99.662 19.103 101.361 17.3142C103.079 15.4889 105.321 14.5763 108.087 14.5763C110.853 14.5763 113.106 15.4889 114.846 17.3142C116.547 19.102 117.473 21.4866 117.422 23.9505C117.475 26.409 116.549 28.7883 114.846 30.5672C113.134 32.3838 110.884 33.291 108.094 33.2888ZM102.908 23.9505C102.908 25.6519 103.397 27.0307 104.376 28.0867C104.859 28.5875 105.439 28.9859 106.081 29.2581C106.723 29.5302 107.413 29.6704 108.11 29.6704C108.807 29.6704 109.497 29.5302 110.139 29.2581C110.781 28.9859 111.36 28.5875 111.844 28.0867C112.825 27.0307 113.314 25.6519 113.312 23.9505C113.31 22.2491 112.821 20.8583 111.844 19.7784C111.36 19.2776 110.781 18.8792 110.139 18.6071C109.497 18.3349 108.807 18.1947 108.11 18.1947C107.413 18.1947 106.723 18.3349 106.081 18.6071C105.439 18.8792 104.859 19.2776 104.376 19.7784C103.395 20.8388 102.906 22.2295 102.908 23.9505Z"
        fill="#081B33"
      />
      <path
        d="M128.5 33.2888C125.733 33.2888 123.493 32.3816 121.777 30.5672C120.072 28.7893 119.146 26.4093 119.201 23.9505C119.148 21.4862 120.074 19.101 121.777 17.3142C123.495 15.4889 125.736 14.5763 128.5 14.5763C131.264 14.5763 133.516 15.4889 135.258 17.3142C136.96 19.102 137.886 21.4866 137.835 23.9505C137.888 26.409 136.961 28.7883 135.258 30.5672C133.536 32.3838 131.283 33.291 128.5 33.2888ZM123.304 23.9505C123.304 25.6519 123.794 27.0307 124.775 28.0867C125.259 28.5874 125.838 28.9858 126.48 29.2579C127.121 29.5299 127.811 29.6702 128.508 29.6702C129.205 29.6702 129.895 29.5299 130.536 29.2579C131.177 28.9858 131.757 28.5874 132.24 28.0867C133.221 27.0307 133.712 25.6519 133.712 23.9505C133.712 22.2491 133.221 20.8583 132.24 19.7784C131.757 19.2777 131.177 18.8793 130.536 18.6073C129.895 18.3352 129.205 18.1949 128.508 18.1949C127.811 18.1949 127.121 18.3352 126.48 18.6073C125.838 18.8793 125.259 19.2777 124.775 19.7784C123.794 20.8388 123.304 22.2295 123.304 23.9505Z"
        fill="#081B33"
      />
      <path
        d="M22.188 36.1017L17.375 33.3312V40L22.188 37.2295C22.2871 37.1722 22.3694 37.0899 22.4266 36.991C22.4837 36.892 22.5138 36.7798 22.5138 36.6656C22.5138 36.5514 22.4837 36.4392 22.4266 36.3402C22.3694 36.2413 22.2871 36.159 22.188 36.1017Z"
        fill="#31319B"
      />
      <path
        d="M11.585 36.6656L17.3756 40V33.3312L11.585 36.6656Z"
        fill="#3C3CAA"
      />
      <path
        d="M5.79102 33.3311L11.585 36.6655V29.9967L5.79102 33.3311Z"
        fill="#4747C9"
      />
      <path d="M0 29.9967L5.79066 33.3311V26.6655L0 29.9967Z" fill="#565CD6" />
      <path d="M0 23.3311L5.79066 26.6655V19.9967L0 23.3311Z" fill="#4473B7" />
      <path d="M0 16.6657L5.79066 20.0001V13.3312L0 16.6657Z" fill="#209AB7" />
      <path d="M0 10.0001L5.79066 13.3312V6.66565L0 10.0001Z" fill="#4AD1A0" />
      <path
        d="M5.79102 6.6656L11.585 10V3.33118L5.79102 6.6656Z"
        fill="#3EAF6E"
      />
      <path
        d="M11.5811 3.33442L17.375 6.66558V0L11.5811 3.33442Z"
        fill="#ABCC46"
      />
      <path
        d="M17.3721 6.66563L23.1627 10.0001V3.33447L17.3721 6.66563Z"
        fill="#E5AA33"
      />
      <path
        d="M23.1592 9.99348L28.9498 13.3279V6.65906L23.1592 9.99348Z"
        fill="#D65F2B"
      />
      <path
        d="M28.9502 13.328L34.7409 16.6591V9.99353L28.9502 13.328Z"
        fill="#BF3030"
      />
      <path
        d="M28.9502 19.9869L34.7409 23.3213V16.6525L28.9502 19.9869Z"
        fill="#AA3F91"
      />
      <path
        d="M17.3721 20.0034L23.166 23.3345V16.6689L17.3721 20.0034Z"
        fill="#4542B7"
      />
      <path
        d="M28.9502 26.646L34.7409 29.9772V23.3116L28.9502 26.646Z"
        fill="#AB46D1"
      />
      <path
        d="M24.144 30.5443L28.9505 33.3116V26.646L24.144 29.41C24.0434 29.4669 23.9597 29.5493 23.9014 29.649C23.8432 29.7486 23.8125 29.8618 23.8125 29.9772C23.8125 30.0925 23.8432 30.2057 23.9014 30.3054C23.9597 30.405 24.0434 30.4874 24.144 30.5443Z"
        fill="#6D4499"
      />
      <path
        d="M17.3756 33.3311L11.585 29.9967V36.6655L17.3756 33.3311Z"
        fill="#4542B7"
      />
      <path
        d="M11.585 29.9967L5.79102 26.6655V33.3311L11.585 29.9967Z"
        fill="#5151E0"
      />
      <path
        d="M5.79066 26.6655L0 23.3311V29.9967L5.79066 26.6655Z"
        fill="#596ED8"
      />
      <path
        d="M5.79066 20.0001L0 16.6656V23.3312L5.79066 20.0001Z"
        fill="#2380AF"
      />
      <path
        d="M17.3717 19.9968L11.5811 16.6656V23.3312L17.3717 19.9968Z"
        fill="#4747C9"
      />
      <path
        d="M17.3756 20.0001V13.3345L11.585 16.6656L17.3756 20.0001Z"
        fill="#5151E0"
      />
      <path
        d="M17.3721 19.9999V26.6655L23.166 23.3344L17.3721 19.9999Z"
        fill="#3C3CAA"
      />
      <path
        d="M17.3721 20.0033L23.166 16.6689L17.3721 13.3345V20.0033Z"
        fill="#4747C9"
      />
      <path
        d="M17.3717 19.9967L11.5811 23.3311L17.3717 26.6655V19.9967Z"
        fill="#4542B7"
      />
      <path
        d="M11.585 10.0001L5.79102 6.66565V13.3312L11.585 10.0001Z"
        fill="#3FBB97"
      />
      <path
        d="M17.375 6.66563L11.5811 3.33447V10.0001L17.375 6.66563Z"
        fill="#62B758"
      />
      <path
        d="M23.166 3.33442L17.3721 0V6.66558L23.166 3.33442Z"
        fill="#EACE49"
      />
      <path
        d="M28.9498 6.65904L23.1592 3.32788V9.99346L28.9498 6.65904Z"
        fill="#E07E24"
      />
      <path
        d="M34.7409 9.99348L28.9502 6.65906V13.3279L34.7409 9.99348Z"
        fill="#D13D2A"
      />
      <path
        d="M34.7409 16.6591L28.9502 13.3247V19.9935L34.7409 16.6591Z"
        fill="#AF3869"
      />
      <path
        d="M34.7409 23.3116L28.9502 19.9772V26.646L34.7409 23.3116Z"
        fill="#B240B2"
      />
      <path
        d="M34.7409 29.9772L28.9502 26.646V33.3116L34.7409 29.9772Z"
        fill="#8A44B2"
      />
      <path
        d="M5.79066 13.3312L0 10.0001V16.6656L5.79066 13.3312Z"
        fill="#25BECE"
      />
    </svg>
  );
};
