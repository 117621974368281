import React, { useState } from "react";
import {
  CloseIcon,
  FiatDepositIcon,
  CryptoTransactionIcon,
} from "../icons/index";
import "../../styles/vendor/vendor-fiat-deposit-modal.scss";
import FiatCard from "../investor-component/dashboard/FiatCard";
import VendorCryptoDepositModal from "./VendorCryptoDepositModal";
import NairaFiatDepositModal from "../investor-component/dashboard/NairaFiatDepositModal";

const VendorDepositModalCard = (props) => {
  const { modal, controlModal, email, loadData } = props;

  const [appear, setAppear] = useState(false);

  const [show, setShow] = useState(false);

  const [showOptions, setShowOptions] = useState(false);

  const controlAppear = () => {
    setAppear(!appear);
  };

  return (
    <>
      {modal && (
        <div className="vendor-fiat-deposit-modal">
          <div onClick={controlModal} className="vendor-fiat-deposit-overlay">
            <div
              className="vendor-deposit-modal"
              onClick={(evt) => evt.stopPropagation()}
            >
              <div className="vendor-deposit-head">
                <h3>Deposit</h3>

                <span onClick={controlModal}>
                  <CloseIcon color="#D13D2A" />
                </span>
              </div>

              <p className="vendor-deposit-heading">
                I want to deposit USDC from my crypto account to Quidroo
              </p>

              {showOptions ? (
                <>
                  <FiatCard
                    icon={<div className="deposit-currency">₦</div>}
                    title1="Naira"
                    onClick={() => setShow(true)}
                    statement="I want to deposit from my naira account to Quidroo"
                  />

                  <FiatCard
                    icon={<div className="deposit-currency">$</div>}
                    title1="Dollar"
                    statement="I want to deposit from my dollar account to Quidroo"
                  />
                </>
              ) : (
                <>
                  {" "}
                  <FiatCard
                    icon={<FiatDepositIcon svgColor="#8A44B2" />}
                    title1="Fiat"
                    statement="I want to deposit fiat money 
                            (such as EUR, AUD,etc.) from
                            my bank account to Quidroo"
                    onClick={() => setShowOptions(true)}
                  />
                  <FiatCard
                    icon={<CryptoTransactionIcon svgColor="#8A44B2" />}
                    title1="Crypto (USDC)"
                    statement="I want to deposit USDC from my 
                        crypto account to Quidroo"
                    onClick={controlAppear}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <VendorCryptoDepositModal
        controlModal={controlAppear}
        modal={appear}
        closeAll={controlModal}
      />

      <NairaFiatDepositModal
        controlModal={() => setShow(false)}
        modal={show}
        email={email}
        closeAll={controlModal}
        loadData={loadData}
      />
    </>
  );
};

export default VendorDepositModalCard;
