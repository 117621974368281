import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  DownwardArrowIcon,
  NoInvoiceIcon,
  PlusIcon,
  UpwardArrowIcon,
} from "../../components/icons";
import "../../styles/admin/admin-dashboard/admin-index-dashboard.scss";
// import { MobileVendorDepositWithdrawModal } from "../../components/vendor-component/MobileVendorDepositWithdrawModal";
// import axios from "axios";
// import { BACKEND_URL } from "../../config";
import { PageLoader } from "../../components/general-component/PageLoader";
// import { formatAmount } from "../../util/formatAmount";
import { AdminStatusBar } from "../../components/admin-component/AdminStatusBar";
import AdminBidderInvoiceCard from "../../components/admin-component/admin-index/AdminBIdderInvoiceCard";
import { AdminUserTotalSummaryCard } from "../../components/admin-component/admin-index/AdminUserTotalSummaryCard";
import { AdminSearchFilter } from "../../components/admin-component/AdminSearchFilter";
import { AdminExpansionHideButton } from "../../components/admin-component/AdminExpansionHideButton";
import AdminInvoiceCard from "../../components/admin-component/admin-invoice/AdminInvoiceCard";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { formatAmount } from "../../util/formatAmount";
import { AnimatePresence, motion } from "framer-motion";

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashBoardPage = () => {
  const [modal, setModal] = useState(false);
  const [high, setHigh] = useState(false);
  const [selection, setSelection] = useState("seller");
  const [searchFilter, setSearchFilter] = useState("");

  const [dashboardData, setDashboardData] = useState();
  const [expire, setExpire] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // mobile deposit & withdrawal Modal
  const controlModal = () => {
    setModal(!modal);
  };

  // invoice container toggler
  const toggleControl = () => {
    setHigh(!high);
  };

  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };

  // console.log(rate)

  //  chart data Array

  const chartData = dashboardData && [
    dashboardData?.pending_tasks.pendingInvoices,
    dashboardData?.pending_tasks.pendingPaymentRequest,
    dashboardData?.pending_tasks.pendingUploadConfirmation,
    dashboardData?.pending_tasks.unverifiedUsers,
    // parseInt(dashboardData.invoice_summary.pending),
    // parseInt(dashboardData.invoice_summary.approved),
    // parseInt(dashboardData.invoice_summary.sold),
    // parseInt(dashboardData.invoice_summary.closed),
    // parseInt(dashboardData.invoice_summary.rejected),
  ];
  const dataChart = {
    labels: [
      "Pending Invoice",
      "Pending Payment",
      "Pending Upload Confirmation",
      "Unverified Users",
    ],
    datasets: [
      {
        label: "My First Dataset",
        data: chartData,
        backgroundColor: ["#E5AA33", "#BF3030", "#3FBB97", "#565CD6"],
        cutout: "90%",
        circumference: 360,
        rotation: 270,
        hoverOffset: 5,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const total =
    dashboardData &&
    dashboardData?.pending_tasks.pendingInvoices +
      dashboardData?.pending_tasks.pendingPaymentRequest +
      dashboardData?.pending_tasks.pendingUploadConfirmation +
      dashboardData?.pending_tasks.unverifiedUsers;

  const loadData = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_dashboard/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setDashboardData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  return (
    <>
      <AdminStatusBar
        //  activated={dashboardData?.user_details.verified}
        activated={true}
        loadData={loadData}
        title={`${dashboardData?.user_details.name} Dashboard`}
      />

      <div className="admin-dashboard-app-holder">
        <AnimatePresence>
          {!high && (
            <motion.div
              className="admin-dashboard-first-half"
              initial={{ y: "-100%" }}
              animate={{ y: 0 }}
              exit={{ y: "-100%" }}
              transition={{ duration: 1 }}
            >
              <div className="admin-dashboard-chart">
                {total ? (
                  <div className="admin-chart">
                    <h1 className="admin-invoice-header ">Pending Task List</h1>
                    <span className="admin-centralization-cover">
                      <Doughnut
                        data={dataChart}
                        options={options}
                        className="admin-doughnut"
                      />
                      <div className="admin-chart-text">
                        <h5 className="admin-invoice-label">Total:</h5>
                        <h4 className="admin-invoice-total">{total}</h4>
                      </div>
                    </span>
                    <ul className="admin-invoice-color-panel mobile-hide">
                      <li className="admin-color-status">
                        <span style={{ background: "#E5AA33" }}></span>
                        Pending Invoice
                      </li>
                      <li className="admin-color-status">
                        <span style={{ background: "#BF3030" }}></span>
                        Pending Payment
                      </li>
                      <li className="admin-color-status">
                        <span style={{ background: "#3FBB97" }}></span>
                        Pending Upload
                      </li>
                      <li className="admin-color-status">
                        <span style={{ background: "#565CD6" }}></span>
                        Unverified Users
                      </li>
                    </ul>
                  </div>
                ) : (
                  <>
                    <h4 className="admin-chart-empty-header">Invoice Status</h4>
                    <NoInvoiceIcon />
                    <p className="admin-no-data-text">
                      You currently do not have any Existing Invoice
                    </p>
                  </>
                )}
              </div>
              <div className="admin-user-summary-details-board">
                <div className="admin-user-summary-details-holder">
                  <AdminUserTotalSummaryCard
                    userName="Total Investors"
                    userTotalNumber={
                      dashboardData?.customers_summary.totalInvestors
                    }
                    statusName="unverified"
                    statusCount={
                      dashboardData?.customers_summary.unverifiedInvestors
                    }
                  />
                  <AdminUserTotalSummaryCard
                    userName="Total Vendors"
                    userTotalNumber={
                      dashboardData?.customers_summary.totalVendors
                    }
                    statusName="unverified"
                    statusCount={
                      dashboardData?.customers_summary.unverifiedVendors
                    }
                  />
                  <AdminUserTotalSummaryCard
                    userName="Total Sellers"
                    userTotalNumber={
                      dashboardData?.customers_summary.totalSellers
                    }
                    statusName="unverified"
                    statusCount={
                      dashboardData?.customers_summary.unverifiedSellers
                    }
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className={`admin-dashboard-second-half ${high ? "long" : ""}`}>
          {dashboardData?.bidded_invoices.length ? (
            <div className="admin-second-half-header mobile-hide">
              <div className="admin-filter mobile-hide">
                <p className="admin-filter mobile-hide">All Bids</p>
                <section className="admin-filter-left">
                  <span
                    style={{
                      display: !high === false ? "flex" : "none",
                    }}
                  >
                    <AdminSearchFilter
                      search={searchFilter}
                      setSearch={setSearchFilter}
                    />
                  </span>
                  <span className="admin-filter-expansion-case">
                    <AdminExpansionHideButton
                      toggleControl={toggleControl}
                      high={high}
                    />
                  </span>
                </section>
              </div>
              <table className="admin-page-invoice-table2">
                <tbody>
                  <tr className="admin-table-invoice-header2 table">
                    <th>Invoice Name</th>
                    <th>Invoice Price</th>
                    <th>Bidders</th>
                    <th>Seller</th>
                    <th>Vendor</th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="admin-invoice-empty-header mobile-hide">
              All Invoices
            </div>
          )}

          <div className="admin-invoice-scroll scroll">
            {dashboardData?.bidded_invoices.length ? (
              <div className="admin-invoice-data-holder mobile-hide">
                {dashboardData?.bidded_invoices.map((invoice, index) => (
                  <AdminBidderInvoiceCard
                    key={invoice?.invoice_id}
                    price={formatAmount(invoice?.invoice_amount)}
                    topBid={4.5}
                    bidderNumber={invoice?.no_of_bidders}
                    seller={invoice?.seller}
                    id={invoice?.invoice_id}
                    vendor={invoice?.vendor}
                    invoiceName={invoice?.invoice_name}
                    sellerId={invoice?.seller_id}
                    vendorId={invoice?.vendor_id}
                    url={invoice?.invoice_url}
                    loadData={loadData}
                  />
                ))}
              </div>
            ) : (
              <div className="admin-empty-invoice mobile-hide">
                <NoInvoiceIcon />
                <p>You currently do not have any active Invoice </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashBoardPage;
