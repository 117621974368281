import React from "react";
import "../../../styles/admin/admin-invoice/admin-notify-invoice-card.scss";

export const AdminNotifyInvoiceCard = (props) => {
  const { invoiceName, bidAmount, bidDate, bidTime, bidRate, index, isTop } =
    props;

  // const max = 5
  // const min = 0

  // Math.max()
  // var random;
  // var max = 5
  // function findRandom() {
  //   random = Math.floor(Math.random(bidRate) * max) //Finds number between 0 - max
  //   console.log(random)
  // }
  // findRandom()

  // console.log(Math.min(bidRate))

  // console.log(rate);

  return (
    <>
      <tr
        // style={{
        //     background:rate? "#1fcc61" : "#802929"
        // }}
        className={`admin-notify-invoice-cards ${isTop ? "top" : ""}`}
      >
        <td>{invoiceName}</td>
        <td
        // style={{
        // color:bidRate <= random? "#1fcc61" : "#802929"
        // }}
        >
          {bidRate}%
        </td>
        <td>₦{bidAmount}</td>
        <td>{bidTime}</td>
        <td>{bidDate}</td>
      </tr>
    </>
  );
};
