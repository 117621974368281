import { validateEmail } from "./validateEmail";
import { validatePassword } from "./validatePassword";
import { validateText } from "./validateText";

/**
 * validateInvestorSignUp checks if all fields in sign up form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateInvestorSignUp({
  firstName,
  lastName,
  email,
  password,
}) {
  const f = validateText(firstName);
  const l = validateText(lastName);
  const e = validateEmail(email);
  const p = validatePassword(password);

  const v = {
    isValid: true,
    error: undefined,
  };

  if (!f.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      firstName: "First Name is required",
    };
  }

  if (!l.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      lastName: "Last Name is required",
    };
  }

  if (/\s/g.test(firstName)) {
    v.isValid = false;
    v.error = {
      ...v.error,
      firstName: "Please, add just a name",
    };
  }

  if (/\s/g.test(lastName)) {
    v.isValid = false;
    v.error = {
      ...v.error,
      lastName: "Please, add just a name",
    };
  }

  if (!e.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      email: e.error,
    };
  }

  if (!p.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      password: p.error,
    };
  }

  return v;
}
