import React from 'react'

export const FiatWithdrawIcon = ({svgColor}) => {
  return (
    <svg 
        width="50" 
        height="40" 
        viewBox="0 0 50 40" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M26.6871 14.5117C26.3436 19.7681 26.3436 25.084 26.6871 30.3387H23.3135C23.657 25.0824 23.657 19.7653 23.3135 14.5117H26.6871Z" 
        fill={svgColor}
    />
    <path 
        d="M39.4664 14.5117C39.1229 19.7681 39.1229 25.084 39.4664 30.3387H36.0928C36.4363 25.0824 36.4363 19.7653 36.0928 14.5117H39.4664Z" 
        fill={svgColor}
    />
    <path 
        d="M3.45117 34.8722C3.45117 35.2208 3.73741 35.5061 4.08995 35.5061H45.9109C46.2634 35.5061 46.5497 35.2208 46.5497 34.8722C46.5497 34.5223 46.2634 34.2383 45.9109 34.2383H4.08995C3.73612 34.2383 3.45117 34.5223 3.45117 34.8722Z" 
        fill={svgColor}
    />
    <path 
        d="M49.3612 38.7324H0.638782C0.286225 38.7324 0 39.0165 0 39.3663C0 39.7162 0.286236 40.0002 0.638782 40.0002H49.3612C49.7138 40.0002 50 39.7162 50 39.3663C50 39.0165 49.7163 38.7324 49.3612 38.7324Z"
        fill={svgColor}
    />
    <path 
        d="M13.9085 30.3397H10.5361C10.8784 25.0833 10.8784 19.7662 10.5361 14.5127H13.9085C13.567 19.7666 13.567 25.0837 13.9085 30.3397Z" 
        fill={svgColor}
    />
    <path 
        d="M23.5428 2.34564C23.7024 2.28747 23.8329 2.16816 23.9018 2.01573C23.9707 1.86373 23.9822 1.68838 23.9223 1.53C23.8304 1.27779 23.8304 0.99884 23.9223 0.746212C24.0297 0.459617 24.2394 0.232457 24.5192 0.104665C24.7977 -0.0222907 25.1084 -0.0337467 25.3984 0.0702729C25.6859 0.174297 25.9148 0.383617 26.0424 0.661299C26.1728 0.93771 26.1844 1.24723 26.0783 1.53255C26.0197 1.69092 26.0274 1.86585 26.0988 2.01828C26.1703 2.17028 26.2995 2.2896 26.4578 2.34947L42.1502 8.06734L41.6967 10.9914L13.8311 10.9919C15.2956 9.36862 16.2322 7.27125 16.3763 4.96182L23.5428 2.34564Z" 
        fill={svgColor}
    />
    <path 
        d="M26.4132 2.34564C26.2536 2.28747 26.1231 2.16816 26.0542 2.01573C25.9854 1.86373 25.9738 1.68838 26.0337 1.53C26.1257 1.27779 26.1257 0.99884 26.0337 0.746212C25.9263 0.459617 25.7167 0.232457 25.4369 0.104665C25.1583 -0.0222907 24.8477 -0.0337467 24.5576 0.0702729C24.2701 0.174297 24.0412 0.383617 23.9137 0.661299C23.7832 0.93771 23.7717 1.24723 23.8778 1.53255C23.9364 1.69092 23.9287 1.86585 23.8572 2.01828C23.7858 2.17028 23.6566 2.2896 23.4983 2.34947L7.80584 8.06734L8.25935 10.9914L36.125 10.9919C34.6605 9.36862 33.7239 7.27125 33.5797 4.96182L26.4132 2.34564Z" 
        fill={svgColor}
    />
    </svg>
  )
}
