import React, { useState } from "react";
import "../../../styles/investor/bids-styles/bid-invoice-card.scss";
import InvestorActiveBidEditorModal from "../dashboard/InvestorActiveBidEditorModal";
import { formatAmount } from "../../../util/formatAmount";

export const MobileInvestorInvoiceCard = (props) => {
  const [modal, setModal] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  const { topBid,topBidUSD, invoiceName, amount, timeRemaining, yourBid, yourBidUSD, url , amountUSD, ROI,loadBids,id,originalAmount, maxPayable} = props;
   const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  return (
    <div className="mobile-invoice-card-container">
      <div
        className="mobile-first-card-bid
                "
        style={{ width: "75%" }}
      >
        <h2 className="mobile-invoice-name-bid">{shortInvoiceName}</h2>
        <h4 className="mobile-invoice-top-bid">
          Top Bid:
          <span
            className="big-bid"
            style={{
              color: topBid > 5 ? "black" : topBid < 4.5 ? "red" : "#3FBB97",
            }}
          >
            {userCurrency === "naira" ? `₦`:`$` }{formatAmount(topBid)}
          </span>
        </h4>
        <h2 className="mobile-invoice-date">{timeRemaining}</h2>
        <h4 className="mobile-card-your-bid">
          Your Bid:
          <span
            className="big-bid"
            style={{
              color: yourBid > 5 ? "black" : yourBid < 4.5 ? "red" : "#3FBB97",
            }}
          >
            {userCurrency === "naira" ? `₦`:`$` }{formatAmount(yourBid)}
          </span>
        </h4>
      </div>
      <div className="mobile-second-card-bid" style={{ width: "25%" }}>
        <h2 className="mobile-invoice-amount-bid">{userCurrency === "naira" ? `₦`:`$` }{formatAmount(amount)} </h2>
        <button onClick={controlModal} className="mobile-bid-editor-btn">
          Edit Bid
        </button>
      </div>

      <InvestorActiveBidEditorModal
        controlModal={controlModal}
        modal={modal}
        url={url}
        invoiceAmount={originalAmount}
        ROI={ROI}
        id={id}
        maxPayable={maxPayable}
        loadInvestor={loadBids}
      />
    </div>
  );
};
// controlModal={toggleModal}
        // invoiceAmount={originalAmount}
        // maxPayable={maxPayable}
        // url={url}
        // modal={modal}
        // ROI={ROI}
        // id={id}
        // originalAmount={originalAmount}
        // loadInvestor={loadBids}
