import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/investor/investor-dashboard-styles/investor-invoice-detail-modal.scss";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { convertTime } from "../../../util/convertTime";
import { validateBid } from "../../../util/validate/validateBid";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { formatAmount } from "../../../util/formatAmount";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";

export const InvestorInvoiceDetailModal = (props) => {
  const {
    modal,
    credit,
    id,
    ROI,
    dueDate,
    dueTime,
    bidderNumber,
    sellerName,
    vendorPhoneNumber,
    vendorEmail,
    maxPayable,
    maxPayable_,
    maxPayableUSD,
    maxPayableUSD_,
    invoiceAmount,
    invoiceAmountUSD,
    vendorName,
    invoiceName,
    onClose,
    invoiceURL,
    loading,
    loadInvestor,
    verified,
    onActivate,
    //    statusIndicator
  } = props;
  // console.log(props ,"verified")
  const [time, setTime] = useState("");
  const [bid, setBid] = useState("");
  const [bidError, setBidError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  const handleBidPlacing = async (evt) => {
    evt.preventDefault();

    const { isValid, error } = validateBid(bid,maxPayable_);

    if (!isValid) {
      setBidError(error);
    } else {
      const token = localStorage.getItem("token");

      try {
        setIsLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        
        const amount = parseFloat(bid);
        const dti = (1-(amount/invoiceAmount))*100;
        const body = {
          amount: bid,
          buyer_dti: dti,
          invoice_id: id,
        };
        console.log(body)
        const { data } = await axios.post(
          `${BACKEND_URL}/make_bid/${token}`,
          body,
          config
        );

        setIsLoading(false);
        console.log(data);

        if (data.success) {
          onClose();
          setSuccess(true);
          loadInvestor();

          setTimeout(() => {
            setSuccess(false);
          }, 1000);
          return;
        }

        if (data.status === 201) {
          setError(data.message);
          setSuccess(true);

          setTimeout(() => setSuccess(false), 2000);
          setTimeout(() => setError(null), 2500);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (convertTime(dueTime).difference <= 0) {
      setTime("Bid closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(dueTime).text);
      }, [1000]);

      return () => clearTimeout(timer);
    }
  });

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="investor-invoice-detail-modal">
              <div
                onClick={onClose}
                className="investor-invoice-detail-overlay"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="investor-detail-invoice-modal"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {loading ? (
                    "Loading..."
                  ) : (
                    <>
                      <div className="investor-modal-invoice-head">
                        <h3>Invoice Detail</h3>
                        <span onClick={onClose}>
                          <CloseIcon />
                        </span>
                      </div>

                      <section className="investor-direction-change">
                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Invoice Name</h5>
                            <h3>{invoiceName}</h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>Vendor Name</h5>
                            <h3>{vendorName}</h3>
                          </span>
                        </div>

                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Invoice Amount</h5>
                            {userCurrency === "naira" ? 
                              <h3>₦{formatAmount(invoiceAmount)} </h3> 
                            :
                            <h3>${formatAmount(invoiceAmountUSD)} </h3> 
                            }
                          </span>
                          <span className="invest-detail-one">
                            {/* <h5>Max Payable Amount</h5> */}
                            <h5>Minimum Biddable Amount</h5>
                            {/* <h3></h3> */}
                            {userCurrency === "naira" ? 
                              <h3>₦{maxPayable} </h3> 
                            :
                            <h3>${maxPayableUSD}</h3> 
                            }
                          </span>
                        </div>

                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Vendor Email</h5>
                            <h3>{vendorEmail}</h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>Vendor Contact</h5>
                            <h3>{vendorPhoneNumber}</h3>
                          </span>
                        </div>
                      </section>

                      <section className="investor-direction-change">
                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Seller Name</h5>
                            <h3>{sellerName}</h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>No of Bidders</h5>
                            <h3>{bidderNumber}</h3>
                          </span>
                        </div>

                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Seller Credit Status</h5>
                            <h3
                              className="credit-status"
                              style={{
                                color:
                                  credit >= 70
                                    ? "green"
                                    : credit >= 50
                                    ? "blue"
                                    : "#E07E24",
                              }}
                            >
                              {credit}%
                            </h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>Minimum Payable Rate</h5>
                            <h3
                              style={{
                                color: (100-ROI) >= 7 ? "#D13D2A" : "#E07E24",
                              }}
                            >
                              {100-ROI}%
                            </h3>
                          </span>
                        </div>

                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Due date</h5>
                            <h3>{dueDate}</h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>Time Remaining</h5>
                            <h3>{time}</h3>
                          </span>
                        </div>
                      </section>
                              
                      {verified ? (
                        <form
                          className="invest-preview-holder"
                          onSubmit={handleBidPlacing}
                        >
                          <div className="bid-editor-holder">
                            <input
                              type="text"
                              placeholder="E.g 10000"
                              className="bid-editor"
                              onChange={(evt) => {
                                bidError && setBidError(null);
                                setBid(evt.target.value);
                              }}
                              value={bid}
                            />
                            {bidError && (
                              <div className="error-text">{bidError}</div>
                            )}
                          </div>
                          <button className="place-bid-btn">Place Bid</button>
                        </form>
                      ) : (
                        <div className="unverified-text">
                          You can't place a bid unless you are verified. Click{" "}
                          <button onClick={onActivate}>here</button> to verify
                        </div>
                      )}

                      <p className="invest-preview">
                        To preview Invoice,
                        <a
                          href={invoiceURL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Click here
                        </a>
                      </p>
                    </>
                  )}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
        {isLoading && <ModalLoader />}
      </AnimatePresence>

      <AnimatePresence>
        {success && <Toolkit text={error ?? "Bid Created"} />}
      </AnimatePresence>
    </>
  );
};
