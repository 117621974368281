import React, { useState, useEffect } from "react";
import "../../../styles/investor/investor-dashboard-styles/investor-balance-card.scss";
import {
  HidePasswordIcon,
  NigeriaFlag,
  PasswordIcon,
  USAFlag,
} from "../../icons";
import CryptoDepositModal from "./CryptoDepositModal";
import CryptoWithdrawalModal from "./CryptoWithdrawalModal";
import FiatBankWithdrawalModal from "./FiatBankWithdrawalModal";
import InvestorDepositModalCard from "./InvestorDepositModalCard";
import InvestorWithdrawModalCard from "./investorwithdrawModalCard";
import NairaFiatDepositModal from "./NairaFiatDepositModal";

export const InvestorBalanceCard = (props) => {
  const {
    nairaMoney,
    USDMoney,
    country,
    email,
    loadData,
    memo_field,
    wallet_address,
    userDetails,
  } = props;

  const getCurrency = () => {
    switch (country) {
      case "U.S.A":
        localStorage.setItem("userCurrency", JSON.stringify("dollar"));
        return "dollar";

      case "Nigeria":
        localStorage.setItem("userCurrency", JSON.stringify("naira"));
        return "naira";

      default:
        // localStorage.setItem("userCurrency", JSON.stringify("naira"));
        // return "naira";
        return null;
    }
  };

  const getFlag = () => {
    switch (country) {
      case "U.S.A":
        return <USAFlag />;

      case "Nigeria":
        return <NigeriaFlag />;

      default:
        return null;
    }
  };

  const [display, setDisplay] = useState(false);
  const [flag, setFlag] = useState(getFlag());
  const [symbol, setSymbol] = useState(getCurrency());
  const [modal, setModal] = useState(false);
  const [appear, setAppear] = useState(false);

  const controlAppear = () => {
    setAppear(!appear);
  };
  const controlModal = () => {
    setModal(!modal);
  };

  const toggleDisplay = () => {
    setDisplay(!display);
  };

  useEffect(() => {
    setSymbol(getCurrency());
    setFlag(getFlag());
  }, [country]);

  return (
    <div className="investor-balance-container">
      <div className="investor-balance-upper">
        <div className="investor-upper-first">
          <div className="investor-upper-first-left">
            <h5 className="investor-balance-name">Quidroo Balance</h5>

            <span className="investor-balance-figure">
              {/* <ModifiedInvestorInputOption
                selected={flag}
                setSelected={setFlag}
                setSymbol={setSymbol}
              /> */}
              {flag}
              {display && (
                <span className="money-container">
                  <span className="money-symbol">
                    {symbol === "naira"
                      ? `₦${nairaMoney}`
                      : symbol === "dollar"
                      ? `$${USDMoney}`
                      : symbol === "pound"
                      ? "£"
                      : "0.00"}
                  </span>
                  {/* <span className="money-digit">{money}</span> */}
                </span>
              )}

              {!display && (
                <span className="blind-digit">
                  <p>****</p>
                </span>
              )}
            </span>
          </div>
          <div onClick={toggleDisplay} className="investor-upper-first-right">
            {display ? <PasswordIcon /> : <HidePasswordIcon />}
          </div>
        </div>
        <div className="investor-upper-second mobile-hide">
          <button
            onClick={controlAppear}
            className="investor-balance-withdrawal"
          >
            Withdraw
          </button>

          <button onClick={controlModal} className="investor-balance-deposit">
            Deposit
          </button>
        </div>
      </div>

      {country === "Nigeria" ? (
        <NairaFiatDepositModal
          modal={modal}
          email={email}
          controlModal={controlModal}
          loadData={loadData}
        />
      ) : (
        <CryptoDepositModal
          controlModal={controlModal}
          modal={modal}
          memo_field={memo_field}
          loadData={loadData}
          wallet_address={wallet_address}
        />
      )}

      {country === "Nigeria" ? (
        <FiatBankWithdrawalModal
          controlModal={controlAppear}
          modal={appear}
          userDetails={userDetails}
          loadData={loadData}
        />
      ) : (
        <CryptoWithdrawalModal
          controlModal={controlAppear}
          modal={appear}
          loadData={loadData}
          closeAll={controlModal}
        />
      )}

      {/* <InvestorDepositModalCard
        controlModal={controlModal}
        modal={modal}
        email={email}
        loadData={loadData}
        memo_field={memo_field}
        country={country}
        wallet_address={wallet_address}
      />

      <InvestorWithdrawModalCard
        loadData={loadData}
        controlModal={controlAppear}
        modal={appear}
        country={country}
        userDetails={userDetails}
      /> */}
    </div>
  );
};
