import React, { useState, useRef, useEffect } from "react";
import InvestorProfile from "../../pages/investor-dashboard/investor-profile";
import dummy from "../../assest/blank-profile-picture.png";
import "../../styles/investor-top-bar.scss";
import {
  CloseIcon,
  DownwardArrowIcon,
  ProfileIcon,
  ViewPhotoIcon,
} from "../icons";

import { MainLogo } from "../icons/logo/index";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { Toolkit } from "./Toolkit";

export const InvestorTopBar = ({ show }) => {
  const [hide, setHide] = useState(false);
  const [open, setOpen] = useState(false);
  const [appear, setAppear] = useState(false);
  const [image, setImage] = useState();
  const [profileData, setProfileData] = useState();
  const [success, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef(null);

  const toggleHide = () => {
    setHide(!hide);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const toggleAppear = () => {
    setAppear(!appear);
  };

  useOutsideClick(ref, () => setHide(false));

  const loadProfile = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/investor_profile/${token}`,
        config
      );
      setIsLoading(false);

      if (data.success) setProfileData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <div className="container-case ">
      <div className="logo-aside">{show && <MainLogo />}</div>
      <button className="image-logo-profile" onClick={toggleHide} ref={ref}>
        {profileData?.profile_pics ? (
          <img
            src={profileData?.profile_pics}
            alt={profileData?.firstname}
            className="profile-id"
          />
        ) : (
          <img
            src={dummy}
            alt={profileData?.firstname}
            className="profile-id"
          />
        )}

        <span className="down-presser">
          <DownwardArrowIcon />
        </span>
      </button>

      <AnimatePresence>
        {hide && (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="profile-button-container"
          >
            <span className="btn-holder" onClick={toggleAppear}>
              <ViewPhotoIcon />
              <h4 className="description-name">View Photo</h4>
            </span>
            <span className="btn-holder" onClick={toggleOpen}>
              <ProfileIcon />
              <h4 className="description-name">Profile</h4>
            </span>
          </motion.section>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {appear && (
          <motion.div
            className="view-photo-container"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "just" }}
          >
            <div className="profile-close">
              <span onClick={toggleAppear}>
                <CloseIcon color="#D13D2A" />
              </span>
            </div>
            <span className=" photo-case-container">
              {profileData?.profile_pics ? (
                <img
                  src={profileData?.profile_pics}
                  alt="profile-id"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                <img src={dummy} alt="" />
              )}
            </span>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {open && (
          <InvestorProfile
            toggleOpen={toggleOpen}
            initImage={profileData?.profile_pics}
            initFirstName={profileData?.firstname}
            initLastName={profileData?.lastname}
            initPhoneNumber={profileData?.phone}
            initAddress={profileData?.address}
            loadProfile={loadProfile}
            onSuccess={() => {
              setSuccess(true);
              setTimeout(() => setSuccess(false), 3000);
            }}
            // setImage={setImage}
            loading={isLoading}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {success && <Toolkit text="Profile updated" />}
      </AnimatePresence>
    </div>
  );
};
