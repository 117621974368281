import { useState } from "react";
import "../../styles/admin/admin-login-page.scss";
import "../../styles/index.scss";
import { Link } from "react-router-dom";
import { MainLogo, MobileLogo } from "../../components/icons/logo";
import { OptimizedInput } from "../../components/form/OptimizedInput";
import {
  HidePasswordIcon,
  LeftArrowIcon,
  PasswordIcon,
} from "../../components/icons";
// import { validateSignIn } from "../util/validate/validateSignIn";
// import axios from "axios";
// import { BACKEND_URL } from "../config";
import { useNavigate } from "react-router";
import { validateSignIn } from "../../util/validate/validateSignIn";
import axios from "axios";
import { BACKEND_URL } from "../../config";

const AdminLoginPage = () => {
  // error states
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [control, setControl] = useState(false);

  // loading state
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const toggleChange = () => {
    if (control === true) {
      setControl(false);
    } else {
      setControl(true);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // handle error states
    const { isValid, error } = validateSignIn({
      email: email.toLowerCase().trim(),
      password,
    });

    if (!isValid) {
      if (error?.email) {
        setEmailError(error.email);
      }

      if (error?.password) {
        setPasswordError(error.password);
      }
    } else {
      try {
        setIsLoading(true);
        const body = {
          email: email.toLowerCase().trim(),
          password,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BACKEND_URL}/admin_signin`,
          body,
          config
        );

        setIsLoading(false);

        if (!data.success) {
          if (data.message === "Wrong Password") {
            setPasswordError(data.message);
          } else {
            setEmailError(data.message);
          }
          return;
        }

        localStorage.setItem("token", data.token);
        navigate("/admin/dashboard");
      } catch (error) {
        const message =
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error;

        if (message === "Wrong Password") {
          setPasswordError(message);
        } else {
          setEmailError(message);
        }
      }
    }
  };

  return (
    <div className="admin-login-container">
      <span className="admin-big-logo mobile-hide">
        <MainLogo />
      </span>

      <div className="admin-page-card">
        <span className="admin-small-logo window-hide">
          <MobileLogo />
        </span>
        <span className="page-title-case">
          <h3 className="admin-page-title">Login</h3>
        </span>

        <form onSubmit={handleSubmit}>
          <div className="admin-card-button">
            <section className="cover">
              <OptimizedInput
                title="Email Address"
                name="Email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                onFocus={() => emailError && setEmailError(null)}
              />
              {emailError && <div className="error-text">{emailError}</div>}

              <OptimizedInput
                title="Password"
                name="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onIconClick={toggleChange}
                type={control ? "text" : "password"}
                icon={control ? <HidePasswordIcon /> : <PasswordIcon />}
                onFocus={() => passwordError && setPasswordError(null)}
              />
              {passwordError && (
                <div className="error-text">{passwordError}</div>
              )}
            </section>
            <Link className="topLink mobile-hide" to="/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <div style={{ textAlign: "center" }}>
            <button className="signin-press" onClick={handleSubmit}>
              {isLoading ? <div className="loading" /> : "Log In"}
            </button>
          </div>
        </form>

        <p className="bottomLink window-hide">
          <Link to="/forgot-password"> Forgot Password? </Link>
        </p>
      </div>
    </div>
  );
};

export default AdminLoginPage;
