export const InvoiceEmptyIcon =() =>{
    return(
        <svg 
            width="53" 
            height="70" 
            viewBox="0 0 53 70" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M39.375 35H13.125V43.75H39.375V35ZM51.543 14.3555L38.1582 0.957031C37.543 0.341797 36.709 0 35.834 0H35V17.5H52.5V16.666C52.5 15.8047 52.1582 14.9707 51.543 14.3555ZM30.625 18.5938V0H3.28125C1.46289 0 0 1.46289 0 3.28125V66.7188C0 68.5371 1.46289 70 3.28125 70H49.2188C51.0371 70 52.5 68.5371 52.5 66.7188V21.875H33.9062C32.1016 21.875 30.625 20.3984 30.625 18.5938ZM8.75 9.84375C8.75 9.23945 9.23945 8.75 9.84375 8.75H20.7812C21.3855 8.75 21.875 9.23945 21.875 9.84375V12.0312C21.875 12.6355 21.3855 13.125 20.7812 13.125H9.84375C9.23945 13.125 8.75 12.6355 8.75 12.0312V9.84375ZM8.75 18.5938C8.75 17.9895 9.23945 17.5 9.84375 17.5H20.7812C21.3855 17.5 21.875 17.9895 21.875 18.5938V20.7812C21.875 21.3855 21.3855 21.875 20.7812 21.875H9.84375C9.23945 21.875 8.75 21.3855 8.75 20.7812V18.5938ZM43.75 60.1562C43.75 60.7605 43.2605 61.25 42.6562 61.25H31.7188C31.1145 61.25 30.625 60.7605 30.625 60.1562V57.9688C30.625 57.3645 31.1145 56.875 31.7188 56.875H42.6562C43.2605 56.875 43.75 57.3645 43.75 57.9688V60.1562ZM43.75 32.8125V45.9375C43.75 47.1461 42.7711 48.125 41.5625 48.125H10.9375C9.72891 48.125 8.75 47.1461 8.75 45.9375V32.8125C8.75 31.6039 9.72891 30.625 10.9375 30.625H41.5625C42.7711 30.625 43.75 31.6039 43.75 32.8125Z" 
            fill="#414141"
        />
        </svg>
    )
}