import React, { useEffect, useState } from "react";
import "../../../styles/investor/investor-dashboard-styles/active-bid.scss";
import { convertTime } from "../../../util/convertTime";
import { formatAmount } from "../../../util/formatAmount";
import InvestorActiveBidEditorModal from "./InvestorActiveBidEditorModal";
// import "../../index"
// import {MobileInvoiceDetailModal} from './MobileInvoiceDetailModal';

export const ActiveBids = (props) => {
  const [modal, setModal] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  const [time, setTime] = useState("");
  const [timeDifference, setTimeDifference] = useState(
    convertTime(time).difference
  );

  const {
    ROI,
    invoiceName,
    amount,
    amountUSD,
    bidderNumber,
    vendor,
    dueTime,
    url,
    id,
    loadData,
    maxPayable
  } = props;

  useEffect(() => {
    const { difference } = convertTime(dueTime);
    setTimeDifference(difference);
    if (convertTime(dueTime).difference <= 0) {
      setTime("Bid Closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(dueTime));
      }, [1000]);
      // console.log(userCurrency)
      return () => clearTimeout(timer);
    }
  }, [time]);

  const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  return (
    <div className="active-bid-container">
      <div className="active-bid-part-one">
        <h2 className="active-bid-name">{shortInvoiceName}</h2>
        <h2 className="active-bid-amount"> {userCurrency === "naira" ? `₦`:`$` }{formatAmount(amount)}</h2>
        <div className="active-double-container">
          <h4 className="active-bidder-number mobile-hide">
            No of Bidders: <span className="big-font">{bidderNumber}</span>
          </h4>
          <h4 className="active-rate-on-return">
            Max ROI:{" "}
            <span className="big-font" style={{ color: "red" }}>
              {ROI}%
            </span>
          </h4>
        </div>

        <h4 className="active-bid-vendor mobile-hide">
          Vendor: <span className="big-font active-vendor">{vendor}</span>
        </h4>
      </div>
      <div className="active-bid-part-two">
        <button
          onClick={() => (timeDifference > 0 ? setModal(true) : null)}
          className={`active-bid-edit-btn ${
            timeDifference <= 0 ? "inactive" : ""
          }`}
        >
          Edit<span className="mobile-hide space">Bid</span>
        </button>

        <div className="active-count-down-time">
          {time === "Bid Closed" ? (
            time
          ) : (
            <>
              <span className="hour-panel">
                <h5 className="label-panel">Days</h5>
                <h1 className="timed-digit">{time.days} :</h1>
              </span>

              <span className="minute-panel">
                <h5 className="label-panel">Hrs</h5>
                <h1 className="timed-digit">{time.hrs} :</h1>
              </span>

              <span className="minute-panel">
                <h5 className="label-panel">Mins</h5>
                <h1 className="timed-digit">{time.mins} :</h1>
              </span>

              <span className="seconds-panel">
                <h5 className="label-panel">Sec</h5>
                <h1 className="timed-digit">{time.sec}</h1>
              </span>
            </>
          )}
        </div>
      </div>
      <InvestorActiveBidEditorModal
        controlModal={controlModal}
        modal={modal}
        url={url}
        invoiceAmount={amount}
        ROI={ROI}
        id={id}
        loadInvestor={loadData}
        maxPayable={maxPayable}
      />
    </div>
  );
};
