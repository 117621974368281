import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/admin/admin-invoice/admin-invoice-detail-approval-modal.scss";
// import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { convertTime } from "../../../util/convertTime";
// import { validateBid } from "../../../util/validate/validateBid";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { formatAmount } from "../../../util/formatAmount";
import { AdminConfirmationModal } from "./AdminConfirmationModal";
// import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";
import { PageLoader } from "../../general-component/PageLoader";
import { Toolkit } from "../../general-component/Toolkit";

export const AdminInvoiceDetailApprovalModal = ({
  modal,
  credit,
  setterFalse,
  id,
  ROI,
  dueDate,
  // dueTime,
  bidderNumber,
  sellerName,
  vendorPhoneNumber,
  vendorEmail,
  maxPayable,
  invoiceAmount,
  vendorName,
  invoiceName,
  onClose,
  invoiceURL,
  vendorContact,
  dueTime,
  loading,
  loadData,
  // verified,
  // onActivate,

  //    statusIndicator
}) => {
  const [time, setTime] = useState("");
  const [bid, setBid] = useState("");
  const [approval, setApproval] = useState(false);
  const [rejected, setRejected] = useState(false);

  // const [bidError, setBidError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rejectSuccess, setRejectSuccess] = useState(false);
  const [error, setError] = useState(null);

  const toggleApproval = () => {
    setApproval(!approval);
    // setterFalse()
  };

  const toggleRejected = () => {
    setRejected(!rejected);
    // setterFalse()
  };

  const handleRejection = () => {};
  const handleConfirmation = () => {};

  const handleApprove = async () => {
    setApproval(false);
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);
      const body = {
        invoice_id: id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_approve_invoice/${token}`,
        body,
        config
      );

      if (data.success) {
        setRejectSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 2000);
        setTimeout(() => {
          onClose();
          loadData();
        }, 2500);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Something went wrong! Please try again.");
      console.error(error);
    }
  };

  const handleReject = async () => {
    setRejected(false);
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);
      const body = {
        invoice_id: id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/admin_reject_invoice/${token}`,
        body,
        config
      );

      if (data.success) {
        setRejectSuccess(true);
        setTimeout(() => {
          setIsLoading(false);
          setRejectSuccess(false);
        }, 2000);
        setTimeout(() => {
          onClose();
          loadData();
        }, 2500);
      }
    } catch (error) {
      setIsLoading(false);
      setError("Something went wrong! Please try again.");
      console.error(error);
    }
  };

  useEffect(() => {
    if (convertTime(dueTime).difference <= 0) {
      setTime("Bid closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(dueTime).text);
      }, [1000]);

      return () => clearTimeout(timer);
    }
  });

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="admin-invoice-detail-modal">
              <div onClick={onClose} className="admin-invoice-detail-overlay">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                  className="admin-detail-invoice-modal"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  {loading ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="admin-modal-invoice-head">
                        <h3>Invoice Detail</h3>
                        <span onClick={onClose}>
                          <CloseIcon />
                        </span>
                      </div>

                      <section className="admin-direction-change">
                        <div className="admin-row-container">
                          <span className="admin-detail-one">
                            <h5>Invoice Name</h5>
                            <h3>{invoiceName}</h3>
                          </span>
                          <span className="admin-detail-one">
                            <h5>Vendor Name</h5>
                            <h3>{vendorName}</h3>
                          </span>
                        </div>

                        <div className="admin-row-container">
                          <span className="admin-detail-one">
                            <h5>Invoice Amount</h5>
                            <h3>₦{invoiceAmount}</h3>
                          </span>
                          <span className="admin-detail-one">
                            <h5>Max Payable Amount</h5>
                            <h3>₦{maxPayable}</h3>
                          </span>
                        </div>

                        <div className="admin-row-container">
                          <span className="admin-detail-one">
                            <h5>Vendor Email</h5>
                            <h3>{vendorEmail}</h3>
                          </span>
                          <span className="admin-detail-one">
                            <h5>Vendor Contact</h5>
                            <h3>{vendorContact}</h3>
                          </span>
                        </div>
                      </section>

                      <section className="admin-direction-change">
                        <div className="admin-row-container">
                          <span className="admin-detail-one">
                            <h5>Seller Name</h5>
                            <h3>{sellerName}</h3>
                          </span>
                          <span className="admin-detail-one">
                            <h5>No of Bidders</h5>
                            <h3>{bidderNumber}</h3>
                          </span>
                        </div>

                        <div className="admin-row-container">
                          <span className="admin-detail-one">
                            <h5>Seller Credit Status</h5>
                            <h3
                              className="credit-status"
                              style={{
                                color:
                                  credit >= 70
                                    ? "green"
                                    : credit >= 50
                                    ? "blue"
                                    : "#E07E24",
                              }}
                            >
                              {credit}%
                            </h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>Max Discount Rate</h5>
                            <h3
                              style={{
                                color: ROI >= 7 ? "#D13D2A" : "#E07E24",
                              }}
                            >
                              {ROI}%
                            </h3>
                          </span>
                        </div>

                        <div className="invest-row-container">
                          <span className="invest-detail-one">
                            <h5>Due date</h5>
                            <h3>{dueDate}</h3>
                          </span>
                          <span className="invest-detail-one">
                            <h5>Time Remaining</h5>
                            <h3>{time}</h3>
                          </span>
                        </div>
                      </section>

                      <div className="admin-invoice-detail-button-container">
                        <button
                          onClick={toggleRejected}
                          className="admin-invoice-detail-button rejected"
                        >
                          Reject
                        </button>

                        <span className="admin-invoice-detail-middle-line"></span>

                        <button
                          onClick={toggleApproval}
                          className="admin-invoice-detail-button  approve"
                        >
                          Approve
                        </button>
                      </div>

                      <p className="invest-preview">
                        To preview Invoice,
                        <a
                          href={invoiceURL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Click here
                        </a>
                      </p>
                    </>
                  )}
                  {/* )} */}
                </motion.div>
              </div>
              <AdminConfirmationModal
                toggleChange={toggleRejected}
                modal={rejected}
                topic="Reject"
                topicColor="#BF3030"
                statement="Confirm the rejection of the sale of this Invoice"
                noBgColor="#F3F3F3"
                noColor="#414141"
                yesBgColor="#FEE4E4"
                yesColor="#BF3030"
                handleConfirm={handleReject}
              />

              <AdminConfirmationModal
                toggleChange={toggleApproval}
                modal={approval}
                topic="Confirmation"
                statement="Would you like to Approve/Authorize the sale of this Invoice"
                noBgColor="#F3F3F3"
                noColor="#414141"
                yesBgColor="#C7E0FF"
                yesColor="#081B33"
                handleConfirm={handleApprove}
              />
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
      {isLoading && <ModalLoader />}

      <AnimatePresence>
        {success && <Toolkit text="Invoice approved successfully" />}
      </AnimatePresence>

      <AnimatePresence>
        {error && <Toolkit text={error} onClose={() => setError(null)} />}
      </AnimatePresence>

      <AnimatePresence>
        {rejectSuccess && <Toolkit text="Invoice rejected successfully" />}
      </AnimatePresence>
    </>
  );
};
