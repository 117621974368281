import { validateAmount } from "./validateAmount";
import { validateText } from "./validateText";

/**
 * validateUSDCDeposit checks if all fields in USDC deposit form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateUSDCDeposit({ amount, hash }) {
  const a = validateAmount(amount);
  const h = validateText(hash);

  const v = {
    isValid: true,
  };

  if (!a.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      amount: a.error,
    };
  }

  if (!h.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      hash: "Please, enter transaction hash",
    };
  }

  return v;
}
