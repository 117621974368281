import React, { useState } from "react";
import "../../../styles/vendor/vendor-transaction/vendor-transaction-invoice-card.scss";
import { VendorTransactionModalCard } from "./VendorTransactionModalCard";

export const VendorTransactionInvoiceCard = (props) => {
  const { price, date, reference, time, name, type } = props;
  const [modal, setModal] = useState(false);

  const toggleTransactionModal = () => {
    setModal(!modal);
  };

  const shortName = name.substring(0, 70) + "...";
  const shortReference = reference.substring(0, 100) + "...";
  return (
    <div
      className="transaction-vendor-invoice-container"
      onClick={toggleTransactionModal}
    >
      <div className="transaction-first-card-vendor">
        <h2 className="transaction-invoice-name-vendor">{shortName}</h2>
        <h4 className="transaction-invoice-reference-vendor">
          {shortReference}
        </h4>
      </div>
      <div className="transaction-second-card-vendor">
        <h4
          className="transaction-card-price-vendor"
          style={{ color: type === "Debit" ? "#BF3030" : "#818181" }}
        >
          {`${type === "Debit" ? "-" : "+"}₦${price}`}
        </h4>
        <h2 className="transaction-invoice-date-vendor">{date}</h2>
        <h4 className="transaction-card-time-vendor">{time}</h4>
      </div>

      <VendorTransactionModalCard
        toggleChange={toggleTransactionModal}
        modal={modal}
        title={name}
        amount={price}
        date={date}
        time={time}
        content={reference}
      />
    </div>
  );
};
