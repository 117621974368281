import { AnimatePresence } from "framer-motion";
import React, { useRef, useState } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import "../../../styles/seller/invoice/dropdown-select.scss";
import { FadeTransition } from "../../FadeTransition";
import { DownwardArrowIcon } from "../../icons";

const FilterOptionDropdown = (props) => {
  const { selected, setSelected, option, style, height } = props;

  const [isOpen, setIsOpen] = useState(false);

  function toggler() {
    setIsOpen(!isOpen);
  }

  const ref = useRef(null);

  const bgColor = (selected) => {
    switch (selected) {
      case "Pending Invoices":
        return "#EAEBFF";
      case "Approved Invoices":
        return "#E1FCFF";
      case "Sold Invoices":
        return "#E0FFED";
      case "Closed Invoices":
        return "#F7E1FF";
      case "Due Invoices":
        return "#D0E5FF";
      case "Rejected Invoice":
        return "#F7E0E0";
      default:
        return "#FFFFFF";
    }
  };

  //   return (
  //     <div className="dropdown mobile-hide">
  //         <div
  //             style={{background: bgColor(selected)}{style}}
  //             className="dropdown-btn"
  //             onClick={toggler}
  //         >
  //             {selected}
  //            <span ><DownwardArrowIcon/></span>
  //         </div>
  // {isOpen  &&  (<div className="dropdown-content" style={{height:height}}>

  //     {option.map(option=>(
  //         <div onClick={()=>{
  //             setSelected(option)
  //             setIsOpen(false)
  //         }}
  //         className="dropdown-item">
  //                 {option}

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className="dropdown mobile-hide">
      <div
        className="dropdown-btn"
        style={{ background: bgColor(selected) }}
        ref={ref}
        onClick={toggler}
      >
        {selected}
        <span>
          <DownwardArrowIcon />
        </span>
      </div>
      <AnimatePresence>
        {isOpen && (
          <FadeTransition>
            <div className="dropdown-content" style={{ height: height }}>
              {option.map((option) => (
                <div
                  onClick={() => {
                    setSelected(option);
                    setIsOpen(false);
                  }}
                  className="dropdown-item"
                >
                  {option}
                </div>
              ))}
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FilterOptionDropdown;
