import { CloseIcon } from "../../icons/index";
import "../../../styles/vendor/vendor-invoice/vendor-wallet-deduct-approval-modal.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { NoInvoiceIcon } from "../../icons/index";
import { Link } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { useState } from "react";
import { Toolkit } from "../../general-component/Toolkit";
import { ModalLoader } from "../../general-component/ModalLoader";

export const VendorWalletDeductApprovalModal = ({
  toggleChange,
  modal,
  price,
  url,
  id,
  loadData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleConfirm = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/vendor_wallet_payment/${token}/${id}`,
        config
      );

      if (!data.success) {
        setError(data.message);
        setTimeout(() => {
          setError(null);
        }, 2000);
        return;
      }

      setSuccess(true);
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 2000);
      setTimeout(() => {
        toggleChange();
        loadData();
      }, 2500);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition>
            <div className="vendor-wallet-modal">
              <div onClick={toggleChange} className="vendor-wallet-overlay">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  className="vendor-wallet-modal-card"
                  onClick={(evt) => evt.stopPropagation()}
                  transition={{ type: "spring" }}
                >
                  <>
                    <div className="vendor-wallet-modal-head">
                      <h3 className="vendor-wallet-modal-header">
                        Wallet Withdrawal
                      </h3>
                      <span
                        onClick={toggleChange}
                        className="vendor-wallet-close"
                      >
                        <CloseIcon color="#D13D2A" />
                      </span>
                    </div>
                    <div className="vendor-wallet">
                      <p className="wallet-statement-holder ">
                        Confirm the Deduction of{" "}
                        <span className="effect-color">N{price} </span>
                        from Your Quidroo Wallet for the Payment of this
                        invoice.
                      </p>

                      <span className="wallet-invoice-icon-holder">
                        <NoInvoiceIcon />
                      </span>

                      <div className="wallet-yes-no-holder">
                        <button
                          className="wallet-confirm-btn no"
                          onClick={toggleChange}
                        >
                          Cancel
                        </button>

                        <button
                          className="wallet-confirm-btn yes"
                          onClick={handleConfirm}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                    <p className="last-statement">
                      To preview Invoice,
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="vendor-invoice-confirm-link"
                      >
                        Click here
                      </a>
                    </p>
                  </>
                  {/* )} */}
                </motion.div>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {success && <Toolkit text="Amount Deducted Successfully" />}
      </AnimatePresence>

      <AnimatePresence>{error && <Toolkit text={error} />}</AnimatePresence>

      {isLoading && <ModalLoader />}
    </>
  );
};
