import { validateAccountNumber } from "./validateAccountNumber";
import { validateAmount } from "./validateAmount";
import { validateDate } from "./validateDate";
import { validatePassportNumber } from "./validatePassportNumber";
import { validatePostalCode } from "./validatePostalCode";
import { validateText } from "./validateText";

/**
 * validateAccountDetails checks if all fields in investor account details form to see if they are valid.
 *
 * Returns object with error messages for fields that contain errors.
 *
 */
export function validateAccountDetails({ accountNo, accountName, bank }) {
  const n = validateAccountNumber(accountNo);
  const a = validateText(accountName);
  const b = validateText(bank);

  const v = {
    isValid: true,
  };

  if (!n.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      accountNo: n.error,
    };
  }

  if (!a.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      accountName: "Please, enter your account name",
    };
  }

  if (!b.isValid) {
    v.isValid = false;
    v.error = {
      ...v.error,
      bank: "Please, enter your bank name",
    };
  }

  return v;
}
