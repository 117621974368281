export const NoInvoiceIcon = () => {
  return (
    <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.6471 8.5835L25.5791 0.457963C25.4646 0.343406 25.3504 0.286327 25.2358 0.229248V8.86969H33.9337C33.8191 8.75513 33.7617 8.64057 33.6471 8.58349L33.6471 8.5835Z" fill="#414141"/>
      <path d="M31.7588 30.7858C32.5599 30.7858 33.361 30.8429 34.105 31.0145L34.1046 11.9023H23.6902C22.832 11.9023 22.1451 11.2158 22.1451 10.3572V0H1.5451C0.686931 0 0 0.686543 0 1.5451V42.2872C0 43.1454 0.686543 43.8323 1.5451 43.8323H20.4284C20.3713 43.3174 20.3138 42.8026 20.3138 42.2301C20.3142 35.9355 25.464 30.7857 31.7583 30.7857L31.7588 30.7858ZM8.46938 8.86971H15.9084C16.7665 8.86971 17.4535 9.55625 17.4535 10.4148C17.4535 11.273 16.7669 11.9599 15.9084 11.9599H8.46938C7.61121 11.9599 6.92428 11.2734 6.92428 10.4148C6.98175 9.55624 7.66829 8.86971 8.46938 8.86971ZM8.46938 16.9952H25.636C26.4942 16.9952 27.1811 17.6818 27.1811 18.5403C27.1811 19.3985 26.4946 20.0854 25.636 20.0854H8.46938C7.61121 20.0854 6.92428 19.3989 6.92428 18.5403C6.98175 17.6818 7.66829 16.9952 8.46938 16.9952ZM8.46938 25.1208H25.636C26.4942 25.1208 27.1811 25.8073 27.1811 26.6659C27.1811 27.5241 26.4946 28.211 25.636 28.211H8.46938C7.61121 28.211 6.92428 27.5244 6.92428 26.6659C6.98175 25.8073 7.66829 25.1208 8.46938 25.1208ZM16.5378 36.2791H8.46938C7.61121 36.2791 6.92428 35.5926 6.92428 34.734C6.92428 33.8758 7.61082 33.1889 8.46938 33.1889H16.5378C17.396 33.1889 18.0829 33.8754 18.0829 34.734C18.0829 35.5926 17.396 36.2791 16.5378 36.2791Z" fill="#414141"/>
      <circle cx="30.9783" cy="41.8477" r="8.15217" fill="#414141"/>
    </svg>

  );
};
