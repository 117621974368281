import React from 'react';

export const PlusIcon = () => {
  return (
        <svg 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
        <path 
            d="M7.11109 0V7.11109H0V8.88891H7.11109V16H8.88891V8.88891H16V7.11109H8.88891V0H7.11109Z" 
            fill="white"
        />
        </svg>
    )
};
