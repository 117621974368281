export const EmptyInvoiceIcon = () => (
  <svg
    width="44"
    height="50"
    viewBox="0 0 44 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3007 10.8749C34.5765 7.32395 33.4995 3.85145 32.085 0.508185C31.9524 0.200516 31.6461 0.000371431 31.3064 0H0.842077C0.560625 0.00148811 0.298447 0.14063 0.142044 0.370908C-0.0139818 0.601566 -0.04345 0.893607 0.0634654 1.14993C2.82015 7.64922 4.23573 14.6234 4.22703 21.6672V42.5007C4.22703 42.7217 4.31618 42.9338 4.47485 43.09C4.63352 43.2463 4.84886 43.3341 5.07326 43.3341H28.7678C28.7636 42.901 28.8006 42.4687 28.8777 42.0424C29.121 40.4922 29.8074 39.0424 30.8561 37.8616C31.9053 36.6812 33.2736 35.8192 34.8013 35.3757C35.317 35.2195 35.847 35.1134 36.3838 35.0591V21.6676C36.3846 18.044 36.0219 14.4291 35.3007 10.8761L35.3007 10.8749ZM13.5356 6.66652H21.998C22.4653 6.66652 22.8442 7.03966 22.8442 7.49986C22.8442 7.96005 22.4653 8.3332 21.998 8.3332H13.5356C13.0683 8.3332 12.6894 7.96005 12.6894 7.49986C12.6894 7.03966 13.0683 6.66652 13.5356 6.66652ZM9.30448 13.3332C9.30448 12.873 9.6834 12.4999 10.1507 12.4999H28.7678C29.2351 12.4999 29.6141 12.873 29.6141 13.3332C29.6141 13.7934 29.2351 14.1666 28.7678 14.1666H10.1507C9.6834 14.1666 9.30448 13.7934 9.30448 13.3332ZM23.6904 37.5001H10.9969C10.5296 37.5001 10.1507 37.1269 10.1507 36.6668C10.1507 36.2066 10.5296 35.8334 10.9969 35.8334H23.6904C24.1577 35.8334 24.5367 36.2066 24.5367 36.6668C24.5367 37.1269 24.1577 37.5001 23.6904 37.5001ZM29.6141 31.6667H10.9969C10.5296 31.6667 10.1507 31.2936 10.1507 30.8334C10.1507 30.3732 10.5296 30 10.9969 30H29.6141C30.0814 30 30.4603 30.3732 30.4603 30.8334C30.4603 31.2936 30.0814 31.6667 29.6141 31.6667ZM29.6141 25.8333H10.9969C10.5296 25.8333 10.1507 25.4602 10.1507 25C10.1507 24.5398 10.5296 24.1667 10.9969 24.1667H29.6141C30.0814 24.1667 30.4603 24.5398 30.4603 25C30.4603 25.4602 30.0814 25.8333 29.6141 25.8333ZM29.6141 20H10.9969C10.5296 20 10.1507 19.6268 10.1507 19.1666C10.1507 18.7064 10.5296 18.3333 10.9969 18.3333H29.6141C30.0814 18.3333 30.4603 18.7064 30.4603 19.1666C30.4603 19.6268 30.0814 20 29.6141 20Z"
      fill="#414141"
    />
    <path
      d="M37.23 36.6665C35.6093 36.6691 34.0434 37.2439 32.8167 38.2867C31.59 39.3299 30.7838 40.7715 30.5448 42.35C30.4858 42.6744 30.4575 43.0036 30.4601 43.3332C30.4601 45.1014 31.1734 46.7972 32.4431 48.0472C33.7125 49.2976 35.4344 49.9999 37.23 49.9999C39.0255 49.9999 40.7475 49.2976 42.0169 48.0472C43.2866 46.7972 43.9998 45.1014 43.9998 43.3332C43.9998 41.565 43.2866 39.8693 42.0169 38.6193C40.7475 37.3689 39.0255 36.6665 37.23 36.6665ZM39.8533 43.8582C40.236 44.0978 40.3588 44.5918 40.1321 44.9788C39.9051 45.3653 39.409 45.507 39.0071 45.2998L38.0762 44.7749V45.8333C38.0762 46.2935 37.6973 46.6666 37.23 46.6666C36.7627 46.6666 36.3837 46.2935 36.3837 45.8333V44.7749L35.4529 45.2998C35.2583 45.4218 35.0215 45.4602 34.7974 45.4062C34.5734 45.3519 34.3815 45.2105 34.2663 45.0137C34.1507 44.8173 34.122 44.5826 34.1862 44.3645C34.2504 44.1465 34.4023 43.9635 34.6067 43.8582L35.5375 43.3333L34.6067 42.8084C34.4023 42.7031 34.2504 42.52 34.1862 42.302C34.122 42.084 34.1507 41.8493 34.2663 41.6528C34.3815 41.456 34.5734 41.3147 34.7974 41.2604C35.0215 41.2064 35.2583 41.2447 35.4529 41.3668L36.3837 41.8917V40.8333C36.3837 40.3731 36.7627 40 37.23 40C37.6973 40 38.0762 40.3731 38.0762 40.8333V41.8917L39.0071 41.3668C39.409 41.1595 39.9051 41.3013 40.1321 41.6878C40.3588 42.0747 40.236 42.5688 39.8533 42.8084L38.9225 43.3333L39.8533 43.8582Z"
      fill="#414141"
    />
  </svg>
);
