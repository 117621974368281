import React, { useState } from "react";
import { CloseIcon } from "../../icons/index";
import "../../../styles/investor/investor-dashboard-styles/investor-activation-form-modal.scss";
import SelectionInputOption from "../../general-component/SelectionInputOption";
import { OptimizedInput } from "../../form/OptimizedInput";
import { AnimatePresence, motion } from "framer-motion";
import { FadeTransition } from "../../FadeTransition";
import { InvestorActivationSuccessModal } from "./InvestorActivationSuccessModal";
import { countryData } from "../../../constants/countryData";
import { UploadInvoiceFile } from "../../icons/UploadInvoiceFile";
import { validateInvestorActivate } from "../../../util/validate/validateInvestorActivate";
import { LogoIcon } from "../../icons/logo";
import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { ModalLoader } from "../../general-component/ModalLoader";
import { validateAccountDetails } from "../../../util/validate/validateAccountDetails";

export const InvestorActivationModalForm = (props) => {
  const { toggleChange, modal, onClose, loadData } = props;

  const [country, setCountry] = useState("Select your Country");
  const [state, setState] = useState("Select your state");
  const [city, setCity] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");

  //account info
  const [accountNo, setAccountNo] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState("");

  const [uploadFileError, setUploadFileError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [passportNumberError, setPassportNumberError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [streetError, setStreetError] = useState(null);
  const [postalCodeError, setPostalCodeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [accountNoError, setAccountNoError] = useState(null);
  const [accountNameError, setAccountNameError] = useState(null);
  const [bankError, setBankError] = useState(null);

  const toggleShow = () => {
    setShow(!show);
  };

  const onLoad = () => {
    setShow(false);
    loadData();
  };

  const handleClose = () => {
    toggleChange();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const { isValid, error } = validateInvestorActivate({
      city: city.trim(),
      passportNo: passportNumber.trim(),
      passport: uploadFile,
      street: street.trim(),
      postalCode: postalCode.trim(),
    });
    if (country === "Select your Country" || state === "Select your state") {
      setHasError(true);
      setTimeout(() => setHasError(false), 1000);
    } else if (!isValid) {
      if (error.city) setCityError(error.city);
      if (error.passportNo) setPassportNumberError(error.passportNo);
      if (error.passport) setUploadFileError(error.passport);
      if (error.street) setStreetError(error.street);
      if (error.postalCode) setPostalCodeError(error.postalCode);
    } else {
      const token = localStorage.getItem("token");
      try {
        onClose();
        setIsLoading(true);
        const formdata = new FormData();
        formdata.append("passport_pics", uploadFile, "passport");
        formdata.append("country", country);
        formdata.append("state", state);
        formdata.append("city", city.trim());
        formdata.append("passport_no", passportNumber.trim());
        formdata.append("street", street.trim());
        formdata.append("postal_code", postalCode.trim());

        if (
          accountName.trim().length ||
          accountNo.trim().length ||
          bank.trim().length
        ) {
          const { isValid, error } = validateAccountDetails({
            accountNo: accountNo.trim(),
            accountName: accountName.trim(),
            bank: bank.trim(),
          });
          if (!isValid) {
            if (error.accountNo) setAccountNoError(error.accountNo);
            if (error.accountName) setAccountNameError(error.accountName);
            if (error.bank) setBankError(error.bank);
            return;
          }
          formdata.append("acc_no", accountNo.trim());
          formdata.append("acc_name", accountName.trim());
          formdata.append("bank", bank.trim());
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = formdata;
        const { data } = await axios.post(
          `${BACKEND_URL}/investor_verification/${token}`,
          body,
          config
        );
        console.log(data);
        setIsLoading(false);
        if (data.success) {
          setShow(true);
          setCountry("Select your Country");
          setState("Select your state");
          setCity("");
          setPassportNumber("");
          setUploadFile("");
          setStreet("");
          setAccountNo("");
          setAccountName("");
          setBank("");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <AnimatePresence>
        {modal && (
          <FadeTransition absolute>
            <div className="investor-activation-form-modal">
              <div
                onClick={onClose}
                className="investor-activation-form-overlay"
              >
                <motion.div
                  className="investor-activation-modal-card"
                  onClick={(evt) => evt.stopPropagation()}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{ type: "spring" }}
                >
                  <section className="mobile-header window-hide">
                    <span className="investor-form ">Investor Form</span>
                  </section>
                  <span className="investor-form mobile-hide">
                    Investor Form
                  </span>
                  <form onSubmit={handleSubmit} className="modal-content">
                    <div className="row one">
                      <span className="investor-form-carrier first">
                        <h4> Country</h4>
                        <SelectionInputOption
                          selected={country}
                          setSelected={setCountry}
                          onChange={() => setState("Select your state")}
                          option={countryData.map((country) => country.name)}
                          contentHeight={60}
                        />
                      </span>
                    </div>
                    <span
                      onClick={onClose}
                      className="trans-modal-presser activation"
                    >
                      <CloseIcon />
                    </span>

                    <div className="row three">
                      <span className="investor-form-carrier">
                        <h4>State </h4>
                        <SelectionInputOption
                          selected={state}
                          setSelected={setState}
                          option={
                            countryData.find((c) => c.name === country)
                              ?.states ?? ["Select your state"]
                          }
                          contentHeight={110}
                        />
                      </span>
                    </div>

                    <div className="row three">
                      <span className="investor-form-carrier">
                        <OptimizedInput
                          title="City"
                          name="City"
                          placeholder="Enter a city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                          maxlength="30"
                          mt={0}
                          error={cityError}
                          onFocus={() => cityError && setCityError(null)}
                        />
                      </span>
                    </div>

                    <div className="row three">
                      <span className="investor-form-carrier">
                        <OptimizedInput
                          title="Street"
                          name="Street"
                          placeholder="Enter your street"
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                          type="text"
                          maxlength="30"
                          mt={0}
                          error={streetError}
                          onFocus={() => streetError && setStreetError(null)}
                        />
                      </span>
                    </div>

                    <div className="row three">
                      <span className="investor-form-carrier">
                        <OptimizedInput
                          title="Postal Code"
                          name="Postal Code"
                          placeholder="Enter your postal code"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          type="text"
                          maxlength="30"
                          mt={0}
                          error={postalCodeError}
                          onFocus={() =>
                            postalCodeError && setPostalCodeError(null)
                          }
                        />
                      </span>
                    </div>

                    <div className="row four">
                      <span className="investor-form-carrier">
                        <OptimizedInput
                          title="Passport Number"
                          name="Passport Number"
                          placeholder="E.g 01234567AD"
                          value={passportNumber}
                          onChange={(e) => setPassportNumber(e.target.value)}
                          type="text"
                          maxlength="30"
                          mt={0}
                          error={passportNumberError}
                          onFocus={() =>
                            passportNumberError && setPassportNumberError(null)
                          }
                        />
                      </span>
                    </div>

                    <div className="row four">
                      <span className="upload-form-carrier">
                        <h4 className="file-header">Upload Passport</h4>
                        <label for="upload-file">
                          <span className="file-placeholder-carrier">
                            {uploadFile ? (
                              <p className="file-placeholder">
                                {uploadFile.name}
                                <span className="shift-present">
                                  <UploadInvoiceFile color="#565cd6" />
                                </span>
                              </p>
                            ) : (
                              <p className="file-placeholder">
                                File type - (png, jpeg, docx, xls, pdf, etc.){" "}
                                <span className="shift">
                                  <UploadInvoiceFile />
                                </span>
                              </p>
                            )}
                          </span>

                          <input
                            className="file-input"
                            name="Upload File"
                            // value={uploadFile}
                            onChange={(evt) => {
                              if (evt.target.files) {
                                const file = evt.target.files[0];
                                if (file.size > 2621440) {
                                  setUploadFileError(
                                    "File should not exceed 2.5mb"
                                  );
                                } else {
                                  setUploadFile(evt.target.files[0]);
                                }
                              }
                            }}
                            onClick={() =>
                              uploadFileError && setUploadFileError(null)
                            }
                            type="file"
                            id="upload-file"
                          />
                          {uploadFileError && (
                            <div className="error-text">{uploadFileError}</div>
                          )}
                        </label>
                      </span>
                    </div>

                    {country === "Nigeria" && (
                      <>
                        <div className="optional-header">
                          Bank Information (optional)
                        </div>
                        <div className="row four">
                          <span className="investor-form-carrier">
                            <OptimizedInput
                              title="Account Number"
                              name="Account Number"
                              placeholder="Enter your 10-digit account number"
                              value={accountNo}
                              onChange={(e) => setAccountNo(e.target.value)}
                              type="text"
                              maxlength="10"
                              mt={0}
                              error={accountNoError}
                              onFocus={() =>
                                accountNoError && setAccountNoError(null)
                              }
                            />
                          </span>
                        </div>

                        <div className="row four">
                          <span className="investor-form-carrier">
                            <OptimizedInput
                              title="Account Name"
                              name="Account Number"
                              placeholder="Enter the name on the account"
                              value={accountName}
                              onChange={(e) => setAccountName(e.target.value)}
                              type="text"
                              mt={0}
                              error={accountNameError}
                              onFocus={() =>
                                accountNameError && setAccountNameError(null)
                              }
                            />
                          </span>
                        </div>

                        <div className="row four">
                          <span className="investor-form-carrier">
                            <OptimizedInput
                              title="Bank"
                              name="Bank"
                              placeholder="e.g. U.B.A"
                              value={bank}
                              onChange={(e) => setBank(e.target.value)}
                              type="text"
                              mt={0}
                              error={bankError}
                              onFocus={() => bankError && setBankError(null)}
                            />
                          </span>
                        </div>
                      </>
                    )}

                    <div className="row five">
                      <span className="investor-form-carrier">
                        {/* onClick={toggleShow} */}
                        <button className="submit-btn">Submit</button>
                      </span>
                    </div>
                  </form>
                </motion.div>

                <AnimatePresence>
                  {hasError && (
                    <motion.div
                      className="failure-modal"
                      initial={{ opacity: 0, y: "-100%" }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: "-100%" }}
                      transition={{ duration: 0.4 }}
                    >
                      <div>Select your country and state</div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </FadeTransition>
        )}
      </AnimatePresence>
      {isLoading && <ModalLoader />}

      <InvestorActivationSuccessModal modal={show} onLoad={onLoad} />
    </>
  );
};
