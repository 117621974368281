import React, { useEffect, useState } from "react";
import "../../styles/investor/investor-dashboard-styles/investor-dashboard.scss";
import { InvestorStatusBar } from "../../components/investor-component/InvestorStatusBar";
import { PlaceHolderHeader } from "../../components/general-component";
import { ActiveBids } from "../../components/investor-component/dashboard/ActiveBids";
import { NewInvoices } from "../../components/investor-component/dashboard/NewInvoices";
import { InvestorBalanceCard } from "../../components/investor-component/dashboard/InvestorBalanceCard";
import { NoTransactionIcon, PlusIcon } from "../../components/icons";
import { InvestorTransactionCard } from "../../components/investor-component/dashboard/InvestorTransactionCard";
import { data1 } from "../../components/seller-component/dashboard-component/dummyData";
import { MobileDepositWithdrawModal } from "../../components/investor-component/dashboard/MobileDepositWithdrawModal";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { formatAmount } from "../../util/formatAmount";
import moment from "moment";
import { EmptyInvoiceIcon } from "../../components/icons/EmptyInvoiceIcon";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";

const InvestorIndex = () => {
  const [modal, setModal] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [expire, setExpire] = useState(false);

  const controlModal = () => {
    setModal(!modal);
  };

  // withdrawal function
  const fundWithdrawal = () => {
    console.log("where is myCash");
  };
  // withdrawal function
  const fundDeposit = () => {
    console.log("Here is myCash");
  };

  const loadInvestor = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/investor_dashboard/${token}`,
        config
      );

      console.log(data, "dashboard_data");
      console.log(token, "token")
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setDashboardData(data);
    } catch (error) {
      console.error(error);
    }
  };

  // const userCurrency =
  //     localStorage.getItem("userCurrency") &&
  //     JSON.parse(localStorage.getItem("userCurrency"));

  useEffect(() => {
    loadInvestor();
    // console.log(userCurrency)
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired />;

  return (
    <>
      <section>
        <InvestorStatusBar
          ownerName={`${dashboardData?.user_details.firstname} ${dashboardData?.user_details.lastname} Dashboard`}
          pending={dashboardData?.user_details?.providedVerificationDetails}
          activated={dashboardData?.user_details.verified}
          loadData={loadInvestor}
        />
      </section>
      <section className="dashboard-investor-holder">
        <div className="dashboard-first-section">
          <div className="available-bids-container">
            <div className="mobile-hide">
              <PlaceHolderHeader
                text="Active Bids"
                width="100%"
                aligner="center"
              />
            </div>
            <div className="window-hide">
              <PlaceHolderHeader
                text="Active Bids"
                width="100%"
                aligner="flex-start"
              />
            </div>

            <div className="scroller-section">
              {dashboardData?.biddedInvoices.length ? (
                dashboardData.biddedInvoices.map((invoice, index) => (
                  <ActiveBids
                    key={invoice.id}
                    id={invoice.bid_id}
                    amount={invoice.invoice_amount}
                    amountUSD={invoice.invoice_amount_USD}
                    payRate={invoice.max_discount.toFixed(2)}
                    dueDate={invoice.due_date}
                    vendor={invoice.vendor_name}
                    payAmount={invoice.payAmount}
                    invoiceName={invoice["invoice_name "]}
                    bidderNumber={invoice.no_of_bidders}
                    ROI={invoice.max_discount.toFixed(2)}
                    dueTime={invoice.bid_close_time}
                    url={invoice.invoice_url}
                    loadData={loadInvestor}
                    maxPayable={invoice.maxPayable}
                  />
                ))
              ) : (
                <div className="empty-invoice-container">
                  <div className="empty-invoice-wrapper">
                    <div className="icon-wrapper">
                      <EmptyInvoiceIcon />
                    </div>
                    You currently do not have any active bids
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="new-invoice-card-container">
            <div className="mobile-hide">
              <PlaceHolderHeader
                text="New Invoice"
                width="100%"
                aligner="center"
              />
            </div>
            <div className="window-hide">
              <PlaceHolderHeader
                text="New Invoice"
                width="100%"
                aligner="flex-start"
              />
            </div>

            <div className="new-invoice-scroller">
              {dashboardData?.available_invoices.length ? (
                dashboardData.available_invoices.map((invoice, index) => (
                  <NewInvoices
                    id={invoice.id}
                    key={invoice.id}
                    amount={formatAmount(invoice.invoice_amount)}
                    amountUSD={formatAmount(invoice.invoice_amount_USD)}
                    roi={invoice.max_discount.toFixed(2)}
                    dueDate={moment(invoice.due_date).format("MMM Do, YYYY")}
                    vendor={invoice.vendor_name}
                    bidders={invoice.no_of_bidders}
                    invoiceName={invoice["invoice_name "]} 
                    dueTime={invoice.bid_close_time}
                    loadInvestor={loadInvestor}
                    verified={dashboardData?.user_details.verified}
                  />
                ))
              ) : (
                <div className="empty-invoice-container">
                  <div className="empty-invoice-wrapper">
                    <div className="icon-wrapper">
                      <EmptyInvoiceIcon />
                    </div>
                    You do not have any new invoices available
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard-second-section">
          <div className="investor-balance-card-case">
            <InvestorBalanceCard
              loadData={loadInvestor}
              nairaMoney={formatAmount(dashboardData?.user_details.NG_balance)}
              country={dashboardData?.user_details.country}
              USDMoney={formatAmount(dashboardData?.user_details.USD_balance)}
              cashWithdrawal={fundWithdrawal}
              cashDeposit={fundDeposit}
              userDetails={dashboardData?.user_details}
              memo_field={dashboardData?.user_details.memo_field}
              wallet_address={dashboardData?.user_details.wallet_address}
              email={dashboardData?.user_details.email}
            />
          </div>
          <div className="investor-transaction-header mobile-hide ">
            <PlaceHolderHeader text="Recent Transactions" width="100%" />
          </div>
          <div className="investor-dashboard-transaction-card mobile-hide">
            {dashboardData && dashboardData?.transaction.length ? (
              dashboardData?.transaction.map((item, index) => (
                <InvestorTransactionCard
                  key={item.tx_id}
                  title={item.transaction_note}
                  amount={formatAmount(item.amount)}
                  currency={item.currency}
                  date={moment(item.created_at).format("MMM Do, YYYY")}
                  time={moment(item.created_at).format("hh:mm a")}
                  content={item.tx_hash}
                  isDebit={item.transaction_type === "Debit"}
                  // account={item.account}
                />
              ))
            ) : (
              <div className="no-data-container">
                <NoTransactionIcon />
                <p className="no-data-text">No recent transactions</p>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="window-hide">
        <button
          onClick={controlModal}
          className="investor-mobile-uploader-btn window-hide"
        >
          <PlusIcon />
        </button>

        <MobileDepositWithdrawModal controlModal={controlModal} modal={modal} />
      </div>
    </>
  );
};

export default InvestorIndex;
